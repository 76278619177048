import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Hello from "@hellodarwin/icons/dist/icons/Hello";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/app-hooks";
import RecentProjectsSection from "../components/home/recent-projects-section";
import RecentResourcesSection from "../components/home/recent-resources-section";
import RecentRoadmapProgramsSection from "../components/program/recent-roadmap-programs-section";
import RoadmapDashboard from "../components/program/roadmap-dashboard";
import {
  fetchGrantsTotals,
  selectGrantsTotals,
  selectGrantsTotalsLoading,
} from "../features/api/slices/grants-slice";
import {
  selectProfile,
  selectProfileLoading,
} from "../features/api/slices/profile-slice";
import {
  GetRoadmapPrograms,
  selectAllRoadmapPrograms,
  selectProgramIsLoading,
} from "../features/api/slices/programs-slice";
import {
  fetchProjects,
  selectProjects,
  selectProjectsLoading,
} from "../features/api/slices/projects-slice";
import { useClientApi } from "../features/api/use-client-api";

const ClientHomePage = () => {
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const profile = useAppSelector(selectProfile);
  const isProfileLoading = useAppSelector(selectProfileLoading);

  const programs = useAppSelector(selectAllRoadmapPrograms);
  const isProgramsLoading = useAppSelector(selectProgramIsLoading);

  const projects = useAppSelector(selectProjects);
  const isProjectsLoading = useAppSelector(selectProjectsLoading);

  const grantsTotals = useAppSelector(selectGrantsTotals);
  const isGrantsTotalsLoading = useAppSelector(selectGrantsTotalsLoading);
  const { selectedLocale } = useLocale();

  useEffect(() => {
    dispatch(GetRoadmapPrograms({ api, locale: selectedLocale }));
    dispatch(fetchProjects({ api, limit: 5 }));
    dispatch(fetchGrantsTotals({ api }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  if (isProfileLoading) return <></>;

  return (
    <PageLayout
      app="client"
      title={
        <Div flex="row" align="center" gap={8} wrap="wrap">
          <Hello style={{ marginBottom: 6 }} />
          <Typography.Title
            level={1}
            noMargin
            nowrap
            elementTheme="h4"
            {...(profile.contact_name.length > 10 ? {} : { nowrap: true })}
          >
            {`${profile.contact_name}!`}
          </Typography.Title>
        </Div>
      }
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
        },
      ]}
      withoutPaddingRight
    >
      <Div
        flex="row"
        gap={38}
        style={{ maxWidth: "100%" }}
        tablet={{ flex: "column-reverse" }}
      >
        <Div
          flex="column"
          gap={38}
          style={{ width: "calc(100% - 400px - 38px)" }}
          xl={{ style: { width: "calc(100% - 300px - 38px)" } }}
          tablet={{ style: { width: "100%" } }}
        >
          <RecentRoadmapProgramsSection
            programs={programs}
            isLoading={isProgramsLoading}
          />
          <RecentProjectsSection
            projects={projects}
            isLoading={isProjectsLoading}
          />

          <RecentResourcesSection />
        </Div>
        <Div
          flex="row"
          align="stretch"
          style={{
            height: "100%",
            width: 400,
            flexShrink: 0,
            flexGrow: 0,
            position: "sticky",
            top: 20,
          }}
          xl={{ style: { width: 300 } }}
          tablet={{
            style: { width: "100%", position: "initial", height: "auto" },
          }}
        >
          <Container
            style={{
              height: "100%",
              border: `1px solid ${theme.colors.primary}`,
            }}
            noPadding
          >
            <RoadmapDashboard
              programs={programs}
              grantsTotals={grantsTotals}
              isLoadingPrograms={isProgramsLoading}
              isLoadingTotals={isGrantsTotalsLoading}
            />
          </Container>
        </Div>
      </Div>
    </PageLayout>
  );
};

export default ClientHomePage;

