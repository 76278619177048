import React, { CSSProperties, PropsWithChildren } from "react";
import Loading from "../../loading";
import { ListContainer, ListContent, ListItemContainer } from "./styles";

export type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm";
export type ColumnCount = number;
export type ListItemLayout = "horizontal" | "vertical";
export type Gutter = number | undefined | Partial<Record<Breakpoint, number>>;

export interface ListGridType {
  gutter?: Gutter;
  column?: ColumnCount;
  sm?: ColumnCount;
  md?: ColumnCount;
  lg?: ColumnCount;
  xl?: ColumnCount;
  xxl?: ColumnCount;
}

export interface ListProps<T> {
  bordered?: boolean;
  divider?: boolean;
  className?: string;
  rootClassName?: string;
  id?: string;
  layout?: ListItemLayout;
  mobileLayout?: ListItemLayout;
  styles?: {
    content?: CSSProperties;
    container?: CSSProperties;
  };
  dataSource: T[];
  grid?: ListGridType;
  loading?: boolean;
  renderItem?: (item: T, index: number) => React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  extra?: React.ReactNode;
}

const List = <T,>({
  bordered = false,
  divider,
  className,
  rootClassName,
  id,
  layout,
  styles,
  dataSource,
  grid,
  loading,
  renderItem,
  header,
  footer,
  extra,
}: ListProps<T>) => {
  return (
    <ListContainer
      className={rootClassName}
      style={{ width: "100%", ...styles?.container }}
    >
      {header}
      <ListContent
        id={id}
        className={className}
        $layout={layout}
        $bordered={bordered}
        $divider={divider}
        style={{ ...styles?.content }}
        $grid={grid}
      >
        {loading ? (
          <Loading />
        ) : (
          React.Children.toArray(
            dataSource?.map((item, index) =>
              renderItem ? (
                renderItem(item, index)
              ) : (
                <React.Fragment key={index}>
                  {JSON.stringify(item)}
                </React.Fragment>
              )
            )
          )
        )}
      </ListContent>
      {extra}
      {footer}
    </ListContainer>
  );
};

export interface ListItemProps {
  style?: CSSProperties;
}

export const ListItem = ({
  children,
  style,
}: ListItemProps & PropsWithChildren) => {
  return (
    <div>
      <ListItemContainer
        style={{
          marginBottom: 32,
          alignSelf: "stretch",
          height: "100%",
          ...style,
        }}
      >
        {children}
      </ListItemContainer>
    </div>
  );
};

List.Item = ListItem;
export default List;
