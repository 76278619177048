import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantTotalAmounts } from "@hellodarwin/core/lib/features/entities/grants-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Tooltip from "antd/es/tooltip";
import CountUp from "react-countup";

interface GrantsSiderProps {
  grantsTotals: GrantTotalAmounts[];
  isLoading: boolean;
  inversed?: boolean;
}

const RoadmapDashboardSectionBigTotalCount = ({
  grantsTotals,
  isLoading,
  inversed,
}: GrantsSiderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (isLoading) return <></>;

  return (
    <Div flex="row" gap={32} justify="space-between">
      <Tooltip placement="left" title={t(`programs|totals.tooltip`)}>
        <div>
          <Typography
            elementTheme="subtitle2"
            ellipsis
            textAlign="left"
            color={theme.colors.white_1}
          >
            {grantsTotals[0]?.financing_type}
          </Typography>
        </div>
      </Tooltip>

      <Typography
        ellipsis
        overflow
        elementTheme="subtitle2"
        color={inversed ? theme.colors.white_1 : theme.colors.green_1}
        textAlign="right"
      >
        <CountUp
          end={grantsTotals[0]?.total_funding}
          separator={t(`programs|totals.seperator`)}
          prefix={t(`programs|totals.prefix`)}
          suffix={t(`programs|totals.suffix`)}
          duration={1}
        />
      </Typography>
    </Div>
  );
};

export default RoadmapDashboardSectionBigTotalCount;

