import { IconProps } from "../features/entities/general";

const Industry = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="industry">
          <path
            id="Vector"
            d="M18.1864 30.2222V19.1111C18.1864 18.8164 18.3017 18.5338 18.5069 18.3254C18.7121 18.1171 18.9904 18 19.2806 18H23.6571C23.9473 18 24.2256 18.1171 24.4308 18.3254C24.636 18.5338 24.7513 18.8164 24.7513 19.1111V30.2222M18.1864 30.2222H1.93485M18.1864 30.2222H24.7513M24.7513 30.2222H30.0615M28.0337 30.2222V12.9615C28.6152 12.6206 29.1116 12.1484 29.4847 11.5815C29.8577 11.0146 30.0972 10.3681 30.1848 9.69206C30.2724 9.01605 30.2057 8.32864 29.9897 7.68302C29.7738 7.0374 29.4145 6.45089 28.9396 5.96888L27.2036 4.20739C26.7936 3.79053 26.2374 3.55606 25.6572 3.55554H6.33766C5.75749 3.55606 5.20126 3.79053 4.79128 4.20739L3.0567 5.96739C2.58292 6.44997 2.2246 7.03659 2.00935 7.68202C1.7941 8.32745 1.72768 9.01446 1.81522 9.69009C1.90275 10.3657 2.14189 11.0119 2.51421 11.5788C2.88653 12.1458 3.38208 12.6183 3.96265 12.96M3.96265 30.2207V12.963C4.83799 13.4755 5.86098 13.6648 6.85809 13.4988C7.85521 13.3327 8.76507 12.8215 9.43334 12.0518C9.84371 12.5251 10.3488 12.904 10.9148 13.1633C11.4808 13.4226 12.0948 13.5563 12.7158 13.5555C14.0229 13.5555 15.1958 12.9733 15.9982 12.0504C16.4084 12.5239 16.9134 12.9031 17.4794 13.1626C18.0455 13.4222 18.6595 13.5562 19.2806 13.5555C20.5877 13.5555 21.7606 12.9733 22.563 12.0504C23.2315 12.8198 24.1414 13.3307 25.1385 13.4965C26.1356 13.6623 27.1585 13.4727 28.0337 12.96M8.3392 25.7763H13.8099C14.1001 25.7763 14.3784 25.6592 14.5836 25.4508C14.7888 25.2425 14.904 24.9599 14.904 24.6652V19.1111C14.904 18.8164 14.7888 18.5338 14.5836 18.3254C14.3784 18.1171 14.1001 18 13.8099 18H8.3392C8.04902 18 7.77072 18.1171 7.56553 18.3254C7.36034 18.5338 7.24506 18.8164 7.24506 19.1111V24.6667C7.24506 25.2815 7.73524 25.7763 8.3392 25.7763Z"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};

export default Industry;
