import Layout from "antd/es/layout";
import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import { AppType } from "../../../../features/entities";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useAppData } from "../../../../features/providers/app-provider";
import theme from "../../../../theme";
import ErrorNetwork from "../../../error/network";
import Loading from "../../../loading";
import { AppLayoutContainer, GeneralLayout, MainLayout } from "./styles";

const { Content } = Layout;

export interface AppLayoutProps extends PropsWithChildren {
  app?: AppType;
  bottomNav?: ReactNode;
  needOnBoarding?: boolean;
  onBoardingContent?: ReactNode;
  noContainerPaddingTablet?: boolean;
  error?: string;
  isSimpleDesign?: boolean;
  isLoading?: boolean;
  navigate: (path: string, options?: any) => void;
}

const AppLayout = ({
  app,
  children,
  needOnBoarding,
  onBoardingContent,
  error,
  isLoading,
  navigate,
}: AppLayoutProps) => {
  const { nav, bottomNav } = useAppData();
  const containerClasses = classNames("hd-content-container");
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  return (
    <AppLayoutContainer className={app}>
      <GeneralLayout hasSider={!isTablet}>
        {nav}

        {error ? (
          <MainLayout
            className={containerClasses}
            style={{ minHeight: "100vh", height: "auto" }}
          >
            <Content style={{ padding: "0 96px" }}>
              <ErrorNetwork errorMessage={error} navigate={navigate} />
            </Content>
          </MainLayout>
        ) : isLoading ? (
          <MainLayout
            className={containerClasses}
            style={{
              minHeight: "100vh",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </MainLayout>
        ) : needOnBoarding ? (
          <>{onBoardingContent}</>
        ) : (
          <MainLayout
            className={containerClasses}
            style={{ minHeight: "100vh", height: "auto" }}
          >
            {children}
          </MainLayout>
        )}
        {bottomNav}
      </GeneralLayout>
    </AppLayoutContainer>
  );
};

export default AppLayout;

