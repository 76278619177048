import {
  GatsbyImage,
  GatsbyImageProps,
  ImageDataLike,
  getImage,
} from "gatsby-plugin-image";
import { CSSProperties } from "react";
import { ContentfulAsset } from "../../../features/entities/resources-entities";
import { StandardImage } from "./styles";

type MediaAssetProps = {
  asset: ContentfulAsset;
  className?: string;
  width?: number;
  height?: number;
  loading?: "eager" | "lazy" | undefined;
  style?: CSSProperties;
  onClick?: (...attr: any) => void;
  objectFit?: CSSProperties["objectFit"];
} & Omit<GatsbyImageProps, "image" | "alt">;

const MediaAsset = ({
  loading,
  className,
  asset,
  width,
  height,
  style,
  onClick,
  objectFit,
  ...props
}: MediaAssetProps) => {
  const image = getImage(asset as ImageDataLike);
  const path = asset?.file?.url || asset?.url;
  const alt = (asset as ContentfulAsset)?.title;

  return !!image ? (
    <GatsbyImage
      {...props}
      className={`gatsby-image ${className ? className : ""}`}
      alt={`${alt}`}
      image={{
        ...image,
        width: width || image.width,
        height: height || image.height,
      }}
      objectFit={objectFit}
      style={style}
      onClick={onClick}
      autoFocus
      loading={loading}
    />
  ) : !!path ? (
    <StandardImage
      className={`standard-image ${className ? className : ""}`}
      src={`${path}`}
      alt={`${alt}`}
      loading={loading}
      width={width}
      height={height}
      style={{ objectFit: objectFit, ...style }}
      onClick={onClick}
    />
  ) : (
    <></>
  );
};

export default MediaAsset;
