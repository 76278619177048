import Div from "@hellodarwin/core/lib/components/common/div";
import Loading from "@hellodarwin/core/lib/components/loading";
import { GrantTotalAmounts } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import RoadmapDashboardSectionFooter from "./roadmap-dashboard-section-footer";
import RoadmapDashboardSectionHeader from "./roadmap-dashboard-section-header";
import RoadmapDashboardSectionTotalsList from "./roadmap-dashboard-section-totals-list";

interface GrantsSiderProps {
  grantsTotals: GrantTotalAmounts[];
  isLoading: boolean;
}

const GrantsSummarySider = (props: GrantsSiderProps) => {
  const { isLoading } = props;
  const { t } = useTranslation();
  return (
    <Div flex="column" gap={24}>
      <RoadmapDashboardSectionHeader
        title={t(`programs|totals.grantsTitle`)}
        {...props}
      />
      {!isLoading ? (
        <RoadmapDashboardSectionTotalsList {...props} />
      ) : (
        <Loading />
      )}
      <RoadmapDashboardSectionFooter
        buttonText={t(`programs|totals.grantsButton`)}
        buttonLink={`/funding-explorer`}
      />
    </Div>
  );
};

export default GrantsSummarySider;

