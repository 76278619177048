import { IconProps } from "../features/entities/general";

const HdAcademy = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.28233 12.9835C4.90144 16.0794 4.67449 19.1945 4.60242 22.3157C8.58881 24.0839 12.4057 26.2553 16.0003 28.7999C19.5953 26.2552 23.4127 24.0838 27.3995 22.3157C27.3274 19.1945 27.1004 16.0794 26.7196 12.9835M26.7196 12.9835C27.9313 12.5512 29.1595 12.1513 30.4003 11.7866C25.8813 8.42288 21.0574 5.54637 16.0003 3.19989C10.9432 5.54687 6.11926 8.42386 1.60028 11.7881C2.83725 12.1507 4.0646 12.5493 5.28094 12.9835C8.97636 14.3026 12.5619 15.9462 16.0003 17.8974C19.4383 15.9463 23.0247 14.3027 26.7196 12.9835ZM8.73035 20.119C9.00579 20.119 9.26996 20.0029 9.46472 19.796C9.65949 19.5892 9.76891 19.3088 9.76891 19.0163C9.76891 18.7238 9.65949 18.4433 9.46472 18.2365C9.26996 18.0297 9.00579 17.9135 8.73035 17.9135C8.45491 17.9135 8.19074 18.0297 7.99598 18.2365C7.80121 18.4433 7.69179 18.7238 7.69179 19.0163C7.69179 19.3088 7.80121 19.5892 7.99598 19.796C8.19074 20.0029 8.45491 20.119 8.73035 20.119ZM8.73035 20.119V14.7156C11.0799 13.1648 13.5068 11.7503 16.0003 10.478M6.29735 27.4604C7.07 26.642 7.6827 25.6696 8.10024 24.5989C8.51778 23.5283 8.73192 22.3806 8.73035 21.2218V19.0163"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default HdAcademy;
