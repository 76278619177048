import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import { Link } from "react-router-dom";

interface RoadmapDashboardSectionFooterProps {
  buttonText: string;
  buttonLink: string;
}

const RoadmapDashboardSectionFooter = ({
  buttonText,
  buttonLink,
}: RoadmapDashboardSectionFooterProps) => {
  return (
    <Div flex="row" align="center" justify="flex-end" style={{ marginTop: 12 }}>
      <Link to={buttonLink}>
        <Button size="small" withArrowRight>
          {buttonText}
        </Button>
      </Link>
    </Div>
  );
};

export default RoadmapDashboardSectionFooter;

