import DarwinError from "@hellodarwin/icons/dist/graphics/DarwinError";
import { useTranslations } from "../../features/providers/translations-provider";
import { useTheme } from "../../plugins/styled";
import Button from "../common/button";
import Div from "../common/div";
import Typography from "../common/typography";

type ErrorNotFoundProps = {
  navigate: (path: string, options?: any) => void;
};

const ErrorNotFound = ({ navigate }: ErrorNotFoundProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <Div
      className="network-error"
      flex="column"
      gap={8}
      align="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <DarwinError width={600} />

      <Typography.Title
        level={4}
        color={theme.colors.purple_2}
        textAlign="center"
      >
        {t("errorsPage|problemPageTitle")}
      </Typography.Title>
      <Typography textAlign="center">{t(`errorsPage|NotFound`)}</Typography>
      <Button size={"large"} onClick={goToHome}>
        {t("errorsPage|goToHome")}
      </Button>
    </Div>
  );
};

export default ErrorNotFound;

