import { IconProps } from "../features/entities/general";

const TeamSize = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9392 26.304C21.059 26.6342 22.2192 26.8012 23.3852 26.8C25.2604 26.8027 27.1112 26.3685 28.7952 25.5306C28.845 24.3356 28.5098 23.1566 27.8405 22.1724C27.1712 21.1882 26.2042 20.4525 25.0862 20.0766C23.9681 19.7008 22.7599 19.7055 21.6447 20.0898C20.5295 20.4742 19.568 21.2173 18.906 22.2066M19.9392 26.304V26.3C19.9392 24.816 19.5637 23.42 18.906 22.2066M19.9392 26.304V26.4453C17.4127 27.9908 14.5182 28.805 11.5689 28.8C8.50887 28.8 5.64571 27.94 3.20133 26.4453L3.20001 26.3C3.19901 24.4126 3.81651 22.5785 4.95517 21.087C6.09383 19.5954 7.68888 18.5312 9.48884 18.0621C11.2888 17.593 13.1913 17.7457 14.8964 18.4961C16.6015 19.2465 18.0122 20.552 18.906 22.2066M16.0009 9.29998C16.0009 10.4935 15.5341 11.638 14.7032 12.482C13.8723 13.3259 12.7453 13.8 11.5703 13.8C10.3952 13.8 9.26825 13.3259 8.43734 12.482C7.60644 11.638 7.13965 10.4935 7.13965 9.29998C7.13965 8.10651 7.60644 6.96192 8.43734 6.11801C9.26825 5.27409 10.3952 4.79999 11.5703 4.79999C12.7453 4.79999 13.8723 5.27409 14.7032 6.11801C15.5341 6.96192 16.0009 8.10651 16.0009 9.29998ZM26.8313 12.3C26.8313 13.2282 26.4682 14.1185 25.8219 14.7749C25.1757 15.4312 24.2992 15.8 23.3852 15.8C22.4713 15.8 21.5948 15.4312 20.9485 14.7749C20.3023 14.1185 19.9392 13.2282 19.9392 12.3C19.9392 11.3717 20.3023 10.4815 20.9485 9.82511C21.5948 9.16873 22.4713 8.79998 23.3852 8.79998C24.2992 8.79998 25.1757 9.16873 25.8219 9.82511C26.4682 10.4815 26.8313 11.3717 26.8313 12.3Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default TeamSize;
