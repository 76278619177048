import { IconProps } from "../features/entities/general";

const Service = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.3748 1.83373C15.85 1.67164 15.2332 1.85646 14.8752 2.45646C13.7087 4.41142 6.71038 16.1412 5.54383 18.0962C4.97915 19.0417 5.61675 20.2729 6.66865 20.2729H14.6669V28.8037C14.6669 30.2255 16.4087 30.7587 17.1248 29.5587L26.4561 13.919C27.0208 12.9735 26.3832 11.7422 25.3313 11.7422H17.333V3.21144C17.333 2.50054 16.8998 1.99723 16.3748 1.83373Z"
          stroke="currentColor"
          strokeWidth="1.8"
        />
      </svg>
    </>
  );
};

export default Service;
