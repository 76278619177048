import Timeline, { TimelineItemProps } from "antd/es/timeline";
import classNames from "classnames";
import { useMemo } from "react";
import parse from "../../../features/helpers/parse";
import { ProgressStep } from "../../project/single/project-state-bar";
import Typography from "../typography";
import { TimelineContainer } from "./styles";

type HdTimelineProps = {
  steps: ProgressStep[];
  title: string;
  paused_at?: string;
};
const HdTimeline = ({ steps, paused_at, title }: HdTimelineProps) => {
  const activeIndex = steps.findIndex((s) => s.state === "active");

  const items = useMemo(() => {
    const newItems: TimelineItemProps[] = [];
    steps.forEach((element, i) => {
      const classes = classNames(element.state, {
        ["current"]: i === activeIndex,
      });
      newItems.push({
        children: (
          <Typography elementTheme="body3" medium style={{ paddingTop: 4 }}>
            {element.state === "completed"
              ? parse(element.labelComplete)
              : parse(element.label)}
          </Typography>
        ),
        color: element.state === "completed" ? "green" : undefined,
        className: classes,
      });
    });
    return newItems;
  }, [steps]);

  return (
    <TimelineContainer flex="column" gap={24}>
      <Typography elementTheme="h6">{title}</Typography>
      <Timeline items={items} />
    </TimelineContainer>
  );
};
export default HdTimeline;
