import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import { Provider } from "../../../../../features/entities";
import { useTheme } from "../../../../../plugins/styled";
import Avatar from "../../../../common/avatar";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import { ProviderCardContainer } from "./styles";

type CardProps = {
  provider: Provider;
  onClick: () => void;
  selected: boolean;
};
const ProviderCard = ({ provider, onClick, selected }: CardProps) => {
  const theme = useTheme();
  return (
    <ProviderCardContainer onClick={onClick} hoverable $isSelected={selected}>
      <Div flex="column" align="center" gap={24} justify="space-between">
        <Div flex="column" align="center" gap={24}>
          <Avatar size={78} shape="rounded-corner" src={provider.logo}>
            {provider.name[0]}
          </Avatar>
          <Div flex="column" align="center" gap={8}>
            <Typography.Title
              level={3}
              elementTheme="subtitle2"
              textAlign="center"
              nowrap
              ellipsis
            >
              {provider.name}
            </Typography.Title>
            <Typography
              elementTheme="caption"
              color={theme.colors.grey_3}
              textAlign="center"
            >
              {provider.city}, {provider.province}
            </Typography>
          </Div>
        </Div>
        {!!provider?.reviews?.length && (
          <Div
            flex="row"
            gap={8}
            align="center"
            fitContent
            borderRadius={24}
            backgroundColor={theme.colors.yellow_4}
            justify="center"
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          >
            <Typography color={theme.colors.yellow_3} fitContent>
              <RatingFill width={16} height={16} />
            </Typography>
            <Typography elementTheme="body2" bold fitContent>
              {provider.reviews_average || 0}
            </Typography>
          </Div>
        )}
      </Div>
    </ProviderCardContainer>
  );
};
export default ProviderCard;

