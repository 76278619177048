import {
  AdminProfile,
  ApiError,
  ApiErrorInitialState,
  Contact,
  ProfileModalProps,
} from "@hellodarwin/core/lib/features/entities";
import ProfileBlocks from "@hellodarwin/core/lib/features/enums/profile-blocks";
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

const adminAdapter = createEntityAdapter({
  selectId: (model: AdminProfile) => model.auth_id,
});

const contactAdapter = createEntityAdapter({
  selectId: (model: Contact) => model.contact_id,
});

export const setShortlistMessage = createAction<string>(
  "global/setShortlistMessage"
);

export const setAskMoreProviderMessage = createAction<string>(
  "global/setAskMoreProviderMessage"
);

export interface GlobalState {
  status: "idle" | "pending";
  error: ApiError;
  activeTab: string;
  modal: {
    isVisible: boolean;
    type: string;
    matchId: string;
    source: string;
  };
  admins: EntityState<AdminProfile, string>;
  contacts: EntityState<Contact, string>;
  shortlistMessage: string;
  askMoreProviderMessage: string;
  profileModal: ProfileModalProps;
}

const initialState: GlobalState = {
  status: "idle",
  error: ApiErrorInitialState,
  activeTab: "project",
  modal: {
    isVisible: false,
    type: "",
    matchId: "",
    source: "",
  },
  admins: adminAdapter.getInitialState(),
  contacts: contactAdapter.getInitialState(),
  shortlistMessage: "",
  askMoreProviderMessage: "",
  profileModal: {
    open: false,
  },
};

export const fetchAdmin = createAsyncThunk<
  AdminProfile,
  { api: ClientApi; adminId: string },
  { rejectValue: ApiError }
>(
  "client/fetchAdmin",
  async (
    { api, adminId }: { api: ClientApi; adminId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchAdmin(adminId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchContactById = createAsyncThunk<
  Contact,
  { api: ClientApi; contactId: string },
  { rejectValue: ApiError }
>(
  "client/fetchContactById",
  async (
    { api, contactId }: { api: ClientApi; contactId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchContactById(contactId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);
export const toggleProfileModal = createAction<{
  open: boolean;
  type?: ProfileBlocks;
  targetId?: string;
}>("global/toggleProfileModal");

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdmin.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchAdmin.fulfilled, (state, { payload }) => {
      adminAdapter.setOne(state.admins, payload);
      state.status = "idle";
    });
    builder.addCase(fetchAdmin.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchContactById.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchContactById.fulfilled, (state, { payload }) => {
      contactAdapter.upsertOne(state.contacts, payload);
      state.status = "idle";
    });
    builder.addCase(fetchContactById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(openMatchModal, (state, { payload }) => {
      state.modal = payload;
      state.modal.isVisible = true;
    });
    builder.addCase(closeMatchModal, (state, { payload }) => {
      state.modal = { isVisible: false, type: "", matchId: "", source: "" };
    });
    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
    builder.addCase(setShortlistMessage, (state, { payload }) => {
      state.shortlistMessage = payload;
    });
    builder.addCase(setAskMoreProviderMessage, (state, { payload }) => {
      state.askMoreProviderMessage = payload;
    });
    builder.addCase(toggleProfileModal, (state, { payload }) => {
      state.profileModal = payload;
    });
  },
});

export const selectActiveTab = (state: RootState) => state.global.activeTab;
export const setActiveTab = createAction<string>("client/setActiveTab");
export const openMatchModal = createAction<{
  isVisible: boolean;
  type: string;
  matchId: string;
  source: string;
}>("client/openMatchModal");
export const closeMatchModal = createAction("client/closeMatchModal");

export const selectIsLoading = (state: RootState) =>
  state.global.status === "pending";
export const selectShortlistMessage = (state: RootState) =>
  state.global.shortlistMessage;
export const selectAskMoreProviderMessage = (state: RootState) =>
  state.global.askMoreProviderMessage;
export const selectClientState = (state: RootState) => state.global;

export const { selectAll: selectAllAdmins, selectById: selectAdminById } =
  adminAdapter.getSelectors((state: RootState) => state.global.admins);

export const selectAdminByAdminId = createSelector(
  [selectAdminById, selectAllAdmins],
  (admin, allAdmins) => {
    if (!admin) {
      return allAdmins.find((admin) => !admin.google_auth_id)!;
    }
    return admin;
  }
);

export const { selectById: selectContactById } = contactAdapter.getSelectors(
  (state: RootState) => state.global.contacts
);
export const selectModal = (state: RootState) => state.global.modal;
export const selectProfileModal = (state: RootState) =>
  state.global.profileModal;

export const globalReducer = globalSlice.reducer;

