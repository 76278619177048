import NoPartnerSelectedBookConsult from "./book-consult";

type NoPartnerSelectedFlowProps = {
  handleClose: () => void;
};

const NoPartnerSelectedFlow = ({ handleClose }: NoPartnerSelectedFlowProps) => {
  return <NoPartnerSelectedBookConsult handleClose={handleClose} />;
};
export default NoPartnerSelectedFlow;
