import { IconProps } from "../features/entities/general";

interface TabArrowProps extends IconProps {
  down?: boolean;
}

const TabArrow = ({
  width = "19",
  height = "20",
  down = false,
  className,
  style,
  onClick,
  color = "currentColor",
}: TabArrowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 9"
    fill="none"
    width={width}
    height={height}
    onClick={onClick}
    style={{
      ...style,
      flexBasis: width,
      flexShrink: 0,
      flexGrow: 0,
      transform: down ? "rotate(0deg)" : "rotate(-180deg)",
      transition: "transform 150ms ease",
    }}
    className={className}
  >
    <path
      d="M1 1L8.5 8L16 1"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TabArrow;

