import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantResult } from "@hellodarwin/core/lib/features/entities/grants-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import CountUp from "react-countup";

interface GrantsDirectoryTotalProps {
  totalAmount: number;
  grants: GrantResult[];
}
const GrantsDirectoryTotal = ({
  totalAmount,
  grants,
}: GrantsDirectoryTotalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (grants?.length === 0) return <></>;
  return (
    <Div
      flex="row"
      gap={8}
      align="center"
      className="fade-in"
      tablet={{ flex: "column", align: "flex-start", style: { marginTop: 24 } }}
    >
      <Div flex="row" gap={8} align="center">
        <Typography elementTheme="h5" nowrap color={theme.colors.purple_1}>
          <CountUp
            end={
              grants !== undefined && grants.length > 0
                ? grants[0]?.total_count ?? 0
                : (0 as number)
            }
            separator=" "
            duration={1}
          />
        </Typography>
        <Typography elementTheme="h5" style={{ fontWeight: 400 }} nowrap>
          {t(`grants|grants`)} {t(`grants|grantsTotal.totaling`)}
        </Typography>
      </Div>
      <Typography elementTheme="h5" nowrap color={theme.colors.purple_1}>
        {totalAmount > 0 && (
          <CountUp
            end={totalAmount}
            separator={t(`grants|grantsTotal.seperatorTotal`)}
            prefix={t(`grants|grantsTotal.prefixTotal`)}
            suffix={t(`grants|grantsTotal.suffixTotal`)}
            duration={1}
          />
        )}
      </Typography>
    </Div>
  );
};

export default GrantsDirectoryTotal;

