import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import Divider from "antd/es/divider";
import { ReactNode } from "react";
import { useTheme } from "../../plugins/styled";
import Avatar from "../common/avatar";
import Button from "../common/button";
import Div from "../common/div";
import Modal from "../common/hd-modal";
import Typography from "../common/typography";
import Loading from "../loading";

interface CtaProps {
  text: string;
  color?: string;
  action: (...attrb) => any;
  headingIcon?: ReactNode;
}
export interface ContactInfoLineProps {
  label: string;
  value: string;
  type?: "text" | "email" | "phone";
}
interface ContactModalProps {
  open: boolean;
  handleClose: () => void;
  picture?: string;
  name: string;
  description?: string;
  infos: ContactInfoLineProps[];
  cta?: CtaProps;
  loading?: boolean;
}

const ContactModal = ({
  open,
  handleClose,
  picture,
  name,
  description,
  infos,
  cta,
  loading,
}: ContactModalProps) => {
  const theme = useTheme();
  const InfoLine = ({ label, value, type }: ContactInfoLineProps) =>
    !!value?.length && (
      <div>
        <Typography elementTheme="caption" color={theme.colors.grey_2}>
          {label}
        </Typography>
        {type === "phone" ? (
          <a rel="nofollow" href={`tel:${value.split(" ")[0]}`}>
            <Typography elementTheme="body2">{value}</Typography>
          </a>
        ) : type === "email" ? (
          <a rel="nofollow" href={`mailto:${value}`}>
            <Typography elementTheme="body2">{value}</Typography>
          </a>
        ) : (
          <Typography elementTheme="body2">{value}</Typography>
        )}
      </div>
    );

  return (
    <Modal open={open} size="small" handleCancel={handleClose}>
      <Div flex={"column"} gap={32} justify={"center"}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Div flex={"column"} justify={"center"} align={"center"} gap={16}>
              <Avatar size={128} shape="square" src={picture}>
                {name?.[0]}
              </Avatar>

              <Typography elementTheme="subtitle1">{name}</Typography>
              {!!description && (
                <Typography elementTheme="caption" color={theme.colors.grey_2}>
                  {description}
                </Typography>
              )}
            </Div>
            <Div flex={"column"} gap={16}>
              <Divider
                style={{ borderColor: theme.colors.grey_2, borderWidth: 1 }}
              />
              {infos?.map((info) => <InfoLine {...info} />)}
              <Divider
                style={{ borderColor: theme.colors.grey_2, borderWidth: 1 }}
              />
            </Div>
            {!!cta && (
              <Button
                defaultStyle={cta.color || theme.colors.yellow_1}
                headingIcon={<Timeline width={24} height={24} />}
                onClick={cta.action}
                size="small"
                style={{ margin: "auto" }}
              >
                {cta.text}
              </Button>
            )}
          </>
        )}
      </Div>
    </Modal>
  );
};

export default ContactModal;

