import { AppName } from "../../../../../features/entities";
import getMeetingUrl from "../../../../../features/helpers/get-meeting-url";
import useLocale from "../../../../../features/providers/locale-provider";
import { useTranslations } from "../../../../../features/providers/translations-provider";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import HubspotMeeting from "../../../../hubspot/hubspot-meeting";
import ProjectStatusFlowLayout from "../../project-status-flow-layout";

type NoPartnerSelectedBookConsultProps = {
  handleClose: () => void;
};
const NoPartnerSelectedBookConsult = ({
  handleClose,
}: NoPartnerSelectedBookConsultProps) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();

  const pageSettings = {
    url: getMeetingUrl(selectedLocale, "website"),
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
    width: "100%",
    height: 950,
  };

  return (
    <ProjectStatusFlowLayout
      buttons={
        <Div>
          <Button onClick={handleClose} withArrowLeft>
            {t("button|back")}
          </Button>
        </Div>
      }
    >
      <HubspotMeeting meetingUrl={pageSettings.url} app={AppName.Client} />
    </ProjectStatusFlowLayout>
  );
};

export default NoPartnerSelectedBookConsult;

