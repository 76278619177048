import { IconProps } from "../features/entities/general";

const Projects = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.60001 17.061V15.9059C1.60001 14.9869 1.95011 14.1055 2.57331 13.4556C3.19651 12.8057 4.04175 12.4407 4.92308 12.4407H27.0769C27.9583 12.4407 28.8035 12.8057 29.4267 13.4556C30.0499 14.1055 30.4 14.9869 30.4 15.9059V17.061M17.5655 7.14269L14.4345 3.87766C14.2288 3.6629 13.9845 3.49251 13.7156 3.37623C13.4467 3.25996 13.1585 3.20008 12.8675 3.20001H4.92308C4.04175 3.20001 3.19651 3.5651 2.57331 4.21496C1.95011 4.86482 1.60001 5.74621 1.60001 6.66525V25.1465C1.60001 26.0656 1.95011 26.947 2.57331 27.5968C3.19651 28.2467 4.04175 28.6118 4.92308 28.6118H27.0769C27.9583 28.6118 28.8035 28.2467 29.4267 27.5968C30.0499 26.947 30.4 26.0656 30.4 25.1465V11.2856C30.4 10.3665 30.0499 9.48514 29.4267 8.83528C28.8035 8.18542 27.9583 7.82033 27.0769 7.82033H19.1326C18.5452 7.81979 17.9806 7.57605 17.5655 7.14269Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Projects;
