import { IconProps } from "../features/entities/general";

const RightArrow = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.6 15.7177L30.4 15.7177M30.4 15.7177L20.9745 6.40002M30.4 15.7177L20.9745 25.0353"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default RightArrow;
