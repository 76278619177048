import { ContainerIcons } from "@hellodarwin/icons/dist/backgrounds/icon-entities";
import { CSSProperties, PropsWithChildren, ReactNode, useEffect } from "react";
import { AppType } from "../../../../features/entities";
import { Breadcrumb } from "../../../../features/entities/context-entities";
import { useAppData } from "../../../../features/providers/app-provider";
import { useBreadcrumbs } from "../../../../features/providers/breadcrumbs-provider";
import useChat from "../../../../features/providers/chat-provider";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import Breadcrumbs from "../../../layout/header/breadcrumbs";
import Div from "../../div";
import { TagProps } from "../../hd-tag";
import PageContainer, { PageContainerProps } from "./PageContainer";
import PageHeading, { PageHeadingProps } from "./PageHeading";

interface PageLayoutProps {
  className?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  footnote?: string;
  status?: TagProps;
  actions?: ReactNode;
  beforeHeader?: ReactNode;
  tabTitle?: string;
  handleBack?: () => void;
  app: AppType;
  isLoading?: boolean;
  withoutPaddingRight?: boolean;
  withoutPaddingHorizontal?: boolean;
  styles?: {
    container?: CSSProperties;
    content?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
  };
  noGap?: boolean;
  noPadding?: boolean;
  breadcrumbs: Breadcrumb[];
}

const PageLayout = ({
  children,
  className,
  title,
  subtitle,
  footnote,
  status,
  actions,
  beforeHeader,
  tabTitle,
  handleBack,
  app,
  isLoading,
  withoutPaddingRight,
  withoutPaddingHorizontal,
  styles,
  noGap,
  breadcrumbs,
  noPadding,
}: PageLayoutProps & PropsWithChildren) => {
  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;
  const { unreadMessagesCount } = useChat();
  const { header, navCollapsed } = useAppData();

  const { setBreadCrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadCrumbs(breadcrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (!!tabTitle) {
        document.title = `${tabTitle}${
          unreadMessagesCount > 0 ? ` (${unreadMessagesCount})` : ""
        } | ${t(`appsNames|${app}`)}`;
      } else if (
        (typeof title === "string" || title instanceof String) &&
        !!title
      ) {
        document.title = `${title}${
          unreadMessagesCount > 0 ? ` (${unreadMessagesCount})` : ""
        } | ${t(`appsNames|${app}`)}`;
      } else {
        document.title =
          t(`appsNames|${app}`) +
          (unreadMessagesCount > 0 ? ` (${unreadMessagesCount})` : "");
      }
    }
  }, [tabTitle, unreadMessagesCount]);

  if (isLoading) return <></>;

  const headingProps: PageHeadingProps = {
    title,
    subtitle,
    actions,
    footnote,
    status,
    handleBack,
    withoutPaddingRight,
    withoutPaddingHorizontal,
  };

  const containerProps: PageContainerProps = {
    className,
    cap: app === "admin" ? theme.caps.xl : theme.caps.normal,
    withoutPaddingRight,
    withoutPaddingHorizontal,
    styles: {
      ...styles,
      container: { ...styles?.container, position: "relative" },
    },
    noPadding,
    app,
  };

  return (
    <PageContainer
      {...containerProps}
      icons={[
        {
          side: "right",
          name: ContainerIcons.Group1Filled,
          color: theme.colors.beige_1,
          top: 300,
          margin: -300,
          width: 900,
          height: 800,
        },
      ]}
    >
      {app === "admin" && (
        <div
          style={{
            position: "fixed",
            zIndex: 100,

            width: `calc(100vw - ${
              !navCollapsed ? theme.appearance.sidebarWidth : 83
            }px)`,
            top: 0,
            left: !navCollapsed ? theme.appearance.sidebarWidth : 83,
            transition: "left 200ms linear",
          }}
        >
          {header}
          <div
            style={{
              backdropFilter: "blur(4px)",
              padding: 6,
              backgroundColor: theme.colors.white_1,
              width: "100%",
              borderTop: `1px solid ${theme.colors.grey_4}`,
            }}
          >
            <Breadcrumbs />
          </div>
        </div>
      )}
      <Div
        position="relative"
        style={{ height: "100%" }}
        flex="column"
        gap={!noGap ? (app === "admin" ? 16 : 32) : 0}
        tablet={{ gap: "00" }}
      >
        <div
          style={{
            ...styles?.header,
            paddingRight:
              withoutPaddingHorizontal || withoutPaddingRight ? 60 : 0,
            paddingLeft: withoutPaddingHorizontal ? 60 : 0,
          }}
        >
          {app !== "admin" && <div style={{ marginBottom: 24 }}>{header}</div>}
          {beforeHeader && <Div>{beforeHeader}</Div>}
          {(title || actions) && <PageHeading {...headingProps} />}
        </div>
        <Div
          className="hd-page-body"
          flex="column"
          style={{ ...styles?.body }}
          gap={32}
          fullHeight
        >
          {children}
        </Div>
      </Div>
    </PageContainer>
  );
};

export default PageLayout;

