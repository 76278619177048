import Button from "@hellodarwin/core/lib/components/common/button";
import Carousel from "@hellodarwin/core/lib/components/common/carousel";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PreviewCard from "@hellodarwin/core/lib/components/common/layout/preview-card";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Project } from "@hellodarwin/core/lib/features/entities/project-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Empty from "antd/es/empty";
import { useNavigate } from "react-router-dom";
import getProjectPreviewProps from "../../features/utils/get-project-preview-props";

interface RecentProgramsSectionProps {
  projects: Project[];
  isLoading: boolean;
}

const RecentProjectsSection = ({
  projects,
  isLoading,
}: RecentProgramsSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedLocale } = useLocale();

  const onAllProjectClick = () => {
    navigate("/projects");
  };

  return (
    <Container
      style={{ height: 420, border: `1px solid ${theme.colors.primary}` }}
    >
      <Div flex="column" gap={24} style={{ height: "100%" }}>
        <Typography.Title level={2} elementTheme="h6">
          {t("project|yourRecentProjects")}
        </Typography.Title>
        <Carousel
          isLoading={isLoading}
          tileWidth={300}
          emptyState={<Empty description={t("project|noProjects.all")} />}
          actions={
            <Button
              defaultStyle={theme.colors.purple_1}
              onClick={onAllProjectClick}
              withArrowRight
              size="small"
              style={{ letterSpacing: 0, fontWeight: 500 }}
            >
              {t("project|seeAll")}
            </Button>
          }
          styles={{ container: { height: "100%" } }}
          buttonPosition="bottom-right"
        >
          {projects.slice(0, 5).map((project, index) => {
            const previewProps = getProjectPreviewProps(
              project,
              selectedLocale,
              t,
              theme
            );
            return (
              <PreviewCard key={index + project.project_id} {...previewProps} />
            );
          })}
        </Carousel>
      </Div>
    </Container>
  );
};

export default RecentProjectsSection;

