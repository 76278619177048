import LeftArrow from "@hellodarwin/icons/dist/icons/LeftArrow";
import RightArrow from "@hellodarwin/icons/dist/icons/RightArrow";
import { CSSProperties } from "react";
import { ArrowContainer } from "./styles";

export type HdArrowProps = {
  dir: "left" | "right" | "top" | "bottom";
  style?: CSSProperties;
};

const HdArrow = ({ dir }: HdArrowProps) => (
  <ArrowContainer className="hd-arrow" $dir={dir} style={{ display: "flex" }}>
    {dir === "left" ? (
      <LeftArrow width={20} height={20} />
    ) : (
      <RightArrow width={20} height={20} />
    )}
  </ArrowContainer>
);

export default HdArrow;
