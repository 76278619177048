import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GrantCard from "@hellodarwin/core/lib/components/grants/card";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/filter/types";
import FundingExplorerListPageLayout from "@hellodarwin/core/lib/components/grants/funding-explorer-list-page";
import Loading from "@hellodarwin/core/lib/components/loading";
import { Company, GrantResult } from "@hellodarwin/core/lib/features/entities";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { usePagination } from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/src/components/forms/utils/company-infos";
import Close from "@hellodarwin/icons/dist/icons/Close";
import Form from "antd/es/form";
import Pagination from "antd/es/pagination";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  fetchBestGrantProjects,
  selectBestGrantProjects,
} from "../../features/api/slices/grants-projects-slice";
import {
  fetchGrantFinancingType,
  fetchGrantServices,
  fetchProvinces,
  selectGrantFinancingType,
  selectGrantService,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectorsForFundingExplorer,
} from "../../features/api/slices/tags-slice";
import { useClientApi } from "../../features/api/use-client-api";
import useDebounce from "../../hooks/use-debounce";
import GrantsDirectoryTotal from "./grants-directory-total";

const ClientFundingExplorer = () => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const [textQuery, setTextQuery] = useState<string | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectorsForFundingExplorer(
      state,
      selectedIndustries || []
    )
  );
  const provinces = useAppSelector(selectProvinces);
  const services = useAppSelector(selectGrantService);
  const {
    activePage,
    goToPage,
    addParamsToURL,
    addParam,
    clearAllParams,
    removeParam,
    pageSize,
    params,
  } = usePagination();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const bestProjects = useAppSelector(selectBestGrantProjects);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  type PageState = {
    grants: any[];
  };

  const pageStateInitialValues: PageState = {
    grants: [],
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);

  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  useEffect(() => {
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchProvinces({ api, locale: selectedLocale }));
    dispatch(fetchGrantServices({ api, locale: selectedLocale }));
    dispatch(fetchBestGrantProjects({ api, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  useEffect(() => {
    applyFilter(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects]);

  const applyFilter = async (isFromUseEffect = false) => {
    var pageFilter: number;

    if (filterform.isFieldsTouched() || isFromUseEffect) {
      pageFilter = 1;
    } else {
      pageFilter = activePage;
    }
    prepareURL(pageFilter);

    fetch(true, false);
    fetchTotalAmount();
  };

  useEffect(() => {
    prepareFilters();
    fetch(true, false);
    fetchTotalAmount();
    addParamsToURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch(true, false);
    fetchTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageSize]);

  const prepareFilters = () => {
    const filterBy = params["filterBy"];
    const industry = params["industry"];
    const subindustry = params["subindustry"];
    const financingType = params["financingType"];
    const status = params["status"];
    const closingDate = params["closingDate"];
    const region = params["region"];
    const service = params["service"];
    const companySize = params["companySize"];
    const annualRevenue = params["annualRevenue"];

    if (!!filterBy?.length) {
      filterform.setFieldValue("filterBy", filterBy);
    }
    if (!!industry?.length) {
      const newIndustry = industry.split(",");
      filterform.setFieldValue("industry", newIndustry);
      setSelectedIndustries(newIndustry);
    }
    if (!!subindustry?.length) {
      filterform.setFieldValue("subindustry", subindustry.split(","));
    }
    if (!!service?.length) {
      filterform.setFieldValue("service", service.split(","));
    }
    if (!!financingType?.length) {
      filterform.setFieldValue("financingType", financingType.split(","));
    }
    if (!!status?.length) {
      filterform.setFieldValue("status", status.split(","));
    }
    if (!!closingDate) {
      filterform.setFieldValue("closingDate", closingDate);
    }
    if (!!region?.length) {
      filterform.setFieldValue("region", region.split(","));
    }
    if (!!companySize?.length) {
      filterform.setFieldValue("companySize", companySize);
    }
    if (!!annualRevenue?.length) {
      filterform.setFieldValue("annualRevenue", annualRevenue);
    }
  };

  const prepareURL = (page: number) => {
    const filterValues = filterform.getFieldsValue();

    if (!!selectedIndustries?.length) {
      addParam("industry", selectedIndustries.join(","));
    } else {
      removeParam("industry");
    }

    if (filterValues) {
      const {
        filterBy,
        subindustry,
        financingType,
        status,
        closingDate,
        region,
        service,
        companySize,
        annualRevenue,
      } = filterValues;

      if (!!filterBy?.length) {
        addParam("filterBy", filterBy);
      } else {
        removeParam("filterBy");
      }

      if (!!subindustry?.length) {
        addParam("subindustry", subindustry.join(","));
      } else {
        removeParam("subindustry");
      }

      if (!!financingType?.length) {
        addParam("financingType", financingType.join(","));
      } else {
        removeParam("financingType");
      }

      if (!!status?.length) {
        addParam("status", status.join(","));
      } else {
        removeParam("status");
      }

      if (!!closingDate) {
        addParam("closingDate", closingDate);
      } else {
        removeParam("closingDate");
      }

      if (!!region && region.length > 0) {
        addParam("region", region.join(","));
      } else {
        removeParam("region");
      }

      if (!!service && service.length > 0) {
        addParam("service", service.join(","));
      } else {
        removeParam("service");
      }

      if (!!companySize) {
        addParam("companySize", companySize);
      } else {
        removeParam("companySize");
      }

      if (!!annualRevenue) {
        addParam("annualRevenue", annualRevenue);
      } else {
        removeParam("annualRevenue");
      }
    }
    goToPage(page);
  };

  const onPageChange = async (page: number, pageSize: number) => {
    goToPage(page, pageSize);
  };

  const fetchTotalAmount = () => {
    (async () => {
      var totalAmount: number;
      const filterValues = filterform.getFieldsValue()!;

      totalAmount = await api.fetchFundingExplorerTotalAmount(
        selectedLocale,
        activePage,
        pageSize,
        filterValues.textQuery,
        undefined,
        filterValues.filterBy,
        filterValues.industry,
        filterValues.subindustry,
        filterValues.status,
        filterValues.financingType,
        filterValues.region,
        filterValues.closingDate,
        filterValues.service,
        filterValues.annualRevenue,
        filterValues.companySize
      );
      setTotalAmount(totalAmount);
    })();
  };

  const FILTER_DELAY_MS = 500;
  const debouncedApplyFilter = useDebounce(textQuery, FILTER_DELAY_MS);

  useEffect(() => {
    if (debouncedApplyFilter || debouncedApplyFilter === "") {
      applyFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedApplyFilter]);

  const onTextQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextQuery(e.target.value);
  };

  const fetch = (loading: boolean, isSearching: boolean) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        setApiState({ isLoading: true, isSearching: true, isErrored: null });
        const filterValues = filterform.getFieldsValue()!;
        var response: GrantResult[];

        response = await api.queryFundingExplorer(
          selectedLocale,
          activePage,
          pageSize,
          filterValues.textQuery,
          undefined,
          filterValues.filterBy,
          filterValues.industry,
          filterValues.subindustry,
          filterValues.status,
          filterValues.financingType,
          filterValues.region,
          filterValues.closingDate,
          filterValues.service,
          selectedProjects,
          filterValues.annualRevenue,
          filterValues.companySize
        );

        const newPageState = {
          grants: response?.map((grant, index) => ({
            key: index,
            ...grant,
          })),
        };
        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const ShowCompanyInfo = () => (
    <Div
      flex="row"
      align="center"
      justify="space-between"
      gap={24}
      backgroundColor={theme.colors.white_2}
      style={{ padding: 24 }}
    >
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Company Id</Typography>
        <Link target="_blank" to={`/companies/${company?.company_id}`}>
          {getShortId(company!.company_id)}
        </Link>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Name</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.name}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Industry</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.industry_sector
            ? company?.industry_sector
            : "No industry specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Company Size</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.size ? company?.size : "No size specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Region</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.province ? company?.province : "No region specified"}
        </Typography>
      </Div>
      <Div flex="column" justify="center" align="center">
        <Typography elementTheme="h6">Annual Revenue</Typography>
        <Typography textAlign="center" elementTheme="body2">
          {company?.annual_revenue
            ? company?.annual_revenue
            : "No annual revenue specified"}
        </Typography>
      </Div>
      <Div
        alignSelf="flex-end"
        onClick={removeCompany}
        style={{ cursor: "pointer", width: 16 }}
      >
        <Close width={16} />
      </Div>
    </Div>
  );

  const removeCompany = () => {
    filterform.resetFields();
    setCompany(null);

    fetch(true, false);
    fetchTotalAmount();
  };

  const resetFilter = () => {
    filterform.resetFields();
    setSelectedProjects([]);
    clearAllParams(0, 10);
    setSelectedIndustries([]);
    setTextQuery("");
    fetch(true, false);
    fetchTotalAmount();
  };

  return (
    <PageLayout
      app="client"
      title={t("navigation.fundingExplorer")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.fundingExplorer"),
        },
      ]}
      actions={
        <GrantsDirectoryTotal
          totalAmount={totalAmount}
          grants={pageState.grants}
        />
      }
      styles={{ container: { overflow: "visible" } }}
    >
      {company ? <ShowCompanyInfo /> : null}

      <FundingExplorerListPageLayout
        filterform={filterform}
        onTextQueryChange={onTextQueryChange}
        financingType={financingType}
        industries={industries}
        subIndustries={subindustries}
        provinces={provinces}
        services={services}
        companySize={companySize}
        annualRevenue={annualRevenue}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        selectedIndustries={selectedIndustries}
        setSelectedIndustries={setSelectedIndustries}
        bestProjects={bestProjects}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      >
        <Container>
          {apiState.isLoading ? (
            <Loading />
          ) : pageState.grants !== undefined && pageState.grants.length > 0 ? (
            pageState.grants.map((grant, index) => (
              <GrantCard {...grant} key={index} />
            ))
          ) : null}

          <Div flex="row" justify="center">
            <Pagination
              total={
                pageState.grants !== undefined && pageState.grants.length > 0
                  ? pageState.grants[0]?.total_count
                  : 0
              }
              current={activePage}
              onChange={(page, pageSize) => onPageChange(page, pageSize)}
              pageSize={pageSize}
            />
          </Div>
        </Container>
      </FundingExplorerListPageLayout>
    </PageLayout>
  );
};

export default ClientFundingExplorer;

