import { ProjectStatusFlowSteps } from ".";
import {
  CancelProjectFlowSteps,
  ChoosePartnerFlowSteps,
} from "../../../features/entities";
import useMediaQuery from "../../../features/helpers/use-media-query";
import { useTranslations } from "../../../features/providers/translations-provider";
import { useTheme } from "../../../plugins/styled";
import Button from "../../common/button";
import Div from "../../common/div";
import Typography from "../../common/typography";

type StatusFlowFooterProps = {
  step: ProjectStatusFlowSteps;
  handleChangeFlow: (step: ProjectStatusFlowSteps) => void;
  cancelFlowStep: CancelProjectFlowSteps;
  choosePartnerFlowStep: ChoosePartnerFlowSteps;
  hasShortlistMatches?: boolean;
};

const StatusFlowFooter = ({
  step,
  handleChangeFlow,
  cancelFlowStep,
  choosePartnerFlowStep,
  hasShortlistMatches,
}: StatusFlowFooterProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const isCancel = [ProjectStatusFlowSteps.CancelProjectFlow].includes(step);
  const isNoPartnerSelected = [
    ProjectStatusFlowSteps.NoPartnerSelectedOptions,
  ].includes(step);
  const isPartnerSelected = [
    ProjectStatusFlowSteps.PartnerChooseSelected,
  ].includes(step);

  const isTablet = useMediaQuery({ maxWidth: 559 });

  const showCancel =
    !isCancel && choosePartnerFlowStep === ChoosePartnerFlowSteps.chosenSpStep;
  const showChoosePartner =
    !isPartnerSelected &&
    hasShortlistMatches &&
    cancelFlowStep === CancelProjectFlowSteps.cancelFormStep;

  if (isNoPartnerSelected || (!showChoosePartner && !showCancel)) return <></>;

  return (
    <Div
      flex="row"
      align="center"
      justify="space-between"
      tablet={{ flex: "column", align: "center", gap: "1" }}
    >
      {isTablet && (
        <Typography
          bold
          elementTheme="overline"
          style={{ letterSpacing: 0.3 }}
          textTransform="uppercase"
          color={theme.colors.purple_3}
        >
          {t("projectStatusFlow|psf_footer_wrong_status")}
        </Typography>
      )}

      <Div fitContent>
        {showCancel && (
          <Button
            onClick={() =>
              handleChangeFlow(ProjectStatusFlowSteps.CancelProjectFlow)
            }
            isLink
          >
            {t("projectStatusFlow|psf_footer_underlink3")}
          </Button>
        )}
      </Div>
      <Div fitContent>
        {showChoosePartner && (
          <Button
            onClick={() =>
              handleChangeFlow(ProjectStatusFlowSteps.PartnerChooseSelected)
            }
            isLink
          >
            {t("projectStatusFlow|psf_footer_underlink2")}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default StatusFlowFooter;

