import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import theme from "@hellodarwin/core/lib/theme";
import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import { ReactNode } from "react";

const MilestoneInfo = ({
  Icon,
  title,
  value,
}: {
  Icon: ({ width, height, style }: IconProps) => ReactNode;
  title: ReactNode;
  value: ReactNode;
}) => {
  return (
    <Div flex="row" gap={6} align="center" fitContent>
      <Icon width={14} height={14} style={{ color: theme.colors.grey_3 }} />

      <Typography elementTheme="body3" color={theme.colors.grey_2}>
        {title} <b>{value}</b>
      </Typography>
    </Div>
  );
};

export default MilestoneInfo;
