import styled from "../../../../plugins/styled";

export const GrantHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  padding: 24px 17px;
  padding-right: 40px;
  padding-bottom: 0;
  border-radius: 11px 11px 0px 0px;

  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 40px;
  }
`;
export const GrantHeaderContainer = styled.div`
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  display: flex;
  flex-direction: column;
  gap: 40px;

  border-radius: 11px 11px 0px 0px;

  a {
    color: inherit;
  }
  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {

    gap: 0;x
  }
`;

export const GrantLogo = styled.img`
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  border-radius: 4px;
  overflow: hidden;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};

  & > * {
    flex: 1;
    text-align: center;
    border-right: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.beige_1};
    &:last-child {
      border-right: none;
    }
    &:hover {
      background: ${({ theme }) => theme.colors.beige_2};
    }
  }

  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 1px;
      height: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
      transition: background 400ms ease;
      border-radius: 5px;
      cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    & > * {
      padding-left: 10px;
      padding-right: 10px;
      flex-shrink: 0;
      text-wrap: nowrap;
    }
  }
`;

