import EmptyProject from "@hellodarwin/icons/dist/graphics/EmptyProject";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
type ProjectEmptySectionProps = {
  title: string;
  withIcon?: boolean;
  buttonText?: string;
  buttonAction?: () => void;
};
const ProjectEmptySection = ({
  title,
  withIcon,
  buttonText,
  buttonAction,
}: ProjectEmptySectionProps) => {
  const theme = useTheme();
  return (
    <Div
      flex="column"
      align="center"
      justify="center"
      gap={12}
      backgroundColor={theme.colors.beige_4}
      borderColor={theme.colors.grey_4}
      borderRadius={4}
      style={{
        borderStyle: "dashed",
        paddingTop: 30,
        paddingBottom: 30,
        height: "100%",
      }}
    >
      <Div flex="column" align="center" gap={16} style={{ maxWidth: 420 }}>
        {withIcon && <EmptyProject width={94} height={90} />}
        <Div flex="column" align="center" gap={8}>
          <Typography
            elementTheme="caption"
            color={theme.colors.grey_2}
            textAlign="center"
            tablet={{ style: { padding: "0 10px" } }}
          >
            {title}
          </Typography>
          {!!buttonText && !!buttonAction && (
            <Button onClick={buttonAction} isLink textWrap size="auto">
              {buttonText}
            </Button>
          )}
        </Div>
      </Div>
    </Div>
  );
};
export default ProjectEmptySection;

