import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { useTheme } from "../../../plugins/styled";
import Typography from "../typography";

export interface HdImageProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string;
  emptyText?: string;
}

const HdImage = ({ src, emptyText, ...imgProps }: HdImageProps) => {
  const theme = useTheme();

  const [hasError, setHasError] = useState(false);
  const handleError = (e) => {
    setHasError(true);
  };

  useEffect(() => {
    setHasError(false);
  }, [src]);

  return (
    <>
      {!src.length || hasError ? (
        <div
          style={{
            ...imgProps.style,
            width: imgProps.width,
            minWidth: 24,
            height: imgProps.height,
            minHeight: 24,
            background: theme.colors.primary,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography color={theme.colors.white_1} elementTheme="h3">
            {emptyText ?? imgProps?.alt?.[0] ?? src[0]}
          </Typography>
        </div>
      ) : (
        <img onError={handleError} src={src} {...imgProps} />
      )}
    </>
  );
};

export default HdImage;

