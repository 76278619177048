import React from "react";
import { useTheme } from "../../../../plugins/styled";
import Typography from "../../typography";

const PreviewRowLabels = ({
  withAction,
  infosLabel,
}: {
  infosLabel: string[];
  withAction?: boolean;
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        paddingLeft: 24,
        paddingRight: 32,
        gap: 32,
      }}
    >
      {React.Children.toArray(
        infosLabel.map((label) => (
          <Typography
            style={{ flex: "2 1 0%" }}
            elementTheme="overline"
            color={theme.colors.grey_2}
          >
            {label}
          </Typography>
        ))
      )}

      {withAction && <span style={{ flex: "1 1 0%", minWidth: 180 }}></span>}
    </div>
  );
};

export default PreviewRowLabels;

