import Edit from "@hellodarwin/icons/dist/icons/Edit";
import { ReactNode, useEffect, useState } from "react";
import { ProfileBlock } from "..";
import { ProfileModalProps } from "../../../../features/entities/profile-entities";
import { Provider } from "../../../../features/entities/providers-entities";
import ProfileBlocks from "../../../../features/enums/profile-blocks";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import ProfileActions from "../profile-actions";
import ProfileBanner from "../profile-assets/profile-banner";
import ProfileLogo from "../profile-assets/profile-logo";
import ProfileMenu from "./profile-menu";
import ReviewsAverageCard from "./reviews-average-card";

type ProfileHeaderProps = {
  provider: Provider;
  isEditable?: boolean;
  sections: ProfileBlocks[];
  blocks: { [block_name: string]: ProfileBlock };
  providerActions?: ReactNode;
  websiteCTA?: () => void;
  handleLeaveAReview?: () => void;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  handleRedirectToSettings?: () => void;
};

const ProfileHeader = ({
  provider,
  isEditable,
  handleRedirectToSettings,
  sections,
  blocks,
  providerActions,
  websiteCTA,
  handleLeaveAReview,
  toggleEditModal,
}: ProfileHeaderProps) => {
  const { logo, name, city, province, banner, tagline } = provider;
  const theme = useTheme();
  //HASH

  const [hash, setHash] = useState("");

  const handleLinkClick = (section: string) => {
    setHash(section);
  };

  const handleScroll = (e: Event) => {
    setHash("");
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      const element = document.getElementById(hash);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    window?.addEventListener("scroll", handleScroll);
    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  });

  //company update

  return (
    <Div
      style={{ borderRadius: "11px 11px 0 0", overflow: "hidden" }}
      borderColor={theme.colors.primary}
      backgroundColor={theme.colors.white_1}
    >
      <ProfileBanner
        banner={!!banner ? `${banner}?${new Date().toString()}` : undefined}
        name={name}
        isEditable={isEditable}
        toggleEditModal={toggleEditModal}
      />
      <Div
        style={{ padding: "27px 48px", paddingBottom: 33 }}
        xl={{
          flex: "column",
          style: { padding: "32px 16px", paddingBottom: 34 },
          align: "center",
        }}
        flex="row"
        gap={32}
        position="relative"
        justify="space-between"
      >
        <Div flex="row" gap={32} xl={{ flex: "column", align: "center" }}>
          <ProfileLogo
            style={{ marginTop: -(27 + 22) }}
            name={provider.name}
            logo={logo || ""}
            isEditable={isEditable}
            toggleEditModal={toggleEditModal}
            size={129}
          />
          <Div flex="column" gap={14}>
            <Div flex="column" gap={11}>
              <Typography.Title
                level={1}
                elementTheme="h3"
                xl={{ textAlign: "center" }}
                noMargin
              >
                {provider.name}
              </Typography.Title>
              {!!city && !!province && (
                <Typography
                  color={theme.colors.grey_3}
                  elementTheme="caption"
                  xl={{ textAlign: "center" }}
                >
                  {city}, {province}
                </Typography>
              )}
            </Div>
            {(!!tagline || isEditable) && (
              <Typography
                elementTheme="body2"
                xl={{ textAlign: "center" }}
                style={{ cursor: isEditable ? "pointer" : "default" }}
                onClick={isEditable ? handleRedirectToSettings : undefined}
              >
                {tagline ?? (isEditable ? "<tagline>" : "")}
                {isEditable && (
                  <Edit
                    width={18}
                    height={18}
                    onClick={handleRedirectToSettings}
                    style={{
                      color: theme.colors.grey_2,
                      lineHeight: theme.fontsLineHeights.body2,
                    }}
                  />
                )}
              </Typography>
            )}
          </Div>
        </Div>
        <Div fitContent flex="row" gap={24}>
          <ProfileActions
            providerActions={providerActions}
            websiteCTA={websiteCTA}
          />
          {!providerActions && (
            <ReviewsAverageCard
              average={provider.reviews_average || 0}
              handleLinkClick={handleLinkClick}
              handleLeaveAReview={handleLeaveAReview}
              mobileStyle={{ position: "absolute", top: 16, right: 16 }}
            />
          )}
        </Div>
      </Div>
      <ProfileMenu
        sections={sections}
        blocks={blocks}
        handleLinkClick={handleLinkClick}
      />
    </Div>
  );
};

export default ProfileHeader;

