import { GrantStatus } from "../../../../../features/entities";
import { useTranslations } from "../../../../../features/providers/translations-provider";
import { StatusTag } from "./styles";

const StatusTags = ({
  application_status,
}: {
  application_status: GrantStatus;
}) => {
  const { t } = useTranslations();
  return (
    <div style={{ display: "flex", gap: 8 }}>
      {application_status === "closingSoon" && (
        <StatusTag $status={"open"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <circle
              cx="6"
              cy="6"
              r="5.25"
              fill="#5C5C5C"
              stroke="#858585"
              strokeWidth="1.5"
            />
          </svg>
          {t(`grant_card_status|open`)}
        </StatusTag>
      )}
      <StatusTag $status={application_status}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <circle
            cx="6"
            cy="6"
            r="5.25"
            fill="#F82E47"
            stroke="#FF88A4"
            strokeWidth="1.5"
          />
        </svg>
        {t(`grant_card_status|${application_status}`)}
      </StatusTag>
    </div>
  );
};

export default StatusTags;

