import styled from "@hellodarwin/core/lib/plugins/styled";

export const MessageCardContainer = styled.div`
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  gap: 19px;
  flex-direction: row;
  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    flex-direction: column;
    align-items: center;
  }
`;

