import { Link } from "react-router-dom";
import { GrantStatus } from "../../../features/entities/grants-entities";
import styled, { css, DefaultTheme } from "../../../plugins/styled";

const TagStyling = css`
  .tag {
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 120px;
    min-height: 26px;
    border-radius: 30px;
    font-size: ${({ theme }) => theme.fonts.body2};
    line-height: ${({ theme }) => theme.fontsLineHeights.body2};
    border-width: 1px;
    padding: 1px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    border-style: solid;
    &.purple2 {
      border-color: ${({ theme }) => theme.colors.purple_4};
      background: ${({ theme }) => theme.colors.purple_5};
      color: ${({ theme }) => theme.colors.purple_2};
    }
  }
`;

const StatusTagStyle = css`
  .status-tag {
    text-transform: capitalize;

    border: 1px solid ${({ theme }) => theme.colors.grey_3};
    background: ${({ theme }) => theme.colors.green_4};

    svg {
      circle {
        fill: ${({ theme }) => theme.colors.grey_3};
        stroke: ${({ theme }) => theme.colors.grey_2};
      }
    }

    &.accepting {
      border-color: ${({ theme }) => theme.colors.green_2};
      background: ${({ theme }) => theme.colors.green_3};
      svg {
        circle {
          fill: #00c288;
          stroke: ${({ theme }) => theme.colors.green_1};
        }
      }
    }
  }
`;

export const GrantCardLink = styled(Link)`
  &,
  &:hover {
    color: inherit;
  }
`;

export const GrantCardContainer = styled.div`
  background: ${({ theme }) => theme.colors.white_1};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &:hover {
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    transform: scale(1.002);
    cursor: pointer;
  }
  .header {
    padding: 24px 32px 20px 17px;
    display: flex;
    gap: 32px;

    .header-infos {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .heading {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;
      }
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tablet}px) {
      flex-direction: column;
      align-items: center;
      .header-infos {
        align-items: center;
        .heading {
          flex-direction: column;
          text-align: center;
          gap: 16px;
        }
        .description {
          text-align: center;
        }
      }
    }
  }

  .body-container {
    border-top: 1px solid ${({ theme }) => theme.colors.grey_4};
    .body {
      display: flex;
      max-width: 90%;
      margin: auto;
      & > div {
        flex: 1;
        border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
        padding: 16px 46px;
        padding-bottom: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-child {
          border: none;
        }
      }
      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.tablet}px) {
        flex-direction: column;
        margin: 0;
        max-width: 100%;
        width: 100%;

        & > div {
          border-right: none;
          padding: 16px 24px;

          border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
        }
      }
    }
  }

  .box {
    .label-container {
      color: ${({ theme }) => theme.colors.grey_2};
      font-size: ${({ theme }) => theme.fonts.overline};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      line-height: ${({ theme }) => theme.fontsLineHeights.overline};
      letter-spacing: 0.33px;
      text-transform: uppercase;
    }
    .content {
      font-size: ${({ theme }) => theme.fonts.body2};
      line-height: ${({ theme }) => theme.fontsLineHeights.body2};
    }
  }
  .title {
    font-family: Figtree;
    font-size: ${({ theme }) => theme.fonts.h6};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: ${({ theme }) => theme.fontsLineHeights.h6};
  }
  .description {
    font-size: ${({ theme }) => theme.fonts.body2};
    line-height: ${({ theme }) => theme.fontsLineHeights.body2};
    color: ${({ theme }) => theme.colors.grey_2};
  }

  ${StatusTagStyle}
  ${TagStyling}
`;

export const getGrantStatusColors = (
  status: GrantStatus,
  theme: DefaultTheme
) => {
  const grantStatusColors: {
    [status: string]: {
      background: string;
      border: string;
      dot: string;
      dotBorder: string;
    };
  } = {
    open: {
      background: theme.colors.green_3,
      border: theme.colors.green_2,
      dot: theme.colors.green_1,
      dotBorder: "#00C288",
    },
    openingSoon: {
      background: theme.colors.purple_4,
      border: theme.colors.purple_3,
      dot: theme.colors.purple_2,
      dotBorder: theme.colors.purple_3,
    },
    closed: {
      background: theme.colors.grey_4,
      border: theme.colors.grey_3,
      dot: theme.colors.grey_2,
      dotBorder: theme.colors.grey_3,
    },
    closingSoon: {
      background: "#FFB9C2",
      border: "#FF88A4",
      dot: theme.colors.error,
      dotBorder: "#FF88A4",
    },
    new: {
      background: "transparent",
      border: "transparent",
      dot: theme.colors.error,
      dotBorder: "#FF88A4",
    },
  };

  return grantStatusColors[status];
};
export const StatusTag = styled.span<{ $status: GrantStatus }>`
  border-radius: 30px;
  font-size: ${({ theme }) => theme.fonts.body2};
  line-height: ${({ theme }) => theme.fontsLineHeights.body2};
  padding: 1px 12px;
  display: flex;
  align-items: center;
  gap: 7px;

  ${({ $status, theme }) =>
    `
      border: 1px solid ${getGrantStatusColors($status, theme).border};
      background: ${getGrantStatusColors($status, theme).background};
      svg {
        circle{
          fill:${getGrantStatusColors($status, theme).dot};
          stroke:${getGrantStatusColors($status, theme).dotBorder};
        }
      }
      `};
`;

