import React from "react";
import { GrantBlock } from "..";
import SingleGrantSections from "../../../../features/enums/single-grant-sections";
import Typography from "../../../common/typography";
import { MenuContainer } from "./styles";
export interface GrantMenuProps {
  sections: SingleGrantSections[];
  blocks: { [block_name: string]: GrantBlock };
}
const Menu = ({ sections, blocks }: GrantMenuProps) => {
  return (
    <MenuContainer>
      {React.Children.toArray(
        sections.map((s) => (
          <a href={`#${blocks[s].id}`}>
            <Typography
              elementTheme="body2"
              style={{ padding: 8, cursor: "pointer" }}
            >
              {blocks[s].menuLabel}
            </Typography>
          </a>
        ))
      )}
    </MenuContainer>
  );
};

export default Menu;

