import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import SingleGrant from "@hellodarwin/core/lib/components/grants/single";
import Loading from "@hellodarwin/core/lib/components/loading";
import GrantApplyModal from "@hellodarwin/core/lib/components/modals/grant-apply-modal";
import SingleGrantSections from "@hellodarwin/core/lib/features/enums/single-grant-sections";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import { selectGrantById } from "../../../features/api/slices/grants-slice";
import { fetchGrant } from "../../../features/api/slices/programs-slice";
import { useClientApi } from "../../../features/api/use-client-api";

const ClientFundingExplorerSingleGrantPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const api = useClientApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => setModalOpen(!modalOpen);

  const params = useParams<{
    id: string;
  }>();
  const grant = useAppSelector((state) =>
    selectGrantById(state, params.id || "")
  );

  useEffect(() => {
    if (!params.id) {
      return;
    }
    dispatch(
      fetchGrant({
        api,
        grantId: params.id,
        locale: selectedLocale,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToFundingExplorer = () => {
    navigate("/funding-explorer");
  };

  if (!params.id) return <Loading />;

  if (!grant) {
    return <Loading />;
  }

  return (
    <PageLayout
      app="client"
      tabTitle={grant?.grant_display_title}
      title={grant?.grant_display_title}
      handleBack={goToFundingExplorer}
      styles={{ container: { maxWidth: theme.caps.normal } }}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.fundingExplorer"),
          path: "/funding-explorer",
        },
        {
          breadcrumbName: !grant
            ? t("grants|singleGrant")
            : grant?.grant_display_title,
        },
      ]}
    >
      <SingleGrant
        grant={grant}
        sections={[
          SingleGrantSections.Summary,
          SingleGrantSections.Preview,
          SingleGrantSections.Projects,
          SingleGrantSections.Admissibility,
          SingleGrantSections.Criteria,
          SingleGrantSections.Register,
          SingleGrantSections.OtherInfo,
          SingleGrantSections.Documents,
          SingleGrantSections.Contact,
        ]}
        onClick={() => setModalOpen(true)}
      />
      <GrantApplyModal
        open={modalOpen}
        handleCancel={handleModal}
      ></GrantApplyModal>
    </PageLayout>
  );
};

export default ClientFundingExplorerSingleGrantPage;

