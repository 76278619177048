import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import ModalLayoutWithIcon from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon";
import { getMeetingUrl } from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useState } from "react";

import HubspotMeeting from "@hellodarwin/core/lib/components/hubspot/hubspot-meeting";
import { AppName } from "@hellodarwin/core/lib/features/entities/core-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import MoreCandidates from "@hellodarwin/icons/dist/icons/MoreCandidates";
import { useAppSelector } from "../../../app/app-hooks";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import {
  selectProjectById,
  selectSelectedProjectId,
} from "../../../features/api/slices/projects-slice";
import ErrorModal from "../../provider/provider-action-modal/error-modal";
type ProjectAdditionalShortlistModalProps = {
  open: boolean;
  handleClose: () => void;
};

const ProjectAdditionalShortlistModal = ({
  open,
  handleClose,
}: ProjectAdditionalShortlistModalProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );

  const [error, setError] = useState<number>();
  const profile = useAppSelector(selectProfile);

  const meetingUrl = getMeetingUrl(selectedLocale, "client");

  const closeError = () => {
    setError(undefined);
  };

  if (!project) return <></>;

  if (!!error)
    return (
      <ErrorModal error={error} isVisible={!!error} onCancel={closeError} />
    );

  return (
    <ModalLayoutWithIcon
      open={open}
      handleCancel={handleClose}
      size="medium"
      Icon={MoreCandidates}
      iconSize={64}
      title={t("project|modal.additionalShortlistings.title")}
      description={t("project|modal.additionalShortlistings.text", {
        raiseHandMax: project.raise_hand_max,
      })}
      Actions={
        <Div flex="column" align="flex-end">
          <Button style={{ flex: 1 }} onClick={handleClose}>
            {t("button.cancel")}
          </Button>
        </Div>
      }
      direction="row"
    >
      <Div className="hubspot-custom-frame">
        <HubspotMeeting
          meetingUrl={meetingUrl}
          name={profile.contact_name}
          email={profile.contact_email}
          app={AppName.Client}
          companyName={profile.company_name}
          phone={profile.contact_phone}
          additionalParams={{
            type: t("project|modal.additionalShortlistings.message"),
          }}
        />
      </Div>
    </ModalLayoutWithIcon>
  );
};

export default ProjectAdditionalShortlistModal;

