import { IconProps } from "../features/entities/general";

const Location = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.2622 13.1555C21.2622 14.3626 20.7827 15.5202 19.9292 16.3737C19.0757 17.2272 17.9181 17.7067 16.7111 17.7067C15.5041 17.7067 14.3465 17.2272 13.493 16.3737C12.6395 15.5202 12.16 14.3626 12.16 13.1555C12.16 11.9485 12.6395 10.7909 13.493 9.93743C14.3465 9.08393 15.5041 8.60444 16.7111 8.60444C17.9181 8.60444 19.0757 9.08393 19.9292 9.93743C20.7827 10.7909 21.2622 11.9485 21.2622 13.1555Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0889 13.1555C28.0889 23.9902 16.7111 30.2222 16.7111 30.2222C16.7111 30.2222 5.33331 23.9902 5.33331 13.1555C5.33331 10.138 6.53204 7.24399 8.66579 5.11025C10.7995 2.9765 13.6935 1.77777 16.7111 1.77777C19.7287 1.77777 22.6226 2.9765 24.7564 5.11025C26.8901 7.24399 28.0889 10.138 28.0889 13.1555Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Location;
