import { IconProps } from "../features/entities/general";

const EmptyProject = ({
  width = 180,
  height = 171,
  className,
  onClick,
  style,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox="0 0 106 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="104.814"
        height="90"
        rx="3.5"
        fill="#FDFCFA"
        stroke="#BBBBBB"
        stroke-dasharray="2 2"
      />
      <rect
        x="31.7441"
        y="8.99414"
        width="42.3256"
        height="42.3256"
        fill="#EEEEEE"
      />
      <line
        x1="72.9829"
        y1="64.0175"
        x2="72.9829"
        y2="71.5567"
        stroke="#EEEEEE"
      />
      <rect
        x="20.105"
        y="64.0175"
        width="46.0291"
        height="7.40698"
        rx="3.70349"
        fill="#EEEEEE"
      />
      <path
        d="M82.342 64.315C82.4149 64.1462 82.6551 64.1462 82.7281 64.315L83.6224 66.3833C83.6528 66.4536 83.7194 66.5018 83.7961 66.5089L86.0474 66.7169C86.2311 66.7339 86.3053 66.9614 86.1668 67.0826L84.4681 68.5689C84.4103 68.6195 84.3848 68.6975 84.4018 68.7722L84.8989 70.969C84.9394 71.1483 84.7451 71.2889 84.5865 71.1951L82.6424 70.0453C82.5762 70.0062 82.4939 70.0062 82.4277 70.0453L80.4836 71.1951C80.325 71.2889 80.1306 71.1483 80.1712 70.969L80.6683 68.7722C80.6852 68.6975 80.6598 68.6195 80.602 68.5689L78.9033 67.0826C78.7647 66.9614 78.8389 66.7339 79.0226 66.7169L81.274 66.5089C81.3506 66.5018 81.4172 66.4536 81.4477 66.3833L82.342 64.315Z"
        fill="#EEEEEE"
      />
    </svg>
  );
};
export default EmptyProject;

