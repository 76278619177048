import styled from "@hellodarwin/core/lib/plugins/styled";
import {
  PROJECT_DEFAULT_HEIGHT,
  PROJECT_DEFAULT_WIDTH,
  PROJECT_OVERVIEW_HEIGHT,
  PROJECT_OVERVIEW_WIDTH,
} from ".";

export const ProviderRowContainer = styled.div<{ $isOverview?: boolean }>`
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  position: relative;
  padding: ${({ $isOverview }) => ($isOverview ? "8px 16px" : "10px 13px")};
  width: ${({ $isOverview }) =>
    $isOverview ? PROJECT_OVERVIEW_WIDTH : PROJECT_DEFAULT_WIDTH}px;
  height: ${({ $isOverview }) =>
    $isOverview ? PROJECT_OVERVIEW_HEIGHT : PROJECT_DEFAULT_HEIGHT}px;

  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    width: 100%;
  }
  &:hover {
    transform: scale(1.01);
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
  }
`;

