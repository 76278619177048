import dayjs from "dayjs";
import("dayjs/locale/en");
import("dayjs/locale/fr");

// getFormattedDate returns formatted date from a string
export const getFormattedDate = (
  date: string | undefined,
  locale: string = "en"
) => {
  return dayjs(date).locale(locale).format("DD/MM/YYYY HH:mm:ss");
};

export const getShortFormattedDate = (
  date: string | undefined,
  locale: string = "en"
) => {
  return dayjs(date).locale(locale).format("DD/MM/YYYY");
};

// getStringDate returns a date in string form from a Date
export const getStringDate = (
  date: Date,
  locale: string | undefined
): string => {
  if (locale == undefined) {
    locale = "en";
  }
  if (locale === "fr") {
    return dayjs(date).locale(locale).format("DD MMMM YYYY");
  } else if (locale === "en") {
    return dayjs(date).locale(locale).format("MMMM DD, YYYY");
  } else {
    return date.toString();
  }
};

export const getStringDateFromString = (
  date: string,
  locale: string | undefined
): string => {
  if (locale == undefined) {
    locale = "en";
  }
  if (locale === "fr") {
    return dayjs(date).locale(locale).format("DD MMMM YYYY");
  } else if (locale === "en") {
    return dayjs(date).locale(locale).format("MMMM DD, YYYY");
  } else {
    return date.toString();
  }
};

export default getFormattedDate;
