import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Loading from "@hellodarwin/core/lib/components/loading";
import ProgramSingle from "@hellodarwin/core/lib/components/programs/single";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import { RootState } from "../../../app/app-store";
import {
  fetchAdmin,
  selectAdminByAdminId,
} from "../../../features/api/slices/global-slice";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import {
  GetProgram,
  fetchGrant,
  selectGrantById,
  selectProgramById,
} from "../../../features/api/slices/programs-slice";
import { useClientApi } from "../../../features/api/use-client-api";

const ClientSingleRoadmapProgram = () => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { programId } = useParams<{
    programId: string;
  }>();

  const profile = useAppSelector(selectProfile);
  const program = useAppSelector((state) =>
    selectProgramById(state, programId || "")
  );

  const admin = useAppSelector((state) =>
    selectAdminByAdminId(state, program?.program_account_manager || "default")
  );

  const { selectedLocale } = useLocale();

  useEffect(() => {
    dispatch(
      GetProgram({ api, programId: programId || "", locale: selectedLocale })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (admin === undefined && !!program?.program_account_manager) {
      const oauthId = program?.program_account_manager.includes(":")
        ? program?.program_account_manager.split(":")?.[1]
        : program?.program_account_manager;

      dispatch(fetchAdmin({ api, adminId: oauthId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  const grant = useAppSelector((state: RootState) =>
    selectGrantById(state, program?.program_grant_id || "")
  );

  useEffect(() => {
    if (!!program && program?.program_grant_id) {
      dispatch(
        fetchGrant({
          api,
          grantId: `${program?.program_grant_id}`,
          locale: selectedLocale,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  const goBack = () => {
    navigate(`/programs/roadmap`);
  };

  if (!program) return <Loading />;

  return (
    <PageLayout
      handleBack={goBack}
      app="client"
      title={program?.program_name}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.programs"),
          path: "/programs",
        },
        {
          breadcrumbName: t("navigation.ongoingPrograms"),
          path: "/programs/roadmap",
        },
        {
          breadcrumbName: program?.program_name,
        },
      ]}
    >
      <ProgramSingle
        {...program}
        grant={grant}
        admin={admin}
        profile={profile}
      />
    </PageLayout>
  );
};

export default ClientSingleRoadmapProgram;

