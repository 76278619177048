import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import { ModalProps } from "@hellodarwin/core/lib/components/common/hd-modal";
import ModalLayoutSimple from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-simple";
import ModalLayoutWithIcon from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import HubspotMeeting from "@hellodarwin/core/lib/components/hubspot/hubspot-meeting";
import { AppName } from "@hellodarwin/core/lib/features/entities";
import { getMeetingUrl } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import GreenCheck from "@hellodarwin/icons/dist/icons/GreenCheck";
import Projects from "@hellodarwin/icons/dist/icons/Projects";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/app-hooks";
import { useClientAnalytics } from "../../../../features/analytics/use-client-analytics";
import { selectProfile } from "../../../../features/api/slices/profile-slice";
import {
  fetchTags,
  selectTags,
} from "../../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../../features/api/use-client-api";
import ProjectActionCard from "./project-action-card";
import ProjectSubmitForm from "./project-submit-form";
type ClientSubmitProjectModalsProps = {
  open: boolean;
  handleClose: () => void;
};

enum SubmitProjectFlowSteps {
  meeting = "Meeting",
  success = "Success",
  form = "Form",
  options = "Options",
}

const ClientSubmitProjectModal = ({
  open,
  handleClose,
}: ClientSubmitProjectModalsProps) => {
  const analytics = useClientAnalytics();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const dispatch = useAppDispatch();
  const api = useClientApi();
  const tags = useAppSelector(selectTags);
  const profile = useAppSelector(selectProfile);
  const [step, setStep] = useState<SubmitProjectFlowSteps>(
    SubmitProjectFlowSteps.options
  );
  const [modalSize, setModalSize] = useState<ModalProps["size"]>("full");

  useEffect(() => {
    if (!open && tags && tags.length > 0) return;
    dispatch(fetchTags({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step === SubmitProjectFlowSteps.success) setModalSize("small");
    if (step === SubmitProjectFlowSteps.meeting) setModalSize("medium");
    if (step === SubmitProjectFlowSteps.options) setModalSize("large");
    if (step === SubmitProjectFlowSteps.form) setModalSize("medium");
  }, [step]);

  const handleModalClose = () => {
    setStep(SubmitProjectFlowSteps.options);
    handleClose();
    setModalSize("full");
  };

  const openMeeting = () => {
    setStep(SubmitProjectFlowSteps.meeting);
    analytics.meetingClicked();
  };

  const meetingUrl = useMemo(
    () => getMeetingUrl(selectedLocale, "client"),
    [selectedLocale]
  );
  return step === SubmitProjectFlowSteps.meeting ? (
    <ModalLayoutWithIcon
      open={open}
      handleCancel={handleModalClose}
      size={modalSize}
      Icon={Timeline}
      iconSize={32}
      styles={{
        body: { padding: 24, paddingTop: 54 },
        content: { padding: 0 },
      }}
      title={t("message.talkToManager")}
      Actions={
        <Div flex="column" align="flex-end">
          <Button
            size="small"
            style={{ flex: 1 }}
            onClick={() => setStep(SubmitProjectFlowSteps.options)}
          >
            {t("button.cancel")}
          </Button>
        </Div>
      }
      direction="row"
    >
      <HubspotMeeting
        meetingUrl={meetingUrl}
        name={profile.contact_name}
        email={profile.contact_email}
        app={AppName.Client}
        companyName={profile.company_name}
        phone={profile.contact_phone}
      />
    </ModalLayoutWithIcon>
  ) : step === SubmitProjectFlowSteps.form ? (
    <ModalLayoutSimple
      open={open}
      handleCancel={handleModalClose}
      size={modalSize}
    >
      <ProjectSubmitForm
        closeModal={() => setStep(SubmitProjectFlowSteps.options)}
        openSuccesModal={() => setStep(SubmitProjectFlowSteps.success)}
        tags={tags}
      />
    </ModalLayoutSimple>
  ) : step === SubmitProjectFlowSteps.success ? (
    <ModalLayoutSimple
      open={open}
      handleCancel={handleModalClose}
      size={modalSize}
    >
      <Div
        flex={"column"}
        justify={"center"}
        align={"center"}
        className="fade-in"
        gap={32}
      >
        <GreenCheck width={200} />

        <Div flex="column" gap={24} align="center">
          <Div flex="column" gap={12} align="center">
            <Typography elementTheme="subtitle1" bold fitContent>
              {t("project|projectForm.successModal.title")}
            </Typography>
            <Typography
              elementTheme="body2"
              className={"hd-2-mb"}
              textAlign={"center"}
              fitContent
            >
              {t("project|projectForm.successModal.text")}
            </Typography>
          </Div>
          <Button
            onClick={handleModalClose}
            defaultStyle={theme.colors.purple_1}
            fitContent
          >
            Ok
          </Button>
        </Div>
      </Div>
    </ModalLayoutSimple>
  ) : (
    step === SubmitProjectFlowSteps.options && (
      <ModalLayoutSimple
        open={open}
        handleCancel={handleModalClose}
        size={modalSize}
      >
        <Div flex="column" justify={"center"} align={"center"} gap={32}>
          <Typography.Title level={4}>
            {t("project|projectHeader.headerTitle")}
          </Typography.Title>
          <Div
            flex={"row"}
            tablet={{ flex: "column", align: "center" }}
            gap={24}
            justify={"center"}
            align={"stretch"}
          >
            <ProjectActionCard
              onClick={openMeeting}
              title={t("project|projectActionCard.bookAMeeting.title")}
              text={t("project|projectActionCard.bookAMeeting.text")}
              Icon={Timeline}
            />
            <ProjectActionCard
              onClick={() => setStep(SubmitProjectFlowSteps.form)}
              title={t("project|projectActionCard.submitAProject.title")}
              text={t("project|projectActionCard.submitAProject.text")}
              Icon={Projects}
            />
          </Div>
        </Div>
      </ModalLayoutSimple>
    )
  );
};

export default ClientSubmitProjectModal;

