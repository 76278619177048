import { ReactNode } from "react";
import Modal, { ModalProps } from "..";
import parse from "../../../../features/helpers/parse";
import { useTheme } from "../../../../plugins/styled";
import Avatar from "../../avatar";
import Div from "../../div";
import Typography from "../../typography";

export type ModalLayoutWithIconSize = 24 | 32 | 64 | 128;

export interface ModalLayoutWithIconProps extends ModalProps {
  avatarUrl?: string;
  avatarName: string;
  iconSize?: ModalLayoutWithIconSize;
  title: string;
  description?: string;
  warning?: string;
  children: ReactNode;
  Actions: ReactNode;
}

const ModalLayoutWithIcon = ({
  avatarUrl,
  avatarName,
  iconSize = 64,
  title,
  description,
  warning,
  children,
  Actions,
  ...modalProps
}: ModalLayoutWithIconProps) => {
  const theme = useTheme();
  const logoContainerSize = 90;

  return (
    <Modal
      {...modalProps}
      styles={{
        ...modalProps?.styles,
        body: {
          padding: 24,
          overflow: "auto",
          height: "100%",
          marginTop: -100,
          ...modalProps?.styles?.body,
        },

        content: {
          padding: `${logoContainerSize / 2}px 0 0 0`,
          overflow: "visible",
          height: "100%",
          maxHeight: `calc(95vh - ${logoContainerSize}px)`,
          ...modalProps?.styles?.content,
        },
      }}
    >
      <Div flex="column" gap={32} align="center">
        <Div flex="column" gap={16} align="center" style={{ width: "80%" }}>
          <Avatar
            shape="round"
            size={iconSize}
            src={avatarUrl}
          >
            {avatarName[0]}
          </Avatar>
          <Typography.Title level={2} elementTheme="h4" textAlign="center">
            {title}
          </Typography.Title>
          {description && (
            <Typography
              elementTheme="body2"
              color={theme.colors.grey_2}
              textAlign="center"
            >
              {parse(description)}
            </Typography>
          )}

          {!!warning && (
            <Typography
              bold
              elementTheme="body2"
              letterSpacing={0.32}
              textAlign="center"
            >
              {warning}
            </Typography>
          )}
        </Div>
        <Div>{children}</Div>

        <Div
          flex="row"
          gap={24}
          align="center"
          fitMaxContent
          style={{ width: "100%" }}
          tablet={{ flex: "column-reverse" }}
        >
          {Actions}
        </Div>
      </Div>
    </Modal>
  );
};

export default ModalLayoutWithIcon;

