import { GrantStatus } from "../../../../../features/entities/grants-entities";
import styled from "../../../../../plugins/styled";

export const SummaryBoxContainer = styled.div`
  padding: 26px 32px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
  }
  &:last-child {
    border: none;
  }
`;

export const StatusTag = styled.span<{ $status: GrantStatus }>`
  border-radius: 30px;
  font-size: ${({ theme }) => theme.fonts.body2};
  line-height: ${({ theme }) => theme.fontsLineHeights.body2};
  padding: 1px 12px;
  display: flex;
  align-items: center;
  gap: 7px;

  ${({ $status, theme }) =>
    $status === "open"
      ? `
      width: 80px;
      border: 1px solid ${theme.colors.green_2};
      background: ${theme.colors.green_3};
      svg {
        circle{
          fill:#00C288;
          stroke:${theme.colors.green_1};
        }
      }
      `
      : $status === "closed"
        ? `
        width: 85px;
        border: 1px solid ${theme.colors.grey_3};
        background: ${theme.colors.grey_4};
        svg {
          circle{
            fill:${theme.colors.grey_2};
            stroke:${theme.colors.grey_3};
          }
        }
        `
        : $status === "closingSoon"
          ? `
          width: 140px;
          border: 1px solid #FF88A4;
          background: #FFB9C2;
          svg {
            circle{
              fill:${theme.colors.error};
              stroke:#FF88A4;
            }
          }
          `
          : $status === "openingSoon"
            ? `
            width: 140px;
            border: 1px solid ${theme.colors.purple_3};
            background: ${theme.colors.purple_4};
            svg {
              circle{
                fill:${theme.colors.purple_2};
                stroke:${theme.colors.purple_3};
              }
            }
            `
            : $status === "new"
              ? `
              width: 80px;
            border: 1px solid ${theme.colors.yellow_2};
            background: ${theme.colors.yellow_3};
            svg {
              circle{
                fill:${theme.colors.yellow_1};
                stroke:${theme.colors.yellow_2};
              }
            }
            `
              : undefined};
`;

