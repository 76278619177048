import { ReactNode } from "react";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import { FormLabelContainer } from "./styles";

interface FormLabelProps {
  label: ReactNode;
  extra?: ReactNode;
  actions?: ReactNode;
  color?: string;
  onClick?: () => void;
}

const Label = (props: FormLabelProps) => {
  const { label, extra, color, onClick, actions } = props;
  const theme = useTheme();
  return (
    <FormLabelContainer
      $withExtra={!!extra}
      onClick={!actions ? onClick : undefined}
    >
      <Typography elementTheme="body2" className="hd-form-label" color={color}>
        {label}
      </Typography>
      {!!extra && (
        <Typography
          elementTheme="body3"
          className="hd-form-extra"
          color={color ?? theme.colors.grey_2}
        >
          {extra}
        </Typography>
      )}
    </FormLabelContainer>
  );
};

const FormLabel = (props: FormLabelProps) => {
  const { actions, onClick } = props;

  return !!actions ? (
    <Div
      flex="row"
      gap={8}
      align="center"
      justify="space-between"
      style={{ width: "100%" }}
      onClick={onClick}
    >
      <Label {...props} />
      {actions}
    </Div>
  ) : (
    <Label {...props} />
  );
};
export default FormLabel;

