import { FormInstance } from "antd/es/form";
import { SelectProps } from "antd/es/select";
import { ReactNode, useState } from "react";
import { useTheme } from "styled-components";
import { GrantProject } from "../../../features/entities/grants-entities";
import FiltersDropdown, { FiltersDropdownProps } from "./filter-dropdown";
import FilterHeader from "./filter-header";
import { FilterSectionContainterStyled, FilterSectionStyled } from "./styles";
import { FundingExplorerFilterValues } from "./types";

export interface FundingExplorerFilterProps {
  filterform: FormInstance<FundingExplorerFilterValues>;
  financingType: SelectProps["options"];
  industries: SelectProps["options"];
  subIndustries: SelectProps["options"];
  provinces: SelectProps["options"];
  services: SelectProps["options"];
  annualRevenue: SelectProps["options"];
  companySize: SelectProps["options"];
  applyFilter: () => Promise<void>;
  resetFilter: () => void;
  selectedIndustries: string[];
  setSelectedIndustries: (values: string[]) => void;
  onTextQueryChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  TagsCollapse?: () => ReactNode;
  bestProjects?: GrantProject[];
  selectedProjects?: string[];
  handleProjectSelection: (projectId: string) => void;
  isAdmin?: boolean;
}

const FundingExplorerFilter = (props: FundingExplorerFilterProps) => {
  const theme = useTheme();

  const [advancedFiltersOpened, setAdvancedFiltersOpen] = useState(false);
  const handleAdvancedFilters = () =>
    setAdvancedFiltersOpen(!advancedFiltersOpened);

  const { handleProjectSelection, filterform } = props;

  const dropdownProps: FiltersDropdownProps = {
    isOpen: advancedFiltersOpened,
    handleClose: handleAdvancedFilters,
    ...props,
  };

  return (
    <FilterSectionContainterStyled $position="top">
      <FilterSectionStyled $position="top">
        <FilterHeader
          {...props}
          advancedFiltersOpened={advancedFiltersOpened}
          handleAdvancedFilters={handleAdvancedFilters}
          handleProjectSelection={handleProjectSelection}
        />
      </FilterSectionStyled>
      <FiltersDropdown {...dropdownProps} />
    </FilterSectionContainterStyled>
  );
};

export default FundingExplorerFilter;

