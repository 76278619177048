import { IconProps } from "../features/entities/general";

const Dots = ({
  width = "4",
  height = "17",
  color = "currentColor",
  className,
  style,
  onClick,
}: IconProps) => (
  <div
    className={className}
    style={{
      ...style,
      width,
      height,
    }}
  >
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill={color} />
      <circle cx="2" cy="8.5" r="2" transform="rotate(90 2 8.5)" fill={color} />
      <circle cx="2" cy="15" r="2" transform="rotate(90 2 15)" fill={color} />
    </svg>
  </div>
);

export default Dots;
