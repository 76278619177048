import styled from "../../../plugins/styled";
import Div from "../div";

export const TimelineContainer = styled(Div)`
  .ant-timeline-item {
    &.active {
      .ant-timeline-item-tail {
        border-inline-start: 2px dashed ${({ theme }) => theme.colors.purple_4};
      }
      .ant-timeline-item-content {
        color: ${({ theme }) => theme.colors.purple_3};
      }
    }
    &.current {
      .ant-timeline-item-head-blue {
        color: ${(props) => props.theme.colors.purple_1};
        border-color: ${(props) => props.theme.colors.purple_1};
      }
      .ant-timeline-item-content {
        color: ${(props) => props.theme.colors.purple_2};
      }
    }
    &.completed {
      .ant-timeline-item-tail {
        border-inline-start: 2px solid ${(props) => props.theme.colors.grey_3};
      }
    }
  }
`;
