import { IconProps } from "../features/entities/general";

const ProjectTags = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6157 3.24362L21.4037 1.86074C22.2036 1.66963 23.0486 1.81169 23.7528 2.25566C24.4571 2.69964 24.9628 3.40916 25.1588 4.22815L26.5773 10.1544C26.7734 10.9737 26.6439 11.836 26.2166 12.549L16.5211 28.7667C15.8137 29.9499 14.4215 30.5335 13.1349 30.0545C9.89678 28.8501 6.92934 26.9792 4.41803 24.559C3.41984 23.5976 3.29663 22.0585 4.00399 20.8754L13.6985 4.65928C13.9099 4.3052 14.188 3.99792 14.517 3.75501C14.8459 3.5121 15.2193 3.33832 15.6157 3.24362Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3839 6.21826L21.3743 6.22054L21.3767 6.2303L21.3862 6.22802L21.3839 6.21826Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ProjectTags;
