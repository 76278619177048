import { CSSProperties, PropsWithChildren } from "react";
import { useTranslations } from "../../../../features/providers/translations-provider";
import Button from "../../../common/button";
import Typography from "../../../common/typography";
import {
  GrantSectionContainer,
  GrantSectionContent,
  GrantSectionHeaderContent,
} from "./styles";

export interface GrantSectionProps {
  title: string;
  id: string;
  styles?: {
    body?: CSSProperties;
    header?: CSSProperties;
  };
  applyButton: boolean;
  onClick?: () => void;
}
const GrantSection = ({
  title,
  id,
  children,
  styles,
  applyButton,
  onClick,
}: GrantSectionProps & PropsWithChildren) => {
  const { t } = useTranslations();
  const SectionHeader = () => (
    <GrantSectionHeaderContent style={{ ...styles?.header }}>
      <Typography.Title noMargin level={2} elementTheme="h4">
        {title}
      </Typography.Title>
      {applyButton && !!onClick && (
        <Button size="small" onClick={onClick}>
          {t("grant_single|applyNow")}
        </Button>
      )}
    </GrantSectionHeaderContent>
  );

  return (
    <GrantSectionContainer style={{ width: "100%" }} id={id}>
      <SectionHeader />
      <GrantSectionContent style={{ ...styles?.body }}>
        {children}
      </GrantSectionContent>
    </GrantSectionContainer>
  );
};

export default GrantSection;

