import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  OnBoardingSteps,
  useOnBoarding,
} from "@hellodarwin/core/lib/context/onboarding-provider";
import { ClientOnboardRequest } from "@hellodarwin/core/lib/features/entities";
import theme from "@hellodarwin/core/lib/theme";
import { useAppDispatch } from "../../app/app-hooks";
import { onboard } from "../../features/api/slices/profile-slice";
import { useClientApi } from "../../features/api/use-client-api";
import ClientOnBoardingProfilePage, {
  CompanyDetailsFormValues,
} from "./components/client-onboarding-profile-page";

const ClientOnBoardingPage = () => {
  const { currentStep } = useOnBoarding();

  const api = useClientApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSave = async (values: CompanyDetailsFormValues) => {
    try {
      const request: ClientOnboardRequest = {
        company_name: values.company_name,
        annual_revenue: values.annual_revenue,
      };
      await dispatch(onboard({ api, data: request }));
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  const Content = useMemo(() => {
    switch (currentStep) {
      case OnBoardingSteps.CompanyDetails:
        return <ClientOnBoardingProfilePage handleSave={handleSave} />;
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <div
      style={{
        maxWidth: theme.breakpoints.xl,
        width: "100%",
        margin: "0 auto",
      }}
    >
      {Content}
    </div>
  );
};

export default ClientOnBoardingPage;

