import { IconProps } from "../features/entities/general";

const CancelProject = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.0566 26.0566C28.7238 23.3894 30.2222 19.772 30.2222 16C30.2222 12.228 28.7238 8.61055 26.0566 5.94336C23.3894 3.27618 19.772 1.77777 16 1.77777C12.228 1.77777 8.61055 3.27618 5.94336 5.94336M26.0566 26.0566C23.3894 28.7238 19.772 30.2222 16 30.2222C12.228 30.2222 8.61055 28.7238 5.94336 26.0566C3.27618 23.3894 1.77777 19.772 1.77777 16C1.77777 12.228 3.27618 8.61055 5.94336 5.94336M26.0566 26.0566L5.94336 5.94336"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default CancelProject;

