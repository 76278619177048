import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Tabs from "@hellodarwin/core/lib/components/common/tabs";
import { ProfileEditModalFunctions } from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal";
import ClientEditModal from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal/client-edit-modal";
import ProfileCompanyForm from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-form/profile-company-form";
import ProfileContactForm from "@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-form/profile-contact-form";
import ProfileUsersList from "@hellodarwin/core/lib/components/profile/profile-users-list";
import {
  Company,
  Contact,
  ProfileModalProps,
} from "@hellodarwin/core/lib/features/entities";
import SingleLanguage from "@hellodarwin/core/lib/features/enums/single-language";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import notification from "antd/es/notification";
import { TabsProps } from "antd/es/tabs";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  selectProfileModal,
  toggleProfileModal,
} from "../../features/api/slices/global-slice";
import {
  deleteCompanyUser,
  deleteLogo,
  fetchCompany,
  fetchCompanyUsers,
  fetchContact,
  getContactByEmail,
  selectCompany,
  selectCompanyUsers,
  selectContact,
  selectProfile,
  selectProfileLoading,
  sendInvitationEmail,
  updateCompany,
  updateContact,
  updateContactById,
  updateLogo,
  upsertCompanyUser,
} from "../../features/api/slices/profile-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from "../../features/api/slices/tags-slice";
import { useClientApi } from "../../features/api/use-client-api";

type SettingsTab = "contact" | "company" | "users";
interface ClientSettingsPageProps {
  defaultActiveKey?: SettingsTab;
}

const ClientSettingsPage = ({
  defaultActiveKey = "contact",
}: ClientSettingsPageProps) => {
  const { t } = useTranslation();
  const { changeLanguage } = useLocale();
  const company = useAppSelector(selectCompany);
  const contact = useAppSelector(selectContact);
  const users = useAppSelector(selectCompanyUsers);
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const industrySectors = useAppSelector(selectIndustriesSectors);
  const industrySubsectors = useAppSelector(selectIndustriesSubsectors);

  useEffect(() => {
    dispatch(fetchCompany({ api }));
    dispatch(fetchContact({ api }));
    dispatch(fetchCompanyUsers({ api }));
    dispatch(fetchIndustries({ api, locale: "en" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveContact = (newContact: Contact) => {
    try {
      const data = new FormData();
      data.append("request", JSON.stringify(newContact));

      dispatch(updateContact({ api, data }));
      notification.success({
        message: t("notification.saved"),
        placement: "top",
      });
    } catch (error) {
      notification.error({
        message: t("notification.saving_error"),
        placement: "top",
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await changeLanguage(
          contact?.preferred_language || SingleLanguage.English
        );
      } catch (e: any) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.preferred_language]);

  const handleSaveCompany = (newCompany: Company) => {
    try {
      const data = new FormData();
      data.append("request", JSON.stringify(newCompany));

      dispatch(updateCompany({ api, data }));

      notification.success({
        message: t("notification.saved"),
        placement: "top",
      });
    } catch (error) {
      notification.error({
        message: t("notification.saving_error"),
        placement: "top",
      });
    }
  };

  const handleProfileModal = (props: ProfileModalProps) => {
    dispatch(toggleProfileModal(props));
  };
  const handleProfileModalCancel = () => {
    handleProfileModal({ open: false });
  };

  const modal = useAppSelector(selectProfileModal);
  const profile = useAppSelector(selectProfile);
  const isLoading = useAppSelector(selectProfileLoading);
  const functions: ProfileEditModalFunctions = {
    isLoading,
    updateLogo: ({ companyId, data }) =>
      dispatch(updateLogo({ api, data, companyId })),
    deleteLogo: ({ companyId }) => dispatch(deleteLogo({ api, companyId })),
  };

  const handleContactDelete = async (contactID: string) => {
    return await dispatch(deleteCompanyUser({ api, contactID }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const data = new FormData();
    data.append("request", JSON.stringify(contact));
    return await dispatch(upsertCompanyUser({ api, data }));
  };

  const handleContactAssign = async (contact: Contact) => {
    if (contact.email) {
      return await dispatch(sendInvitationEmail({ api, email: contact.email }));
    }
  };
  const handleEmailExists = async (email: string) => {
    if (users.find((u) => u.email === email)) {
      return Promise.reject("Email already exists");
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const handleContactEdit = async (contact: Contact) => {
    const data = new FormData();
    data.append("request", JSON.stringify(contact));

    return await dispatch(updateContactById({ api, data }));
  };

  const tabs: TabsProps["items"] = useMemo(() => {
    return [
      {
        label: t("profile|profileForm.contact.title"),
        key: "contact",
        children: (
          <ProfileContactForm
            contact={contact}
            handleSave={handleSaveContact}
          />
        ),
      },
      {
        label: t("profile|profileForm.company.title"),
        key: "company",
        children: (
          <ProfileCompanyForm
            company={company}
            toggleEditModal={handleProfileModal}
            handleSave={handleSaveCompany}
            industrySectors={industrySectors}
            industrySubsectors={industrySubsectors}
          />
        ),
        forceRender: true,
      },
      {
        label: t("profile|profileForm.users.title"),
        key: "users",
        children: (
          <ProfileUsersList
            contactId={contact.contact_id}
            companyId={company.company_id}
            users={users}
            handleContactDelete={handleContactDelete}
            handleContactAdd={handleContactAdd}
            handleContactAssign={handleContactAssign}
            handleEmailExists={handleEmailExists}
            handleContactEdit={handleContactEdit}
            destination={"Client"}
            isLoading={isLoading}
            isAccountManager={contact.roles?.includes("Account Manager")}
          />
        ),
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, company, users, t]);

  const navigate = useNavigate();

  const handleTabClick = (activeKey: string) => {
    navigate(`/profile/${activeKey}`);
  };

  return (
    <PageLayout
      app="client"
      title={t("profile|profileForm.informationTitle")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.mySettings"),
        },
      ]}
    >
      <Container>
        <Tabs
          items={tabs}
          defaultActiveKey={defaultActiveKey}
          onTabClick={handleTabClick}
        />
      </Container>
      <ClientEditModal
        functions={functions}
        modal={modal}
        handleCancel={handleProfileModalCancel}
        profile={profile}
      />
    </PageLayout>
  );
};

export default ClientSettingsPage;

