import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Empty from "antd/es/empty";
import { useNavigate } from "react-router-dom";

const ProjectEmptyState = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/projects");
  };
  return (
    <Div flex="column" fullHeight align="center" justify="center" gap={24}>
      <Empty description={t("project|noProjects.all")} />
      <Button onClick={handleClick} size="small">
        {t("project|returnProject")}
      </Button>
    </Div>
  );
};
export default ProjectEmptyState;

