import { IconProps } from "../features/entities/general";

const Check = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.2593 16.1852L13.8149 19.7407L19.7408 11.4444M29.2223 15C29.2223 16.8677 28.8544 18.7171 28.1397 20.4426C27.4249 22.1681 26.3773 23.736 25.0567 25.0566C23.736 26.3773 22.1682 27.4249 20.4427 28.1396C18.7171 28.8544 16.8677 29.2222 15.0001 29.2222C13.1324 29.2222 11.283 28.8544 9.55745 28.1396C7.83193 27.4249 6.26408 26.3773 4.94342 25.0566C3.62277 23.736 2.57517 22.1681 1.86043 20.4426C1.1457 18.7171 0.777832 16.8677 0.777832 15C0.777832 11.228 2.27624 7.61055 4.94342 4.94336C7.61061 2.27618 11.2281 0.777771 15.0001 0.777771C18.772 0.777771 22.3895 2.27618 25.0567 4.94336C27.7239 7.61055 29.2223 11.228 29.2223 15Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Check;

