import { ReactNode } from "react";
import Modal, { ModalProps } from "..";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import Div from "../../div";
import Typography from "../../typography";

// *** MODAL HEADER ***
type HeaderProps = {
  title: string;
  action?: ReactNode;
};

const Header = ({ title, action }: HeaderProps) => {
  const theme = useTheme() as DefaultTheme;

  return (
    <Div
      className="modal-header"
      style={{ padding: "15px 20px" }}
      bordersSide="bottom"
      borderColor={theme.colors.primary}
      flex="row"
      justify="space-between"
      tablet={{ style: { padding: 12 } }}
    >
      <Typography elementTheme="subtitle1" bold letterSpacing={-0.2}>
        {title}
      </Typography>
      {action}
    </Div>
  );
};

// *** MODAL FOOTER ***

type FooterProps = {
  actions: ReactNode;
};

const Footer = ({ actions }: FooterProps) => {
  const theme = useTheme() as DefaultTheme;

  return (
    <Div
      bordersSide="top"
      style={{ padding: "15px 20px", boxSizing: "border-box" }}
      borderColor={theme.colors.primary}
      flex="row"
      align="center"
      gap={10}
      justify="flex-end"
      tablet={{ flex: "column", style: { padding: 12 } }}
    >
      {actions}
    </Div>
  );
};

// *** MODAL CONTENT ***

type ModalWithHeaderFooterProps = {
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  noPadding?: boolean;
} & ModalProps;
const ModalWithHeaderFooter = ({
  header,
  footer,
  children,
  noPadding,
  ...modalProps
}: ModalWithHeaderFooterProps) => {
  return (
    <Modal
      {...modalProps}
      styles={{
        ...modalProps?.styles,
        content: { padding: 0, ...modalProps?.styles?.content },
      }}
    >
      <Div flex="column" style={{ maxHeight: "80vh", boxSizing: "border-box" }}>
        {header}
        <Div
          className="modal-body"
          style={{
            padding: noPadding ? undefined : "15px 20px",
            overflow: "auto",
            boxSizing: "border-box",
          }}
          tablet={{ style: { padding: 12 } }}
        >
          {children}
        </Div>
        {footer}
      </Div>
    </Modal>
  );
};
ModalWithHeaderFooter.Header = Header;
ModalWithHeaderFooter.Footer = Footer;

export default ModalWithHeaderFooter;

