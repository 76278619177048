import { ModalProps as AntdModalProps } from "antd/es/modal";
import { ReactNode } from "react";
import { ModalContainer } from "./styles";
export { default as ModalConfirm } from "antd/es/modal/confirm";
export interface ModalProps extends AntdModalProps {
  children: ReactNode;
  open: boolean;
  handleCancel: () => void;
  destroyOnClose?: boolean;
  size?: "small" | "medium" | "large" | "extra-large" | "full";
  noPadding?: boolean;
}

const Modal = ({
  children,
  open,
  handleCancel,
  destroyOnClose,
  size = "medium",
  noPadding,
  className,
  styles,
  style,
  ...modalProps
}: ModalProps) => {
  return (
    <ModalContainer
      open={open}
      onCancel={handleCancel}
      footer={null}
      title={null}
      style={{ ...style }}
      styles={{
        ...styles,
        body: {
          padding: 0,
          ...styles?.body,
        },

        content: {
          padding: noPadding ? 0 : 32,
          borderRadius: 4,
          overflow: "hidden",
          position: "relative",
          ...styles?.content,
        },
      }}
      destroyOnClose={destroyOnClose}
      className={className}
      $size={size}
      {...modalProps}
    >
      {children}
    </ModalContainer>
  );
};

export default Modal;

