import Progress from "antd/es/progress";
import { ProjectProgress } from "../../../../../features/entities";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import { ProjectProgressCardContainer } from "./styles";

type ProjectProgressCardProps = {
  title: string;
  value: ProjectProgress;
  onClick: (progress: ProjectProgress) => void;
  selected: boolean;
};
const ProjectProgressCard = ({
  title,
  value,
  onClick,
  selected,
}: ProjectProgressCardProps) => {
  const theme = useTheme();
  const handleClick = () => {
    onClick(value);
  };

  return (
    <ProjectProgressCardContainer
      onClick={handleClick}
      $isSelected={selected}
      hoverable
      style={{ flex: 1, cursor: "pointer" }}
    >
      <Div flex="column" align="center" gap={24}>
        <Progress
          type="circle"
          percent={value}
          success={{ percent: value, strokeColor: theme.colors.green_1 }}
          trailColor={theme.colors.grey_5}
          strokeColor={theme.colors.purple_1}
        />

        <Typography elementTheme="body2" textAlign="center" nowrap>
          {title}
        </Typography>
      </Div>
    </ProjectProgressCardContainer>
  );
};
export default ProjectProgressCard;

