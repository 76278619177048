import Checkbox from "antd/es/checkbox/Checkbox";
import TextArea from "antd/es/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ChangeEvent } from "react";
import { PossibleCancelAnswers } from "../../../../../features/entities";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";

export type ReasonCheckboxProps = {
  answer: PossibleCancelAnswers;
  selected?: PossibleCancelAnswers;
  handleChange: (name: PossibleCancelAnswers) => void;
  handleOtherReasonChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  t: (translaton: string) => string;
};

const ReasonCheckbox = ({
  answer,
  selected,
  handleChange,
  handleOtherReasonChange,
  t,
}: ReasonCheckboxProps) => {
  const theme = useTheme();

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const selected = e.target.name as PossibleCancelAnswers;
    if (!!selected) {
      if (Object.values(PossibleCancelAnswers).includes(selected)) {
        handleChange(selected);
      }
    }
  };
  return (
    <Div flex="column" gap={8}>
      <Checkbox
        checked={selected === answer}
        name={answer}
        onChange={handleCheckboxChange}
        key={answer}
        style={{ alignItems: "flex-start", gap: 8 }}
      >
        <Div flex="row" align="center" gap={3}>
          <Typography elementTheme="body2" medium>
            {t(`projectStatusFlow|psf_cancel_checkbox${answer}`)}
          </Typography>
          {[PossibleCancelAnswers.Other].includes(answer) && (
            <Typography
              color={theme.colors.grey_2}
              textTransform="lowercase"
              elementTheme="body3"
              medium
            >
              ({t(`projectStatusFlow|psf_cancel_placeholder1`)})
            </Typography>
          )}
        </Div>
      </Checkbox>
      <div style={{ paddingLeft: `calc(16px + 16px)` }}>
        {[
          PossibleCancelAnswers.Rescoping,
          PossibleCancelAnswers.PartnerSelection,
        ].includes(answer) &&
          selected === answer && (
            <Typography elementTheme="caption" color={theme.colors.purple_1}>
              {t(`projectStatusFlow|psf_cancel_infos${answer}`)}
            </Typography>
          )}
        {[PossibleCancelAnswers.Other].includes(answer) &&
          selected === answer && (
            <TextArea
              onChange={handleOtherReasonChange}
              placeholder={t(`projectStatusFlow|psf_cancel_placeholder1`)}
              style={{ minHeight: 100 }}
            />
          )}
      </div>
    </Div>
  );
};

export default ReasonCheckbox;

