import { IconProps } from "../features/entities/general";

const LineViewFill = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.14286C0 0.511673 0.511675 0 1.14286 0H30.8571C31.4883 0 32 0.511675 32 1.14286V7.58442C32 8.2156 31.4883 8.72727 30.8571 8.72727H1.14286C0.511674 8.72727 0 8.2156 0 7.58442V1.14286Z"
          fill="currentColor"
        />
        <path
          d="M0 12.7792C0 12.148 0.511675 11.6364 1.14286 11.6364H30.8571C31.4883 11.6364 32 12.148 32 12.7792V19.2208C32 19.852 31.4883 20.3636 30.8571 20.3636H1.14286C0.511674 20.3636 0 19.8519 0 19.2208V12.7792Z"
          fill="currentColor"
        />
        <path
          d="M0 24.4156C0 23.7844 0.511675 23.2727 1.14286 23.2727H30.8571C31.4883 23.2727 32 23.7844 32 24.4156V30.8571C32 31.4883 31.4883 32 30.8571 32H1.14286C0.511674 32 0 31.4883 0 30.8571V24.4156Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default LineViewFill;
