import { CSSProperties } from "react";
import { ContentfulResource } from "../../../features/entities";
import { getStringDate } from "../../../features/helpers/get-formatted-date";
import useLocale from "../../../features/providers/locale-provider";
import { useTheme } from "../../../plugins/styled";
import { DefaultTheme } from "../../../theme";
import Image from "../../common/ContentfulMediaAsset";
import Button from "../../common/button";
import Div from "../../common/div";
import HdTag from "../../common/hd-tag";
import Typography from "../../common/typography";
import { ResourceCardContainer } from "./styles";

export interface ResourceCardProps extends ContentfulResource {
  description?: string;
  handleClick: () => void;
  isHighlighted?: boolean;
  style?: CSSProperties;
  size?: "small" | "medium" | "large" | "extra-large";
}

const CARD_HEIGHT = 280;
const BODY_HEIGHT = 160;
const THUMNBAIL_HEIGHT = CARD_HEIGHT - BODY_HEIGHT + 30;
const ResourceCard = ({
  title,
  type,
  date,
  thumbnailImage,
  handleClick,
  description,
  isHighlighted,
  style,
  size = "medium",
}: ResourceCardProps) => {
  const theme = useTheme() as DefaultTheme;
  const { selectedLocale } = useLocale();
  const width =
    size === "small"
      ? 300
      : size === "large"
        ? 454
        : size === "extra-large"
          ? 600
          : 336;

  return (
    <ResourceCardContainer
      hoverable
      cover={
        !!thumbnailImage ? (
          <Image
            asset={thumbnailImage}
            width={width}
            height={THUMNBAIL_HEIGHT}
            loading="lazy"
            objectFit="cover"
            style={{ borderRadius: 0, width: "100%" }}
          />
        ) : (
          <Div
            backgroundColor={theme.colors.grey_1}
            style={{ height: THUMNBAIL_HEIGHT }}
          />
        )
      }
      onClick={handleClick}
      styles={{
        cover: {
          width,
          height: THUMNBAIL_HEIGHT,
          padding: 0,
          margin: 0,
          borderRadius: "4px 4px 0px 0px",
          border: `1px solid ${theme?.colors?.primary}`,
          overflow: "hidden",
        },
        body: {
          width,
          height: BODY_HEIGHT,
          borderRadius: "15px 15px 4px 4px",
          padding: "16px 16px",
          marginTop: -30,
          backgroundColor: theme?.colors?.white_1,
          border: `1px solid ${theme?.colors?.primary}`,
          overflow: "hidden",
        },
      }}
      style={{
        ...style,
        width,
        height: CARD_HEIGHT,
        borderRadius: 4,
        overflow: "visible",
        border: "none",
      }}
      {...{ $size: size }}
    >
      <Div
        flex={"column"}
        gap={12}
        justify="space-between"
        style={{ height: "100%" }}
      >
        <Div flex={"column"} gap={12}>
          {!!type && <HdTag text={type} color={theme.colors.grey_2} />}
          <Typography.Title
            level={3}
            textAlign={"left"}
            elementTheme="subtitle1"
            lineClamp={size === "small" ? 2 : 3}
          >
            {title}
          </Typography.Title>
          {!!description && (
            <Typography
              elementTheme="caption"
              textAlign="left"
              color={theme?.colors?.grey_1}
            >
              {description}
            </Typography>
          )}
        </Div>
        <Div flex="row" justify="space-between" align="flex-end">
          {!!date ? (
            <Typography
              elementTheme="caption"
              style={{ fontSize: 10 }}
              color={theme?.colors?.grey_3}
            >
              {getStringDate(new Date(date), selectedLocale)}
            </Typography>
          ) : (
            <span></span>
          )}

          <Button
            defaultStyle={
              isHighlighted ? theme?.colors?.grey_1 : theme?.colors?.white_1
            }
            style={{ lineHeight: 1 }}
            onClick={handleClick}
            withArrowRight
            size="auto"
          />
        </Div>
      </Div>
    </ResourceCardContainer>
  );
};

export default ResourceCard;

