import ModalWarning from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-warning";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";

type ErrorModalProps = {
  isVisible: boolean;
  error: number;
  onCancel: () => void;
};

const ErrorModal = ({ isVisible, error, onCancel }: ErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ModalWarning open={isVisible} handleCancel={onCancel}>
        <>
          <Typography.Title
            level={4}
            textAlign={"center"}
            style={{ marginBottom: "2rem" }}
          >
            {t(`errors|${error}.message`)}
          </Typography.Title>
          <Typography center={true} color={theme.colors.grey_2}>
            {t(`errors|${error}.description`)}
          </Typography>
        </>
      </ModalWarning>
    </div>
  );
};

export default ErrorModal;
