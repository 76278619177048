import ContactPreview from "@hellodarwin/core/lib/components/provider/profile/profile-contact/contact-preview";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/index";
import {
  fetchContactById,
  selectContactById,
} from "../../../features/api/slices/global-slice";
import { selectProjectMatchById } from "../../../features/api/slices/matches-slice";
import { useClientApi } from "../../../features/api/use-client-api";

type ProviderContactPreviewProps = {
  open: boolean;
  handleCancel: () => void;
  matchId: string;
};
const ProviderContactPreview = ({
  open,
  handleCancel,
  matchId,
}: ProviderContactPreviewProps) => {
  const selectedMatch = useAppSelector((state) =>
    selectProjectMatchById(state, matchId)
  );
  const contact = useAppSelector((state) =>
    selectContactById(state, selectedMatch?.provider_contact_id || "")
  );

  const dispatch = useAppDispatch();
  const api = useClientApi();
  useEffect(() => {
    if (selectedMatch && selectedMatch.provider_contact_id) {
      dispatch(
        fetchContactById({ api, contactId: selectedMatch.provider_contact_id })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatch]);

  if (!selectedMatch?.provider_contact_id || !contact) return <></>;

  return (
    <ContactPreview
      isVisible={open}
      logo={selectedMatch.provider.logo || ""}
      onCancel={handleCancel}
      name={contact.name}
      preferred_language={contact?.preferred_language || ""}
      phone={contact.phone || ""}
      email={contact?.email || ""}
      ext={contact?.ext || ""}
      title={""}
    />
  );
};

export default ProviderContactPreview;

