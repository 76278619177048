import Form from "antd/es/form/Form";
import { PropsWithChildren } from "react";
import FundingExplorerFilter, { FundingExplorerFilterProps } from "./filter";
import FilterProjects from "./filter/filter-projects";

interface FundingExplorerListPageLayoutProps
  extends Omit<FundingExplorerFilterProps, "handleProjectSelection">,
    PropsWithChildren {
  setSelectedProjects?: React.Dispatch<React.SetStateAction<string[]>>;
}

const FundingExplorerListPageLayout = (
  props: FundingExplorerListPageLayoutProps
) => {
  const {
    setSelectedProjects,
    bestProjects,
    children,
    filterform,
    selectedProjects,
  } = props;

  const handleProjectSelection = (projectId: string) => {
    setSelectedProjects((prevSelectedProjects) => {
      const isSelected = prevSelectedProjects.includes(projectId);
      const newSelection = isSelected
        ? prevSelectedProjects.filter((id) => id !== projectId)
        : [...prevSelectedProjects, projectId];

      return newSelection;
    });
  };

  return (
    <Form form={filterform} layout="vertical">
      <FundingExplorerFilter
        {...props}
        handleProjectSelection={handleProjectSelection}
      />

      <div style={{ marginTop: 24 }}>{children}</div>

      <FilterProjects
        handleProjectSelection={handleProjectSelection}
        selectedProjects={selectedProjects}
        bestProjects={bestProjects}
      />
    </Form>
  );
};

export default FundingExplorerListPageLayout;

