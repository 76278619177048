import Div from "@hellodarwin/core/lib/components/common/div";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import DarwinLooking from "@hellodarwin/icons/dist/graphics/DarwinLooking";

const ResourceEmptyState = () => {
  const { t } = useTranslation();

  return (
    <Div
      flex="column"
      align="center"
      justify="center"
      style={{ marginTop: "2rem", marginBottom: "2rem" }}
      gap={16}
    >
      <DarwinLooking />

      {t("resource|NoResources")}
    </Div>
  );
};

export default ResourceEmptyState;

