import { DefaultTheme, useTheme } from "../../plugins/styled";
import { ProgramGrantStatus } from "../entities/programs-entities";
import ProjectStatus from "../enums/project-status";

export const getProgramStatusColor = (status: string): string => {
  const theme = useTheme();

  const colors: { [index: string]: string } = {
    Completed: theme.colors.green_1,
    Active: theme.colors.white_1,
    Rejected: theme.colors.red_1,
    Canceled: theme.colors.red_1,
  };

  return colors[status];
};

export const getMilestoneStatusColor = (status: string): string => {
  const theme = useTheme();

  const colors: { [index: string]: string } = {
    Completed: theme.colors.green_1,
    Scheduled: theme.colors.grey_2,
    ToSchedule: theme.colors.yellow_1,
  };

  return colors[status];
};

export const getProjectStatusColor = (
  status: ProjectStatus,
  theme: DefaultTheme
): string => {
  switch (status) {
    case ProjectStatus.PendingApproval:
      return theme.colors.yellow_1;
    case ProjectStatus.Active:
      return theme.colors.orange_1;
    case ProjectStatus.FilterCandidates:
      return theme.colors.blue_1;
    case ProjectStatus.EvaluateFinalists:
      return theme.colors.purple_1;
    case ProjectStatus.Completed:
      return theme.colors.green_1;
    case ProjectStatus.Canceled:
    case ProjectStatus.Rejected:
      return theme.colors.grey_2;
    default:
      return "";
  }
};

export const getProgramGrantStatusColor = (
  status: ProgramGrantStatus
): string => {
  const theme = useTheme();

  switch (status) {
    case ProgramGrantStatus.ValidationRequired:
      return theme.colors.yellow_1;
    case ProgramGrantStatus.IdentifiedOpportunities:
      return theme.colors.orange_1;
    case ProgramGrantStatus.OngoingWorkByHelloDarwin:
    case ProgramGrantStatus.OngoingInformationRequired:
      return theme.colors.blue_1;
    case ProgramGrantStatus.CompletedToSubmit:
      return theme.colors.purple_1;
    case ProgramGrantStatus.Accepted:
      return theme.colors.green_1;
    case ProgramGrantStatus.Refused:
      return theme.colors.grey_2;
    default:
      return "";
  }
};

