import Button from "@hellodarwin/core/lib/components/common/button";
import ModalLayoutWithIcon from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon";
import ProviderCard from "@hellodarwin/core/lib/components/project/status-flow/choose-partner-flow/chosen-sp/card";
import { Match } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import MoreCandidates from "@hellodarwin/icons/dist/icons/MoreCandidates";
import { useAppDispatch, useAppSelector } from "../../../../app/index";
import { openMatchModal } from "../../../../features/api/slices/global-slice";
import {
  selectProjectMatchById,
  selectShortlistedMatches,
  selectUnshortlistedMatches,
} from "../../../../features/api/slices/matches-slice";
import {
  selectProjectById,
  selectSelectedProjectId,
} from "../../../../features/api/slices/projects-slice";
import {
  CandidatesModalProvidersList,
  CandidatesModalProvidersScroll,
} from "./styles";
type ShortlistMoreCandidateModalProps = {
  open: boolean;
  handleClose: () => void;
  matchId?: string;
};

const ShortlistMoreCandidateModal = ({
  open,
  handleClose,
  matchId,
}: ShortlistMoreCandidateModalProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );

  const selectedMatch = useAppSelector((state) =>
    selectProjectMatchById(state, matchId || "")
  );

  const unselectedMatches = useAppSelector(selectUnshortlistedMatches);
  const shortlistedMatches = useAppSelector(selectShortlistedMatches);

  //Functions
  const handleProviderClick = (match: Match) => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: "shortlist",
        matchId: match.match_id,
        source: "Client-More",
      })
    );
  };

  if (!project || !unselectedMatches.length) return <></>;

  return (
    <ModalLayoutWithIcon
      open={open}
      handleCancel={handleClose}
      size={"large"}
      Icon={MoreCandidates}
      iconSize={64}
      title={t("project|modal.shortlistMoreCandidates.title")}
      description={t("project|modal.shortlistMoreCandidates.text", {
        count: project.provider_max,
      })}
      Actions={
        <>
          <Button onClick={handleClose} isLink style={{ margin: "auto" }}>
            {t("project|modal.shortlistMoreCandidates.cancel")}
          </Button>
        </>
      }
      warning={t("project|modal.shortlistMoreCandidates.remaining", {
        count: project.provider_max - shortlistedMatches.length,
      })}
    >
      <CandidatesModalProvidersScroll>
        <CandidatesModalProvidersList $listLength={unselectedMatches.length}>
          {unselectedMatches.map((m) => (
            <ProviderCard
              key={m.match_id}
              provider={m.provider}
              onClick={() => handleProviderClick(m)}
              selected={selectedMatch?.match_id === m.match_id}
            />
          ))}
        </CandidatesModalProvidersList>
      </CandidatesModalProvidersScroll>
    </ModalLayoutWithIcon>
  );
};

export default ShortlistMoreCandidateModal;

