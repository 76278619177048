import useLocale from "../../features/providers/locale-provider";
import ModalLayoutWithHdLogo from "../common/hd-modal/layouts/modal-with-logo";
import HubspotForm from "../hubspot/hubspot-form";

interface ApplyModalProps {
  open: boolean;
  handleCancel: () => void;
}

const GrantApplyModal = ({ open, handleCancel }: ApplyModalProps) => {
  const { selectedLocale } = useLocale();
  return (
    <ModalLayoutWithHdLogo open={open} handleCancel={handleCancel} size="large">
      <HubspotForm
        containerTag="grant-inquiry-modal"
        hubspotId={
          selectedLocale === "en"
            ? "b50e2456-1d66-4e5c-9099-071683514c27"
            : "0b706767-afec-4eed-a2a6-daca16f9ecf5"
        }
        assignedTheme="Full Width"
      />
    </ModalLayoutWithHdLogo>
  );
};

export default GrantApplyModal;
