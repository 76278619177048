import { IconProps } from "../features/entities/general";

const Link = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7358 11.1688C18.6837 11.6214 19.5092 12.2948 20.1428 13.1326C20.7765 13.9703 21.1998 14.9479 21.3773 15.9832C21.5548 17.0184 21.4813 18.0812 21.1629 19.0822C20.8445 20.0831 20.2905 20.9931 19.5475 21.7355L12.9834 28.2996C11.7524 29.5306 10.0828 30.2222 8.3419 30.2222C6.60098 30.2222 4.93137 29.5306 3.70036 28.2996C2.46935 27.0686 1.77777 25.399 1.77777 23.6581C1.77777 21.9172 2.46935 20.2476 3.70036 19.0166L6.26327 16.4536M25.7367 15.5463L28.2996 12.9834C29.5306 11.7524 30.2222 10.0828 30.2222 8.3419C30.2222 6.60098 29.5306 4.93137 28.2996 3.70036C27.0686 2.46935 25.399 1.77777 23.6581 1.77777C21.9172 1.77777 20.2476 2.46935 19.0166 3.70036L12.4525 10.2644C11.7095 11.0069 11.1555 11.9169 10.8371 12.9178C10.5187 13.9188 10.4452 14.9816 10.6227 16.0168C10.8002 17.0521 11.2235 18.0297 11.8572 18.8674C12.4908 19.7052 13.3163 20.3786 14.2642 20.8312"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Link;

