import { User, useAuth0 } from "@auth0/auth0-react";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../plugins/styled";
import useLocale from "./locale-provider";
interface LoginContextType {
  handleLogout: () => void;
  user?: User;
  accessToken: string;
}

export const LoginContext = createContext<LoginContextType>({
  handleLogout: () => {},
  user: {},
  accessToken: "",
});

const LoginProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { selectedLocale, changeLanguage } = useLocale();
  const [accessToken, setAccessToken] = useState("");
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo:
          (window?.location?.protocol || "") +
          "//" +
          (window.location.host || ""),
      },
    });
  };

  const getLocale = (uiLocale: string | null) => {
    if (!!uiLocale) {
      return uiLocale;
    } else {
      return getSelectedLanguage();
    }
  };

  const getSelectedLanguage = () => {
    if (selectedLocale == "en") {
      return "en";
    } else {
      return "fr-CA";
    }
  };

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  useEffect(() => {
    (async () => {
      if (!isLoading) {
        if (timer) {
          clearTimeout(timer);
          setTimer(undefined);
        }
        if (isAuthenticated) {
          const redirectUrl = localStorage.getItem("redirectUrl");
          localStorage.removeItem("redirectUrl");

          if (redirectUrl !== null) {
            navigate(redirectUrl);
          }
          getAccessTokenSilently().then((token) => {
            setAccessToken(token);
          });

          return;
        } else {
          if (typeof localStorage !== "undefined") {
            localStorage.setItem(
              "redirectUrl",
              window?.location?.pathname + window?.location?.search || ""
            );
          }

          const params = new URLSearchParams(location.search);
          const locale: string = getLocale(params.get("locale"));

          changeLanguage(locale == "en" ? "en" : "fr");

          if (window?.location?.pathname === "/signup") {
            const url = new URL(window?.location?.href);
            const email = url.searchParams.get("email");
            if (email) {
              await loginWithRedirect({
                authorizationParams: {
                  prompt: "login",
                  screen_hint: "signup",
                  login_hint: email,
                  ui_locales: locale,
                },
              });
            } else {
              await loginWithRedirect({
                authorizationParams: {
                  prompt: "login",
                  screen_hint: "signup",
                  ui_locales: locale,
                },
              });
            }
            return;
          }

          await loginWithRedirect({
            authorizationParams: { prompt: "login", ui_locales: locale },
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  if (isLoading || !accessToken)
    return <div style={{ background: theme.colors.beige_2 }} />;

  if (!user)
    return (
      <div style={{ background: theme.colors.beige_2 }}>Failed to get User</div>
    );

  return (
    <LoginContext.Provider
      value={{
        handleLogout,
        user,
        accessToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
export const useLogin = () => useContext(LoginContext);
export default LoginProvider;

