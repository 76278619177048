import Divider from "antd/es/divider";
import { CSSProperties, useMemo } from "react";
import { Match } from "../../../../features/entities/matches-entities";
import { Project } from "../../../../features/entities/project-entities";
import { Language } from "../../../../features/entities/translations_ns";
import ProjectType from "../../../../features/enums/project-type";
import { getStringDate } from "../../../../features/helpers/get-formatted-date";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import { Tablet } from "../../../common/Media";
import Container from "../../../common/container";
import Timeline from "../../../common/timeline";
import ProgressPreview from "./progress-preview";

interface ProjectStateBarProps {
  project: Project;
  candidates: Match[];
  shortlist: Match[];
  classicMatches: Match[];
  hasWinner: boolean;
  locale: Language;
  style?: CSSProperties;
}

export interface ProgressStep {
  label: string;
  labelComplete: string;
  sublabel?: string;
  state: "active" | "completed" | "paused";
  isLast?: boolean;
  index?: 0;
}

const ProjectStateBar = ({
  project,
  candidates,
  shortlist,
  classicMatches,
  hasWinner,
  locale,
  style,
}: ProjectStateBarProps) => {
  const theme = useTheme();
  const { t } = useTranslation("project-single");

  const steps = useMemo(() => {
    let progressSteps: ProgressStep[] = [];

    progressSteps.push({
      labelComplete: t("project-single|activity.steps.completed.creation", {
        date: getStringDate(new Date(project.created_at), locale),
      }),
      label: t("project-single|activity.steps.waiting.creation"),
      state: project?.completion_rate >= 17 ? "completed" : "active",
    });

    progressSteps.push({
      labelComplete: t("project-single|activity.steps.completed.activation", {
        date: getStringDate(new Date(project.activated_at), locale),
      }),
      label: t("project-single|activity.steps.waiting.activation"),
      state: project?.completion_rate >= 33 ? "completed" : "active",
    });

    if (project.type !== ProjectType.Classic) {
      progressSteps.push({
        label: t("project-single|activity.steps.waiting.candidates"),
        labelComplete: t("project-single|activity.steps.completed.candidates", {
          date: getStringDate(
            new Date(candidates[0]?.purchased_at || shortlist[0]?.purchased_at),
            locale
          ),
        }),
        sublabel: t("project-single|activity.steps.sublabel.candidates", {
          value: candidates.length,
          max: project.raise_hand_max,
        }),
        state: project?.completion_rate >= 50 ? "completed" : "active",
      });

      progressSteps.push({
        label: t("project-single|activity.steps.waiting.proposals", {
          nb: project.provider_max,
        }),
        labelComplete: t("project-single|activity.steps.completed.proposals", {
          nb: project.provider_max,
          date: getStringDate(new Date(shortlist?.[0]?.shortlisted_at), locale),
        }),
        sublabel: t("project-single|activity.steps.sublabel.candidates", {
          value: shortlist.length,
          max: project.provider_max,
        }),
        state: project?.completion_rate >= 66 ? "completed" : "active",
      });
    } else {
      progressSteps.push({
        label: t("project-single|activity.steps.waiting.candidates", {
          nb: project.provider_max,
        }),
        labelComplete: t("project-single|activity.steps.completed.candidates", {
          nb: project.provider_max,
          date: getStringDate(
            new Date(classicMatches?.[0]?.purchased_at),
            locale
          ),
        }),
        sublabel: t("project-single|activity.steps.sublabel.candidates", {
          value: classicMatches.length,
          max: project.provider_max,
        }),
        state: project?.completion_rate >= 66 ? "completed" : "active",
      });
    }

    progressSteps.push({
      label: t("project-single|activity.steps.waiting.getInTouch"),
      labelComplete: t("project-single|activity.steps.completed.getInTouch"),
      state: project?.completion_rate >= 83 ? "completed" : "active",
    });
    const winner = hasWinner ? shortlist.find((m) => !!m.winner_at) : undefined;

    progressSteps.push({
      label: t("project-single|activity.steps.waiting.winner"),
      labelComplete: t("project-single|activity.steps.completed.winner"),
      sublabel: winner
        ? t("project-single|activity.steps.sublabel.winner", {
            date: getStringDate(new Date(winner.winner_at), locale),
          })
        : undefined,
      state: project?.completion_rate >= 100 ? "completed" : "active",
      isLast: true,
    });
    return progressSteps;
  }, [locale, candidates, shortlist, hasWinner]);

  if (!steps?.length) return <></>;

  const Content = ({
    project,
    steps,
  }: {
    project: Project;
    steps: ProgressStep[];
  }) => {
    return (
      <div>
        <div style={{ padding: 32 }}>
          <ProgressPreview
            progress={project.completion_rate || 0}
            title={t("project-single|progress.title")}
            size="large"
          />
        </div>
        <Divider style={{ borderColor: theme.colors.primary, margin: 0 }} />
        <div style={{ padding: 32 }}>
          <Timeline steps={steps} title={t("propulsion|steps")} />
        </div>
      </div>
    );
  };
  return (
    <>
      <Tablet direction="up">
        <Container style={{ padding: 0, ...style }}>
          <Content project={project} steps={steps} />
        </Container>
      </Tablet>
      <Tablet direction="down">
        <Content project={project} steps={steps} />
      </Tablet>
    </>
  );
};

export default ProjectStateBar;

