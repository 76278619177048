import classNames from "classnames";
import { Suspense, lazy } from "react";
import { HeadingProps } from "./entities";
import { H1, H2, H3, H4, H5, H6, TypographyContainerProps } from "./styles";
import useTypographyProps from "./utils/use-typography-props";

const CopyButton = lazy(() => import("./copyButton"));
const getHeading = (level: HeadingProps["level"]) => {
  switch (level) {
    case 1:
      return H1;
    case 2:
      return H2;
    case 3:
      return H3;
    case 4:
      return H4;
    case 5:
      return H5;
    case 6:
      return H6;
  }
};

const Content = (props: HeadingProps) => {
  let { children, onClick, onBlur, id, className, elementTheme, level } = props;

  let headingElementTheme: HeadingProps["elementTheme"] = !!elementTheme
    ? elementTheme
    : `h${level}`;

  const classes = classNames(className, "hd-heading", {
    [`${headingElementTheme}`]: !!headingElementTheme,
  });

  const { currentProps, currentStyle } = useTypographyProps({
    ...props,
    elementTheme: headingElementTheme,
  });

  const textProps: TypographyContainerProps = {
    ...currentProps,
    className: classes,
    style: currentStyle,
    onClick,
    onBlur,
    id,
    children,
  };
  const Title = getHeading(level);

  return <Title {...textProps} />;
};

const Heading = (props: HeadingProps) => {
  const { copyable } = props;

  return !!copyable ? (
    <div
      style={{
        display: "flex",
        gap: 8,
        alignItems: "center",
      }}
    >
      <Content {...props} />
      {!!copyable ? (
        <Suspense fallback={""}>
          <CopyButton copyable={copyable} />
        </Suspense>
      ) : undefined}
    </div>
  ) : (
    <Content {...props} />
  );
};

export default Heading;

