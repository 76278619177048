import Checkmark from "@hellodarwin/icons/dist/icons/Checkmark";
import parse from "../../../../features/helpers/parse";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";

type CancelConfirmationProps = {
  handleClose: () => void;
};

const CancelConfirmation = ({ handleClose }: CancelConfirmationProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Div borderRadius={24} className="fade-in" style={{ maxWidth: 600 }}>
      <Div flex="column" align="center" gap={20}>
        <Checkmark
          style={{ color: theme.colors.green_1 }}
          width={64}
          height={64}
        />

        <Div flex="column" gap={16} align="center">
          <Typography.Title level={1} elementTheme="h5" textAlign="center">
            {t("projectStatusFlow|psf_cancelThankYou_header1")}
          </Typography.Title>
          <Typography textAlign="center">
            {parse(t("projectStatusFlow|psf_cancelThankYou_text1"))}
          </Typography>
        </Div>
        <Button onClick={handleClose} isLink>
          {t("button|close")}
        </Button>
      </Div>
    </Div>
  );
};

export default CancelConfirmation;

