import { IconProps } from "../features/entities/general";

const Propulsion = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5997 25.6L10.393 13.7214L17.1981 19.958C18.4423 15.9837 22.6419 11.4118 26.356 9.04995L30.3997 6.46983M30.3997 6.46983L20.0968 4.80005M30.3997 6.46983L28.5718 15.8815"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Propulsion;
