import { FormInstance } from "antd/es/form/Form";
import { ReactNode } from "react";
import {
  Company,
  CompanyInitialState,
  CompanyRequest,
  Contact,
  ContactInitialState,
} from "./core-entities";
import { GrantPreview } from "./grants-entities";
import { FormSubmissionEligilityState } from "../../components/forms/form-viewer";

export type TeamMemberFormValues = {
  name: string;
  role: string;
  linked_in: string;
  email?: string;
  phone?: string;
};

export type AssetFormValues = {
  title: string;
  url: string;
};

export type RatingFormValues = {
  rating_is_winner: boolean;
  rating_budget: number;
  rating_collaboration: number;
  rating_quality: number;
  rating_schedule: number;
};

export type LegacyExperienceFormValues = {
  cons: string;
  pros: string;
  summary: string;
  recommend: boolean;
};

export type ExperienceFormValues = {
  service: string;
  quality: number;
  relationship: number;
  summary: string;
  recommend: boolean;
};

export interface ContactFormValues {
  first_name: string;
  last_name: string;
  company_name: string;
  email: string;
  phone: string;
  province: string;
  preferred_language: string;
}

export interface Forms {
  form_id: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  grants: GrantPreview[];
  title_en: string;
  title_fr: string;
  subtitle_en: string;
  subtitle_fr: string;
  form_slug_en?: string;
  form_slug_fr?: string;
  form_type: string;
  published_at?: Date;
}
export const InitialForms: Forms = {
  form_id: "",
  grants: [],
  title_en: "",
  title_fr: "",
  subtitle_en: "",
  subtitle_fr: "",
  form_type: "",
};

export interface FormsItems {
  form_item_id: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  key: string;
  form_id: string;
  content_en: string;
  content_fr: string;
  heading_en?: string;
  heading_fr?: string;
  type: string;
  field_type?: string;
  required?: string;
  disposition?: string;
  formSelectOptions?: FormsSelectOptions[];
}

export interface FormsPage {
  form_page_id: string;
  form_id?: string;
  page_number: number;
  page_title_en?: string;
  page_title_fr?: string;
  page_heading_en?: string;
  page_heading_fr?: string;
  page_type?: string;
}

export interface FormsGroup {
  form_group_id: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  form_id?: string;
  form_page_id?: string;
  group_title_en?: string;
  group_title_fr?: string;
  group_disposition?: string;
  group_order: number;
}

export interface FormsGroupsItem {
  forms_groups_items_id: string;
  form_group_id: string;
  form_item_id: string;
  forms_groups_items_order: number;
}

export interface FormsItemsTotal {
  form_group?: FormsGroup;
  form_group_item: FormsGroupsItem;
  form_item: FormsItems;
}

export interface FormsSelectOptions {
  form_select_option_id: string;
  form_item_id: string;
  label_en: string;
  label_fr: string;
  value: string;
  display_order: number;
  hidden: boolean;
}

export const InitialFormsSelectOptions: FormsSelectOptions = {
  form_select_option_id: "",
  form_item_id: "",
  label_en: "",
  label_fr: "",
  value: "",
  display_order: 0,
  hidden: false,
};
export interface AdminFormResult {
  form: Forms;
  form_items: FormsItems[];
}

export interface FormsItemsOrder {
  form_item_id: string;
  display_order: number;
}

export interface ReviewForms {
  experienceForm?: FormInstance<ExperienceFormValues> | undefined;
  contactForm?: FormInstance<CompanyRequest> | undefined;
}

export interface AdminHubspotFormResponse {
  crm_id: string;
  hubspot_url: string;
}
export interface AdminFormResponse {
  form_id: string;
  crm_id?: string;
  hubspot_url?: string;
  title_en?: string;
  title_fr?: string;
  subtitle_en?: string;
  subtitle_fr?: string;
  form_type?: string;
  form_slug_en?: string;
  form_slug_fr?: string;
  published_at?: Date;
  grants?: GrantPreview[];
  pages: FormPageResponse[];
}

export const InitialAdminFormResponse: AdminFormResponse = {
  form_id: "",
  pages: [],
};

export interface FormPageResponse {
  form_page_id: string;
  page_number: number;
  page_title_en?: string;
  page_title_fr?: string;
  page_heading_en?: string;
  page_heading_fr?: string;
  page_type?: string;
  form_id?: string;
  groups: FormGroupResponse[];
  deleted?: boolean;
}

export const InitialFormPageResponse: FormPageResponse = {
  form_page_id: "",
  page_number: 0,
  groups: [],
};

export interface FormGroupResponse {
  form_id: string;
  form_page_id: string;
  form_group_id: string;
  group_order: number;
  group_title_en?: string;
  group_title_fr?: string;
  group_disposition?: "vertical" | "horizontal";
  items: FormItemResponse[];
  deleted?: boolean;
}

export const InitialFormGroupResponse: FormGroupResponse = {
  form_group_id: "",
  form_id: "",
  form_page_id: "",
  group_order: 0,
  items: [],
};
export interface FormItemResponse {
  forms_groups_items_id: string;
  forms_groups_items_order: number;
  form_item_id: string;
  key: string;
  content_en?: string;
  content_fr?: string;
  heading_en?: string;
  heading_fr?: string;
  type?: string;
  field_type?: string;
  required?: string;
  disposition?: string;
  form_select_options?: FormsSelectOptions[];
  form_item_conditions?: FormsConditions[];
}

export const InitialFormItemResponse: FormItemResponse = {
  forms_groups_items_id: "",
  forms_groups_items_order: 0,
  form_item_id: "",
  key: "",
};

export type FormsConditionType =
  | "equals"
  | "not equals"
  | "contains"
  | "empty"
  | "filled"
  | "greater"
  | "lower";

export enum FormsConditionTypeList {
  "empty" = "Empty",
  "equals" = "Equals",
  "not equals" = "Not Equals",
  "contains" = "Contains",
  "filled" = "Filled",
  "greater" = "Greater",
  "lower" = "Lower",
}
export interface FormsConditions {
  form_condition_id: string;
  condition_type: FormsConditionType;
  trigger_values: string[];
  action_page?: number;
}

export interface AdminFormSubmissionResponse {
  submission: FormsSubmission;
  submissionItems: FormsSubmissionItem[];
}

export interface FormSubmissionResponse {
  submission: FormsSubmission;
  submissionItems: FormsSubmissionItem[];
  contact: ContactFormValues;
}

export interface FormsSubmission {
  company_id?: string;
  contact_id?: string;
  created_at?: Date;
  deleted_at?: Date;
  form_id: string;
  form_submission_id: string;
  form_submission_crm_id?: string;
  form_crm_id: string;
  eligibility_state?: FormSubmissionEligilityState;
  source: string;
  page_name: string;
}
export interface AdminFormsSubmission extends FormsSubmission {
  company_name: string;
  contact_email: string;
  contact_name: string;
  grants: GrantPreview[];
  title: string;
}

export interface FormsSubmissionItem {
  form_submission_item_id: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  form_submission_id: string;
  form_item_id: string;
  value: string;
  form_item_title: string;
}

export interface TransferFromGroup {
  form_page_source_id: string;
  form_page_destination_id: string;
  form_group_id: string;
  group_order: number;
}

export const InitialTransferFormGroup: TransferFromGroup = {
  form_page_source_id: "",
  form_page_destination_id: "",
  form_group_id: "",
  group_order: 0,
};

export interface FormSection {
  form_item: FormItemResponse;
  forms_submissions_item: FormsSubmissionItem;
}

export interface FormsSubmissionPreview {
  form_submission_id: string;
  form_crm_id: string;
  deleted_at?: Date;
  company_id?: string;
  form_id: string;
  contact_id?: string;
  source: string;
  title?: string;
  contact_name?: string;
  company_name?: string;
  grants?: GrantPreview[];
}

export interface AdminFormSubmission {
  form: Forms;
  form_sections: FormSection[];
  contact: Contact;
  company: Company;
}
export const InitialAdminFormSubmission: AdminFormSubmission = {
  form: InitialForms,
  contact: ContactInitialState,
  company: CompanyInitialState,
  form_sections: [],
};
export interface FormItemEntity {
  key: string;
  label: string;
  content: ReactNode;
}

export enum ItemsTypes {
  "main" = "Main",
  "groups" = "Groups",
  "items" = "Items",
}

export interface FormBuilderItem {
  id: string;
  type: string;
  entity: FormItemResponse;
  category: ItemsTypes;
}

export interface FormBuilderGroup {
  id: string;
  items: { [key: string]: FormBuilderItem };
  entity: FormGroupResponse;
}

export interface FormBuilderPage {
  id: string;
  groups: { [key: string]: FormBuilderGroup };
  entity: FormPageResponse;
}

export interface MappedFormBuilderPage {
  [key: string]: FormBuilderPage;
}

export interface TranslatedFormResponse {
  form_id: string;
  crm_id: string;
  title?: string;
  subtitle?: string;
  form_type?: string;
  form_slug?: string;
  grants?: GrantPreview[];
  pages: TranslatedFormPageResponse[];
}

export const InitialTranslatedFormResponse: TranslatedFormResponse = {
  form_id: "",
  crm_id: "",
  pages: [],
};
export interface TranslatedFormPageResponse {
  form_page_id: string;
  page_number: number;
  page_title?: string;
  page_heading?: string;
  page_type?: string;
  form_id?: string;
  groups: TranslatedFormGroupResponse[];
  deleted?: boolean;
}

export interface TranslatedFormGroupResponse {
  form_group_id: string;
  group_order: number;
  items: TranslatedFormItemResponse[];
  deleted?: boolean;
  group_title: string;
  group_disposition: string;
}

export interface TranslatedFormItemResponse {
  forms_groups_items_id: string;
  forms_groups_items_order: number;
  form_item_id: string;
  key: string;
  content?: string;
  heading?: string;
  type?: string;
  field_type?: string;
  required?: string;
  disposition?: string;
  form_select_options?: TranslatedFormsSelectOptions[];
  form_item_conditions?: FormsConditions[];
}

export interface TranslatedFormsSelectOptions {
  form_select_option_id: string;
  form_item_id: string;
  label: string;
  value: string;
  display_order: number;
  hidden: boolean;
}

