import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import OnboardingFooter from "@hellodarwin/core/lib/components/onboarding/footer";
import OnboardingHeader from "@hellodarwin/core/lib/components/onboarding/header";
import {
  OnBoardingSteps,
  useOnBoarding,
} from "@hellodarwin/core/lib/context/onboarding-provider";
import { CompanyDuplicateResponse } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Form from "antd/es/form";
import useForm from "antd/es/form/hooks/useForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/app-hooks";
import {
  fetchCompany,
  onboard,
  selectCompany,
} from "../../../../features/api/slices/profile-slice";
import { useClientApi } from "../../../../features/api/use-client-api";
import DuplicateCompanyModal from "../duplicate-company-modal";
import OnboardingCompanyDetailsFormFields from "./onboarding-company-details-fields";

export type CompanyDetailsFormValues = {
  company_name: string;
  annual_revenue: string;
};

type ClientOnBoardingProfilePageProps = {
  handleSave: (values: CompanyDetailsFormValues) => void;
};

const ClientOnBoardingProfilePage = ({
  handleSave,
}: ClientOnBoardingProfilePageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const navigate = useNavigate();
  const { currentStep } = useOnBoarding();
  const [form] = useForm<CompanyDetailsFormValues>();
  const company = useAppSelector(selectCompany);
  const [duplicateCompanies, setDuplicateCompanies] = useState<
    CompanyDuplicateResponse[]
  >([]);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    company_name: company?.name,
    annual_revenue: company?.annual_revenue,
  };

  useEffect(() => {
    dispatch(fetchCompany({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company) {
      if (company.name && company.annual_revenue) {
        handleOnboard({
          company_name: company.name,
          annual_revenue: company.annual_revenue,
        });
      }
      if (company.name) {
        form.setFieldsValue({ company_name: company.name });
      }
      if (company.annual_revenue) {
        form.setFieldsValue({ annual_revenue: company.annual_revenue });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleOnboard = async (values: CompanyDetailsFormValues) => {
    try {
      setLoading(true);
      const request = {
        company_name: values.company_name,
        annual_revenue: values.annual_revenue,
      };
      await dispatch(onboard({ api, data: request }));
      navigate("/");
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleNext = () => {
    try {
      switch (currentStep) {
        case OnBoardingSteps.CompanyDetails:
          form
            .validateFields()
            .then(async (values) => {
              if (values.company_name !== company?.name) {
                const duplicateCompanies = await api.checkDuplicateCompany(
                  values.company_name
                );

                if (duplicateCompanies && duplicateCompanies.length > 0) {
                  setDuplicateCompanies(duplicateCompanies);
                  return;
                }
              }
              handleSave(values);
            })
            .catch((e) => console.error(e));

          break;
      }
    } catch (error) {}
  };
  const handlePrev = () => {
    switch (currentStep) {
    }
  };

  return (
    <PageLayout
      app="provider"
      title={t("onboarding|profile.title")}
      subtitle={t("onboarding|sider.description")}
      breadcrumbs={[]}
    >
      <OnboardingHeader />
      <Form form={form} layout={"vertical"} initialValues={initialValues}>
        {currentStep === OnBoardingSteps.CompanyDetails && (
          <OnboardingCompanyDetailsFormFields isLoading={loading} />
        )}
      </Form>

      <OnboardingFooter
        handlePrev={
          currentStep !== OnBoardingSteps.CompanyDetails
            ? handlePrev
            : undefined
        }
        handleNext={handleNext}
        isLoading={false}
        lastStep={currentStep === OnBoardingSteps.CompanyDetails}
      />
      <DuplicateCompanyModal
        duplicateCompanies={duplicateCompanies}
        visible={duplicateCompanies.length > 0}
        handleClose={() => setDuplicateCompanies([])}
        handleOnboard={() => {
          handleOnboard(form.getFieldsValue());
        }}
        form={form}
      />
    </PageLayout>
  );
};

export default ClientOnBoardingProfilePage;

