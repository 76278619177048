import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import { FormInstance, useWatch } from "antd/es/form/Form";
import { SelectProps } from "antd/es/select";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { GrantProject } from "../../../features/entities/grants-entities";
import Button from "../../common/button";
import HdTag from "../../common/hd-tag";
import { FundingExplorerTagBarStyled } from "./styles";
import {
  FundingExplorerFilterValues,
  InitialFundingExplorerFilterValues,
} from "./types";
import getFilterTagBarTheme from "./utils/get-filter-tag-bar-theme";

export interface FilterTagBarProps {
  filterform: FormInstance<FundingExplorerFilterValues>;
  financingType: SelectProps["options"];
  industries: SelectProps["options"];
  subIndustries: SelectProps["options"];
  provinces: SelectProps["options"];
  services: SelectProps["options"];
  companySize: SelectProps["options"];
  annualRevenue: SelectProps["options"];
  selectedIndustries: string[];
  setSelectedIndustries: (values: string[]) => void;
  selectedProjects?: string[];
  bestProjects?: GrantProject[];
  setHasChanged: Dispatch<SetStateAction<boolean>>;
  handleProjectSelection: (projectId: string) => void;
}

const FilterTagBar = ({
  filterform,
  setHasChanged,
  industries,
  subIndustries,
  financingType,
  provinces,
  services,
  companySize,
  annualRevenue,
  setSelectedIndustries,
  selectedIndustries,
  selectedProjects,
  bestProjects,
  handleProjectSelection,
}: FilterTagBarProps) => {
  const theme = useTheme();
  const values = useWatch([], filterform);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const handleTagBarOpen = () => setIsOpen(!isOpen);

  const mappedIndustries: { [value: string]: string } = useMemo(
    () =>
      industries.reduce(
        (o, industry) => ({ ...o, [industry.value]: industry.label }),
        {}
      ),
    [industries]
  );
  const mappedSubindustries: { [value: string]: string } = useMemo(
    () =>
      subIndustries.reduce(
        (o, subindustry) => ({ ...o, [subindustry.value]: subindustry.label }),
        {}
      ),
    [subIndustries]
  );
  const mappedFinancingType: { [value: string]: string } = useMemo(
    () =>
      financingType.reduce(
        (o, type) => ({ ...o, [type.value]: type.label }),
        {}
      ),
    [financingType]
  );
  const mappedProvinces: { [value: string]: string } = useMemo(
    () =>
      provinces.reduce(
        (o, province) => ({ ...o, [province.value]: province.label }),
        {}
      ),
    [provinces]
  );
  const mappedServices: { [value: string]: string } = useMemo(
    () =>
      services.reduce(
        (o, service) => ({ ...o, [service.value]: service.label }),
        {}
      ),
    [services]
  );
  const mappedProjects: { [value: string]: string } = useMemo(
    () =>
      bestProjects.reduce(
        (o, project) => ({
          ...o,
          [project.grant_project_id]: project.short_description,
        }),
        {}
      ),
    [bestProjects]
  );

  const mappedCompanySizes: { [value: string]: string } = useMemo(
    () =>
      companySize.reduce(
        (o, compSize) => ({ ...o, [compSize.value]: compSize.label }),
        {}
      ),
    [companySize]
  );

  const mappedAnnualRevenues: { [value: string]: string } = useMemo(
    () =>
      annualRevenue.reduce(
        (o, annRevenue) => ({
          ...o,
          [annRevenue.value]: annRevenue.label,
        }),
        {}
      ),
    [annualRevenue]
  );

  const {
    subindustry,
    service,
    financingType: financingTypeValues,
    region,
    status,
    companySize: companySizeValues,
    annualRevenue: annualRevenueValues,
  }: FundingExplorerFilterValues = useMemo(() => {
    if (!values) return InitialFundingExplorerFilterValues;

    return values;
  }, [values]);

  const removeIndustry = (key: string) => {
    setSelectedIndustries(
      selectedIndustries.filter((selectedKey) => selectedKey !== key)
    );
    setHasChanged(true);
  };

  const removeTag = (key: string, name: keyof FundingExplorerFilterValues) => {
    filterform.setFieldValue(
      name,
      filterform
        .getFieldValue(name)
        .filter((selectedKey) => selectedKey !== key)
    );
    setHasChanged(true);
  };

  const removeTagString = (
    key: string,
    name: keyof FundingExplorerFilterValues
  ) => {
    if (filterform.getFieldValue(name) === key) {
      filterform.setFieldValue(name, undefined);
      setHasChanged(true);
    }
  };

  const [scrollHeight, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>();
  const updateHeight = () => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  };

  useEffect(() => {
    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const allTags = useMemo(() => {
    return [
      ...(selectedIndustries ?? []),
      ...(subindustry ?? []),
      ...(financingTypeValues ?? []),
      ...(region ?? []),
      ...(service ?? []),
      ...(status ?? []),
      ...(selectedProjects ?? []),
      ...(companySizeValues ? [companySizeValues] : []),
      ...(annualRevenueValues ? [annualRevenueValues] : []),
    ];
  }, [
    selectedIndustries,
    subindustry,
    financingTypeValues,
    region,
    service,
    status,
    selectedProjects,
    companySizeValues,
    annualRevenueValues,
  ]);

  useEffect(() => {
    updateHeight();
  }, [allTags]);

  const withDrawer = useMemo(() => scrollHeight > 100, [scrollHeight]);

  useEffect(() => {
    if (!withDrawer) {
      setIsOpen(false);
    }
  }, [withDrawer]);

  if (!allTags?.length) return <></>;
  return (
    <FundingExplorerTagBarStyled
      ref={ref}
      $isOpen={isOpen}
      $withDrawer={withDrawer}
    >
      {selectedIndustries?.map((value) => (
        <HdTag
          text={mappedIndustries[value]}
          hoverable
          tagTheme={getFilterTagBarTheme("industry", theme, value)}
          onClose={() => removeIndustry(value)}
        />
      ))}
      {subindustry?.map((value) => (
        <HdTag
          tagTheme={getFilterTagBarTheme("subindustry", theme, value)}
          text={mappedSubindustries[value]}
          hoverable
          onClose={() => removeTag(value, "subindustry")}
        />
      ))}
      {financingTypeValues?.map((value) => (
        <HdTag
          text={mappedFinancingType[value]}
          hoverable
          tagTheme={getFilterTagBarTheme("financingType", theme, value)}
          onClose={() => removeTag(value, "financingType")}
        />
      ))}
      {region?.map((value) => (
        <HdTag
          text={mappedProvinces[value]}
          hoverable
          tagTheme={getFilterTagBarTheme("region", theme, value)}
          onClose={() => removeTag(value, "region")}
        />
      ))}
      {service?.map((value) => (
        <HdTag
          text={mappedServices[value]}
          hoverable
          tagTheme={getFilterTagBarTheme("service", theme, value)}
          onClose={() => removeTag(value, "service")}
        />
      ))}

      {status?.map((value) => (
        <HdTag
          text={t(`grants|grantCardStatus.${value}`)}
          hoverable
          tagTheme={getFilterTagBarTheme("status", theme, value)}
          onClose={() => removeTag(value, "status")}
        />
      ))}
      {companySizeValues && (
        <HdTag
          text={mappedCompanySizes[companySizeValues]}
          hoverable
          tagTheme={getFilterTagBarTheme(
            "companySize",
            theme,
            companySizeValues
          )}
          onClose={() => removeTagString(companySizeValues, "companySize")}
        />
      )}

      {annualRevenueValues && (
        <HdTag
          text={mappedAnnualRevenues[annualRevenueValues]}
          hoverable
          tagTheme={getFilterTagBarTheme(
            "annualRevenue",
            theme,
            annualRevenueValues
          )}
          onClose={() => removeTagString(annualRevenueValues, "annualRevenue")}
        />
      )}
      {selectedProjects?.map((value) => (
        <HdTag
          text={mappedProjects[value]}
          hoverable
          tagTheme={{
            textColor: theme.colors.purple_1,
            backgroundColor: theme.colors.purple_4,
            borderColor: theme.colors.purple_1,
            textColorHover: theme.colors.purple_1,
            backgroundColorHover: theme.colors.purple_5,
            borderColorHover: theme.colors.purple_1,
          }}
          onClose={() => handleProjectSelection(value)}
        />
      ))}
      {withDrawer && (
        <Button
          onClick={handleTagBarOpen}
          defaultStyle={theme.colors.white_1}
          style={{ position: "absolute", bottom: 0, right: 0, zIndex: 100 }}
          headingIcon={<TabArrow width={12} height={12} down={!isOpen} />}
          size="square"
          transparent
        />
      )}
    </FundingExplorerTagBarStyled>
  );
};
export default FilterTagBar;

