import { IconProps } from "../features/entities/general";

const Budget = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.77777 25.4242C9.56077 25.4181 17.3099 26.4142 24.8207 28.3864C25.8812 28.6655 26.9402 27.9042 26.9402 26.8411V25.4242M3.96581 5.33334V6.39076C3.96581 6.6712 3.85054 6.94016 3.64538 7.13846C3.44021 7.33676 3.16194 7.44817 2.87179 7.44817H1.77777M1.77777 7.44817V6.91946C1.77777 6.04393 2.51295 5.33334 3.4188 5.33334H28.0342M1.77777 7.44817V20.1371M28.0342 5.33334V6.39076C28.0342 6.97445 28.5243 7.44817 29.1282 7.44817H30.2222M28.0342 5.33334H28.5812C29.487 5.33334 30.2222 6.04393 30.2222 6.91946V20.6658C30.2222 21.5414 29.487 22.252 28.5812 22.252H28.0342M1.77777 20.1371V20.6658C1.77777 21.0865 1.95066 21.4899 2.25842 21.7874C2.56617 22.0848 2.98357 22.252 3.4188 22.252H3.96581M1.77777 20.1371H2.87179C3.16194 20.1371 3.44021 20.2485 3.64538 20.4468C3.85054 20.6451 3.96581 20.9141 3.96581 21.1945V22.252M28.0342 22.252V21.1945C28.0342 20.9141 28.1494 20.6451 28.3546 20.4468C28.5598 20.2485 28.838 20.1371 29.1282 20.1371H30.2222M28.0342 22.252H3.96581M20.3761 13.7926C20.3761 14.9144 19.915 15.9903 19.0943 16.7835C18.2737 17.5767 17.1606 18.0223 16 18.0223C14.8394 18.0223 13.7263 17.5767 12.9056 16.7835C12.085 15.9903 11.6239 14.9144 11.6239 13.7926C11.6239 12.6709 12.085 11.595 12.9056 10.8018C13.7263 10.0086 14.8394 9.563 16 9.563C17.1606 9.563 18.2737 10.0086 19.0943 10.8018C19.915 11.595 20.3761 12.6709 20.3761 13.7926ZM24.7521 13.7926H24.7638V13.8039H24.7521V13.7926ZM7.24786 13.7926H7.25953V13.8039H7.24786V13.7926Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Budget;
