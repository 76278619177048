import { debounce, isEqual } from "lodash";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Language } from "../entities/translations_ns";
import isBrowser from "../helpers/is-browser";

export const LOCALE_CACHE_KEY = "language";
export const DEFAULT_LANGUAGE = "en";
export const SUPPORTED_LANGUAGES = ["en", "fr"];

export interface LocaleContextType {
  selectedLocale: Language;
  changeLanguage: (locale: Language) => void;
  localeProviderLoading: boolean;
}

export const LocaleContext = React.createContext<LocaleContextType>({
  selectedLocale: "en",
  changeLanguage(locale) {},
  localeProviderLoading: false,
});

interface LocaleProviderProps extends PropsWithChildren {
  appChangeLanguage?: (locale: string) => void;
}

export const LocaleProvider = ({
  children,
  appChangeLanguage,
}: LocaleProviderProps) => {
  const getLanguage = (): Language => {
    const storedLanguage = isBrowser
      ? (localStorage.getItem(LOCALE_CACHE_KEY) as Language)
      : "en";

    const browserLanguage = navigator.language as Language;

    return SUPPORTED_LANGUAGES.includes(storedLanguage)
      ? storedLanguage
      : browserLanguage.startsWith("fr")
        ? "fr"
        : DEFAULT_LANGUAGE;
  };

  const [selectedLocale, setSelectedLocale] = useState<Language>(getLanguage);
  const [localeProviderLoading, setLocaleProviderLoading] = useState(false);

  const changeLanguage = debounce((locale: Language) => {
    setLocaleProviderLoading(true);
    const validLocale = SUPPORTED_LANGUAGES.includes(locale)
      ? locale
      : DEFAULT_LANGUAGE;
    setSelectedLocale(validLocale);
    setLocaleProviderLoading(false);
  }, 300);

  useEffect(() => {
    if (
      isBrowser &&
      !isEqual(selectedLocale, localStorage.getItem(LOCALE_CACHE_KEY))
    ) {
      localStorage.setItem(LOCALE_CACHE_KEY, selectedLocale);
    }
    appChangeLanguage?.(selectedLocale);
    setLocaleProviderLoading(false);
  }, [selectedLocale]);

  const contextValue = useMemo(
    () => ({
      selectedLocale,
      changeLanguage,
      localeProviderLoading,
    }),
    [selectedLocale, localeProviderLoading]
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};

const useLocale = () => useContext(LocaleContext);

export default useLocale;

