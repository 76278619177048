import { PropsWithChildren } from "react";
import Div from "../../../common/div";
import FormItem from "../../../forms/form-layouts/form-item";
import FormLabel from "../../../forms/form-layouts/form-label";
import validateAnyType from "../../../forms/utils/validate-any-type";
interface ReviewStepCardProps extends PropsWithChildren {
  label: string;
  extra?: string;
  name: string;
  error?: string;
  type?: "boolean" | "checkbox" | "radio" | "rating" | "textarea";
}
const ReviewStepCard = ({
  extra,
  label,
  name,
  type,
  error,
  children,
}: ReviewStepCardProps) => {
  return (
    <div>
      {type === "rating" ? (
        <Div flex="row" tablet={{ flex: "column" }}>
          <div style={{ flex: 1 }}>
            <FormLabel label={label} extra={extra} />
          </div>
          <FormItem
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            name={name}
            rules={[
              () => ({
                validator: validateAnyType({
                  message: error,
                  isRequired: true,
                }),
              }),
            ]}
          >
            {children}
          </FormItem>
        </Div>
      ) : (
        <FormItem
          name={name}
          label={<FormLabel label={label} extra={extra} />}
          rules={[
            () => ({
              validator: validateAnyType({
                message: error,
                isRequired: true,
              }),
            }),
          ]}
        >
          {children}
        </FormItem>
      )}
    </div>
  );
};
export default ReviewStepCard;

