import { ResourceCardProps } from ".";
import styled from "../../../plugins/styled";
import Card from "../../common/Card";
import { ArrowContainer } from "../../common/button/arrow/styles";
import { ButtonContainer } from "../../common/button/styles";

export const ResourceCardContainer = styled(Card)<{
  $fullWidth?: boolean;
  $size?: ResourceCardProps["size"];
}>`
  cursor: pointer;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border-radius: 0;
  padding: 0;
  .ant-card-cover {
    img {
      border-radius: 0;
    }
  }

  ${ButtonContainer} {
    width: 32px;
    height: 32px;
  }
  &:hover {
    ${ButtonContainer} {
      width: 46px;
      ${ArrowContainer} {
        width: 30px;
      }
    }
  }
`;

