import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  selectProjectsModal,
  toggleProjectsModal,
} from "../../../features/api/slices/projects-slice";
import ProjectAdditionalShortlistModal from "./project-additional-shortlist";
import ProjectManagerContactModal from "./project-manager-contact-modal";
import ProjectMoreCandidatesModal from "./project-more-candidates";
import ProjectStatusFlowModal from "./project-status-flow-modal";
import ShortlistMoreCandidateModal from "./shortlist-more-candidate-modal";
import ProjectStatusModal from "./status-modal";
import ClientSubmitProjectModal from "./submit-project-modal";

const ProjectActionModal = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(selectProjectsModal);

  const handleClose = () => {
    dispatch(
      toggleProjectsModal({ isVisible: false, type: "", props: undefined })
    );
  };

  switch (modal.type) {
    case "projectSubmit":
      return (
        <ClientSubmitProjectModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );
    case "projectStatusFlow":
      if (!modal.props) return <></>;

      return (
        <ProjectStatusFlowModal
          open={modal.isVisible}
          handleClose={handleClose}
          state={modal.props}
        />
      );

    case "manager":
      return (
        <ProjectManagerContactModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );

    case "shortlistMore":
      return (
        <ShortlistMoreCandidateModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );

    case "moreCandidates":
      return (
        <ProjectMoreCandidatesModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );
    case "additionalShortlisting":
      return (
        <ProjectAdditionalShortlistModal
          open={modal.isVisible}
          handleClose={handleClose}
        />
      );

    case "projectStatus":
      return (
        <ProjectStatusModal open={modal.isVisible} handleClose={handleClose} />
      );

    default:
      return <></>;
  }
};

export default ProjectActionModal;

