import Layout from "antd/es/layout";
import styled from "../../../../plugins/styled";
import Div from "../../div";

export const MainLayout = styled(Layout)``;

export const GeneralLayout = styled(Layout)``;

export const AppLayoutContainer = styled(Div)`
  width: 100vw;
  position: relative;
  min-height: 100vh;

  .hd-content-container,
  .hd-onboarding-content {
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100vh;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) { {
      height: auto;
      overflow-x: hidden;
      overflow-y: unset;
      padding: 0;
    }
  }
`;

