import { LogoProps } from "../logo-entities";

const InstagramLogo = ({
  width = 32,
  height = 32,
  style,
  onClick,
  className,
}: LogoProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        onClick={onClick}
        className={className}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0986 1.59998H22.4068C27.0957 1.59998 30.9054 5.40968 30.9054 10.0985V22.4068C30.9054 24.6608 30.01 26.8224 28.4162 28.4162C26.8224 30.01 24.6608 30.9054 22.4068 30.9054H10.0986C5.40971 30.9054 1.60001 27.0957 1.60001 22.4068V10.0985C1.60001 7.84458 2.49539 5.68294 4.08918 4.08915C5.68297 2.49536 7.84461 1.59998 10.0986 1.59998ZM9.80552 4.53051C8.40651 4.53051 7.0648 5.08627 6.07555 6.07552C5.0863 7.06477 4.53054 8.40648 4.53054 9.80548V22.6999C4.53054 25.6157 6.88963 27.9748 9.80552 27.9748H22.6999C24.0989 27.9748 25.4406 27.4191 26.4299 26.4298C27.4191 25.4406 27.9749 24.0989 27.9749 22.6999V9.80548C27.9749 6.8896 25.6158 4.53051 22.6999 4.53051H9.80552ZM23.9454 6.72842C24.4311 6.72842 24.897 6.92139 25.2405 7.26488C25.584 7.60837 25.777 8.07424 25.777 8.56001C25.777 9.04577 25.584 9.51164 25.2405 9.85513C24.897 10.1986 24.4311 10.3916 23.9454 10.3916C23.4596 10.3916 22.9937 10.1986 22.6502 9.85513C22.3067 9.51164 22.1138 9.04577 22.1138 8.56001C22.1138 8.07424 22.3067 7.60837 22.6502 7.26488C22.9937 6.92139 23.4596 6.72842 23.9454 6.72842ZM16.2527 8.92632C18.1958 8.92632 20.0593 9.6982 21.4332 11.0722C22.8072 12.4461 23.579 14.3096 23.579 16.2527C23.579 18.1957 22.8072 20.0592 21.4332 21.4332C20.0593 22.8071 18.1958 23.579 16.2527 23.579C14.3096 23.579 12.4461 22.8071 11.0722 21.4332C9.69823 20.0592 8.92635 18.1957 8.92635 16.2527C8.92635 14.3096 9.69823 12.4461 11.0722 11.0722C12.4461 9.6982 14.3096 8.92632 16.2527 8.92632ZM16.2527 11.8569C15.0869 11.8569 13.9688 12.32 13.1444 13.1444C12.32 13.9687 11.8569 15.0868 11.8569 16.2527C11.8569 17.4185 12.32 18.5366 13.1444 19.361C13.9688 20.1854 15.0869 20.6485 16.2527 20.6485C17.4185 20.6485 18.5366 20.1854 19.361 19.361C20.1854 18.5366 20.6485 17.4185 20.6485 16.2527C20.6485 15.0868 20.1854 13.9687 19.361 13.1444C18.5366 12.32 17.4185 11.8569 16.2527 11.8569Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default InstagramLogo;
