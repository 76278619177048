import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectActionsButton from "@hellodarwin/core/lib/components/project/project-actions-button";
import { Match } from "@hellodarwin/core/lib/features/entities";
import { PrioritizedProjectActions } from "@hellodarwin/core/lib/features/enums/action-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import Confirm from "@hellodarwin/icons/dist/icons/Confirm";
import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import RatingOutline from "@hellodarwin/icons/dist/icons/RatingOutline";
import User from "@hellodarwin/icons/dist/icons/User";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/index";
import { useClientAnalytics } from "../../../features/analytics/use-client-analytics";
import { openMatchModal } from "../../../features/api/slices/global-slice";
import { WinnerTag } from "./styles";

type ProjectActionsProps = {
  match: Match;
  hasWinner: boolean;
  average?: number;
  isSecondary?: boolean;
  isPrimary?: boolean;
  isClassic?: boolean;
  isBigger?: boolean;
  onProfile?: boolean;
  isOverview?: boolean;
};

const ProviderActions = ({
  match,
  hasWinner,
  average,
  isPrimary,
  isSecondary,
  isClassic,
  isBigger,
  onProfile,
  isOverview,
}: ProjectActionsProps) => {
  const { t } = useTranslation();
  const analytics = useClientAnalytics();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLeaveAReview = () => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: "leaveReview",
        matchId: match.match_id,
        source: "Client",
      })
    );
  };

  const handleSeeProfile = () => {
    navigate(`/projects/${match.project_id}/providers/${match.match_id}`);
  };
  const handleShortlist = () => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: "shortlist",
        matchId: match.match_id,
        source: "Client",
      })
    );
  };

  const handleIgnore = () => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: "ignore",
        matchId: match.match_id,
        source: "Client",
      })
    );
  };

  const handleSelectAsWiner = () => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: "winner",
        matchId: match.match_id,
        source: "Client",
      })
    );
  };

  const handleShowContact = () => {
    dispatch(
      openMatchModal({
        isVisible: true,
        type: "contact",
        matchId: match.match_id,
        source: "Client",
      })
    );

    analytics?.providerContactClicked({
      clicked_provider_id: match.provider_id,
      clicked_provider_contact_id:
        match.provider.contacts?.[0].contact_id || "",
      clicked_project_id: match.project_id,
    });
  };

  const newOverviewActions: PrioritizedProjectActions = {
    primaryAction: [
      {
        label: t("provider-profile|cta.profil"),
        action: handleSeeProfile,
        Icon: <User width={12} height={12} />,
      },
    ],
    secondaryActions: [],
  };

  const newCandidatesActions: PrioritizedProjectActions = {
    primaryAction: [
      {
        label: t("provider-profile|cta.shortlist"),
        action: handleShortlist,
      },
    ],
    secondaryActions: [
      {
        label: t("provider-profile|cta.ignore"),
        action: handleIgnore,
        Icon: <CancelProject width={12} height={12} />,
      },
    ],
  };

  const newShortlistActions: PrioritizedProjectActions = {
    primaryAction: onProfile
      ? [
          { label: "Contact", action: handleShowContact },
          {
            label: t("provider-profile|cta|review"),
            action: handleLeaveAReview,
            beforeLabel: average ? (
              <Div
                flex={"row"}
                align={"center"}
                gap={8}
                bordersSide={"right"}
                borderColor={theme.colors.yellow_2}
                style={{ paddingRight: "1rem" }}
              >
                <Typography color={theme.colors.yellow_2}>
                  <RatingFill width={24} height={24} />
                </Typography>
                <Typography color={theme.colors.yellow_2}>{average}</Typography>
              </Div>
            ) : undefined,
          },
        ]
      : [{ label: "Contact", action: handleShowContact }],
    secondaryActions: !onProfile
      ? [
          {
            label: t("provider-profile|cta|winner"),
            action: handleSelectAsWiner,
            Icon: <Confirm width={12} height={12} />,
          },
          {
            label: t("provider-profile|cta|review"),
            action: handleLeaveAReview,
            Icon: (
              <RatingOutline
                width={12}
                height={12}
                style={{
                  display: "flex",
                  justifySelf: "center",
                  alignSelf: "center",
                }}
              />
            ),
          },
        ]
      : [
          {
            label: t("provider-profile|cta|winner"),
            action: handleSelectAsWiner,
            Icon: <Confirm width={12} height={12} />,
          },
        ],
  };

  const newWinnerProjectAcitons: PrioritizedProjectActions = {
    primaryAction: onProfile
      ? [
          { label: "Contact", action: handleShowContact },
          {
            label: t("provider-profile|cta|review"),
            action: handleLeaveAReview,
            beforeLabel: average ? (
              <Div
                flex={"row"}
                align={"center"}
                gap={8}
                bordersSide={"right"}
                borderColor={theme.colors.yellow_2}
                style={{ paddingRight: "1rem" }}
              >
                <Typography color={theme.colors.yellow_2}>
                  <RatingFill width={24} height={24} />
                </Typography>
                <Typography color={theme.colors.yellow_2}>{average}</Typography>
              </Div>
            ) : undefined,
          },
        ]
      : [{ label: "Contact", action: handleShowContact }],
    secondaryActions: onProfile
      ? []
      : [
          {
            label: t("provider-profile|cta|review"),
            action: handleLeaveAReview,
            Icon: (
              <RatingOutline
                width={12}
                height={12}
                style={{
                  display: "flex",
                  justifySelf: "center",
                  alignSelf: "center",
                }}
              />
            ),
          },
        ],
  };

  const newRejectedActions: PrioritizedProjectActions = {
    primaryAction: [
      {
        label: t("provider-profile|cta.profil"),
        action: handleSeeProfile,
      },
    ],
    secondaryActions: [],
  };

  let actions;
  if (isOverview) {
    actions = (
      <ProjectActionsButton
        actions={newOverviewActions}
        isPrimary={isPrimary}
        isSecondary={isSecondary}
        isBigger={isBigger}
      />
    );
  } else if (match.raise_hand_rejected_at) {
    isPrimary ? (
      onProfile ? (
        (actions = (
          <Div flex="column" justify="center" gap={3.2} align="center">
            <Typography elementTheme="body2" color={theme.colors.red_1} bold>
              {t("project|refused_reason")} :
            </Typography>
            <Typography elementTheme="body2">
              {match.raise_hand_reject_reason}
            </Typography>
          </Div>
        ))
      ) : (
        (actions = (
          <ProjectActionsButton
            actions={newRejectedActions}
            isPrimary={isPrimary}
            isSecondary={isSecondary}
            isBigger={isBigger}
          />
        ))
      )
    ) : (
      <></>
    );
  } else if (!match.shortlisted_at && !isClassic) {
    actions = (
      <ProjectActionsButton
        actions={newCandidatesActions}
        isPrimary={isPrimary}
        isSecondary={isSecondary}
        isBigger={isBigger}
      />
    );
  } else if (hasWinner) {
    if (match.winner_at) {
      actions = (
        <>
          {isPrimary ? (
            <WinnerTag
              size="extra-small"
              style={{ width: "100%" }}
              defaultStyle={theme.colors.green_1}
            >
              {t("project|matchesList.winner.title")}
            </WinnerTag>
          ) : (
            <ProjectActionsButton
              actions={newWinnerProjectAcitons}
              isPrimary={isPrimary}
              isSecondary={isSecondary}
              isBigger={isBigger}
            />
          )}
        </>
      );
    } else {
      actions = (
        <ProjectActionsButton
          actions={newWinnerProjectAcitons}
          isPrimary={isPrimary}
          isSecondary={isSecondary}
          isBigger={isBigger}
        />
      );
    }
  } else if (!!match.shortlisted_at || isClassic) {
    actions = (
      <>
        <ProjectActionsButton
          actions={newShortlistActions}
          isPrimary={isPrimary}
          isSecondary={isSecondary}
          isBigger={isBigger}
        />
      </>
    );
  }
  return <>{actions}</>;
};

export default ProviderActions;

