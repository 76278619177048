import { IconProps } from "../features/entities/general";

const Warning = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0004 11.849V18.7036M2.22887 23.5755C0.946907 25.7401 2.55011 28.4444 5.11256 28.4444H26.8882C29.4492 28.4444 31.0524 25.7401 29.7719 23.5755L18.8856 5.17901C17.6036 3.01439 14.3972 3.01439 13.1152 5.17901L2.22887 23.5755ZM16.0004 23.0329H16.0108V23.0444H16.0004V23.0329Z"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Warning;

