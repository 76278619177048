import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import ModalLayoutWithIcon, {
  ModalLayoutWithIconProps,
} from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-icon";
import HubspotMeeting, {
  HubspotMeetingProps,
} from "@hellodarwin/core/lib/components/hubspot/hubspot-meeting";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";

interface MeetingModalProps extends HubspotMeetingProps {
  open: boolean;
  handleClose: () => void;
  meetingUrl: string;
  title: string;
  description?: string;

  Icon: ModalLayoutWithIconProps["Icon"];
}

const MeetingModal = ({
  open,
  handleClose,
  meetingUrl,
  title,
  description,
  Icon,
  ...hubspotMeetingProps
}: MeetingModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalLayoutWithIcon
      open={open}
      handleCancel={handleClose}
      size="large"
      Icon={Icon}
      title={title}
      description={description}
      Actions={
        <Div flex="column" align="flex-end">
          <Button size="small" style={{ flex: 1 }} onClick={handleClose}>
            {t("button.cancel")}
          </Button>
        </Div>
      }
      direction="row"
    >
      <Div className={"hubspot-custom-frame"}>
        <HubspotMeeting meetingUrl={meetingUrl} {...hubspotMeetingProps} />
      </Div>
    </ModalLayoutWithIcon>
  );
};

export default MeetingModal;

