/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  closeMatchModal,
  selectModal,
} from "../../../features/api/slices/global-slice";
import LeaveReviewModal from "./leave-review-modal";
import ProviderConfirmModal from "./provider-confirm-modal";
import ProviderContactPreview from "./provider-contact-preview";
import ProviderMessageModal from "./provider-message-modal";
import RefuseModal from "./refuse-modal";

const ProviderActionModal = () => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(selectModal);

  const handleCancel = () => {
    dispatch(closeMatchModal());
  };

  switch (modal.type) {
    case "shortlist":
      return (
        <ProviderConfirmModal
          open={modal.isVisible}
          action={modal.type}
          matchId={modal.matchId}
          source={modal.source}
        />
      );

    case "shortlist-message":
      return (
        <ProviderMessageModal
          open={modal.isVisible}
          handleClose={handleCancel}
          matchId={modal.matchId}
        />
      );

    case "ignore":
      return (
        <RefuseModal
          open={modal.isVisible}
          handleCancel={handleCancel}
          matchId={modal.matchId}
        />
      );

    case "winner":
      return (
        <ProviderConfirmModal
          open={modal.isVisible}
          action={modal.type}
          matchId={modal.matchId}
        />
      );

    case "contact":
      return (
        <ProviderContactPreview
          open={modal.isVisible}
          handleCancel={handleCancel}
          matchId={modal.matchId}
        />
      );

    case "leaveReview":
      return (
        <LeaveReviewModal
          open={modal.isVisible}
          handleCancel={handleCancel}
          matchId={modal.matchId}
        />
      );

    default:
      return <></>;
  }
};

export default ProviderActionModal;
