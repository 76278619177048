import { companySize } from "@hellodarwin/core/src/components/forms/utils/company-infos";
import Form from "antd/es/form";
import Input from "antd/es/input/Input";
import Select from "antd/es/select";
import { Dispatch, SetStateAction, useEffect } from "react";
import { CompanyRequest } from "../../../../features/entities/core-entities";
import { ReviewForms } from "../../../../features/entities/form-entities";
import { ReviewFormSteps } from "../../../../features/entities/project-status-flow-entities";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTranslation } from "../../../../plugins/i18n";
import Grid from "../../../common/hd-grid";
import FormItem from "../../../forms/form-layouts/form-item";
import FormLabel from "../../../forms/form-layouts/form-label";
import validateEmail from "../../../forms/utils/validate-email";
import ReviewNavigation from "./review-navigation";

type ReviewContactProps = {
  contactInfo: CompanyRequest;
  setForms: Dispatch<SetStateAction<ReviewForms>>;
  isActive?: boolean;
  setFlowStep: (step: ReviewFormSteps) => void;
  handleSubmit?: () => Promise<void>;
  isSubmit?: boolean;
  companyTags: string[];
};

const ReviewContact = ({
  contactInfo,
  setForms,
  setFlowStep,
  isActive,
  handleSubmit,
  isSubmit,
  companyTags,
}: ReviewContactProps) => {
  const [form] = Form.useForm<CompanyRequest>();
  const { t } = useTranslations();
  const { t: translateTags } = useTranslation(["tags"]);

  useEffect(() => {
    setForms((prevState: ReviewForms) => ({
      ...prevState,
      contactForm: form,
    }));
  }, [form, setForms]);

  const initialValues: CompanyRequest = {
    contact_name: contactInfo?.contact_name,
    contact_email: contactInfo?.contact_email,
    contact_title: contactInfo?.contact_title,
    company_name: contactInfo?.company_name,
    company_size: contactInfo?.company_size,
    company_industry: contactInfo?.company_industry,
    terms_and_conditions: contactInfo?.terms_and_conditions,
  };

  const validateFormFields = async (form) => {
    try {
      const values = await form.validateFields();
      return values;
    } catch (error) {
      return null;
    }
  };

  const handleNextStep = async () => {
    if (await validateFormFields(form)) {
      handleSubmit();
    }
  };

  return (
    <Form
      form={form}
      layout={"vertical"}
      initialValues={initialValues}
      name={"contact"}
      hidden={!isActive}
    >
      <div style={{ marginTop: 32 }}>
        <Grid center xl={2} lg={2} md={2} sm={1} gutter={24}>
          <FormItem
            label={<FormLabel label={t("review_contact|rc_name_label")} />}
            name={"contact_name"}
            style={{ width: "100%" }}
            rules={[
              { required: true, message: t("review_contact|rc_name_error") },
            ]}
          >
            <Input placeholder={t("review_contact|rc_name_placeholder")} />
          </FormItem>

          <FormItem
            label={<FormLabel label={t("review_contact|rc_email_label")} />}
            name={"contact_email"}
            style={{ width: "100%" }}
            rules={[
              { validator: validateEmail(t("review_contact|rc_email_error")) },
            ]}
          >
            <Input placeholder={t("review_contact|rc_email_placeholder")} />
          </FormItem>

          <FormItem
            label={<FormLabel label={t("review_contact|rc_title_label")} />}
            name={"contact_title"}
            style={{ width: "100%" }}
          >
            <Input placeholder={t("review_contact|rc_title_placeholder")} />
          </FormItem>

          <FormItem
            label={
              <FormLabel label={t("review_contact|rc_companyName_label")} />
            }
            name={"company_name"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: t("review_contact|rc_companyName_error"),
              },
            ]}
          >
            <Input
              placeholder={t("review_contact|rc_companyName_placeholder")}
            />
          </FormItem>

          <FormItem
            name={"company_size"}
            label={<FormLabel label={t("review_contact|rc_companySize")} />}
          >
            <Select placeholder="Select company size">
              {companySize.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            name={"company_industry"}
            label={<FormLabel label={t("review_contact|rc_companyIndustry")} />}
          >
            <Select placeholder="Select company industry">
              {companyTags?.map((category) => (
                <Select.Option key={category} value={category}>
                  {translateTags(`tags|${category}`)}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </Grid>
        <ReviewNavigation
          handleNext={() => setFlowStep(ReviewFormSteps.Confirm)}
          handlePrev={() => setFlowStep(ReviewFormSteps.Experience)}
          handleSubmit={handleNextStep}
          isSubmit={isSubmit}
        />
      </div>
    </Form>
  );
};

export default ReviewContact;

