import ProjectStatusFlow, {
  ProjectStatusFlowSteps,
} from "@hellodarwin/core/lib/components/project/status-flow";
import {
  ProjectInitialState,
  ProjectUpdateFunctions,
} from "@hellodarwin/core/lib/features/entities";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/index";
import {
  reviewProvider,
  selectAsWinner,
  selectShortlistedMatches,
} from "../../../features/api/slices/matches-slice";
import {
  cancelProject,
  fetchProject,
  selectProjectById,
  setClientProjectProgression,
  toggleProjectsModal,
} from "../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../features/api/use-client-api";

interface ProjectStatusFlowModalProps {
  state: StatusFlowModalState;
  handleClose: () => void;
  open: boolean;
}

export interface StatusFlowModalState {
  initialStep?: ProjectStatusFlowSteps;
  projectId: string;
}

const ProjectStatusFlowModal = ({
  state: { initialStep, projectId },
  open,
  handleClose,
}: ProjectStatusFlowModalProps) => {
  const dispatch = useAppDispatch();
  const api = useClientApi();

  const activeProject = useAppSelector((state) =>
    selectProjectById(state, projectId || "")
  );

  const shortlistMatches = useAppSelector(selectShortlistedMatches);

  const editionFunctions: ProjectUpdateFunctions = {
    cancel: async (project_id, canceled_reason, canceled_reason_specified) => {
      await dispatch(
        cancelProject({
          api,
          project_id,
          canceled_reason,
          canceled_reason_specified,
        })
      );
    },
    review: async (projectId, providerId, reviewRequest, matchId) => {
      if (matchId === undefined) {
        matchId = "";
      }
      await dispatch(
        reviewProvider({ api, reviewRequest, providerId, projectId, matchId })
      );
    },

    selectAsWinner: async (matchId) => {
      await dispatch(selectAsWinner({ api, matchId }));
    },
    selectProgression: async (projectId, clientProgression) => {
      await dispatch(
        setClientProjectProgression({ api, projectId, clientProgression })
      );
    },
  };

  const handleChangeFlow = (step: ProjectStatusFlowSteps) => {
    switch (step) {
      case ProjectStatusFlowSteps.CancelProjectFlow:
        dispatch(
          toggleProjectsModal({
            props: {
              initialStep: ProjectStatusFlowSteps.CancelProjectFlow,
              projectId,
            },
            isVisible: true,
            type: "projectStatusFlow",
          })
        );
        break;
      case ProjectStatusFlowSteps.PartnerChooseSelected:
        dispatch(
          toggleProjectsModal({
            props: {
              initialStep: ProjectStatusFlowSteps.PartnerChooseSelected,
              projectId,
            },
            isVisible: true,
            type: "projectStatusFlow",
          })
        );
        break;
      case ProjectStatusFlowSteps.NoPartnerSelectedOptions:
        dispatch(
          toggleProjectsModal({
            props: {
              initialStep: ProjectStatusFlowSteps.NoPartnerSelectedOptions,
              projectId,
            },
            isVisible: true,
            type: "projectStatusFlow",
          })
        );
        break;
      default:
        dispatch(
          toggleProjectsModal({
            props: {
              initialStep: ProjectStatusFlowSteps.PartnerChooseSelected,
              projectId,
            },
            isVisible: true,
            type: "projectStatusFlow",
          })
        );
        break;
    }
  };

  useEffect(() => {
    if (projectId && activeProject?.project_id !== projectId) {
      dispatch(fetchProject({ api, projectId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectStatusFlow
      project={activeProject || ProjectInitialState}
      shortlistMatches={shortlistMatches}
      editionFunctions={editionFunctions}
      initialStep={initialStep}
      handleClose={handleClose}
      handleChangeFlow={handleChangeFlow}
      isModal
      modalOpen={open}
    />
  );
};

export default ProjectStatusFlowModal;

