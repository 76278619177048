import { IconProps } from "../features/entities/general";

const Accueil = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.77777 15.3715L14.8389 2.25985C15.4807 1.61708 16.5193 1.61708 17.1597 2.25985L30.2222 15.3715M5.05982 12.0771V26.9018C5.05982 27.8111 5.795 28.549 6.70085 28.549H12.7179M19.282 28.549H25.2991C26.205 28.549 26.9402 27.8111 26.9402 26.9018V12.0771M10.5299 28.549H22.5641"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Accueil;
