import Logout from "@hellodarwin/icons/dist/icons/Logout";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Divider from "antd/es/divider";
import Dropdown from "antd/es/dropdown";
import { MenuProps } from "antd/es/menu/menu";
import { CSSProperties, ReactNode, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { AppType, HdUser } from "../../../../features/entities/core-entities";
import { NavigationLink } from "../../../../features/entities/layout-entities";
import { handleExternalLinkCLick } from "../../../../features/helpers/get-website-url";
import {
  GetTranslationFunc,
  useTranslations,
} from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import theme from "../../../../theme";
import Avatar from "../../../common/avatar";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import { MobileItemLabel } from "../../navigation/mobile-navigation";
import { LogoutItem, UserDropdownContent } from "./styles";

type UserMenuProps = {
  user: HdUser;
  links: NavigationLink[];
  handleLogout: () => void;
  navigate: (path: string) => void;
  app: AppType;
};

const UserMenuButton = ({
  withArrow,
  user,
  pale,
}: {
  withArrow?: boolean;
  user: HdUser;
  pale?: boolean;
}) => {
  return (
    <Div gap={15} flex="row" overflow="hidden" align="center">
      <Avatar
        src={user.logo}
        shape="rounded-corner"
        size={40}
        style={{
          background: pale ? theme.colors.white_1 : undefined,
          color: pale ? theme.colors.primary : undefined,
        }}
      >
        {user?.name?.[0]?.toUpperCase()}
      </Avatar>

      <Typography nowrap bold elementTheme="body2">
        {user?.name}
      </Typography>
      {withArrow && <TabArrow down width={10} height={10} />}
    </Div>
  );
};

const MobileUserMenu = ({
  user,
  handleLogout,
  links,
  navigate,
  app,
}: UserMenuProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const handleLinkClick = (link: NavigationLink) => {
    if (link.external_link) handleExternalLinkCLick(link.external_link);
    else if (link.onClick) link.onClick();
    else {
      navigate(link.pathname);
    }
  };

  return (
    <Div flex="column" gap={26} className="user-menu">
      <UserMenuButton user={user} pale={app === "provider"} />
      {links.map((l, i) => (
        <div className="hd-user-menu-item" key={l.pathname}>
          <MobileItemLabel link={l} handleLinkClick={handleLinkClick} />
        </div>
      ))}
      <Divider style={{ borderWidth: 1, borderColor: theme.colors.white_1 }} />
      <div className="hd-user-menu-item">
        <MobileItemLabel
          link={{
            pathname: "?logout=true",
            onClick: handleLogout,
            label: t("navigation|logout"),
            Icon: Logout,
          }}
          handleLinkClick={handleLinkClick}
        />
      </div>
    </Div>
  );
};

const itemStyle: CSSProperties = {
  minWidth: 145,
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 12,
  borderRadius: 0,
  cursor: "pointer",
  fontSize: 15,
  color: theme.colors.grey_2,
};

const DropdownRender = ({
  originalNode,
  t,
  handleLogout,
}: {
  originalNode: ReactNode;
  t: GetTranslationFunc;
  handleLogout: UserMenuProps["handleLogout"];
}) => {
  return (
    <UserDropdownContent>
      {originalNode}

      <LogoutItem style={{ ...itemStyle }} onClick={handleLogout}>
        <Logout
          width={16}
          height={16}
          style={{ flexBasis: 16, flexShrink: 0 }}
        />

        {t("navigation|logout")}
      </LogoutItem>
    </UserDropdownContent>
  );
};

const DesktopUserMenu = ({
  user,
  handleLogout,
  links,
  navigate,
}: UserMenuProps) => {
  const theme = useTheme();
  const { t } = useTranslations();

  const handleLinkClick = (link: NavigationLink) => {
    if (link.external_link) handleExternalLinkCLick(link.external_link);
    else if (link.onClick) link.onClick();
    else {
      navigate(link.pathname);
    }
  };

  const items: MenuProps["items"] = useMemo(() => {
    const menuItems: MenuProps["items"] = [];
    links.forEach((link, i) => {
      menuItems.push({
        label: link.isExternal ? (
          <>
            {link.label}
            <NewWindow width={12} height={12} style={{ marginLeft: 8 }} />
          </>
        ) : (
          link.label
        ),
        onClick: () => handleLinkClick(link),
        key: `link-${i}`,
        icon: !!link.Icon ? <link.Icon width={16} height={16} /> : "",
        style: itemStyle,
      });
    });

    return menuItems;
  }, [links]);

  return (
    <Dropdown
      menu={{
        items,
        style: { borderRadius: 0, padding: 0, boxShadow: "none" },
      }}
      overlayStyle={{ width: "max-content" }}
      trigger={["click"]}
      dropdownRender={(originalNode) => (
        <DropdownRender
          originalNode={originalNode}
          t={t}
          handleLogout={handleLogout}
        />
      )}
    >
      <a>
        <Button transparent defaultStyle={theme.colors.grey_1}>
          <UserMenuButton user={user} withArrow />
        </Button>
      </a>
    </Dropdown>
  );
};
const UserMenu = (props: UserMenuProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  if (!props.user) return <></>;
  const Menu = isTablet ? MobileUserMenu : DesktopUserMenu;
  return <Menu {...props} />;
};

export default UserMenu;

