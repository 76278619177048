import { ReactNode } from "react";
import Modal, { ModalProps } from "..";

type ModalLayoutWithHdLogoProps = {
  children: ReactNode;
} & ModalProps;
const ModalLayoutSimple = ({
  children,
  ...modalProps
}: ModalLayoutWithHdLogoProps) => {
  return <Modal {...modalProps}>{children}</Modal>;
};

export default ModalLayoutSimple;

