import {
  ApiError,
  ApiErrorInitialState,
  Industry,
} from "@hellodarwin/core/lib/features/entities";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

export interface TagsState {
  status: "idle" | "pending";
  error: ApiError;
  industries: Industry[];
}

const initialState: TagsState = {
  status: "idle",
  error: ApiErrorInitialState,
  industries: [],
};

export const fetchIndustries = createAsyncThunk<
  Industry[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchIndustries",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchIndustries(locale);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIndustries.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchIndustries.fulfilled, (state, { payload }) => {
      state.industries = payload;
      state.status = "idle";
    });
    builder.addCase(fetchIndustries.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const selectIndustriesSectors = createSelector(
  (state: RootState) => state.tags.industries,
  (industries) =>
    industries
      .filter((item) => item.level === 1)
      .map((item) => {
        return {
          label: `${item.id} - ${item.class_title}`,
          value: item.id,
        };
      })
);

export const selectIndustriesSubsectors = createSelector(
  (state: RootState) => state.tags.industries,
  (industries) => industries.filter((item: Industry) => item.level === 2)
);

export const selectIndustriesSubsectorsForFundingExplorer = createSelector(
  [
    (state: RootState, _) => state.tags.industries,
    (_, sectors: string[]) => sectors,
  ],
  (industries, sectors) =>
    industries
      .filter(
        (item: Industry) =>
          item.level === 2 && sectors.some((sector) => sector === item.parent)
      )
      .map((item) => {
        return {
          label: `${item.id} - ${item.class_title}`,
          value: item.id,
        };
      })
      .sort((a, b) => a.value.localeCompare(b.value))
);

export const selectTagsIsLoading = (state: RootState) =>
  state.tags.status === "pending";
export const tagsReducer = tagsSlice.reducer;

