import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Checkbox from "antd/es/checkbox";
import { FormInstance } from "antd/es/form/Form";
import { BaseOptionType, DefaultOptionType, SelectProps } from "antd/es/select";
import { CSSProperties, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { GrantStatus } from "../../../../features/entities/grants-entities";
import Div from "../../../common/div";
import HdTag from "../../../common/hd-tag";
import Typography from "../../../common/typography";
import FormItem from "../../../forms/form-layouts/form-item";
import { StatusTag } from "../../single/grant-sections/summary/styles";
import { FilterMultipleSelectStyled, FilterSelectDropdown } from "../styles";
import { FundingExplorerFilterValues } from "../types";
import getFilterTagBarTheme from "../utils/get-filter-tag-bar-theme";

interface FilterMultipleSelectProps {
  label: string;
  name: string;
  placeholder: string;
  options: SelectProps["options"];
  filterform: FormInstance<FundingExplorerFilterValues>;
  applyFilter: () => Promise<void>;
  onChange?: SelectProps["onChange"];
  isMultiple?: boolean;
  isStatusTag?: boolean;
  notFoundContent?: ReactNode;
}

const FilterMultipleSelect = ({
  label,
  name,
  placeholder,
  options,
  filterform,
  applyFilter,
  onChange,
  isMultiple,
  isStatusTag,
  notFoundContent,
}: FilterMultipleSelectProps) => {
  const theme = useTheme();

  const filterOption = (input: string, option: DefaultOptionType) => {
    const comparingKey = `${option.label}`;
    return comparingKey.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  };

  const checkIfSelected = (value: string, fieldValue: string | string[]) => {
    const fieldValueArray = Array.isArray(fieldValue) ? fieldValue : [];
    return fieldValueArray.includes(value);
  };

  const dropdownStyle: CSSProperties = {
    padding: 0,
    borderRadius: 0,
    border: `1px solid ${theme.colors.primary}`,
  };
  const dropdownRender: SelectProps["dropdownRender"] = (el) => (
    <FilterSelectDropdown>{el}</FilterSelectDropdown>
  );

  const optionRender = (option: BaseOptionType) => {
    return (
      <Div
        flex="row"
        gap={8}
        align="center"
        borderColor={theme.colors.grey_4}
        bordersSide="bottom"
        justify="space-between"
        style={{ padding: "12px 16px" }}
      >
        <Typography
          elementTheme="body3"
          color={theme.colors.primary}
          ellipsis
          overflow
          nowrap
        >
          {option.label}
        </Typography>
        {isMultiple && (
          <Checkbox
            type="square"
            checked={checkIfSelected(
              option.value.toString(),
              filterform.getFieldValue(
                name as keyof FundingExplorerFilterValues
              )
            )}
          />
        )}
      </Div>
    );
  };

  const optionRenderStatusTag = (option: BaseOptionType) => {
    const { t } = useTranslation();
    return (
      <Div
        flex="row"
        gap={8}
        align="center"
        borderColor={theme.colors.grey_4}
        bordersSide="bottom"
        justify="space-between"
        style={{ padding: "12px 16px" }}
      >
        <StatusTag $status={option.value as GrantStatus}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <circle
              cx="6"
              cy="6"
              r="5.25"
              fill="#F82E47"
              stroke="#FF88A4"
              strokeWidth="1.5"
            />
          </svg>
          {t(`grantCardStatus|${option.label}`)}
        </StatusTag>
        {isMultiple && (
          <Checkbox
            type="square"
            checked={checkIfSelected(
              option.value.toString(),
              filterform.getFieldValue(
                name as keyof FundingExplorerFilterValues
              )
            )}
          />
        )}
      </Div>
    );
  };

  return (
    <FormItem label={label} name={name} style={{ margin: 0 }} layout="vertical">
      <FilterMultipleSelectStyled
        mode={isMultiple ? "multiple" : undefined}
        style={{ width: "100%" }}
        onChange={!!onChange ? onChange : applyFilter}
        options={options}
        filterOption={filterOption}
        tagRender={({ label, onClose, value }) => (
          <HdTag
            onClose={onClose}
            text={label}
            size="small"
            style={{ marginRight: 2, overflow: "hidden" }}
            tagTheme={getFilterTagBarTheme(name, theme, value)}
          />
        )}
        size="middle"
        maxTagCount={3}
        dropdownRender={dropdownRender}
        dropdownStyle={dropdownStyle}
        menuItemSelectedIcon={<></>}
        suffixIcon={<TabArrow width={8} down />}
        placeholder={placeholder}
        optionRender={isStatusTag ? optionRenderStatusTag : optionRender}
        notFoundContent={notFoundContent}
      />
    </FormItem>
  );
};

export default FilterMultipleSelect;

