import styled from "../../../../plugins/styled";

export const IconContent = styled.div<{
  $side: "left" | "right";
  $iconTop?: number;
  $iconWidth?: number;
  $iconHeight?: number;
  $iconRotate?: number;
  $iconMargin?: number | string;
  $iconColor?: string;
  $inversed?: boolean;
}>`
  position: absolute;
  top: ${(props) => (!!props.$iconTop ? `${props.$iconTop}px` : 0)};
  width: fit-content;
  height: fit-content;
  svg {
    overflow: visible;
  }
  ${(props) =>
    props.$side === "right"
      ? `right: ${
          typeof props.$iconMargin === "number"
            ? `${props.$iconMargin}px`
            : props.$iconMargin
        };`
      : `left: ${
          typeof props.$iconMargin === "number"
            ? `${props.$iconMargin}px`
            : props.$iconMargin
        };`}

  svg {
    ${(props) =>
      !!props.$iconColor ? `color: ${props.$iconColor};` : undefined}

    ${(props) =>
      !!props.$iconWidth ? `width: ${props.$iconWidth}px;` : undefined}
    ${(props) =>
      !!props.$iconHeight ? `height: ${props.$iconHeight}px;` : undefined}
    ${(props) =>
      !!props.$inversed
        ? !!props.$iconRotate
          ? `transform: rotate(${props.$iconRotate}deg) scaleY(-1);`
          : `transform: scaleY(-1);`
        : !!props.$iconRotate
          ? `transform: rotate(${props.$iconRotate}deg);`
          : undefined};
  }
`;

export const PageContainerStyled = styled.main<{
  $backgroundColor?: string;
  $cap?: number | string;
  $withoutPaddingRight?: boolean;
  $withoutPaddingHorizontal?: boolean;
  $withoutPadding?: boolean;
  $isAdmin?: boolean;
}>`
  padding-left: 0;
  padding-right: 0;

  ${({
    $withoutPaddingRight,
    $withoutPaddingHorizontal,
    $withoutPadding,
    $isAdmin,
  }) =>
    $withoutPadding
      ? `
        margin:0px;
        padding:0px;
        width:100%;
        max-width:100%;

      `
      : $withoutPaddingHorizontal
        ? `
      margin:0px;
      padding-top: 40px;
      padding-bottom: 40px; 
      width:100% ;
      `
        : $withoutPaddingRight
          ? `
      margin-left: ${$isAdmin ? `30px` : `60px`};;
      padding-top: 40px;
      padding-bottom: 40px; 
      width: ${$isAdmin ? `calc(100% - 30px)` : `calc(100% - 60px)`} ;
      `
          : `
        margin:auto;
      padding-top: 40px;
      padding-bottom: 40px; 
      width: ${$isAdmin ? `calc(100% - 60px)` : `calc(100% - 120px)`} ;
       `}
  ${({ $isAdmin }) => ($isAdmin ? `margin-top:58px;` : ``)}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 1;

  ${(props) =>
    props.$backgroundColor
      ? `background-color: ${props.$backgroundColor}`
      : undefined};

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.background};
    overflow: hidden;
  }
  li {
    width: fit-content;
    .hd-text {
      width: fit-content;
      display: inline;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0;
    padding-top: 0;
    padding-bottom: 60px;
    width: 100%;
  }
`;

