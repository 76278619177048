import {
  ApiError,
  ApiErrorInitialState,
  Project,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import ClientApi from "../client-api";

const wonprojectAdapter = createEntityAdapter<Project, string>({
  selectId: (wonProject: Project) => wonProject.project_id,
});

export interface clientState {
  status: "idle" | "pending";
  error: ApiError;
  projects: EntityState<Project, string>;
}

const initialState: clientState = {
  status: "idle",
  error: ApiErrorInitialState,
  projects: wonprojectAdapter.getInitialState(),
};

export const fetchWonProjects = createAsyncThunk<
  Project[],
  { api: ClientApi; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/fetchWonProjects",
  async (
    { api, providerId }: { api: ClientApi; providerId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchWonProjects(providerId);
    } catch (err: any) {
      // showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const wonprojectsSlice = createSlice({
  name: "wonProjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWonProjects.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        wonprojectAdapter.addMany(state.projects, payload);
      }
      state.status = "idle";
    });
    builder.addCase(fetchWonProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchWonProjects.pending, (state) => {
      state.status = "pending";
    });
  },
});

export const selectWonProjectsByProviderId = createSelector(
  [
    (state: RootState, _) => selectAllProject(state),
    (_, providerId: string) => providerId,
  ],
  (projects, providerId) =>
    projects.filter((project) => project.provider_id === providerId)
);

export const { selectAll: selectAllProject, selectById: selectProject } =
  wonprojectAdapter.getSelectors(
    (state: RootState) => state.wonProjects.projects
  );
export const selectProjectsLoading = (state: RootState) =>
  state.wonProjects.status === "pending";

export const wonPojectsReducer = wonprojectsSlice.reducer;

