import { IconProps } from "../features/entities/general";

const ProjectsList = ({
  width = 32,
  height = 32,
  style,
  onClick,
  className,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        onClick={onClick}
        className={className}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6032 9.36296H24.5333M12.6032 16H24.5333M12.6032 22.637H24.5333M7.96375 9.36296H7.97303V9.37307H7.96375V9.36296ZM8.46083 9.36296C8.46083 9.48869 8.40846 9.60927 8.31524 9.69818C8.22202 9.78708 8.09558 9.83703 7.96375 9.83703C7.83191 9.83703 7.70548 9.78708 7.61225 9.69818C7.51903 9.60927 7.46666 9.48869 7.46666 9.36296C7.46666 9.23723 7.51903 9.11664 7.61225 9.02774C7.70548 8.93883 7.83191 8.88888 7.96375 8.88888C8.09558 8.88888 8.22202 8.93883 8.31524 9.02774C8.40846 9.11664 8.46083 9.23723 8.46083 9.36296ZM7.96375 16H7.97303V16.0101H7.96375V16ZM8.46083 16C8.46083 16.1257 8.40846 16.2463 8.31524 16.3352C8.22202 16.4241 8.09558 16.4741 7.96375 16.4741C7.83191 16.4741 7.70548 16.4241 7.61225 16.3352C7.51903 16.2463 7.46666 16.1257 7.46666 16C7.46666 15.8743 7.51903 15.7537 7.61225 15.6648C7.70548 15.5759 7.83191 15.5259 7.96375 15.5259C8.09558 15.5259 8.22202 15.5759 8.31524 15.6648C8.40846 15.7537 8.46083 15.8743 8.46083 16ZM7.96375 22.637H7.97303V22.6471H7.96375V22.637ZM8.46083 22.637C8.46083 22.7628 8.40846 22.8833 8.31524 22.9723C8.22202 23.0612 8.09558 23.1111 7.96375 23.1111C7.83191 23.1111 7.70548 23.0612 7.61225 22.9723C7.51903 22.8833 7.46666 22.7628 7.46666 22.637C7.46666 22.5113 7.51903 22.3907 7.61225 22.3018C7.70548 22.2129 7.83191 22.163 7.96375 22.163C8.09558 22.163 8.22202 22.2129 8.31524 22.3018C8.40846 22.3907 8.46083 22.5113 8.46083 22.637ZM2.77777 30.2222H29.2222C29.7745 30.2222 30.2222 29.7745 30.2222 29.2222V2.77777C30.2222 2.22549 29.7745 1.77777 29.2222 1.77777H2.77777C2.22549 1.77777 1.77777 2.22549 1.77777 2.77777V29.2222C1.77777 29.7745 2.22549 30.2222 2.77777 30.2222Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ProjectsList;
