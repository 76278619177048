import {
  AdminGrantResult,
  ApiError,
  ApiErrorInitialState,
  GrantFinancingType,
  GrantInitialState,
  GrantProject,
  GrantResult,
  GrantService,
  GrantTotalAmounts,
  Province,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

export const fetchGrant = createAsyncThunk<
  GrantResult,
  { api: ClientApi; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchGrant",
  async (
    {
      api,
      grantId,
      locale,
    }: { api: ClientApi; grantId: string; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrant(grantId, locale);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantFinancingType = createAsyncThunk<
  GrantFinancingType[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchGrantFinancingType",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantFinancingType(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProvinces = createAsyncThunk<
  Province[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchProvinces",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProvinces(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantServices = createAsyncThunk<
  GrantService[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchGrantServices",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchGrantService(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGrantsTotals = createAsyncThunk<
  GrantTotalAmounts[],
  { api: ClientApi },
  { rejectValue: ApiError }
>(
  "client/fetchGrantsTotals",
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.fetchGrantsTotals();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBestGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchBestGrantProjects",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchBestGrantProjects(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantsResultAdapter = createEntityAdapter({
  selectId: (model: GrantResult) => model.grant_id,
});

const adminGrantsAdapter = createEntityAdapter({
  selectId: (model: AdminGrantResult) => model.grant_id,
});

const grantProjectsAdapter = createEntityAdapter({
  selectId: (model: GrantProject) => model.grant_project_id,
});

export interface GrantState {
  status: "idle" | "pending";
  error: ApiError;
  grantsSearch: EntityState<GrantResult, string>;
  selectedGrant: GrantResult;
  grant: EntityState<AdminGrantResult, string>;
  grantFinancingType: GrantFinancingType[];
  provinces: Province[];
  services: GrantService[];
  grantTotals: GrantTotalAmounts[];
  bestGrantProjects: EntityState<GrantProject, string>;
}

const initialState: GrantState = {
  status: "idle",
  error: ApiErrorInitialState,
  grantsSearch: grantsResultAdapter.getInitialState(),
  selectedGrant: GrantInitialState,
  grant: grantsResultAdapter.getInitialState(),
  grantFinancingType: [],
  provinces: [],
  services: [],
  grantTotals: [],
  bestGrantProjects: grantProjectsAdapter.getInitialState(),
};

const grantsSlice = createSlice({
  name: "grants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGrant.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrant.fulfilled, (state, { payload }) => {
      adminGrantsAdapter.upsertOne(state.grant, payload);
      state.status = "idle";
    });
    builder.addCase(fetchGrant.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantFinancingType.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantFinancingType.fulfilled, (state, { payload }) => {
      state.grantFinancingType = payload ?? [];
      state.status = "idle";
    });
    builder.addCase(fetchGrantFinancingType.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchProvinces.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProvinces.fulfilled, (state, { payload }) => {
      state.provinces = payload;
      state.status = "idle";
    });
    builder.addCase(fetchProvinces.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantServices.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantServices.fulfilled, (state, { payload }) => {
      state.services = payload;
      state.status = "idle";
    });
    builder.addCase(fetchGrantServices.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchGrantsTotals.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchGrantsTotals.fulfilled, (state, { payload }) => {
      state.grantTotals = payload;
      state.status = "idle";
    });
    builder.addCase(fetchGrantsTotals.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchBestGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchBestGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.setAll(state.bestGrantProjects, payload);
      state.status = "idle";
    });
    builder.addCase(fetchBestGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const selectGrantsIsLoading = (state: RootState) =>
  state.grants.status === "pending";

export const { selectById: selectGrantById } = grantsResultAdapter.getSelectors(
  (state: RootState) => state.grants.grant
);

export const selectGrantFinancingType = createSelector(
  [(state) => state.grants.grantFinancingType],
  (financingType: GrantFinancingType[]) =>
    financingType.map((item) => {
      return {
        label: item.type,
        value: item.grant_financing_type_id,
      };
    })
);

export const selectProvinces = createSelector(
  [(state) => state.grants.provinces],
  (provinces: Province[]) =>
    provinces.map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    })
);
export const selectGrantService = createSelector(
  [(state) => state.grants.services],
  (services: GrantService[]) =>
    services.map((item) => {
      return {
        label: item.name,
        value: item.grant_service_id,
      };
    })
);

export const selectGrantsTotals = (state: RootState) =>
  state.grants.grantTotals;
export const selectGrantsTotalsLoading = (state: RootState) =>
  state.projects.status === "pending";

export const grantsReducer = grantsSlice.reducer;

