import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import Invoices from "@hellodarwin/icons/dist/icons/Invoices";
import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { GrantResult } from "../../../features/entities/grants-entities";
import { dollarFormat } from "../../../features/helpers";
import useLocale from "../../../features/providers/locale-provider";
import { useTranslations } from "../../../features/providers/translations-provider";
import { useTheme } from "../../../plugins/styled";
import { CustomUl } from "../../../theme/list-style";
import Collapse from "../../common/Collapse";
import { Tablet } from "../../common/Media";
import Button from "../../common/button";
import Div from "../../common/div";
import HdImage from "../../common/hd-image/index";
import Typography from "../../common/typography";
import { GrantCardContainer, GrantCardLink, StatusTag } from "./styles";

export interface GrantCardProps extends GrantResult {
  segments?: string[];
  isSmall?: boolean;
  isAdmin?: boolean;
  search_score?: string;
}

const GrantCard = React.memo(({ isAdmin, search_score, ...grant }: GrantCardProps) => {
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const {
    grant_display_title,
    grant_description_short,
    grant_logo,
    grant_financing_type,
    application_status,
    grant_industry_sectors,
    funding_max_amount,
    funding_min_amount,
    percentage_funding,
  } = grant;

  const logoWidth = 112;

  const logoStyle: CSSProperties = {
    fontSize: 64,
    borderRadius: 4,
    flexBasis: logoWidth,
    fontWeight: "bolder",
    display: "flex",
    width: logoWidth,
    height: logoWidth,
    alignItems: "center",
    justifyContent: "center",
    background: theme.colors.white_1,
    color: theme.colors.grey_1,
    border: `1px solid ${theme.colors.grey_4}`,
    flexShrink: 0,
    flexGrow: 0,
  };

  const StatusTags = React.memo(() => {
    return (
      <div style={{ display: "flex", gap: 8 }}>
        {application_status === "closingSoon" && (
          <StatusTag $status={"open"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <circle
                cx="6"
                cy="6"
                r="5.25"
                fill="#5C5C5C"
                stroke="#858585"
                strokeWidth="1.5"
              />
            </svg>
            {t(`grant_card_status|open`)}
          </StatusTag>
        )}
        <StatusTag $status={application_status}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <circle
              cx="6"
              cy="6"
              r="5.25"
              fill="#F82E47"
              stroke="#FF88A4"
              strokeWidth="1.5"
            />
          </svg>
          {t(`grant_card_status|${application_status}`)}
        </StatusTag>
      </div>
    );
  });

  const Header = React.memo(() => (
    <div className="header">
      {grant_logo ? (
        <HdImage
          src={grant_logo}
          width={logoWidth}
          height={logoWidth}
          data-firstletter={grant_display_title[0]}
          alt={`${grant_display_title}`}
          style={logoStyle}
        />
      ) : (
        <span style={logoStyle}>{grant_display_title?.[0] || ""}</span>
      )}
      <div className="header-infos">
        <StatusTags />
        <div className="heading">
          <Typography.Title level={3} elementTheme="h6" noMargin>
            {grant_display_title}
          </Typography.Title>
        </div>
        <div className="description">{grant_description_short}</div>
      </div>
      <Tablet direction="up">
        <Div fitContent flex="column" align="flex-end" gap={8}>
          <Typography elementTheme="body3" color={theme.colors.grey_2}>
            {grant.city}
            {!!grant.city && grant.province && ", "}
            {grant.province}
            {(!!grant.city || grant.province) && grant.country && ", "}
            {grant.country}
          </Typography>
          <Button
            withArrowRight
            size="small"
            defaultStyle={theme.colors.purple_2}
          >
            {t("grant_card|learnMore")}
          </Button>
          {isAdmin && search_score && (
            <Div flex="column" align="flex-end">
              <Typography elementTheme="body3" color={theme.colors.grey_2} ellipsis>
                Search score: {search_score}
              </Typography>
            </Div>
          )}
        </Div>
      </Tablet>
    </div >
  ));

  const Box = React.memo(
    ({
      label,
      children,
      Icon,
    }: { label: string; Icon: ReactNode } & PropsWithChildren) => (
      <div
        className="box-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className="box"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 17,
            width: "100%",
          }}
        >
          <div
            className="label-container"
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            {Icon}
            <div className="label">{label}</div>
          </div>
          <div className="content" style={{ width: "100%" }}>
            {children}
          </div>
        </div>
      </div>
    )
  );

  const Body = React.memo(() => (
    <div className="body-container">
      <div className="body">
        <Box
          Icon={<Budget width={14} height={14} />}
          label={t("grant_card|eligibleFinancing")}
        >
          <CustomUl>
            {!!funding_min_amount && !!funding_max_amount ? (
              <li>
                {t("grant_card|fromMinToMax", {
                  min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                  max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                })}
              </li>
            ) : !!funding_min_amount ? (
              <li>
                {t("grant_card|minCount", {
                  count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                })}
              </li>
            ) : (
              !!funding_max_amount && (
                <li>
                  {t("grant_card|maxCount", {
                    count: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                  })}
                </li>
              )
            )}
            {!!percentage_funding && (
              <li>
                {t("grant_card|projectCostPercent", {
                  percent: `${percentage_funding}`,
                })}
              </li>
            )}
            {!funding_min_amount &&
              !funding_max_amount &&
              !percentage_funding && <li>{t("grant_card|noCondition")}</li>}
          </CustomUl>
        </Box>

        <Box
          Icon={<Industry width={14} height={14} />}
          label={t("grant_card|eligibleIndustries")}
        >
          <Collapse
            ExpandIcon={(props) =>
              grant_industry_sectors?.length > 4 || props.isActive ? (
                <CustomUl style={{ width: "100%", marginTop: 12 }}>
                  <li style={{ width: "100%" }}>
                    {t("grant_card|moreIndustries")}
                  </li>
                </CustomUl>
              ) : (
                <></>
              )
            }
            expandIconPosition="bottom"
            items={[
              {
                noPadding: true,
                id: "grant_card-industries",
                title: (
                  <CustomUl style={{ width: "100%" }}>
                    {!!grant_industry_sectors?.length ? (
                      grant_industry_sectors
                        .slice(0, 4)
                        .map((tag) => <li key={tag}>{tag}</li>)
                    ) : (
                      <li style={{ width: "100%" }}>
                        {t("grant_card|allIndustries")}
                      </li>
                    )}
                  </CustomUl>
                ),
                ghost: true,

                children: (
                  <CustomUl>
                    {grant_industry_sectors?.length > 4 &&
                      grant_industry_sectors.map((tag) => (
                        <li key={tag}>{tag}</li>
                      ))}
                  </CustomUl>
                ),
              },
            ]}
          />
        </Box>
        <Box
          Icon={<Invoices width={14} height={14} />}
          label={t("grant_card|financingType")}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 8,
              alignItems: "flex-start",
              height: "fit-content",
            }}
          >
            {grant_financing_type?.map((type) => (
              <span className="tag purple2" key={type}>
                {type}
              </span>
            ))}
          </div>
        </Box>
        <Tablet direction="down">
          <div style={{ padding: 24 }}>
            <Button
              withArrowRight
              size="small"
              defaultStyle={theme.colors.purple_2}
            >
              {t("grant_card|learnMore")}
            </Button>
          </div>
        </Tablet>
      </div>
    </div>
  ));

  return (
    <GrantCardLink to={location.pathname.startsWith("/search-grants")
      ? `/search-grants/${grant?.grant_id}` : `/funding-explorer/${grant?.grant_id}`}>
      <GrantCardContainer>
        <Header />
        <Body />
      </GrantCardContainer>
    </GrantCardLink>
  );
});

export default GrantCard;

