import { IconProps } from "../features/entities/general";

const Upload = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1238_2100)">
          <path
            d="M1.77778 23.1111V26.6667C1.77778 27.6097 2.15238 28.514 2.81918 29.1808C3.48597 29.8476 4.39034 30.2222 5.33333 30.2222H26.6667C27.6097 30.2222 28.514 29.8476 29.1808 29.1808C29.8476 28.514 30.2222 27.6097 30.2222 26.6667V23.1111M8.88889 8.88888L16 1.77777M16 1.77777L23.1111 8.88888M16 1.77777V23.1111"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1238_2100">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Upload;
