import ReviewFormModal from "@hellodarwin/core/lib/components/reviews/review/review-form-modal";
import { ProjectUpdateFunctions } from "@hellodarwin/core/lib/features/entities";
import { useAppDispatch, useAppSelector } from "../../../app/index";
import {
  reviewProvider,
  selectProjectMatchById,
} from "../../../features/api/slices/matches-slice";
import { useClientApi } from "../../../features/api/use-client-api";

type LeaveReviewModalProps = {
  open: boolean;
  handleCancel: () => void;
  matchId: string;
};

const LeaveReviewModal = ({
  open,
  handleCancel,
  matchId,
}: LeaveReviewModalProps) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const selectedMatch = useAppSelector((state) =>
    selectProjectMatchById(state, matchId)
  );

  const editionFunctions: ProjectUpdateFunctions = {
    review: async (projectId, providerId, reviewRequest, matchId) => {
      if (matchId === undefined) {
        matchId = "";
      }
      await dispatch(
        reviewProvider({
          api,
          reviewRequest,
          projectId,
          matchId,
          providerId,
        })
      );
    },
  };
  if (!selectedMatch?.provider || !selectedMatch?.project_id) return <></>;

  return (
    <ReviewFormModal
      open={open}
      project_id={selectedMatch.project_id}
      provider={selectedMatch.provider}
      matchId={selectedMatch.match_id}
      handleClose={handleCancel}
      editionFunctions={editionFunctions}
    />
  );
};
export default LeaveReviewModal;

