import styled from "@hellodarwin/core/lib/plugins/styled";

export const ResourceSingleContent = styled.div`
  .body2.ant-typography {
    padding-block: 0 16px;
  }

  .heading.ant-typography {
    padding-block: 8px 16px;
  }

  .video {
    padding-block: 24px;
  }
  .body2:first-child,
  ul:first-child {
    margin-block-start: 0;
  }
`;

