import {
  ContentfulFAQ,
  ContentfulResource,
} from "@hellodarwin/core/lib/features/entities";
import { Language } from "@hellodarwin/core/lib/features/entities/translations_ns";
import {
  ApolloClient,
  DocumentNode,
  NormalizedCacheObject,
  gql,
} from "@hellodarwin/core/lib/plugins/apollo";

export default class ContentfulApi {
  private readonly locale: Language;
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(
    apolloClient: ApolloClient<NormalizedCacheObject>,
    locale: Language
  ) {
    this.apolloClient = apolloClient;
    this.locale = locale;
  }

  fetchData = async (query: DocumentNode) => {
    try {
      const { data, error } = await this.apolloClient.query({
        query,
        variables: {},
      });
      if (!!error) {
        console.error(error);

        return undefined;
      }

      return data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  fetchFAQ = async (): Promise<ContentfulFAQ[]> => {
    const query = gql`
      {
        faqComponent ( where:{localisation:"client"} , locale: "${this.locale}", order: [date_DESC]) {
          id
          title
          body
          cta {
            id
            name
            externalLink
            internalLink {
              handle
              parentPage {
                handle
              }
            }
            size
            action
            openInNewTab
            withArrow
            theme
          }
          items {
            id
            image {
              id
              contentful_id
              sys {
                type
                revision
              }
              title
            
              file {
                url
                fileName
              }
            }
            icon {
              id
              icon {
                id
                contentful_id
                sys {
                  type
                  revision
                }
                title
                file {
                  url
                  fileName
                }
              }
              background {
                id
                contentful_id
                sys {
                  type
                  revision
                }
                title
                file {
                  url
                  fileName
                }
              }
              backgroundColor
              inversedX
              inversedY
              size
            }
            overline
            title
            richTitle {
              raw
            }
            body
            bodyLong {
              raw
            }
            backgroundColor
            CTAs {
              id
              name
              externalLink
              internalLink {
                handle
                parentPage {
                  handle
                }
              }
              size
              action
              openInNewTab
              withArrow
              theme
            }
            flexSize
          }
        }
      }
      `;

    const data = await this.fetchData(query);

    return data?.learningArticleCollection?.items;
  };

  fetchResources = async (): Promise<ContentfulResource[]> => {
    try {
      const query = gql`
        {
          learningArticleCollection ( locale: "${this.locale}", order: [date_DESC]) {
            items {
              slug
              title
              type
              thumbnailImage {
                url
              }
              tags
              date
            }
          }
        }
      `;
      const data = await this.fetchData(query);

      return data?.learningArticleCollection?.items;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  fetchResourcesWithLimit = async (
    limit: number
  ): Promise<ContentfulResource[]> => {
    const query = gql`
      {
        learningArticleCollection (limit : ${limit}, locale: "${this.locale}", order: [date_DESC]) {
          items {
            slug
            title
            type
            thumbnailImage {
              url(transform: { width: 596, height: 300, format: WEBP })
            }
            date
          }
        }
      }
      `;

    const data = await this.fetchData(query);

    return data?.learningArticleCollection?.items;
  };

  fetchResourceBySlug = async (
    resourceSlug: string
  ): Promise<ContentfulResource> => {
    const query = gql`
    {
      learningArticleCollection(where: { slug: "${resourceSlug}"}, locale: "${this.locale}" , limit: 1) {
        items {
          slug
          title
          type    
          linkToResource
          thumbnailImage {
            url
          }
          bannerImage {
            url
          }
          date
          scribeEmbed
          content {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  url
                  title
                  width
                  height
                  description
                  contentType
                }
              }
            }
          }
        }
      }
    }
    `;

    const data = await this.fetchData(query);

    return data?.learningArticleCollection?.items?.[0];
  };
}

