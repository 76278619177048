import Container from "@hellodarwin/core/lib/components/common/container";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import ReviewCard from "@hellodarwin/core/lib/components/reviews/review/review-card";
import ReviewHeader from "@hellodarwin/core/lib/components/reviews/review/review-card/review-header";
import ReviewOverallScore from "@hellodarwin/core/lib/components/reviews/review/review-card/review-score";
import { ReviewResult } from "@hellodarwin/core/lib/features/entities";
import { getStringDateFromString } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import ConfigProvider from "antd/es/config-provider";
import Empty from "antd/es/empty";
import Table, { TableProps } from "antd/es/table";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/index";
import { selectProfile } from "../../features/api/slices/profile-slice";
import {
  fetchReviews,
  selectReviews,
  selectReviewsLoading,
} from "../../features/api/slices/reviews-slice";
import { useClientApi } from "../../features/api/use-client-api";

const ClientReviewsPage = () => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const api = useClientApi();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const reviews = useAppSelector(selectReviews);
  const loading = useAppSelector(selectReviewsLoading);

  useEffect(() => {
    dispatch(fetchReviews({ api, companyId: profile.company_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const renderEmpty = () => <Empty description={t("reviews|noReviews")} />;

  const columns: TableProps<ReviewResult>["columns"] = [
    {
      title: t("reviews|list.compagny"),
      key: "company",
      render(value, record, index) {
        return <ReviewHeader review={record} />;
      },
    },
    {
      title: t("reviews|list.status"),
      key: "status",
      render(value, record, index) {
        return (
          <HdTag
            color={
              record.status === "hidden"
                ? theme.colors.grey_2
                : theme.colors.green_1
            }
            text={t(`reviews|status.${record.status}`)}
          />
        );
      },
    },
    {
      title: t("reviews|list.note"),
      key: "note",
      render(value, record, index) {
        return <ReviewOverallScore review={record} />;
      },
    },
    {
      width: 350,
      title: t("reviews|list.yourReview"),
      key: "yourReview",
      dataIndex: "description_summary",
    },
    {
      title: t("reviews|list.date"),
      key: "date",
      render(value, record, index) {
        return record.reviewed_at
          ? getStringDateFromString(record.reviewed_at, selectedLocale)
          : "";
      },
    },
  ];

  const expandedRowRender = (result: ReviewResult) => {
    return <ReviewCard index={0} review={result} defaultOpen />;
  };
  return (
    <PageLayout
      app="client"
      title={t("reviews|myReviews")}
      isLoading={loading}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.myReviews"),
        },
      ]}
    >
      <Container>
        <ConfigProvider renderEmpty={renderEmpty}>
          <Table
            dataSource={reviews}
            columns={columns}
            expandable={{ expandedRowRender }}
          />
        </ConfigProvider>
      </Container>
    </PageLayout>
  );
};

export default ClientReviewsPage;

