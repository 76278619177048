import { useMemo } from "react";
import { useAppSelector } from "../../app/app-hooks";
import { selectProfile } from "../api/slices/profile-slice";
import ClientAnalytics from "./client-analytics";

export const useClientAnalytics = () => {
  const client = useAppSelector(selectProfile);
  const analytics = window.analytics;
  return useMemo(() => new ClientAnalytics(analytics, client), [
    analytics,
    client,
  ]);
};
