import { IconProps } from "../features/entities/general";

const Grants = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 6.51851V25.4815M11.2593 21.0283L12.6483 22.0697C14.4988 23.4588 17.4996 23.4588 19.3517 22.0697C21.2037 20.6807 21.2037 18.4304 19.3517 17.0414C18.4273 16.3461 17.2136 16 16 16C14.8543 16 13.7086 15.6523 12.8348 14.9586C11.087 13.5696 11.087 11.3193 12.8348 9.93027C14.5825 8.54123 17.4175 8.54123 19.1652 9.93027L19.821 10.4517M30.2222 16C30.2222 17.8677 29.8544 19.7171 29.1396 21.4426C28.4249 23.1681 27.3773 24.736 26.0566 26.0566C24.736 27.3773 23.1681 28.4249 21.4426 29.1396C19.7171 29.8544 17.8677 30.2222 16 30.2222C14.1323 30.2222 12.2829 29.8544 10.5574 29.1396C8.83187 28.4249 7.26402 27.3773 5.94336 26.0566C4.62271 24.736 3.57511 23.1681 2.86037 21.4426C2.14564 19.7171 1.77777 17.8677 1.77777 16C1.77777 12.228 3.27618 8.61055 5.94336 5.94336C8.61055 3.27618 12.228 1.77777 16 1.77777C19.772 1.77777 23.3894 3.27618 26.0566 5.94336C28.7238 8.61055 30.2222 12.228 30.2222 16Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Grants;
