import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantTotalAmounts } from "@hellodarwin/core/lib/features/entities/grants-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import React from "react";
import CountUp from "react-countup";

interface RoadmapDashboardSectionTotalsListProps {
  grantsTotals: GrantTotalAmounts[];
  inversed?: boolean;
}

const RoadmapDashboardSectionTotalsList = (
  props: RoadmapDashboardSectionTotalsListProps
) => {
  const { grantsTotals, inversed } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Div flex="column" gap={10}>
      {React.Children.toArray(
        grantsTotals.map(
          ({ financing_type, total_funding, label }) =>
            financing_type !== "Total" && (
              <Div
                flex="row"
                gap={8}
                align="center"
                justify="space-between"
                borderColor={theme.colors.grey_4}
                backgroundColor={theme.colors.white_1}
                borderRadius={4}
                style={{ padding: 8 }}
              >
                <Typography
                  elementTheme="body3"
                  ellipsis
                  overflow
                  textAlign="center"
                >
                  {label ?? financing_type}
                </Typography>

                <Typography
                  ellipsis
                  overflow
                  elementTheme="body3"
                  color={
                    inversed ? theme.colors.green_1 : theme.colors.purple_1
                  }
                >
                  <CountUp
                    end={total_funding}
                    separator={t(`programs|totals.seperator`)}
                    prefix={t(`programs|totals.prefix`)}
                    suffix={t(`programs|totals.suffix`)}
                    duration={1}
                  />
                </Typography>
              </Div>
            )
        )
      )}
    </Div>
  );
};

export default RoadmapDashboardSectionTotalsList;

