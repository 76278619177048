import { LogoProps } from "@hellodarwin/icons/dist/logo-entities";
import { PropsWithChildren, ReactNode } from "react";
import { useTheme } from "../../../../../plugins/styled";
import Typography from "../../../../common/typography";
import { SummaryBoxContainer } from "./styles";

const SummaryBox = ({
  label,
  Icon,
  children,
}: {
  label: string;
  Icon: (props: LogoProps) => ReactNode;
} & PropsWithChildren) => {
  const theme = useTheme();

  return (
    <SummaryBoxContainer>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <Icon width={14} height={14} style={{ color: theme.colors.grey_2 }} />
        <Typography color={theme.colors.grey_2} elementTheme="body3">
          {label}
        </Typography>
      </div>
      <div>{children}</div>
    </SummaryBoxContainer>
  );
};

export default SummaryBox;
