import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectEmptySection from "@hellodarwin/core/lib/components/project/single/project-content/project-empty-section";
import ProjectSingleTile from "@hellodarwin/core/lib/components/project/single/project-content/project-single-tile";
import { Match } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import ProviderCard, {
  PROJECT_OVERVIEW_HEIGHT,
  PROJECT_OVERVIEW_WIDTH,
} from "../../provider/provider-row";

interface OverviewMatchesListProps {
  candidates: Match[];
  shortlist: Match[];
  classicMatches: Match[];
  hasWinner: boolean;
}

const MatchOverviewInfoCard = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  const theme = useTheme();
  return (
    <Div
      backgroundColor={theme.colors.yellow_4}
      flex={"column"}
      justify={"center"}
      align="center"
      gap={8}
      style={{
        width: PROJECT_OVERVIEW_WIDTH,
        height: PROJECT_OVERVIEW_HEIGHT,
        padding: "27px 20px",
        borderRadius: 4,
      }}
      tablet={{ style: { width: "100%" } }}
    >
      <Typography
        elementTheme="subtitle2"
        color={theme.colors.primary}
        bold
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography
        color={theme.colors.primary}
        elementTheme="body3"
        textAlign="center"
      >
        {subtitle}
      </Typography>
    </Div>
  );
};

const MatchesList = ({
  matches,
  title,
  subtitle,
  hasWinner,
  isClassic,
}: {
  matches: Match[];
  title: string;
  subtitle: string;
  hasWinner: boolean;
  isClassic?: boolean;
}) => (
  <Div flex="row" gap={32} wrap="wrap" justify="stretch">
    <MatchOverviewInfoCard title={title} subtitle={subtitle} />
    {matches.map((match) => (
      <ProviderCard
        key={match.match_id}
        match={match}
        hasWinner={hasWinner}
        isOverview
        isClassic={isClassic}
      />
    ))}
  </Div>
);

const OverviewMatchesList = ({
  candidates,
  shortlist,
  classicMatches,
  hasWinner,
}: OverviewMatchesListProps) => {
  const { t } = useTranslation();

  return (
    <ProjectSingleTile title={t("project|overview.matches.title")}>
      {!!shortlist.length ? (
        <MatchesList
          matches={shortlist}
          title={
            hasWinner
              ? t("project|overview.matches.winner.title")
              : t("project|overview.matches.shortlist.title")
          }
          subtitle={
            hasWinner
              ? t("project|overview.matches.winner.subtitle")
              : t("project|overview.matches.shortlist.subtitle", {
                  count: shortlist.length,
                })
          }
          hasWinner={hasWinner}
        />
      ) : !!candidates.length ? (
        <MatchesList
          matches={candidates}
          title={t("project|overview.matches.candidates.title")}
          subtitle={t("project|overview.matches.candidates.subtitle")}
          hasWinner={hasWinner}
        />
      ) : !!classicMatches.length ? (
        <MatchesList
          matches={classicMatches}
          title={
            hasWinner
              ? t("project|overview.matches.winner.title")
              : t("project|overview.matches.candidates.title")
          }
          subtitle={
            hasWinner
              ? t("project|overview.matches.winner.subtitle")
              : t("project|overview.matches.candidates.subtitle")
          }
          hasWinner={hasWinner}
          isClassic
        />
      ) : (
        <ProjectEmptySection
          title={t("project|overview.matches.empty")}
          withIcon
        />
      )}
    </ProjectSingleTile>
  );
};

export default OverviewMatchesList;

