import Filter from "@hellodarwin/icons/dist/icons/Filter";
import Search from "@hellodarwin/icons/dist/icons/Search";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Input from "antd/es/input";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { FundingExplorerFilterProps } from ".";
import Button from "../../common/button";
import Div from "../../common/div";
import FormItem from "../../forms/form-layouts/form-item";
import IndustriesSelector, {
  IndustriesSelectorProps,
} from "./industries-selector";
import FilterTagBar, { FilterTagBarProps } from "./tag-bar";

export interface FilterHeaderProps extends FundingExplorerFilterProps {
  handleAdvancedFilters: () => void;
  advancedFiltersOpened: boolean;
  handleProjectSelection: (projectId: string) => void;
}

const FilterHeader = ({
  handleAdvancedFilters,
  advancedFiltersOpened,
  selectedIndustries,
  services,
  subIndustries,
  provinces,
  setSelectedIndustries,
  filterform,
  financingType,
  applyFilter,
  industries,
  onTextQueryChange,
  selectedProjects,
  bestProjects,
  handleProjectSelection,
  companySize,
  annualRevenue,
}: FilterHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (hasChanged) {
      applyFilter();
      setHasChanged(false);
    }
  }, [hasChanged, selectedIndustries]);

  const tagBarProps: FilterTagBarProps = {
    subIndustries,
    financingType,
    services,
    provinces,
    companySize,
    annualRevenue,
    filterform,
    setHasChanged,
    setSelectedIndustries,
    selectedIndustries,
    industries,
    selectedProjects,
    bestProjects,
    handleProjectSelection,
  };

  const industriesSelectorProps: IndustriesSelectorProps = {
    industries,
    selectedIndustries,
    setSelectedIndustries,
    applyFilter,
    filterform,
  };

  return (
    <Div flex="column" gap={8} tablet={{ gap: 24 }}>
      <Div flex="row" gap={8} tablet={{ flex: "column" }}>
        <IndustriesSelector {...industriesSelectorProps} />
        <FormItem name="textQuery" style={{ flex: 4, margin: 0 }}>
          <Input
            onChange={onTextQueryChange}
            placeholder={t(`grants|fundingExplorerFilter.textQueryPlaceholder`)}
            size="large"
            style={{
              fontSize: 18,
              height: 45,
            }}
            prefix={
              <Search
                width={20}
                height={20}
                style={{ marginRight: 8 }}
                color={theme.colors.grey_3}
              />
            }
          />
        </FormItem>
        <Button
          defaultStyle={theme.colors.purple_3}
          style={{ height: 45, flex: 1, borderRadius: 4, gap: 24 }}
          headingIcon={<Filter width={18} height={18} />}
          trailingIcon={
            <TabArrow width={12} height={8} down={advancedFiltersOpened} />
          }
          tablet={{ style: { width: "100%" } }}
          onClick={handleAdvancedFilters}
        >
          {t(`grants|fundingExplorerFilter.advancedFilters`)}
        </Button>
      </Div>
      <FilterTagBar {...tagBarProps} />
    </Div>
  );
};
export default FilterHeader;

