import notification from "antd/es/notification";
import { PropsWithChildren, useEffect, useState } from "react";
import { gql } from "../../plugins/apollo";
import useApolloClient from "../hooks/use-appollo-client";
import useLocale from "./locale-provider";
import TranslationsProvider from "./translations-provider";
import {
  flattenTranslations,
  FlatTranslation,
  processTranslationFiles,
} from "./translations-provider/translations-utils";

const TRANSLATIONS_QUERY = gql`
  query GetTranslations($locale: String!) {
    translationFileCollection(
      where: { sys: { firstPublishedAt_exists: true } }
      locale: $locale
    ) {
      items {
        key
        translationsFieldsCollection(
          where: { sys: { firstPublishedAt_exists: true } }
        ) {
          items {
            key
            value
          }
        }
      }
    }
  }
`;

interface TranslationField {
  key: string;
  value: string;
}

interface TranslationFile {
  key: string;
  translationFileCollection?: { items: TranslationFile[] };
  translationsFieldsCollection?: { items: TranslationField[] };
}

interface TranslationsQueryData {
  translationFileCollection: {
    items: TranslationFile[];
  };
}

const AppTranslationsProvider = ({ children }: PropsWithChildren) => {
  const { selectedLocale } = useLocale();
  const [translationsData, setTranslationData] = useState<FlatTranslation>({});
  const client = useApolloClient(
    "modxx330knqj",
    "P8xozOvf7K4HtnL7xpr7Sp5aO1FV4WLyJlCquu9tPz8"
  );

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const { data } = await client.query<TranslationsQueryData>({
          query: TRANSLATIONS_QUERY,
          variables: { locale: selectedLocale },
        });

        if (!data?.translationFileCollection?.items) return;

        const mappedTranslations = processTranslationFiles(
          data.translationFileCollection.items
        );
        const flatTranslations = flattenTranslations([mappedTranslations]);

        setTranslationData(flatTranslations);
      } catch (error) {
        notification.error({
          type: "error",
          message: "Translations failed",
          description: error.message,
          placement: "bottomLeft",
        });
      }
    };

    fetchTranslations();
  }, [selectedLocale]);

  return (
    <>
      <TranslationsProvider initialTranslations={translationsData}>
        <>{children}</>
      </TranslationsProvider>
    </>
  );
};

export default AppTranslationsProvider;

