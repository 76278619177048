import { IconProps } from "../features/entities/general";

const MoreCandidates = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1238_2092)">
          <path
            d="M6.51852 8.88888V13.6296M6.51852 13.6296V18.3703M6.51852 13.6296H1.77777M6.51852 13.6296H11.2593M14.8148 7.1111C14.8148 7.81148 14.9528 8.50501 15.2208 9.15208C15.4888 9.79915 15.8817 10.3871 16.3769 10.8823C16.8722 11.3776 17.4601 11.7704 18.1072 12.0385C18.7542 12.3065 19.4478 12.4444 20.1481 12.4444C20.8485 12.4444 21.5421 12.3065 22.1891 12.0385C22.8362 11.7704 23.4241 11.3776 23.9194 10.8823C24.4146 10.3871 24.8075 9.79915 25.0755 9.15208C25.3435 8.50501 25.4815 7.81148 25.4815 7.1111C25.4815 5.69661 24.9196 4.34006 23.9194 3.33987C22.9192 2.33967 21.5626 1.77777 20.1481 1.77777C18.7337 1.77777 17.3771 2.33967 16.3769 3.33987C15.3767 4.34006 14.8148 5.69661 14.8148 7.1111ZM30.2222 27.4331V27.2592C30.2222 24.5874 29.1608 22.025 27.2716 20.1358C25.3823 18.2465 22.82 17.1852 20.1481 17.1852C17.4763 17.1852 14.914 18.2465 13.0247 20.1358C11.1354 22.025 10.0741 24.5874 10.0741 27.2592V27.4315C13.1153 29.2631 16.5995 30.2282 20.1497 30.2222C23.8333 30.2222 27.2798 29.2029 30.2222 27.4315V27.4331Z"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1238_2092">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default MoreCandidates;
