import { FormInstance } from "antd/es/form/Form";
import dayjs from "dayjs";
import CompanyType from "../enums/company-type";
import Language from "../enums/language";
import ProfileBlocks from "../enums/profile-blocks";
import SingleLanguage from "../enums/single-language";
import {
  Company,
  CompanyAsset,
  Contact,
  FindAuth0IDResponse,
} from "./core-entities";

export interface ProfileFormValues {
  contact_name: string;
  contact_phone: string;
  contact_preferred_language: SingleLanguage;
  project_language: Language;
  profile_language: SingleLanguage;
  contact_email: string;
  company_name: string;
  company_email: string;
  company_website: string;
  logo: string;
  address: string;
  city: string;
  postal_code: string;
  province: string;
  country: string;
  company_founded_at?: dayjs.Dayjs;
  company_size: string;
  company_description: string;
  terms_and_conditions: boolean;
  company_type?: CompanyType;
}

export interface Profile {
  contact_id: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  contact_preferred_language: SingleLanguage;
  project_language: Language;
  profile_language: SingleLanguage;
  company_id: string;
  company_name: string;
  company_address: string;
  company_email: string;
  company_website: string;
  company_city: string;
  company_postal_code: string;
  company_province: string;
  company_country: string;
  company_size: string;
  company_founded_at?: string | null;
  company_description: string;
  terms_and_conditions: boolean;
  logo?: string;
  banner?: string;
  assets?: CompanyAsset[];
  lat?: number;
  lng?: number;
  company_type?: CompanyType;
  is_propulsion?: boolean;
  onboarded_at?: string;
}

export type AdminRole =
  | "SuperAdmin"
  | "Admin"
  | "Viewer"
  | "Editor"
  | "Unverified";
export const AdminRoles: AdminRole[] = [
  "SuperAdmin",
  "Admin",
  "Viewer",
  "Editor",
  "Unverified",
];

export const ProfileInitialState: Profile = {
  contact_id: "",
  contact_name: "",
  contact_email: "",
  contact_phone: "",
  logo: "",
  contact_preferred_language: SingleLanguage.English,
  project_language: Language.Any,
  profile_language: SingleLanguage.English,
  company_id: "",
  company_name: "",
  company_address: "",
  company_email: "",
  company_website: "",
  company_city: "",
  company_postal_code: "",
  company_country: "",
  company_province: "",
  company_size: "",
  company_founded_at: null,
  company_description: "",
  terms_and_conditions: false,
};

export interface ClientOnboardRequest {
  company_name: string;
  annual_revenue: string;
}

export interface ClientOnBoardResponse {
  company_name: string;
  onboarded_at: string;
}

export interface HubspotUserData {
  hubspot_id: string;
  email: string;
  name: string;
  preferred_language: string;
}

export interface CompanyDuplicateResponse {
  company_id: string;
  company_name: string;
  domain: string;
  email: string;
}
export interface AdminProfile {
  admin_id: string;
  auth_id: string;
  auth_id_match: boolean;
  google_auth_id: string;
  hubspot?: HubspotUserData;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  last_login_at: string;
  settings?: Map<string, string>;
  roles: AdminRole;
  picture: string;
  meeting_link_en?: string;
  meeting_link_fr?: string;
  auth?: FindAuth0IDResponse;
  title: string;
  is_verified_admin: boolean;
}
export interface AdminProfileRequest {
  admin_id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  roles?: AdminRole;
  auth_id: string;
  google_auth_id: string;
}
export const AdminProfileInitialState: AdminProfile = {
  first_name: "",
  last_name: "",
  auth_id: "",
  auth_id_match: false,
  google_auth_id: "",
  email: "",
  phone: "",
  admin_id: "",
  last_login_at: "",
  roles: "Admin",
  picture: "",
  title: "",
  is_verified_admin: false,
};

interface AnalyticsUser {
  user_type: string;
  entity_id: string;
  company_id: string;
  company_name: string;
  company_email: string;
  company_size: string;
  city: string;
}

export const newClientAnalyticsUser = (profile: Profile) => {
  const user: AnalyticsUser = {
    user_type: "Client",
    entity_id: `${profile.contact_id}`,
    company_id: profile.company_id,
    company_name: profile.company_name,
    company_email: profile.company_email,
    company_size: profile.company_size,
    city: profile.company_city,
  };

  return user;
};

export interface ProfileRequest {
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  contact_preferred_language?: SingleLanguage;
  company_country?: string;
  company_name?: string;
  company_address?: string;
  company_email?: string;
  company_website?: string;
  company_city?: string;
  company_postal_code?: string;
  company_province?: string;
  company_size?: string;
  company_founded_at?: string | null;
  company_description?: string;
  company_id?: string;
  terms_and_conditions?: boolean;
  project_language?: Language;
  profile_language?: SingleLanguage;
  lat?: number;
  lng?: number;
  company_type?: CompanyType;
}

export interface ProfileForms {
  contactForm?: FormInstance<Contact>;
  companyForm?: FormInstance<Company>;
}

export interface ProfileModalProps {
  open: boolean;
  type?: ProfileBlocks;
  targetId?: string;
}

