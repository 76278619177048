import Button from "@hellodarwin/core/lib/components/common/button";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { withTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme";

import { useAppDispatch } from "../../app/index";
import { toggleProjectsModal } from "../../features/api/slices/projects-slice";

const ProjectSubmissionRow = ({ theme }: { theme: DefaultTheme }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleProjectSubmitModal = () => {
    dispatch(toggleProjectsModal({ isVisible: true, type: "projectSubmit" }));
  };

  return (
    <Button
      defaultStyle={theme?.colors?.yellow_1}
      size="small"
      onClick={handleProjectSubmitModal}
      style={{ minWidth: 168 }}
    >
      {t("project|submit")}
    </Button>
  );
};

export default withTheme(ProjectSubmissionRow);
