import Form from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import React from "react";
import { Match } from "../../features/entities/matches-entities";
import { useTranslation } from "../../plugins/i18n";
import Button from "../common/button";
import Modal from "../common/hd-modal";
import Typography from "../common/typography";

export type MatchRefusalFormValues = {
  refusalReason: string;
  refusalReasonSpecified: string;
};

type ProviderRefuseModalProps = {
  match: Match;
  handleRefuse: (
    match_id: string,
    refusedReason: string,
    refusedReasonSpecified: string
  ) => Promise<void>;
  closeModal: () => void;
  modalVisible: boolean;
};

const ProviderRefuseModal = ({
  match,
  closeModal,
  handleRefuse,
  modalVisible,
}: ProviderRefuseModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<MatchRefusalFormValues>();

  const matchRefusalFormValues: MatchRefusalFormValues = {
    refusalReason: match.raise_hand_reject_reason || "",
    refusalReasonSpecified: match.raise_hand_reject_reason || "",
  };
  const handleCancel = async () => {
    form.resetFields();
    closeModal();
  };

  const handleFinish = async () => {
    handleRefuse(
      match.match_id,
      form.getFieldValue(
        matchRefusalFormValues.refusalReason as keyof MatchRefusalFormValues
      ),
      form.getFieldValue(
        matchRefusalFormValues.refusalReasonSpecified as keyof MatchRefusalFormValues
      )
    );
    form.resetFields();
    closeModal();
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!form.getFieldValue("refusalReason")) {
      form.setFieldsValue({
        ["refusalReason"]: "Other",
      });
    }
  };

  return (
    <Modal open={modalVisible} handleCancel={handleCancel}>
      <Typography.Title level={4}>
        {t("project|matchRejectModal.title")}
      </Typography.Title>
      <Form
        form={form}
        layout={"vertical"}
        size="large"
        onFinish={handleFinish}
        name={"refuseMatch"}
        initialValues={matchRefusalFormValues}
      >
        <Form.Item
          name="refusalReason"
          rules={[
            {
              required: true,
              message: t("project|matchRejectModal.form.validation"),
            },
          ]}
        >
          <Radio.Group size={"large"}>
            <Space direction="vertical">
              <Radio value={"Portfolio doesn't fit"}>
                {t("project|matchRejectModal.reasons.portfolioFit")}
              </Radio>
              <Radio value={"Company size"}>
                {t("project|matchRejectModal.reasons.companySize")}
              </Radio>
              <Radio value={"Intro message"}>
                {t("project|matchRejectModal.reasons.introMessage")}
              </Radio>
              <Radio value={"Localisation"}>
                {t("project|matchRejectModal.reasons.localisation")}
              </Radio>
              <Radio value={"Other"}>
                {t("project|matchRejectModal.reasons.other")}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="refusalReasonSpecified">
          <TextArea
            allowClear={true}
            placeholder={t("project|matchRejectModal.reasons.pleaseSpecify")}
            onChange={handleChange}
          />
        </Form.Item>
        <Button onClick={handleFinish} size={"large"}>
          {t("button.refuseProvider")}
        </Button>
      </Form>
    </Modal>
  );
};

export default ProviderRefuseModal;

