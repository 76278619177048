import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectSingleTile from "@hellodarwin/core/lib/components/project/single/project-content/project-single-tile";
import { Project } from "@hellodarwin/core/lib/features/entities/project-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useMemo } from "react";
import { useAppSelector } from "../../../app/app-hooks";
import {
  selectCandidateMatches,
  selectShortlistedMatches,
} from "../../../features/api/slices/matches-slice";
import MessageCard from "./message-card";

interface ProjectMessagesProps {
  project: Project;
}

const ProjectMessages = ({ project }: ProjectMessagesProps) => {
  const proposals = useAppSelector(selectShortlistedMatches);
  const matches = useAppSelector(selectCandidateMatches);

  const allMessageMatches = useMemo(
    () =>
      [...proposals, ...matches].filter(
        (match) => !!match.hello_message || !!match.shortlist_message
      ),
    [proposals, matches]
  );

  const ProjectMessagesHeader = ({ nbMessages }: { nbMessages: number }) => {
    const { t } = useTranslation();

    return (
      <Div
        flex="column"
        gap={13}
        style={{ marginBottom: nbMessages > 0 ? 24 : 0 }}
      >
        <Typography elementTheme="subtitle2">
          {t("project|messages.title")}
        </Typography>
        <Typography elementTheme="body2">
          {nbMessages > 0
            ? t("project|messages.unreadDescription")
            : t("project|messages.noDescription")}
        </Typography>
      </Div>
    );
  };

  return (
    <ProjectSingleTile>
      <ProjectMessagesHeader nbMessages={allMessageMatches.length} />
      <Div flex="column" gap={18}>
        {allMessageMatches.map((match) => (
          <MessageCard match={match} />
        ))}
      </Div>
    </ProjectSingleTile>
  );
};

export default ProjectMessages;
