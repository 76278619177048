import Div from "@hellodarwin/core/lib/components/common/div";
import { Milestone } from "@hellodarwin/core/lib/features/entities";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import StepType from "@hellodarwin/icons/dist/icons/StepType";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import Divider from "antd/es/divider";

import MilestoneInfo from "../milestone-card/milestone-info";

type MilestoneHeaderInfomrationsProps = {
  milestone: Milestone;
};
const MilestoneHeaderInfomrations = ({
  milestone,
}: MilestoneHeaderInfomrationsProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  return (
    <Div flex="row" gap={16} align="center">
      {(milestone.milestone_created_at ||
        milestone.milestone_completed_at ||
        milestone.milestone_scheduled_at) && (
        <>
          <MilestoneInfo
            title={
              !!milestone.milestone_created_at
                ? t(`programs|singleDigitalTransfo.createdAt`)
                : !!milestone.milestone_completed_at
                  ? t(`programs|singleDigitalTransfo.completedAt`)
                  : t(`programs|singleDigitalTransfo.scheduledAt`)
            }
            value={
              !!milestone.milestone_created_at
                ? `${getStringDate(
                    new Date(milestone.milestone_created_at || ""),
                    selectedLocale
                  )}`
                : !!milestone.milestone_completed_at
                  ? `${getStringDate(
                      new Date(milestone.milestone_completed_at || ""),
                      selectedLocale
                    )}`
                  : `${getStringDate(
                      new Date(milestone.milestone_scheduled_at || ""),
                      selectedLocale
                    )}`
            }
            Icon={Timeline}
          />
          <Divider
            style={{
              margin: 0,
              height: 13,
              top: 0,
              borderColor: theme.colors.primary,
            }}
            type="vertical"
          />
        </>
      )}
      <MilestoneInfo
        value={t(`propulsion|milestoneTypes.${milestone?.milestone_type}`)}
        title={t("programs|singleDigitalTransfo.format")}
        Icon={StepType}
      />
    </Div>
  );
};

export default MilestoneHeaderInfomrations;

