import Card from "antd/es/card/Card";
import styled, { css } from "../../../../../plugins/styled";
interface ProviderCardContainerProps {
  $isSelected?: boolean;
}

export const SelectedStyling = css`
  margin: 0;
  background-color: ${(props) => props.theme.colors.green_4};
  border: 2px solid ${(props) => props.theme.colors.green_1};
  box-shadow: none;
`;
export const ProviderCardContainer = styled(Card)<ProviderCardContainerProps>`
  margin: 1px;
  cursor: pointer;
  width: 220px;
  padding: 24px;
  flex-shrink: 0;
  border: 2px solid ${(props) => props.theme.colors.primary};

  border-radius: 4px;
  ${({ $isSelected }) => ($isSelected ? SelectedStyling : undefined)}
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.purple_3};
    box-shadow: 0px 4px 10px rgba(25, 0, 51, 0.09);
    ${({ $isSelected }) => ($isSelected ? SelectedStyling : undefined)}
  }
`;

