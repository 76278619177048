import { QA } from "../../features/entities/core-entities";
import parse from "../../features/helpers/parse";
import Div from "../common/div";
import Typography from "../common/typography";

const FaqBlock = ({ qa }: { qa: QA }) => {
  return (
    <Div flex="column">
      {typeof qa.answer === "string" ? (
        <Typography elementTheme="body2">{parse(qa.answer)}</Typography>
      ) : (
        <Typography elementTheme="body2">{qa.answer}</Typography>
      )}
      {qa.videos && (
        <Div
          flex="column"
          gap={16}
          style={{ paddingTop: "1rem", paddingLeft: "2rem" }}
        >
          {qa.videos?.map((v, i) => (
            <Div key={i}>
              <Typography elementTheme="body2" bold>
                {v.label}
              </Typography>
              <div className="iframe-container">
                <iframe
                  title={v.name}
                  src={v.url}
                  className="iframe"
                  allowFullScreen
                ></iframe>
              </div>
            </Div>
          ))}
        </Div>
      )}
    </Div>
  );
};
export default FaqBlock;
