import Sider from "antd/es/layout/Sider";
import { AppType } from "../../../features/entities";
import { toHex } from "../../../features/helpers/convertToHex";
import styled, { css } from "../../../plugins/styled";
import theme from "../../../theme";

type NavigationContainerProps = {
  $app?: AppType;
  $isOpen?: boolean;
};

export const SocialIcon = styled.svg`
  cursor: pointer;
`;

export const SocialContainer = styled.div<NavigationContainerProps>`
  width: ${(props) => (props.$isOpen ? "100%" : "fit-content")};
  color: ${(props) =>
    props.$app === "provider"
      ? props.theme.colors.white_1
      : props.theme.colors.grey_2};
  ${SocialIcon} {
    color: ${(props) =>
      props.$app === "provider"
        ? props.theme.colors.white_1
        : props.theme.colors.grey_2};
    &:hover {
      color: ${(props) =>
        props.$app === "provider"
          ? props.theme.colors.purple_3
          : props.theme.colors.purple_1};
    }
  }
`;
export const MenuItemStyling = css<NavigationContainerProps>`
  margin: 0;
  height: fit-content;
  border-radius: 0;
  align-items: center;
  white-space: break-spaces;
  .ant-menu-submenu-title {
    height: auto;
  }
  .ant-menu-title-content {
    height: auto;
    padding: 11px 16px;
    padding-left: 0;
  }

  &.ant-menu-submenu {
    padding: 0;
    padding-bottom: 11px;
  }

  &.ant-menu-submenu-open {
    position: relative;
    display: block !important;
    &:before {
      content: "";
      position: absolute;
      height: calc(100% - 22px - 16px - 8px);
      left: 24px;
      top: calc(22px + 16px);
      width: 1px;
      z-index: 200;
      border-left: 1px solid
        ${({ $app }) =>
          $app === "provider" ? theme.colors.white_1 : theme.colors.grey_4};
    }
  }

  ${(props) => (!props.$isOpen ? "line-height:0;" : undefined)}
  .ant-menu-title-content,
  .ant-menu-submenu-title {
    margin-inline-start: 0 !important;
  }
  &.hd-menu-subitem .ant-menu-title-content {
    padding: 0;
  }
`;

export const Burger = styled.div<NavigationContainerProps>`
  background-color: ${(props) => props.theme.colors.grey_3 + toHex(3)};
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: fit-content;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey_3 + toHex(6)};
  }
  ${(props) =>
    props.$app === "provider"
      ? `
  .bar1,
  .bar2,
  .bar3 {
    background-color: ${(props) => props.theme.colors.white_1};
  }
  `
      : undefined}
`;

export const MenuStyling = css<NavigationContainerProps>`
  a {
    text-decoration: none;
    color: inherit;
    & >  * {
      color: inherit;
    }
  }
  .ant-menu-root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;

    border: none;
    ${(props) =>
      !props.$isOpen
        ? `
  align-items: center;

  
  .hd-menu-item,
  .ant-menu-submenu-title {
    display: block;
    
    padding:8px !important;
    width: calc(8px + 18px + 8px);
    height: calc(8px + 18px + 8px);
   
  }
  .ant-menu-submenu{
    padding:0 !important;

  }
  
  `
        : undefined};

    &::before {
      display: none;
    }
  }

  .ant-menu-submenu {
    .ant-menu-sub {
      max-height: 0;
      height: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
      transition: max-height 100ms linear;
      .ant-menu-item {
        padding: 8px 32px !important;
        flex-grow: 0;
        flex-shrink: 0;
      }
      &:after {
        display: none;
      }
    }

    &.ant-menu-submenu-open {
      .ant-menu-sub {
        max-height: 200px;
      }
    }
  }

  .hd-menu-item,
  .hd-menu-subitem {
    ${MenuItemStyling}
  }
  .hd-menu-subitem {
    white-space: break-spaces;
  }
  .hd-menu-item {
    transition: color 100ms linear;
    align-items: center;
    gap: 1rem;

    .ant-menu-submenu-title {
      gap: 1rem;
    }
    .anticon {
      font-size: 20px;
    }
    ul {
      gap: 0;
    }
  }
`;
export const Navigation = styled.div<NavigationContainerProps>`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding: ${(props) => (!props.$isOpen ? "32px 8px" : "32px 8px")};
  padding-top: 40px;
  .ant-menu-submenu-title a {
    pointer-events: none;
  }
  ${(props) =>
    props.$app === "provider"
      ? `
 ${SocialContainer} .ant-typography{
   color:${props.theme.colors.white_1};
 }
 `
      : undefined}

  ${MenuStyling}
`;

export const MobileNavigationContainer = styled.div<NavigationContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 0;
  gap: 20px;

  ${MenuStyling}
  .ant-menu-root {
    gap: 10px;
  }
  .hd-navigation-footer {
    padding-left: 6px;
    padding-bottom: 2rem;
    .hd-user-menu-item ,.user-menu{
      color: ${(props) =>
        props.$app === "provider"
          ? props.theme.colors.white_1
          : props.theme.colors.grey_2};
      ${SocialIcon} {
        color: ${(props) =>
          props.$app === "provider"
            ? props.theme.colors.white_1
            : props.theme.colors.grey_2};
        &:hover {
          color: ${(props) =>
            props.$app === "provider"
              ? props.theme.colors.purple_3
              : props.theme.colors.purple_1};
    }
  }
  .hd-menu-item {
    &.ant-menu-submenu-open {
      &::before {
        border: none;
        display: none;
      }
    }
  }
`;

export const NavSider = styled(Sider)`
  z-index: 100;
  background-color: white;
  .ant-layout-sider-trigger {
    position: absolute;
    top: 80px;
    z-index: 1100;
    right: -15px;
    background-color: transparent !important;
    color: white;
    width: 30px !important;
    height: 30px;
    cursor: pointer;

    svg {
      circle {
        fill: ${theme.colors.grey_4};
      }
    }
    &:hover {
      svg {
        circle {
          fill: ${theme.colors.grey_3};
        }
      }
    }
  }
`;

