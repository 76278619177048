import { LogoProps } from "../logo-entities";

const LinkedinLogo = ({
  width = 32,
  height = 32,
  style,
  onClick,
  className,
}: LogoProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        onClick={onClick}
        className={className}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.46108 6.13202C7.4607 6.90924 7.15157 7.65448 6.60171 8.20379C6.05186 8.7531 5.30631 9.06148 4.52908 9.06109C3.75185 9.0607 3.00661 8.75158 2.45731 8.20172C1.908 7.65186 1.59962 6.90631 1.60001 6.12909C1.6004 5.35186 1.90952 4.60662 2.45938 4.05731C3.00923 3.508 3.75478 3.19962 4.53201 3.20001C5.30924 3.2004 6.05448 3.50953 6.60379 4.05938C7.15309 4.60924 7.46147 5.35479 7.46108 6.13202ZM7.549 11.2312H1.68792V29.5763H7.549V11.2312ZM16.8095 11.2312H10.9777V29.5763H16.7509V19.9495C16.7509 14.5866 23.7402 14.0884 23.7402 19.9495V29.5763H29.528V17.9567C29.528 8.91603 19.1832 9.25304 16.7509 13.6928L16.8095 11.2312Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default LinkedinLogo;
