import {
  ApiError,
  ApiErrorInitialState,
  GrantProject
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

export const fetchBestGrantProjects = createAsyncThunk<
  GrantProject[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  "client/fetchBestGrantProjects",
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchBestGrantProjects(locale);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const grantProjectsAdapter = createEntityAdapter({
  selectId: (model: GrantProject) => model.grant_project_id,
});

export interface GrantState {
  status: "idle" | "pending";
  error: ApiError;
  grantProjects: EntityState<GrantProject, string>;
}

const initialState: GrantState = {
  status: "idle",
  error: ApiErrorInitialState,
  grantProjects: grantProjectsAdapter.getInitialState(),
};

const grantsProjectsSlice = createSlice({
  name: "grants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBestGrantProjects.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchBestGrantProjects.fulfilled, (state, { payload }) => {
      grantProjectsAdapter.setAll(state.grantProjects, payload ?? []);
      state.status = "idle";
    });
    builder.addCase(fetchBestGrantProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const { selectAll: selectBestGrantProjects } =
  grantProjectsAdapter.getSelectors(
    (state: RootState) => state.grantsProjects.grantProjects
  );

export const grantsProjectsReducer = grantsProjectsSlice.reducer;

