import Div from "@hellodarwin/core/lib/components/common/div";
import {
  GrantTotalAmounts,
  Program,
} from "@hellodarwin/core/lib/features/entities";
import Divider from "antd/es/divider";
import GrantsSummarySider from "./client-grants-programs-sider";
import ProgramSummarySider from "./client-roadmap-programs-sider";

interface GrantsSiderProps {
  grantsTotals: GrantTotalAmounts[];
  programs: Program[];
  isLoadingTotals: boolean;
  isLoadingPrograms: boolean;
}

const RoadmapDashboard = ({
  grantsTotals,
  programs,
  isLoadingTotals,
  isLoadingPrograms,
}: GrantsSiderProps) => {
  return (
    <Div style={{ padding: 24 }} flex="column" gap={32}>
      {!!programs.length && (
        <ProgramSummarySider
          programs={programs}
          isLoading={isLoadingPrograms}
        />
      )}
      {!!programs.length && (
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      )}
      {!!grantsTotals && (
        <GrantsSummarySider
          grantsTotals={grantsTotals}
          isLoading={isLoadingTotals}
        />
      )}
    </Div>
  );
};

export default RoadmapDashboard;

