import ProjectStateBar from "@hellodarwin/core/lib/components/project/single/project-state-bar";
import { Project } from "@hellodarwin/core/lib/features/entities/project-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useAppSelector } from "../../app/app-hooks";
import {
  selectCandidateMatches,
  selectClassicMatches,
  selectHasWinner,
  selectShortlistedMatches,
} from "../../features/api/slices/matches-slice";

interface ClientProjectSidebarProps {
  project: Project;
}
const ClientProjectSidebar = ({ project }: ClientProjectSidebarProps) => {
  const { selectedLocale } = useLocale();

  const matches = useAppSelector(selectClassicMatches);
  const shortlist = useAppSelector(selectShortlistedMatches);
  const candidates = useAppSelector(selectCandidateMatches);
  const hasWinner = useAppSelector(selectHasWinner);

  return (
    <ProjectStateBar
      project={project}
      candidates={candidates}
      shortlist={shortlist}
      classicMatches={matches}
      hasWinner={hasWinner}
      locale={selectedLocale}
    />
  );
};
export default ClientProjectSidebar;

