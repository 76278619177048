import { GrantStatus } from "../../../../features/entities/grants-entities";
import { DefaultTheme } from "../../../../plugins/styled";
import { TagTheme } from "../../../common/hd-tag/styles";
import { getGrantStatusColors } from "../../card/styles";

const getFilterTagBarTheme = (
  type: string,
  theme: DefaultTheme,
  value: string
): TagTheme => {
  switch (type) {
    case "industry":
      return {
        backgroundColor: theme.colors.purple_2,
        textColor: theme.colors.white_1,
        borderColor: theme.colors.purple_2,
        borderColorHover: theme.colors.purple_3,
        backgroundColorHover: theme.colors.purple_3,
        textColorHover: theme.colors.white_1,
      };
    case "subindustry":
      return {
        backgroundColor: theme.colors.purple_3,
        textColor: theme.colors.white_1,
        borderColor: theme.colors.purple_3,
        borderColorHover: theme.colors.purple_4,
        backgroundColorHover: theme.colors.purple_4,
        textColorHover: theme.colors.purple_1,
      };
    case "financingType":
      return {
        backgroundColor: theme.colors.yellow_1,
        textColor: theme.colors.white_1,
        borderColor: theme.colors.yellow_1,
        borderColorHover: theme.colors.yellow_3,
        backgroundColorHover: theme.colors.yellow_3,
        textColorHover: theme.colors.yellow_1,
      };
    case "region":
      return {
        backgroundColorHover: theme.colors.blue_4,
        borderColorHover: theme.colors.blue_4,
        textColorHover: theme.colors.blue_1,
        backgroundColor: theme.colors.blue_1,
        borderColor: theme.colors.blue_1,
        textColor: theme.colors.blue_4,
      };
    case "service":
      return {
        backgroundColorHover: theme.colors.orange_4,
        borderColorHover: theme.colors.orange_4,
        textColorHover: theme.colors.orange_1,
        backgroundColor: theme.colors.orange_1,
        borderColor: theme.colors.orange_1,
        textColor: theme.colors.orange_4,
      };
    case "companySize":
      return {
        backgroundColorHover: theme.colors.beige_2,
        borderColorHover: theme.colors.beige_1,
        textColorHover: theme.colors.black,
        backgroundColor: theme.colors.beige_1,
        borderColor: theme.colors.beige_3,
        textColor: theme.colors.black,
      };
    case "annualRevenue":
      return {
        backgroundColorHover: theme.colors.green_4,
        borderColorHover: theme.colors.green_2,
        textColorHover: theme.colors.green_1,
        backgroundColor: theme.colors.green_1,
        borderColor: theme.colors.green_4,
        textColor: theme.colors.white_1,
      };
    case "status":
      const grantStatusTheme = getGrantStatusColors(
        value as GrantStatus,
        theme
      );
      return value === "new"
        ? {
            borderColor: theme.colors.purple_4,
            backgroundColor: theme.colors.purple_1,
            textColor: theme.colors.white_1,
            borderColorHover: theme.colors.purple_5,
            backgroundColorHover: theme.colors.purple_2,
            textColorHover: theme.colors.white_1,
          }
        : !!grantStatusTheme
          ? {
              borderColor: grantStatusTheme.border,
              textColor: theme.colors.grey_1,
              backgroundColor: grantStatusTheme.background,
              borderColorHover: grantStatusTheme.background + "EE",
              textColorHover: theme.colors.grey_1,
              backgroundColorHover: grantStatusTheme.background + "EE",
            }
          : undefined;

    default:
      return undefined;
  }
};

export default getFilterTagBarTheme;

