export interface TagEntity {
  tag: string;
  isSelected: boolean;
}

export interface AdminTag {
  tag: string;
  meta: { [key: string]: string };
  min_budget: number;
  max_budget: number;
  description?: string;
}

export interface AdminTags {
  mappedCategories: { [key: string]: CategoryTagEntity };
  mappedIndustries: { [key: string]: TagEntity };
}

export interface ProviderTagRequest {
  tag: string;
  minBudget: number;
  maxBudget: number;
  description?: string;
}
export interface AdminCategoryTag {
  label: string;
  isSelected: boolean;
  services: ServiceTag[];
  specialties: SpecialtyTag[];
}

export interface AdminIndustryTag {
  label: string;
  tag: string;
}

export interface AdminSelectedTags {
  categories: AdminCategoryTag[];
  industries: AdminIndustryTag[];
  selectedIndustry: string;
  selectedServices: ServiceTag[];
  selectedSpecialties: SpecialtyTag[];
  selectedCategories: CategoryTag[];
}

export const InitialAdminSelectedTags: AdminSelectedTags = {
  categories: [],
  industries: [],
  selectedCategories: [],
  selectedIndustry: "",
  selectedServices: [],
  selectedSpecialties: [],
};
export interface ParsedClientTag {
  category: string;
  services: string[];
  specialties: string[];
}

export interface ParsedTag {
  category: string;
  services: ProviderTag[];
  specialties: string[];
}

export interface Tag {
  tag: string;
}

export interface ProviderTag {
  tag: string;
  provider_id: string;
  min_budget: number;
  max_budget: number;
  description?: string;
}

export interface Tags {
  Tags: Tag[];
  PartnerTags: { [key: string]: ParsedTag };
}

export interface MappedTags {
  categories: { label: string; isSelected: boolean }[];
  selectedServices: ServiceTag[];
  selectedSpecialties: SpecialtyTag[];
  selectedCategories: CategoryTag[];
}

export interface CategoryTagEntity {
  tag: string;
  label: string;
  isSelected: boolean;
  isSaved?: boolean;

  services: { [key: string]: ServiceTag };
  specialties: { [key: string]: SpecialtyTag };
}

export interface ServiceTag {
  category: string;
  tag: string;
  label: string;
  isSelected: boolean;
  min_budget: number;
  max_budget: number;
  description?: string;
}

export interface SpecialtyTag {
  category?: string;
  tag: string;
  label: string;
  isSelected: boolean;
}

export interface CategoryTag {
  tag: string;
  label: string;
  isSelected: boolean;
  services: ServiceTag[];
  specialties: SpecialtyTag[];
}

export interface Industry {
  id: string;
  level?: number;
  hierarchical_structure?: string;
  parent?: string;
  class_title?: string;
  class_definition?: string;
  superscript?: string;
}

