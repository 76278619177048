import { useEffect, useState } from "react";
import {
  ChoosePartnerFlowSteps,
  Match,
  MatchInitialState,
  Project,
  ProjectProgress,
  ProjectUpdateFunctions,
} from "../../../../features/entities";

import ChosenSp from "./chosen-sp/index";
import ProjectProgressStep from "./progress";
import QuickReviewStep from "./quick-review-flow";

type ChooseProjectPartnerFlowProps = {
  handleClose: () => void;
  editionFunctions: ProjectUpdateFunctions;
  project: Project;
  shortlistMatches: Match[];
  flowStep: ChoosePartnerFlowSteps;
  setFlowStep: (step: ChoosePartnerFlowSteps) => void;
};

const ChooseProjectPartnerFlow = ({
  handleClose,
  editionFunctions,
  project,
  shortlistMatches,
  flowStep,
  setFlowStep,
}: ChooseProjectPartnerFlowProps) => {
  const [selectedMatch, setSelectedMatch] = useState<Match>(MatchInitialState);
  const [progress, setProjectProgress] = useState<
    ProjectProgress | undefined
  >();

  useEffect(() => {
    if (editionFunctions?.selectProgression && !!progress) {
      editionFunctions.selectProgression(project.project_id, progress);
    }
  }, [progress]);

  return (
    <>
      {flowStep === ChoosePartnerFlowSteps.chosenSpStep ? (
        <ChosenSp
          shortlistMatches={shortlistMatches}
          setStep={setFlowStep}
          selectedMatch={selectedMatch}
          editionFunctions={editionFunctions}
          setSelectedMatch={setSelectedMatch}
        />
      ) : flowStep === ChoosePartnerFlowSteps.progressStep ? (
        <ProjectProgressStep
          setStep={setFlowStep}
          progress={progress}
          setProjectProgress={setProjectProgress}
          provider={selectedMatch.provider}
        />
      ) : flowStep === ChoosePartnerFlowSteps.QuickReviewStep ? (
        <QuickReviewStep
          handleClose={handleClose}
          provider={selectedMatch.provider}
          editionFunctions={editionFunctions}
          projectId={project.project_id}
          matchId={selectedMatch.match_id}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ChooseProjectPartnerFlow;
