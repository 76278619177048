import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import RatingOutline from "@hellodarwin/icons/dist/icons/RatingOutline";
import Divider from "antd/es/divider";
import Input from "antd/es/input/Input";
import message from "antd/es/message";
import Rate from "antd/es/rate";
import Select from "antd/es/select";
import { useEffect, useState } from "react";
import { Provider } from "../../../../features/entities/providers-entities";
import { ReviewResult } from "../../../../features/entities/reviews-entities";
import getAgenciesPage from "../../../../features/helpers/get-agencies-page";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import { Tablet } from "../../../common/Media";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import ReviewList from "../../../reviews/review/review-list";
import ProfileSection from "../profile-section";
import ProfileEmptySection from "../profile-section/empty-state";
import { ReviewHeaderContainer } from "./styles";

type ProfileReviewsProps = {
  provider: Provider;
  reviews: ReviewResult[];
  id: string;
  handleLeaveAReview?: () => void;
  locale?: string;
  state: "client" | "website" | "edit";
};

const ProfileReviews = ({
  provider,
  reviews,
  handleLeaveAReview,
  id,
  state,
  locale,
}: ProfileReviewsProps) => {
  const { t, getFullLanguage } = useTranslations();
  const theme = useTheme() as DefaultTheme;
  const [url, setUrl] = useState("");
  const [language, setLanguage] = useState(provider?.profile_language);
  useEffect(() => {
    (async () => {
      getAgenciesPage({
        provider,
        withReviews: true,
        locale: language,
      }).then((page) => setUrl(page));
    })();
  }, [provider]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
  };
  const [messageApi, contextHolder] = message.useMessage();

  const ReviewsHeader = () => {
    return (
      <ReviewHeaderContainer flex="row" style={{ height: "100%" }}>
        <Div flex="column" fitContent tablet={{ style: { flex: 1 } }}>
          <Typography
            elementTheme="overline"
            textTransform="uppercase"
            color={theme.colors.grey_3}
            tablet={{ textAlign: "center" }}
          >
            {t("reviews|totalReviews")}
          </Typography>
          <Typography elementTheme="h4" tablet={{ textAlign: "center" }}>
            {reviews?.length}
          </Typography>
        </Div>
        <Tablet direction="up">
          <Divider
            style={{
              borderColor: theme.colors.grey_4,
              borderWidth: 1,
              margin: "0 2rem",
              height: "50px",
            }}
            type="vertical"
          />
        </Tablet>
        <Div flex="column" fitContent tablet={{ style: { flex: 1 } }}>
          <Typography
            elementTheme="overline"
            textTransform="uppercase"
            color={theme.colors.grey_3}
            tablet={{ textAlign: "center" }}
          >
            {t("reviews|averageRating")}
          </Typography>
          <Div
            flex="row"
            gap={16}
            align="center"
            wrap="wrap"
            tablet={{ flex: "column" }}
          >
            <Typography elementTheme="h4" tablet={{ textAlign: "center" }}>
              {provider.reviews_average}
            </Typography>
            <Rate
              value={provider?.reviews_average}
              disabled
              character={(props) => {
                return !!props.value &&
                  props.value >= (props?.index || 0) + 1 ? (
                  <RatingFill
                    color={theme.colors.yellow_star}
                    width={30}
                    height={30}
                  />
                ) : (
                  <RatingOutline
                    color={theme.colors.grey_3}
                    width={30}
                    height={30}
                  />
                );
              }}
            />
          </Div>
        </Div>
      </ReviewHeaderContainer>
    );
  };

  const successcopy = () => {
    handleCopyClick();
    messageApi.open({
      type: "success",
      content: t("common|message.clipboard"),
    });
  };

  return (
    <ProfileSection
      level={2}
      title={t("profile_reviews|profileReviewTitle", {
        companyName: provider.name,
      })}
      id={id}
      Actions={
        !!handleLeaveAReview &&
        ["website", "client"].includes(state) &&
        reviews?.length > 0 && (
          <Button
            onClick={handleLeaveAReview}
            defaultStyle={theme.colors.purple_1}
            size="small"
          >
            {t("profile_reviews|profileReviewCta")}
          </Button>
        )
      }
    >
      {reviews?.length > 0 ? (
        <Div flex="column" gap={28}>
          <ReviewsHeader />
          <ReviewList
            reviews={reviews}
            providerName={provider?.name}
            average={provider?.reviews_average}
            locale={locale}
          />
        </Div>
      ) : (
        <>
          {state === "edit" ? (
            <Div flex="column" align="center" gap={10}>
              <ProfileEmptySection
                title={""}
                sectionName="Reviews"
                subtitle={""}
                isEditable={true}
              />

              <Typography
                inline
                textAlign="center"
                elementTheme="body2"
                color={theme.colors.grey_3}
              >
                {t("profile_reviews|noReviewsSendLink")}
              </Typography>
              <Div
                flex="row"
                align="center"
                justify="center"
                gap={8}
                tablet={{ flex: "column", style: { marginTop: 20 } }}
              >
                {contextHolder}
                <Input
                  disabled
                  style={{
                    borderRadius: "0px",
                    width: "300px",
                    border: `1px solid ${theme.colors.grey_4}`,
                    background: theme.colors.grey_5,
                    color: theme.colors.grey_2,
                  }}
                  value={url}
                />
                <Select
                  placeholder="Select option"
                  value={language}
                  onSelect={setLanguage}
                >
                  <Select.Option value={"en"}>
                    {getFullLanguage("en")}
                  </Select.Option>
                  <Select.Option value={"fr"}>
                    {getFullLanguage("fr")}
                  </Select.Option>
                </Select>
                <Button
                  defaultStyle={theme.colors.purple_1}
                  size="small"
                  children={t("button|copy")}
                  onClick={successcopy}
                />
              </Div>
            </Div>
          ) : (
            <Div flex="column" align="center" gap={24}>
              <ProfileEmptySection
                title={undefined}
                sectionName="Reviews"
                subtitle={undefined}
                isEditable={true}
              />

              <Typography
                color={theme.colors.grey_3}
                textAlign="center"
                elementTheme="body2"
              >
                {t("profile_reviews|noReviewsWebsiteVersion", {
                  providerName: provider?.name,
                })}
              </Typography>
              <Button onClick={handleLeaveAReview} size="small">
                {t("profile_reviews|profileReviewCta")}
              </Button>
            </Div>
          )}
        </>
      )}
    </ProfileSection>
  );
};
export default ProfileReviews;

