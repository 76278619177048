import { TeamMember } from "../../../../features/entities/core-entities";
import { useTheme } from "../../../../plugins/styled";
import List from "../../../common/List";
import Div from "../../../common/div";
import TeamMemberCard from "./team-member-card";

type TeamListProps = {
  teamMembers: TeamMember[];
  selectedMember: TeamMember;
  setSelectedMember: (selectedMember: TeamMember) => void;
  isEditable?: boolean;
};

const TeamList = ({
  teamMembers,
  selectedMember,
  setSelectedMember,
  isEditable,
}: TeamListProps) => {
  const theme = useTheme();

  return (
    <Div
      bordersSide="left"
      borderColor={theme.colors.primary}
      overflow="auto"
      style={{ padding: "15px 20px" }}
    >
      <List
        grid={{ column: 1, gutter: 20 }}
        dataSource={
          isEditable ? [{} as TeamMember, ...teamMembers] : teamMembers
        }
        renderItem={(m) => (
          <List.Item
            style={{ paddingBottom: 24, marginBottom: 0, height: "auto" }}
          >
            <TeamMemberCard
              key={m.provider_team_member_id}
              selected={
                m.provider_team_member_id ===
                selectedMember.provider_team_member_id
              }
              member={m}
              handleClick={() => setSelectedMember(m)}
            />
          </List.Item>
        )}
      />
    </Div>
  );
};

export default TeamList;

