import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantTotalAmounts } from "@hellodarwin/core/lib/features/entities/grants-entities";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import RoadmapDashboardSectionBigTotalCount from "./roadmap-dashboard-section-big-total-count";

interface RoadmapDashboardSectionHeaderProps {
  grantsTotals: GrantTotalAmounts[];
  isLoading: boolean;
  title: string;
  inversed?: boolean;
}

const RoadmapDashboardSectionHeader = (
  props: RoadmapDashboardSectionHeaderProps
) => {
  const { title, inversed } = props;
  const theme = useTheme();

  return (
    <Div
      flex="column"
      gap={10}
      borderRadius={4}
      style={{ padding: 12 }}
      backgroundColor={inversed ? theme.colors.green_1 : theme.colors.purple_2}
    >
      <Typography elementTheme="h6" bold color={theme.colors.white_1}>
        {title}
      </Typography>
      <RoadmapDashboardSectionBigTotalCount {...props} />
    </Div>
  );
};

export default RoadmapDashboardSectionHeader;

