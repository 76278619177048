import { Tablet } from "@hellodarwin/core/lib/components/common/Media";
import ProjectEmptySection from "@hellodarwin/core/lib/components/project/single/project-content/project-empty-section";
import ProjectSingleTile from "@hellodarwin/core/lib/components/project/single/project-content/project-single-tile";
import { Match, Project } from "@hellodarwin/core/lib/features/entities";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import EmptyProject from "@hellodarwin/icons/dist/graphics/EmptyProject";
import { MenuProps } from "antd/es/menu";
import ProviderRow, {
  PROJECT_DEFAULT_HEIGHT,
  PROJECT_DEFAULT_WIDTH,
} from "../../provider/provider-row";
import Div from "@hellodarwin/core/lib/components/common/div";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";

type ProjectMatchesListProps = {
  matches: Match[];
  matchesType: "rejected" | "candidates" | "shortlist" | "classic";
  project: Project;
  hasWinner: boolean;
  actionMenuItems?: MenuProps["items"];
  handleMore?: () => void;
  matchesMax?: number;
  isClassic?: boolean;
};

const ProjectMatchesList = ({
  matches,
  matchesType,
  project,
  hasWinner,
  actionMenuItems,
  handleMore,
  matchesMax = 3,
  isClassic,
}: ProjectMatchesListProps) => {
  const { t } = useTranslation();

  return (
    <ProjectSingleTile
      title={`${t(`project|matchesList.${matchesType}.title`)} ${
        matches.length > 0 ? `(${matches.length})` : ""
      }`}
      subtitle={parse(
        t(`project|matchesList.${matchesType}.subtitle`, {
          count:
            matchesType === "shortlist"
              ? project.provider_max
              : project.raise_hand_max,
        })
      )}
      dropdownMenuItems={actionMenuItems}
    >
      {matches.length > 0 ? (
        <Div flex="row" gap={32} wrap="wrap" justify="stretch">
          {matches.map((match) => (
            <ProviderRow
              key={match.match_id}
              match={match}
              hasWinner={hasWinner}
              isClassic={isClassic}
            />
          ))}
          <Tablet direction="up">
            {Array.apply(
              null,
              Array(Math.max(0, matchesMax - matches.length))
            ).map((_) => (
              <EmptyProject
                width={PROJECT_DEFAULT_WIDTH}
                height={PROJECT_DEFAULT_HEIGHT}
              />
            ))}
          </Tablet>
        </Div>
      ) : (
        <ProjectEmptySection
          title={
            matchesType === "candidates" &&
            project.status === ProjectStatus.Completed
              ? t(`project|matchesList.${matchesType}.finished`, {
                  count: project.provider_max,
                })
              : t(`project|matchesList.${matchesType}.empty`)
          }
          buttonText={
            matchesType === "candidates" &&
            project.status === ProjectStatus.Completed
              ? t(`project|matchesList.${matchesType}.more`)
              : undefined
          }
          buttonAction={
            matchesType === "candidates" &&
            project.status === ProjectStatus.Completed
              ? handleMore
              : undefined
          }
          withIcon
        />
      )}
    </ProjectSingleTile>
  );
};

export default ProjectMatchesList;

