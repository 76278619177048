import { IconProps } from "../features/entities/general";

const Doc = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.4444 19.282V15.453C28.4444 14.1473 27.8966 12.8951 26.9214 11.9718C25.9462 11.0486 24.6236 10.5299 23.2444 10.5299H20.9333C20.4736 10.5299 20.0327 10.357 19.7077 10.0493C19.3826 9.74151 19.2 9.32411 19.2 8.88888V6.70085C19.2 5.39517 18.6521 4.14296 17.677 3.21971C16.7018 2.29645 15.3791 1.77777 14 1.77777H11.1111M11.1111 20.3761H22.6667M11.1111 24.7521H16.8889M14.5778 1.77777H7.06667C6.10987 1.77777 5.33333 2.51295 5.33333 3.4188V28.5812C5.33333 29.487 6.10987 30.2222 7.06667 30.2222H26.7111C27.6679 30.2222 28.4444 29.487 28.4444 28.5812V14.906C28.4444 11.4242 26.9835 8.08495 24.383 5.62293C21.7825 3.16092 18.2554 1.77777 14.5778 1.77777Z"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Doc;
