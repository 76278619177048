import Divider from "antd/es/divider";
import React, { ReactNode } from "react";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../button";
import Div from "../../div";
import HdTag, { TagProps } from "../../hd-tag";
import Typography from "../../typography";

export interface PageHeadingProps {
  handleBack?: () => void;
  title?: ReactNode;
  subtitle?: ReactNode;
  footnote?: string;
  status?: TagProps;
  actions?: ReactNode;
  withoutPaddingRight?: boolean;
  withoutPaddingHorizontal?: boolean;
}
const PageHeading = ({
  handleBack,
  title,
  subtitle,
  footnote,
  status,
  actions,
  withoutPaddingRight,
  withoutPaddingHorizontal,
}: PageHeadingProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <Div flex="column" gap={16} tablet={{ style: { padding: "25px 21px" } }}>
      <Div
        flex="row"
        justify="space-between"
        align="center"
        tablet={{
          flex: "column",
          align: "flex-start",
        }}
      >
        <Div flex="row" gap={16} align="center">
          {handleBack ? (
            <Button
              isLink
              onClick={handleBack}
              withArrowLeft
              size="small"
              defaultStyle={theme.colors.purple_1}
            >
              {t("button|back")}
            </Button>
          ) : (
            <Div flex={"row"} align={"center"} wrap={"wrap"}>
              {typeof title === "string" ? (
                <Div
                  flex="row"
                  align="center"
                  gap={24}
                  tablet={{
                    flex: "column-reverse",
                    align: "flex-start",
                    gap: 14,
                  }}
                >
                  <Typography.Title level={1} elementTheme="h5" noMargin>
                    {title}
                  </Typography.Title>
                  {status && (
                    <>
                      <Div fitContent tablet={{ hidden: true }}>
                        <Divider
                          type="vertical"
                          style={{
                            height: 26,
                            borderColor: theme.colors.primary,
                            top: 0,
                            margin: 0,
                          }}
                        />
                      </Div>
                      <HdTag {...status} />
                    </>
                  )}
                </Div>
              ) : (
                <>{title}</>
              )}
            </Div>
          )}
        </Div>
        {!!actions && <Div fitContent>{React.Children.toArray(actions)}</Div>}
      </Div>
      {subtitle && (
        <Typography elementTheme="body2" style={{ maxWidth: 600 }}>
          {subtitle}
        </Typography>
      )}
      {footnote && (
        <Typography elementTheme="caption" color={theme.colors.grey_3}>
          {footnote}
        </Typography>
      )}
    </Div>
  );
};

export default PageHeading;

