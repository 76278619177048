import Modal from "antd/es/modal";
import { ModalProps } from ".";
import styled, { css } from "../../../plugins/styled";

const smallModal = css`
  width: 540px !important;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw !important;
    max-width: 100vw !important;
  }
`;

const mediumModal = css`
  width: 760px !important;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw !important;
    max-width: 100vw !important;
  }
`;

const largeModal = css`
  width: 1080px !important;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw !important;
    max-width: 100vw !important;
  }
`;
const extraLargeModal = css`
  width: 1300px !important;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw !important;
    max-width: 100vw !important;
  }
`;
const fullModal = css`
  width: calc(
    100vw - ${(props) => props.theme.appearance.sidebarWidth}px - 2rem
  ) !important;
  margin-left: ${(props) => props.theme.appearance.sidebarWidth}px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}px) {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0;
  }
`;

export const ModalContainer = styled(Modal)<{ $size: ModalProps["size"] }>`
  ${(props) =>
    props.$size === "small"
      ? smallModal
      : props.$size === "medium"
        ? mediumModal
        : props.$size === "large"
          ? largeModal
          : props.$size === "extra-large"
            ? extraLargeModal
            : props.$size === "full"
              ? fullModal
              : undefined}

  .hd-modal-with-icon {
    &.hd-modal-horizontal {
      padding: 0px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      padding: 20px;
      &.hd-modal-horizontal {
        padding: 0px;
      }
    }
  }
  .ant-modal-content {
    width: 100%;
    overflow: hidden;
    padding: 0;
  }

  .ant-modal-body {
    padding: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .modal-body {
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
    }
  }
`;

