import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import {
  ApiError,
  ApiErrorInitialState,
  Match,
  ReviewRequest,
  ReviewResult,
  ShortlistParams,
} from "@hellodarwin/core/lib/features/entities";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

const matchAdapter = createEntityAdapter({
  selectId: (model: Match) => model.match_id,
});

export interface MatchState {
  status: "idle" | "pending";
  projectMatches: EntityState<Match, string>;
  error: ApiError;
}

const initialState: MatchState = {
  status: "idle",
  projectMatches: matchAdapter.getInitialState(),
  error: ApiErrorInitialState,
};

export const fetchMatch = createAsyncThunk<
  Match,
  { api: ClientApi; matchId: string },
  { rejectValue: ApiError }
>("client/fetchMatch", async ({ api, matchId }, { rejectWithValue }) => {
  try {
    const match = await api.fetchMatch(matchId);
    return match;
  } catch (err: any) {
    showErrorNotification(err.response.data.error_code);
    return rejectWithValue(err.response.data);
  }
});

export const fetchProjectMatches = createAsyncThunk<
  Match[],
  { api: ClientApi; projectId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/fetchProjectMatches",
  async (
    { api, projectId }: { api: ClientApi; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchProjectMatches(projectId);
    } catch (err: any) {
      showErrorNotification(err.response?.data?.error_code);
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const selectAsWinner = createAsyncThunk<
  Match,
  { api: ClientApi; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/selectAsWinner",
  async (
    { api, matchId }: { api: ClientApi; matchId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.selectAsWinner(matchId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const refuseProvider = createAsyncThunk<
  Match,
  {
    api: ClientApi;
    matchId: string;
    refusedReason: string;
    refusedReasonSpecified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  "client/refuseProvider",
  async (
    {
      api,
      matchId,
      refusedReason,
      refusedReasonSpecified,
    }: {
      api: ClientApi;
      matchId: string;
      refusedReason: string;
      refusedReasonSpecified: string;
    },
    { rejectWithValue }
  ) => {
    try {
      return await api.refuseProvider(
        matchId,
        refusedReason,
        refusedReasonSpecified
      );
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const shortlist = createAsyncThunk<
  Match,
  { api: ClientApi; matchId: string; source: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/shortlist",
  async (
    {
      api,
      matchId,
      source,
    }: { api: ClientApi; matchId: string; source: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.shortlist({
        matchId: matchId,
        source: source,
      } as ShortlistParams);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const viewProvider = createAsyncThunk<
  string,
  { api: ClientApi; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/viewProvider",
  async (
    { api, matchId }: { api: ClientApi; matchId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.viewProvider(matchId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { projects } = getState();
      if (projects.status === "pending") return false;
    },
  }
);

export const reviewProvider = createAsyncThunk<
  {
    review: ReviewResult;
    providerId: string;
    projectId: string;
    matchId: string;
  },
  {
    api: ClientApi;
    reviewRequest: ReviewRequest;
    providerId: string;
    projectId: string;
    matchId: string;
  },
  { rejectValue: ApiError }
>(
  "client/reviewProvider",
  async (
    {
      api,
      reviewRequest,
      providerId,
      matchId,
      projectId,
    }: {
      api: ClientApi;
      reviewRequest: ReviewRequest;
      providerId: string;
      projectId: string;
      matchId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const createdReview = await api.reviewProvider(reviewRequest);
      return { review: createdReview, providerId, projectId, matchId };
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

const matchSlice = createSlice({
  name: "match",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //create the builder cases for fetchMatch
    builder.addCase(fetchMatch.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchMatch.fulfilled, (state, { payload }) => {
      state.projectMatches = matchAdapter.setOne(state.projectMatches, payload);
      state.status = "idle";
    });
    builder.addCase(fetchMatch.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchProjectMatches.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchProjectMatches.fulfilled, (state, { payload }) => {
      state.projectMatches = matchAdapter.setAll(state.projectMatches, payload);
      state.status = "idle";
    });
    builder.addCase(fetchProjectMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(shortlist.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(shortlist.fulfilled, (state, { payload }) => {
      state.projectMatches = matchAdapter.setOne(state.projectMatches, payload);
      state.status = "idle";
    });
    builder.addCase(shortlist.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(selectAsWinner.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(selectAsWinner.fulfilled, (state, { payload }) => {
      state.projectMatches = matchAdapter.setOne(state.projectMatches, payload);
      state.status = "idle";
    });
    builder.addCase(selectAsWinner.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(refuseProvider.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(refuseProvider.fulfilled, (state, { payload }) => {
      state.projectMatches = matchAdapter.setOne(state.projectMatches, payload);
      state.status = "idle";
    });
    builder.addCase(refuseProvider.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const {
  selectAll: selectAllProjectMatches,
  selectById: selectProjectMatchById,
} = matchAdapter.getSelectors(
  (state: RootState) => state.matches.projectMatches
);

// create a selector for loading
export const selectMatchesLoading = (state: RootState) =>
  state.matches.status === "pending";

export const selectClassicMatches = createSelector(
  (state: RootState) => selectAllProjectMatches(state),
  (matches) =>
    matches.filter(
      (match) =>
        match.purchased_at &&
        !match.shortlisted_at &&
        !match.raise_hand_rejected_at &&
        !match.rejected_at
    )
);

export const selectShortlistedMatches = createSelector(
  (state: RootState) => selectAllProjectMatches(state),
  (matches) =>
    matches.filter(
      (match) => match.shortlisted_at && !match.raise_hand_rejected_at
    )
);
export const selectRejectedMatches = createSelector(
  (state: RootState) => selectAllProjectMatches(state),
  (matches) => matches.filter((match) => match.raise_hand_rejected_at)
);

export const selectCandidateMatches = createSelector(
  (state: RootState) => selectAllProjectMatches(state),
  (matches) =>
    matches.filter(
      (match) =>
        match.raised_hand_at &&
        !match.shortlisted_at &&
        !match.raise_hand_rejected_at
    )
);

export const selectUnshortlistedMatches = createSelector(
  (state: RootState) => selectAllProjectMatches(state),
  (matches) =>
    matches.filter(
      (match) => !match.shortlisted_at && !match.raise_hand_rejected_at
    )
);

export const selectHasWinner = createSelector(
  (state: RootState) => selectAllProjectMatches(state),
  (matches) => matches.some((match) => match.winner_at)
);

export const selectMatchesError = (state: RootState) => state.matches.error;

export const matchesReducer = matchSlice.reducer;

