import React, { PropsWithChildren, useContext } from "react";
import { AppContextType } from "../entities/context-entities";

export const AppContext = React.createContext<AppContextType>({
  header: <></>,
  setHeader: (props) => {},
  navCollapsed: false,
  handleNavCollapse: () => {},
  nav: <></>,
  setNav: (props) => {},
  bottomNav: <></>,
  setBottomNav: (props) => {},
});

interface AppProviderProps extends PropsWithChildren {
  defaultNavCollapsed?: boolean;
}
const AppProvider = ({
  children,
  defaultNavCollapsed = false,
}: AppProviderProps) => {
  const [header, setHeader] = React.useState<AppContextType["header"]>([]);
  const [navCollapsed, setNavCollapsed] =
    React.useState<AppContextType["navCollapsed"]>(defaultNavCollapsed);

  const [nav, setNav] = React.useState<AppContextType["header"]>([]);
  const [bottomNav, setBottomNav] = React.useState<AppContextType["header"]>(
    []
  );

  const handleNavCollapse = () => setNavCollapsed(!navCollapsed);

  return (
    <AppContext.Provider
      value={{
        header,
        setHeader,
        navCollapsed,
        handleNavCollapse,
        nav,
        setNav,
        bottomNav,
        setBottomNav,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppData = () => useContext(AppContext);

export default AppProvider;

