import {
  ChoosePartnerFlowSteps,
  ProjectProgress,
  Provider,
} from "../../../../../features/entities";
import { useTranslations } from "../../../../../features/providers/translations-provider";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import ProjectStatusFlowLayout from "../../project-status-flow-layout";
import ProjectProgressCard from "./card";
type ProgressReportStepProps = {
  setStep: (step: ChoosePartnerFlowSteps) => void;
  progress?: ProjectProgress;
  provider: Provider;
  setProjectProgress: (newProgress: ProjectProgress | undefined) => void;
};

const ProgressReportStep = ({
  setStep,
  progress,
  provider,
  setProjectProgress,
}: ProgressReportStepProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const handleNext = () => {
    if (!!progress || progress == 0) {
      setStep(ChoosePartnerFlowSteps.QuickReviewStep);
    }
  };
  const handlePrev = () => {
    setStep(ChoosePartnerFlowSteps.chosenSpStep);
  };

  const handleCardClick = (newProgress: ProjectProgress) => {
    setProjectProgress(newProgress);
  };

  return (
    <ProjectStatusFlowLayout
      title={t("projectStatusFlow|psf_projectStatus_header1")}
      description={t("projectStatusFlow|psf_projectStatus_text1", {
        SP: provider.name,
      })}
      buttons={
        <>
          <Button
            defaultStyle={theme.colors.white_1}
            onClick={handlePrev}
            tablet={{ fullWidth: true }}
            size="small"
          >
            {t("button|previous")}
          </Button>
          <Button
            tablet={{ fullWidth: true }}
            onClick={handleNext}
            size="small"
            disabled={progress === undefined}
          >
            {t("button|next")}
          </Button>
        </>
      }
    >
      <>
        <Div flex="row" gap={16} justify="center" align="center">
          <ProjectProgressCard
            title={t("projectStatusFlow|psf_projectStatus_selectionNotStarted")}
            value={ProjectProgress.NotStarted}
            onClick={handleCardClick}
            selected={progress === ProjectProgress.NotStarted}
          />

          <ProjectProgressCard
            title={t("projectStatusFlow|psf_projectStatus_selectionInProgress")}
            value={ProjectProgress.InProgress}
            onClick={handleCardClick}
            selected={progress === ProjectProgress.InProgress}
          />

          <ProjectProgressCard
            title={t("projectStatusFlow|psf_projectStatus_selectionCompleted")}
            value={ProjectProgress.Completed}
            onClick={handleCardClick}
            selected={progress === ProjectProgress.Completed}
          />
        </Div>
      </>
    </ProjectStatusFlowLayout>
  );
};

export default ProgressReportStep;

