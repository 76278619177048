import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Match } from "@hellodarwin/core/lib/features/entities/matches-entities";
import { getAverageAllReviews } from "@hellodarwin/core/lib/features/helpers/get-average-reviews";
import { getStringDateFromString } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import Avatar from "antd/es/avatar";
import Divider from "antd/es/divider";
import { useMemo } from "react";
import ProviderActions from "../../provider/provider-actions";
import { MessageCardContainer } from "./styles";

interface MessageCardProps {
  match: Match;
}
const MessageCard = ({ match }: MessageCardProps) => {
  const theme = useTheme();

  const ProviderInfo = ({ match }: { match: Match }) => {
    const {
      provider: { reviews, city, province, name },
      purchased_at,
    } = match;
    const { selectedLocale } = useLocale();
    const average = useMemo(() => {
      return reviews ? getAverageAllReviews(reviews) : undefined;
    }, [reviews]);
    return (
      <Div
        flex="row"
        gap={24}
        align="center"
        justify="space-between"
        tablet={{
          flex: "column",
          align: "center",
        }}
      >
        <Div flex="column" gap={8}>
          <Typography
            elementTheme="subtitle2"
            nowrap
            ellipsis
            tablet={{ textAlign: "center" }}
          >
            {name}
          </Typography>
          <Div
            flex="row"
            align="center"
            gap={10}
            tablet={{ flex: "column", align: "center" }}
          >
            {!!city && (
              <Typography
                elementTheme="overline"
                color={theme.colors.grey_2}
                fitContent
                tablet={{ textAlign: "center" }}
              >
                {`${city}, ${province}`}
              </Typography>
            )}
            {!!city && average && reviews && (
              <Divider
                type="vertical"
                style={{
                  margin: 0,
                  height: "15px",
                  top: 0,
                  borderColor: theme.colors.grey_4,
                }}
              />
            )}
            {average && reviews && (
              <Div fitContent flex="row" align="center" gap={3.2}>
                <RatingFill width={12} height={12} />
                <Typography elementTheme="overline">
                  {Math.round(average * 10) / 10}
                </Typography>
              </Div>
            )}
          </Div>
        </Div>
        {!!match.purchased_at && (
          <Typography elementTheme="body3" color={theme.colors.grey_2} nowrap>
            {getStringDateFromString(purchased_at || "", selectedLocale)}
          </Typography>
        )}
      </Div>
    );
  };

  const MessageInfo = ({
    message,
    isClient,
  }: {
    message: string;
    isClient?: boolean;
  }) => {
    return (
      <div
        style={{
          paddingLeft: isClient ? 24 : 0,
          paddingRight: !isClient ? 24 : 0,
        }}
      >
        <Div
          backgroundColor={
            isClient ? theme.colors.green_4 : theme.colors.beige_3
          }
          borderColor={isClient ? theme.colors.green_3 : theme.colors.beige_2}
          style={{
            padding: "8px 16px",
          }}
        >
          <Typography elementTheme="body2">{message}</Typography>
        </Div>
      </div>
    );
  };

  return (
    <MessageCardContainer>
      <Avatar src={match.provider.logo} size={46} shape="square">
        <Typography elementTheme="h4" color={theme.colors.white_1} center>
          {match.provider.name?.[0]}
        </Typography>
      </Avatar>
      <Div flex="column" gap={16} tablet={{ align: "center" }}>
        <ProviderInfo match={match} />

        {match.hello_message && <MessageInfo message={match.hello_message} />}
        {match.shortlist_message && (
          <MessageInfo message={match.shortlist_message} isClient />
        )}

        <div style={{ width: "fit-content" }}>
          <ProviderActions match={match} hasWinner={false} isPrimary />
        </div>
      </Div>
    </MessageCardContainer>
  );
};

export default MessageCard;

