import useApolloClient from "@hellodarwin/core/lib/features/hooks/use-appollo-client";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useMemo } from "react";
import ContentfulApi from "./client-contentful-api";

export const useContentfulApi = () => {
  const accessToken = "P8xozOvf7K4HtnL7xpr7Sp5aO1FV4WLyJlCquu9tPz8";
  const spaceId = "modxx330knqj";
  const { selectedLocale } = useLocale();
  const apolloClient = useApolloClient(spaceId, accessToken);
  return useMemo(
    () => new ContentfulApi(apolloClient, selectedLocale),
    [apolloClient, selectedLocale]
  );
};

