import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectEmptySection from "@hellodarwin/core/lib/components/project/single/project-content/project-empty-section";
import ProjectSingleTile from "@hellodarwin/core/lib/components/project/single/project-content/project-single-tile";
import { Project } from "@hellodarwin/core/lib/features/entities";
import ProjectDescriptionType from "@hellodarwin/core/lib/features/enums/project-description-type";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Divider from "antd/es/divider";
import parse, {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";

type OverviewSummaryProps = {
  project: Project;
};
const OverviewSummary = ({ project }: OverviewSummaryProps) => {
  const { t } = useTranslation();

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        const props = attributesToProps(domNode.attribs);
        if (domNode.name === "ul") {
          return (
            <Typography elementTheme="body2">
              <ul {...props}>{domToReact(domNode.children)}</ul>
            </Typography>
          );
        }
        if (domNode.name === "p") {
          return (
            <Typography elementTheme="body2">
              {domToReact(domNode.children)}
            </Typography>
          );
        }
        if (domNode.name === "li") {
          return (
            <li {...props}>
              <Typography inline elementTheme="body2">
                {domToReact(domNode.children)}
              </Typography>
            </li>
          );
        }
        if (domNode.name === "h4") {
          return (
            <Div flex="column" gap={6}>
              <Typography
                color={theme.colors.grey_3}
                textTransform="uppercase"
                letterSpacing={0.3}
                bold
                elementTheme="caption"
              >
                {domToReact(domNode.children)}
              </Typography>
              <Divider style={{ margin: 0 }} />
            </Div>
          );
        }
      }
    },
  };

  return (
    <ProjectSingleTile title={t("project|overview.summary.title")}>
      <Typography elementTheme="body2">
        {project.status === "PendingApproval" ? (
          <Div flex="column" gap={12}>
            {parse(project.original_description || "", options)}
          </Div>
        ) : project.description ? (
          <Div> {parse(project.description, options)} </Div>
        ) : project.description_container ? (
          <Div flex="column" gap={6}>
            {parse(
              (project?.description_container?.[
                ProjectDescriptionType.Details
              ] as string) || "",
              options
            )}
          </Div>
        ) : (
          <ProjectEmptySection title={t("project|overview.summary.empty")} />
        )}
      </Typography>
    </ProjectSingleTile>
  );
};

export default OverviewSummary;

