import Button from "@hellodarwin/core/lib/components/common/button";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { ProjectStatusFlowSteps } from "@hellodarwin/core/lib/components/project/status-flow";
import { Project } from "@hellodarwin/core/lib/features/entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import Checkmark from "@hellodarwin/icons/dist/icons/Checkmark";
import Help from "@hellodarwin/icons/dist/icons/Help";
import { ReactNode, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/index";
import {
  selectHasWinner,
  selectShortlistedMatches,
} from "../../features/api/slices/matches-slice";
import { toggleProjectsModal } from "../../features/api/slices/projects-slice";

interface ProjectAction {
  label: string;
  Icon: (props: IconProps) => ReactNode;
  onClick: () => void;
}

type ProjectActionsProps = {
  project: Project;
};

const ProjectActions = ({ project }: ProjectActionsProps) => {
  const { selectedLocale } = useLocale();

  const { t } = useTranslation("project-single");

  const shortlist = useAppSelector(selectShortlistedMatches);
  const hasWinner = useAppSelector(selectHasWinner);

  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(
      toggleProjectsModal({
        props: {
          initialStep: ProjectStatusFlowSteps.CancelProjectFlow,
          projectId: project.project_id,
        },
        isVisible: true,
        type: "projectStatusFlow",
      })
    );
  };

  const handleFinalist = () => {
    dispatch(
      toggleProjectsModal({
        props: {
          initialStep: ProjectStatusFlowSteps.PartnerChooseSelected,
          projectId: project.project_id,
        },
        isVisible: true,
        type: "projectStatusFlow",
      })
    );
  };

  const handleHelp = () => {
    dispatch(toggleProjectsModal({ type: "manager", isVisible: true }));
  };

  const actions: ProjectAction[] = useMemo(() => {
    const currentActions: ProjectAction[] = [];

    if (!hasWinner) {
      currentActions.push({
        label: t("project-single|actions.cancel"),
        Icon: CancelProject,
        onClick: handleCancel,
      });

      if (!!shortlist.length) {
        currentActions.push({
          label: t("project-single|actions.chose"),
          Icon: Checkmark,
          onClick: handleFinalist,
        });
      }
    }

    currentActions.push({
      label: t("project-single|actions.help"),
      Icon: Help,
      onClick: handleHelp,
    });

    return currentActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale, shortlist, hasWinner]);

  if (!project) return <></>;

  return (
    <Container>
      <Div flex="column" gap={16}>
        <Typography elementTheme="h6" bold>
          Actions
        </Typography>
        <Div flex="column" gap={5}>
          {actions.map((a) => (
            <Button
              onClick={a.onClick}
              defaultStyle={theme.colors.grey_1}
              key={a.label}
              transparent
              headingIcon={<a.Icon width={20} height={20} />}
              style={{
                gap: 12,
                padding: 8,
                justifyContent: "flex-start",
                width: "100%",
                minHeight: 34,
              }}
            >
              {a.label}
            </Button>
          ))}
        </Div>
      </Div>
    </Container>
  );
};
export default ProjectActions;

