import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import FaqContent from "@hellodarwin/core/lib/components/faq";
import { QA } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";

const FAQClientPage = () => {
  const { t } = useTranslation();

  const QAs: QA[] = [
    {
      question: t("faq|faq.question1.question"),
      answer: t("faq|faq.question1.answer"),
    },
    {
      question: t("faq|faq.question2.question"),
      answer: t("faq|faq.question2.answer"),
    },
    {
      question: t("faq|faq.question3.question"),
      answer: t("faq|faq.question3.answer"),
      images: [
        {
          url: t("faq|faq.question3.image1.url"),
        },
      ],
    },
    {
      question: t("faq|faq.question4.question"),
      answer: t("faq|faq.question4.answer"),
      images: [
        {
          url: t("faq|faq.question4.image1.url"),
        },
      ],
    },
    {
      question: t("faq|faq.question5.question"),
      answer: t("faq|faq.question5.answer"),
      images: [
        {
          url: t("faq|faq.question5.image1.url"),
        },
      ],
    },
    {
      question: t("faq|faq.question6.question"),
      answer: t("faq|faq.question6.answer"),
    },
    {
      question: t("faq|faq.question7.question"),
      answer: t("faq|faq.question7.answer"),
    },
    {
      question: t("faq|faq.question8.question"),
      answer: t("faq|faq.question8.answer"),
    },
  ];

  const contact_email = "clients@hellodarwin.com";
  return (
    <PageLayout
      app="client"
      title={t("faq|faq.title")}
      subtitle={
        <>
          {t("faq|faq.subtitle")}
          <a href={`mailto:${contact_email}`}>{contact_email}</a>
        </>
      }
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("faq|faq.title"),
        },
      ]}
    >
      <Container>
        <FaqContent QAs={QAs} />
      </Container>
    </PageLayout>
  );
};

export default FAQClientPage;

