import { ProfileBlock } from "../..";
import ProfileBlocks from "../../../../../features/enums/profile-blocks";
import { useTheme } from "../../../../../plugins/styled";
import { DefaultTheme } from "../../../../../theme";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import { MenuContainer } from "../../../../grants/single/grant-header/styles";

interface ProfileMenuProps {
  sections: ProfileBlocks[];
  blocks: { [block_name: string]: ProfileBlock };
  handleLinkClick: (section: string) => void;
}

const ProfileMenu = ({
  sections,
  blocks,
  handleLinkClick,
}: ProfileMenuProps) => {
  const theme = useTheme() as DefaultTheme;

  return (
    <MenuContainer>
      {sections
        .filter((s) => !blocks[s].hide && !!blocks[s].label.length)
        .map((s) => (
          <Div
            borderColor={theme.colors.grey_5}
            onClick={() => handleLinkClick(s)}
            style={{ padding: "14px 20px", cursor: "pointer" }}
            className="profile-menu-btn"
            key={s}
          >
            <Typography
              elementTheme="caption"
              textAlign="center"
              color={theme.colors.grey_2}
              nowrap
            >
              {blocks[s].label}
            </Typography>
          </Div>
        ))}
    </MenuContainer>
  );
};

export default ProfileMenu;

