import { SpinContainer } from "./styles";

export type SpinSize = "large" | "medium" | "small";
interface SpinProps {
  color?: string;
  size?: SpinSize;
}
const Spin = ({ color, size = "medium" }: SpinProps) => {
  return (
    <SpinContainer $color={color} $size={size}>
      <div></div>
      <div></div>
      <div></div>
    </SpinContainer>
  );
};

export default Spin;

