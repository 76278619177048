import { useState } from "react";
import { ProfileEditModalFunctions } from ".";
import { Profile, ProfileModalProps } from "../../../../features/entities";
import ProfileBlocks from "../../../../features/enums/profile-blocks";
import { useTranslation } from "../../../../plugins/i18n";
import ModalWithHeaderFooter from "../../../common/hd-modal/layouts/modal-with-header-footer";
import ProfileAssetsForm from "./profile-assets-form";

type ClientEditModalProps = {
  profile: Profile;
  modal: ProfileModalProps;
  handleCancel: () => void;
  functions: ProfileEditModalFunctions;
};

const ClientEditModal = ({
  profile,
  modal,
  handleCancel,
  functions,
}: ClientEditModalProps) => {
  const { t } = useTranslation();

  const { open, type } = modal;

  const [footer, setFooter] = useState<JSX.Element | undefined>(undefined);

  const renderFormBasedOnType = () => {
    switch (type) {
      case ProfileBlocks.Logo:
        return (
          <ProfileAssetsForm
            handleCancel={handleCancel}
            company_id={profile.company_id}
            type="logo"
            asset_url={profile.logo || ""}
            setFooter={setFooter}
            functions={functions}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <ModalWithHeaderFooter
      open={open}
      handleCancel={handleCancel}
      noPadding
      size="full"
      header={
        <ModalWithHeaderFooter.Header
          title={t(`provider-profile|${type}.edit.title`)}
        />
      }
      footer={footer}
    >
      {renderFormBasedOnType()}
    </ModalWithHeaderFooter>
  );
};

export default ClientEditModal;

