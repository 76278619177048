import { HdArrowProps } from ".";
import styled from "../../../../plugins/styled";

interface ArrowContainerProps {
  $dir: HdArrowProps["dir"];
}
export const ArrowContainer = styled.span<ArrowContainerProps>`
  display: block;
  width: 20px;
  flex-direction: column;
  align-items: ${({ $dir }) => ($dir === "left" ? "flex-start" : "flex-end")};
  justify-content: center;
  position: relative;
  transition: width 100ms linear;

  &:after {
    content: "";
    ${({ $dir }) => ($dir === "left" ? "left: 1px;" : "right: 1px;")};

 
    content: "";
   
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey_1};
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}
  }
 
`;
