import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import Modal, { ModalProps } from "..";
import parse from "../../../../features/helpers/parse";
import Div from "../../div";
import Typography from "../../typography";

export type ModalLayoutWithIconSize = 24 | 32 | 64 | 96 | 128;
export interface ModalLayoutWithIconProps
  extends Omit<ModalProps, "children">,
    PropsWithChildren {
  Icon: (props: IconProps) => ReactNode;
  iconSize?: ModalLayoutWithIconSize;
  iconColor?: string;
  title: string;
  description?: string;
  warning?: string;
  Actions: ReactNode;
  direction?: "column" | "row";
}

const ModalLayoutWithIcon = ({
  Icon,
  iconSize = 32,
  iconColor,
  title,
  description,
  warning,
  children,
  Actions,
  direction = "column",
  noPadding,
  ...modalProps
}: ModalLayoutWithIconProps) => {
  const align = direction === "column" ? "center" : undefined;
  const style = direction === "row" ? { flex: 1 } : undefined;
  const classes = classNames("hd-modal-container", "hd-modal-with-icon", {
    [`hd-modal-horizontal`]: direction === "row",
  });

  const logoContainerSize = 90;

  return (
    <Modal
      {...modalProps}
      style={{
        top: 0,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      styles={{
        ...modalProps?.styles,
        body: {
          overflow: "auto",
          height: "100%",
          ...modalProps?.styles?.body,
        },

        content: {
          padding: noPadding ? 0 : "32px 115px",
          overflow: "visible",
          height: "100%",
          maxHeight: `calc(95vh - ${logoContainerSize}px)`,
          ...modalProps?.styles?.content,
        },
      }}
    >
      <Div
        flex="column"
        align="center"
        className={classes}
        gap={32}
        fitContent
        style={{ margin: "auto" }}
      >
        <Div flex={direction} align="center" gap={32} fitContent>
          <Div
            flex="column"
            gap={24}
            align={align}
            style={{ width: direction === "row" ? 350 : undefined }}
          >
            <Div style={style} flex="column" align={align} gap={20}>
              <Icon
                width={iconSize}
                height={iconSize}
                style={{ color: iconColor }}
              />
              <Typography.Title level={3} textAlign={align}>
                {title}
              </Typography.Title>
              {description && (
                <Typography elementTheme="body2" textAlign={align}>
                  {parse(description)}
                </Typography>
              )}
            </Div>
            {!!warning && (
              <Typography
                bold
                elementTheme="body2"
                letterSpacing={0.32}
                textAlign={align}
              >
                {warning}
              </Typography>
            )}
          </Div>
          {!!children && <Div style={style}>{children}</Div>}
        </Div>
        <Div
          flex="row"
          gap={24}
          align="center"
          fitMaxContent
          style={{ width: "100%" }}
          tablet={{ flex: "column-reverse" }}
        >
          {Actions}
        </Div>
      </Div>
    </Modal>
  );
};

export default ModalLayoutWithIcon;

