import classNames from "classnames";
import {
  CSSProperties,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from "react";
import useMediaQuery from "../../../features/helpers/use-media-query";
import { useTheme } from "../../../plugins/styled";
import { DefaultTheme } from "../../../theme";
import Spin from "../spin";
import HdArrow from "./arrow";
import { ButtonContainer, ButtonContainerProps } from "./styles";

export type ButtonSize =
  | "small"
  | "extra-small"
  | "medium"
  | "large"
  | "auto"
  | "circle"
  | "square";
export type HTMLType = "submit" | "reset" | "button";

export interface GeneralButtonProps {
  ref?: any;
  id?: string;
  className?: string;
  loading?: boolean;
  headingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  htmlType?: HTMLType;
  withArrowLeft?: boolean;
  withArrowRight?: boolean;
  size?: ButtonSize;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

export interface ViewButtonProps {
  style?: CSSProperties;
  defaultStyle?: string;
  fitContent?: boolean;
  fullWidth?: boolean;
  transparent?: boolean;
  isLink?: boolean;
  hidden?: boolean;
  textWrap?: boolean;

  onClick?: MouseEventHandler<HTMLElement>;
}

export type ButtonProps = GeneralButtonProps &
  PropsWithChildren &
  ViewButtonProps & {
    tablet?: ViewButtonProps;
    xl?: ViewButtonProps;
  };

const Button = (props: ButtonProps) => {
  const {
    children,
    id,
    textWrap,
    className,
    headingIcon,
    trailingIcon,
    withArrowLeft,
    withArrowRight,
    fullWidth,
    defaultStyle,
    transparent,
    fitContent,
    size,
    tablet,
    xl,
    isLink,
    loading,
    disabled,
    ...buttonProps
  } = props;

  const classes = classNames("hd-button", className);

  const theme = useTheme() as DefaultTheme;
  const isXL = useMediaQuery({ maxWidth: theme.breakpoints.l });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const defaultClick = () => !!props.onClick && props.onClick(e);

    if (isXL) {
      !!props?.xl?.onClick ? props.xl.onClick(e) : defaultClick();
    } else if (isTablet) {
      !!props?.tablet?.onClick ? props.tablet.onClick(e) : defaultClick();
    } else {
      defaultClick();
    }
  };

  const { currentProps, currentStyle } = useMemo(() => {
    const currentProps: ButtonContainerProps = {};
    const currentStyle: CSSProperties = {};

    const addProp = (property, value) => {
      if (value !== undefined) {
        currentProps[property] = value;
      }
    };

    const addStyling = (property, value) => {
      if (value !== undefined) {
        currentStyle[property] = value;
      }
    };

    const prepareStyling = (style: CSSProperties) => {
      if (style) {
        Object.entries(style).forEach(([key, value]) => {
          addStyling(key, value);
        });
      }
    };

    const prepareProps = (data: ViewButtonProps) => {
      if (data) {
        const {
          fitContent,
          fullWidth,
          isLink,
          transparent,
          textWrap,
          defaultStyle,

          hidden,
          style,
        } = data;
        prepareStyling(style);

        const propMappings = {
          $fitContent: fitContent,
          $isTransparent: transparent,
          $isLink: isLink,
          $fullWidth: fullWidth,
          $wrap: textWrap,
          $hidden: hidden,
          $defaultTheme: defaultStyle,
        };

        Object.entries(propMappings).forEach(([key, value]) => {
          addProp(key, value);
        });
      }
    };

    prepareProps(props);
    if (isXL) prepareProps(props.xl);
    if (isTablet) prepareProps(props.tablet);

    return { currentProps, currentStyle };
  }, [isTablet, isXL, props]);

  return (
    <ButtonContainer
      {...buttonProps}
      className={classes}
      onClick={!!props.onClick ? handleClick : undefined}
      id={id}
      style={currentStyle}
      $fitContent={currentProps.$fitContent}
      $isTransparent={currentProps.$isTransparent}
      $isLink={currentProps.$isLink}
      $fullWidth={currentProps.$fullWidth}
      $wrap={currentProps.$wrap}
      $defaultTheme={currentProps.$defaultTheme}
      disabled={loading || disabled}
      $withArrow={withArrowLeft || withArrowRight}
      $size={size}
    >
      {!!headingIcon && <span>{headingIcon}</span>}
      {withArrowLeft && <HdArrow dir="left" />}
      {!!children && <span>{children}</span>}
      {!!loading ? (
        <Spin color={theme.colors.white_1} size="small" />
      ) : (
        <>
          {withArrowRight && <HdArrow dir="right" />}
          {!!trailingIcon && <span>{trailingIcon}</span>}
        </>
      )}
    </ButtonContainer>
  );
};

export default Button;

