import { Language } from "../entities/translations_ns";

// Format number to return a user friendly string
const dollarFormat = (
  x: number,
  lang: string | Language,
  withDollarSign?: boolean
): string => {
  if (!x) return "";
  const value =
    lang === "fr"
      ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(160))
      : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (withDollarSign) {
    return `${lang === "en" ? "$" : ""}${value}${lang === "fr" ? "$" : ""}`;
  }

  return value;
};
export default dollarFormat;

