import { IconProps } from "../features/entities/general";

interface GreenCheckProps extends IconProps {
  bgColor?: string;
}
const GreenCheck = ({
  width = 74,
  height = 74,
  style,
  className,
  onClick,
  color = "#00D098",
  bgColor = "#BAFFE5",
}: GreenCheckProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 74 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="37" cy="37" r="37" fill={bgColor} />
        <g>
          <path
            d="M19 34.8824L31.5926 49L53 25"
            stroke={color}
            strokeWidth="4"
          />
        </g>
      </svg>
    </>
  );
};

export default GreenCheck;

