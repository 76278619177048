import { Portfolio } from "../../../../features/entities/core-entities";
import HdModal from "../../../common/hd-modal";
import { default as PortfolioPreview } from "./portfolio-preview";

export enum ModalState {
  PortfolioEdit = "portfolioEdit",
  Empty = "",
}

type PortfolioProps = {
  open: boolean;
  handleCancel: () => void;
  portfolio?: Portfolio;
};
const PortfolioModal = ({ open, handleCancel, portfolio }: PortfolioProps) => {
  if (!portfolio) return <></>;

  return (
    <HdModal
      open={open}
      handleCancel={handleCancel}
      size="extra-large"
      noPadding
    >
      <PortfolioPreview portfolio={portfolio} />
    </HdModal>
  );
};

export default PortfolioModal;

