import { IconProps } from "../features/entities/general";

const Timeline = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = "currentColor",
}: IconProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M7.7037 1.77777V5.33333M24.2963 1.77777V5.33333M1.77777 26.6667V8.88888C1.77777 7.94589 2.15237 7.04152 2.81917 6.37473C3.48597 5.70793 4.39033 5.33333 5.33333 5.33333H26.6667C27.6097 5.33333 28.514 5.70793 29.1808 6.37473C29.8476 7.04152 30.2222 7.94589 30.2222 8.88888V26.6667M1.77777 26.6667C1.77777 27.6097 2.15237 28.514 2.81917 29.1808C3.48597 29.8476 4.39033 30.2222 5.33333 30.2222H26.6667C27.6097 30.2222 28.514 29.8476 29.1808 29.1808C29.8476 28.514 30.2222 27.6097 30.2222 26.6667M1.77777 26.6667V14.8148C1.77777 13.8718 2.15237 12.9674 2.81917 12.3007C3.48597 11.6339 4.39033 11.2593 5.33333 11.2593H26.6667C27.6097 11.2593 28.514 11.6339 29.1808 12.3007C29.8476 12.9674 30.2222 13.8718 30.2222 14.8148V26.6667M16 17.1852H16.0126V17.1978H16V17.1852ZM16 20.7407H16.0126V20.7534H16V20.7407ZM16 24.2963H16.0126V24.3089H16V24.2963ZM12.4444 20.7407H12.4571V20.7534H12.4444V20.7407ZM12.4444 24.2963H12.4571V24.3089H12.4444V24.2963ZM8.88888 20.7407H8.90152V20.7534H8.88888V20.7407ZM8.88888 24.2963H8.90152V24.3089H8.88888V24.2963ZM19.5556 17.1852H19.5682V17.1978H19.5556V17.1852ZM19.5556 20.7407H19.5682V20.7534H19.5556V20.7407ZM19.5556 24.2963H19.5682V24.3089H19.5556V24.2963ZM23.1111 17.1852H23.1237V17.1978H23.1111V17.1852ZM23.1111 20.7407H23.1237V20.7534H23.1111V20.7407Z"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Timeline;

