import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../../components/common/layout/app-layout";
import { AppType } from "../entities";

export interface OauthOptions {
  domain: string;
  client_id: string;
  audience: string;
  scope: string;
}

export interface Auth0ProviderWithNavigateProps extends PropsWithChildren {
  config?: OauthOptions;
  error?: AxiosError;
  app: AppType;
}

export const Auth0ProviderWithNavigate = ({
  children,
  config,
  error,
  app,
}: Auth0ProviderWithNavigateProps) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(config?.domain && config?.client_id))
    return (
      <AppLayout
        app={app}
        navigate={navigate}
        error={error?.message}
        children={<></>}
      />
    );

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.client_id}
      authorizationParams={{
        redirect_uri: window?.location?.origin || "",
        scope: app === "admin" ? "profile" : config.scope,
        audience: config.audience,
        cacheLocation: "memory",
        useRefreshTokens: true,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;

