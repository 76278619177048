import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ApiError,
  ApiErrorInitialState,
  ReviewResult,
} from "@hellodarwin/core/lib/features/entities";
import { RootState } from "../../../app/app-store";
import showErrorNotification from "../../utils/show-error-notifications";
import ClientApi from "../client-api";

export interface ReviewsState {
  status: "idle" | "pending";
  error: ApiError;
  reviews: ReviewResult[];
}

const initialState: ReviewsState = {
  status: "idle",
  error: ApiErrorInitialState,
  reviews: [],
};

export const fetchReviews = createAsyncThunk<
  ReviewResult[],
  { api: ClientApi; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  "client/fetchReviews",
  async (
    { api, companyId }: { api: ClientApi; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      return await api.fetchReviews(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReviews.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchReviews.fulfilled, (state, { payload }) => {
      state.reviews = payload;
      state.status = "idle";
    });
    builder.addCase(fetchReviews.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const selectReviews = (state: RootState) => state.reviews.reviews;
export const selectReviewsLoading = (state: RootState) =>
  state.reviews.status === "pending";
export const reviewsReducer = reviewsSlice.reducer;

