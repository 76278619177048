import HdLogo from "@hellodarwin/icons/dist/icons/HdLogo";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Menu from "antd/es/menu";
import { ItemType } from "antd/es/menu/interface";
import { MenuProps } from "antd/es/menu/menu";
import { SelectInfo } from "rc-menu/lib/interface";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AppType } from "../../../features/entities";
import { NavigationLink } from "../../../features/entities/layout-entities";
import { prefixHTTPS } from "../../../features/helpers";
import { useAppData } from "../../../features/providers/app-provider";
import { useTranslations } from "../../../features/providers/translations-provider";
import { useTheme } from "../../../plugins/styled";
import Div from "../../common/div";
import HdTag from "../../common/hd-tag";
import Typography from "../../common/typography";
import Socials from "./socials";
import { Navigation } from "./styles";
interface ItemLabelProps {
  link: NavigationLink;
  isSubitem?: boolean;
  isExternal?: boolean;
}
interface DesktopNavigationProps {
  links: NavigationLink[];
  app: AppType;
  pathname: string;
  navigate: (path: string) => void;
}

const DesktopNavigation = ({
  links,
  app,
  pathname,
  navigate,
}: DesktopNavigationProps) => {
  const theme = useTheme();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenedKeys] = useState<string[]>([]);
  const { navCollapsed } = useAppData();
  useEffect(() => {
    if (!selectedKeys.includes(pathname)) {
      setSelectedKeys([pathname]);
      setOpenedKeys((prevKeys) => {
        const parentPath = `/${pathname.split("/")[1]}`;
        return !prevKeys.includes(parentPath)
          ? [...prevKeys, parentPath]
          : prevKeys;
      });
    }
  }, [pathname]);

  const handleLinkCLick = (link: NavigationLink, sublinks?: ItemType[]) => {
    if (
      !sublinks?.length ||
      !selectedKeys.find((key) => !!sublinks.find((sl) => sl.key === key))
    ) {
      if (link.onClick) link.onClick();
      else if (!link.external_link) {
        navigate(link.pathname);
      }
    }
  };

  const { t } = useTranslations();

  const ItemLabelLink = ({ link, isSubitem }: ItemLabelProps) => {
    return link.isExternal && !!link.external_link ? (
      <a
        href={prefixHTTPS(link.external_link, "navigation", "partner-app")}
        rel={`nofollow noopener noreferrer`}
        target="__blank"
      >
        <ItemLabel link={link} isSubitem={isSubitem} />
      </a>
    ) : link.pathname && !link.isExternal ? (
      <Link to={link.pathname}>
        <ItemLabel link={link} isSubitem={isSubitem} />
      </Link>
    ) : (
      <ItemLabel link={link} isSubitem={isSubitem} />
    );
  };

  const ItemLabel = ({ link, isSubitem }: ItemLabelProps) => {
    if (navCollapsed) {
      return (
        <Div flex="row" gap={12} align="center">
          {!!link.Icon && (
            <link.Icon
              width={18}
              height={18}
              style={{ flexBasis: 18, flexGrow: 0, flexShrink: 0 }}
            />
          )}
          {link.label}
        </Div>
      );
    }
    return (
      <Div flex="row" gap={17} align="center">
        {!!link.Icon && (
          <link.Icon
            width={16}
            height={16}
            style={{ flexBasis: 16, flexShrink: 0, flexGrow: 0 }}
          />
        )}

        <Typography
          elementTheme={isSubitem ? "caption" : "body2"}
          medium={!isSubitem}
          style={{
            fontSize: isSubitem ? 14 : 15,
            width: "fit-content",
            paddingLeft: isSubitem && !navCollapsed ? 18 : undefined,
          }}
          fitContent
        >
          {link.label}
        </Typography>
        {!!link.isExternal ? (
          <NewWindow
            width={18}
            height={18}
            style={{ flexBasis: 14, flexShrink: 0, flexGrow: 0 }}
          />
        ) : (
          link.isNew && (
            <HdTag
              color={theme.colors.purple_2}
              text={t("navigation|new")}
              size="small"
            />
          )
        )}
      </Div>
    );
  };

  const menuItems = useMemo(() => {
    const items: MenuProps["items"] = [];

    links?.forEach((link) => {
      const subitems: ItemType[] = [];
      link?.subLinks
        ?.filter((s) => !s.hide)
        ?.forEach((sublink) => {
          subitems.push({
            label: <ItemLabelLink link={sublink} isSubitem />,
            key: sublink.pathname,
            onClick: () => handleLinkCLick(sublink),
            className: `hd-menu-subitem ${app} ${
              sublink.pathname === pathname ? "active" : ""
            }`,
          });
        });
      items.push({
        key: link.pathname,
        children: subitems.length ? subitems : undefined,
        label: <ItemLabelLink link={link} />,
        onClick: !subitems.length
          ? () => handleLinkCLick(link, subitems)
          : undefined,
        className: `hd-menu-item ${app} ${
          link.pathname === pathname ? "active" : ""
        }`,
      });
    });
    return items;
  }, [links, navCollapsed]);

  const handleOpen = (newOpenKeys: string[]) => {
    setOpenedKeys(newOpenKeys);
  };
  const handleSelect = ({ selectedKeys }: SelectInfo) => {
    setSelectedKeys(selectedKeys);
  };
  return (
    <Navigation $app={app} $isOpen={!navCollapsed}>
      <Div flex="column" gap={36} align="center">
        <Div
          style={{
            width: navCollapsed ? 48 : 178,
          }}
          flex="row"
        >
          <HdLogo
            backgroundType={app === "provider" ? "dark" : "light"}
            type={navCollapsed ? "tertiary" : "primary"}
            color="b/w"
            width={navCollapsed ? 48 : 178}
            height={48}
          />
        </Div>
        <Div flex={"column"} justify={"space-between"} className="hd-menu">
          <Div className="hd-menu-links">
            <Menu
              selectedKeys={selectedKeys}
              onOpenChange={handleOpen}
              onSelect={handleSelect}
              forceSubMenuRender
              openKeys={openKeys}
              mode="inline"
              items={menuItems}
              className={`nav ${navCollapsed ? "collapsed" : ""}`}
              inlineIndent={16}
              theme={app === "provider" ? "dark" : "light"}
            />
          </Div>
        </Div>
      </Div>
      {app !== "admin" && <Socials minimize={navCollapsed} app={app} />}
    </Navigation>
  );
};

export default DesktopNavigation;

