import { IconProps } from "../features/entities/general";

const RatingFill = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={{ color, ...style }}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1659 1.28527C15.4811 0.556276 16.5189 0.556276 16.8341 1.28527L20.6977 10.2208C20.8292 10.5249 21.117 10.7331 21.448 10.7637L31.1745 11.6622C31.968 11.7355 32.2887 12.7185 31.69 13.2423L24.3513 19.6633C24.1016 19.8818 23.9917 20.2187 24.0647 20.5417L26.2125 30.0325C26.3877 30.8068 25.5481 31.4144 24.8628 31.0091L16.4637 26.0419C16.1779 25.8729 15.8221 25.8729 15.5363 26.0419L7.13716 31.0091C6.45192 31.4144 5.61233 30.8068 5.78755 30.0325L7.93525 20.5417C8.00834 20.2187 7.89842 19.8818 7.64868 19.6633L0.310012 13.2423C-0.288707 12.7185 0.0319905 11.7355 0.825519 11.6622L10.552 10.7637C10.883 10.7331 11.1708 10.5249 11.3023 10.2208L15.1659 1.28527Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default RatingFill;

