import ModalWarning from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-warning";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ContactModal from "@hellodarwin/core/lib/components/modals/contact-modal";
import { AppName } from "@hellodarwin/core/lib/features/entities";
import { getMeetingUrl } from "@hellodarwin/core/lib/features/helpers";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  fetchAdmin,
  selectAdminByAdminId,
  selectIsLoading,
} from "../../../features/api/slices/global-slice";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import {
  selectProjectById,
  selectSelectedProjectId,
} from "../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../features/api/use-client-api";
import MeetingModal from "./meeting-modal";

type ProjectManagerContactModalProps = {
  open: boolean;
  handleClose: () => void;
  isPropulsion?: boolean;
};

const ProjectManagerContactModal = ({
  open,
  handleClose,
  isPropulsion,
}: ProjectManagerContactModalProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const dispatch = useAppDispatch();
  const api = useClientApi();

  const profile = useAppSelector(selectProfile);

  const projectId = useAppSelector(selectSelectedProjectId) || "";
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId)
  );
  const loading = useAppSelector(selectIsLoading);
  const adminId = project?.account_manager || "";
  const admin = useAppSelector((state) => selectAdminByAdminId(state, adminId));

  const [meetingOpen, setMeetingOpen] = useState(false);

  useEffect(() => {
    if (admin === undefined && adminId) {
      dispatch(fetchAdmin({ api, adminId: adminId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (admin === undefined && !loading) {
    return (
      <ModalWarning open={open} size="small" handleCancel={handleClose}>
        <Typography> {t("common|message.noAccountManager")}</Typography>
      </ModalWarning>
    );
  }

  const openMeeting = () => setMeetingOpen(true);
  const closeMeeting = () => setMeetingOpen(false);

  let meetingUrl =
    selectedLocale === "en" ? admin?.meeting_link_en : admin?.meeting_link_fr;

  if (!meetingUrl)
    meetingUrl = getMeetingUrl(
      selectedLocale,
      "client",
      isPropulsion ? "propulsion" : "projects"
    );

  return (
    <>
      <ContactModal
        open={open}
        handleClose={handleClose}
        name={
          admin?.first_name || admin?.last_name
            ? `${admin?.first_name} ${admin?.last_name}`
            : ""
        }
        loading={loading}
        picture={admin?.picture}
        infos={[
          { label: t(`provider-profile|contact.email`), value: admin?.email },
          { label: t(`provider-profile|contact.phone`), value: admin?.phone },
        ]}
        cta={{
          text: t("project|projectActionCard.bookAMeeting.title"),
          action: openMeeting,
          headingIcon: <Timeline width={16} height={16} />,
        }}
      />

      <MeetingModal
        open={meetingOpen}
        handleClose={closeMeeting}
        meetingUrl={meetingUrl}
        Icon={Timeline}
        title={t("message.talkToManager")}
        name={profile.contact_name}
        email={profile.contact_email}
        app={AppName.Client}
        phone={profile.contact_phone}
        additionalParams={{
          app: "Client App",
          companyName: profile.company_name,
        }}
      />
    </>
  );
};

export default ProjectManagerContactModal;

