import Employees from "@hellodarwin/icons/dist/icons/Employees";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import Location from "@hellodarwin/icons/dist/icons/Location";
import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import RecommendNo from "@hellodarwin/icons/dist/icons/RecommendNo";
import RecommendYes from "@hellodarwin/icons/dist/icons/RecommendYes";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Divider from "antd/es/divider";
import React, { ReactNode, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReviewResult } from "../../../../features/entities/reviews-entities";
import {
  getAverageAllReviews,
  getStringDate,
} from "../../../../features/helpers";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Collapse from "../../../common/Collapse";
import { CollapsePanelExtendedProps } from "../../../common/Collapse/CollapsePanel";
import { Tablet } from "../../../common/Media";
import Div from "../../../common/div";
import HdTag from "../../../common/hd-tag";
import Typography from "../../../common/typography";
import ReviewHeader from "./review-header";
import ReviewOverallScore from "./review-score";
import {
  RatingsGridBoxContainer,
  RatingsGridContainer,
  ReviewCollapse,
} from "./styles";

interface ReviewCardProps {
  review: ReviewResult;
  index: number;
  locale?: string;
  defaultOpen?: boolean;
}

const OverallScore = ({ review }: { review: ReviewResult }) => {
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <Div flex="column" gap={7} fitContent>
      <Typography
        elementTheme="overline"
        color={theme.colors.grey_3}
        textTransform="uppercase"
        medium
        nowrap
        tablet={{ textAlign: "center" }}
      >
        {t("review_card|rc_overallScore")}
      </Typography>
      <Div flex="row" gap={8} align="center" tablet={{ flex: "column" }}>
        <RatingFill
          width={26}
          height={26}
          style={{ color: theme.colors.yellow_star }}
        />
        <Typography elementTheme="h5" nowrap tablet={{ textAlign: "center" }}>
          {getAverageAllReviews([review])}
        </Typography>
      </Div>
    </Div>
  );
};

const RatingsBox = ({ label, value }: { label: string; value: ReactNode }) => {
  const theme = useTheme();
  return (
    <RatingsGridBoxContainer>
      <Typography
        elementTheme="overline"
        medium
        color={theme.colors.grey_3}
        nowrap
      >
        {label}
      </Typography>
      <Div
        flex="row"
        align="center"
        justify="center"
        gap={8}
        tablet={{ flex: "column" }}
      >
        {value}
      </Div>
    </RatingsGridBoxContainer>
  );
};

const RatingsGrid = ({ review }: { review: ReviewResult }) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const ratings = [
    { label: t("review_card|rc_rating_budget"), value: review.rating_budget },
    { label: t("review_card|rc_rating_quality"), value: review.rating_quality },
    {
      label: t("review_card|rc_rating_schedule"),
      value: review.rating_schedule,
    },
    {
      label: t("review_card|rc_rating_collaboration"),
      value: review.rating_collaboration,
    },
  ];
  return (
    <RatingsGridContainer>
      <RatingsBox
        label={t("review_card|rc_serviceReceived")}
        value={
          <HdTag text={review.project_service} color={theme.colors.purple_1} />
        }
      />
      <RatingsBox
        label={t("review_card|rc_recommend")}
        value={
          <>
            {review.recommend ? (
              <RecommendYes
                width={18}
                height={18}
                style={{
                  color: theme.colors.green_1,
                }}
              />
            ) : (
              <RecommendNo
                width={18}
                height={18}
                style={{
                  color: theme.colors.error,
                }}
              />
            )}
            <Typography elementTheme="body2">
              {review.recommend ? t("review_card|yes") : t("review_card|no")}
            </Typography>
          </>
        }
      />

      {React.Children.toArray(
        ratings.map((rate) =>
          rate.value > 0 ? (
            <RatingsBox
              key={rate.label}
              label={rate.label}
              value={
                <>
                  <RatingFill
                    width={24}
                    height={24}
                    style={{ color: theme.colors.yellow_star }}
                  />
                  <Typography>{rate.value} / 5</Typography>
                </>
              }
            />
          ) : (
            <React.Fragment key={rate.label} />
          )
        )
      )}
    </RatingsGridContainer>
  );
};

const ReviewBody = ({
  review,
  index,
  locale,
}: {
  review: ReviewResult;
  index: number;
  locale: string;
}) => {
  const theme = useTheme();
  const { t } = useTranslations();

  const formatCityTag = () =>
    [review.city, review.province].filter(Boolean).join(", ");

  const renderDescriptionCollapse = (
    descriptionKey: string,
    descriptionTitle: string,
    descriptionContent: string
  ) => {
    return (
      <Collapse
        key={`review-card-${index}-${descriptionKey}`}
        ExpandIcon={({ isActive }) => <TabArrow width={14} down={isActive} />}
        items={[
          {
            id: `${index}-${descriptionKey}`,
            title: (
              <Typography
                elementTheme="body2"
                color={theme.colors.grey_2}
                tablet={{ textAlign: "center" }}
              >
                {descriptionTitle}
              </Typography>
            ),
            children: (
              <Typography
                style={{ margin: "0rem 1rem 1rem 1rem" }}
                tablet={{ textAlign: "center" }}
              >
                {descriptionContent}
              </Typography>
            ),
            styles: {
              container: { borderColor: theme.colors.grey_5 },
              header: { borderColor: theme.colors.grey_5 },
              content: { borderColor: theme.colors.grey_5 },
            },
          },
        ]}
      />
    );
  };

  return (
    <Div flex="column" gap={18}>
      <RatingsGrid review={review} />
      <Div
        flex="column"
        gap={16}
        bordersSide="bottom"
        borderColor={theme.colors.grey_4}
      >
        {review.description_summary &&
          renderDescriptionCollapse(
            "2",
            t("reviews|newReviewDescribeLabel", {
              providerName: review.company_name,
            }),
            review.description_summary
          )}
        {review.description_pros &&
          renderDescriptionCollapse(
            "3",
            t("review_card|rc_experience_pros"),
            review.description_pros
          )}
        {review.description_cons &&
          renderDescriptionCollapse(
            "4",
            t("review_card|rc_experience_cons"),
            review.description_cons
          )}
      </Div>
      <Div
        flex={"row"}
        style={{ padding: "1.5rem" }}
        tablet={{ style: { padding: 16 }, flex: "column" }}
        wrap="wrap"
        gap={16}
      >
        <Div
          flex={"row"}
          style={{ padding: "1.5rem" }}
          tablet={{
            style: { padding: "1rem" },
            flex: "column",
            align: "center",
          }}
          wrap="wrap"
          gap={24}
        >
          <Div flex="column" wrap="nowrap" fitContent>
            <Typography
              elementTheme="overline"
              medium
              color={theme.colors.grey_3}
              style={{ paddingBottom: "0.5rem" }}
              tablet={{ textAlign: "center" }}
            >
              {t("review_card|rc_publishOn")}
            </Typography>
            <HdTag
              text={getStringDate(
                new Date(review?.reviewed_at || ""),
                locale
              ).toString()}
            />
          </Div>
          {(review.size ||
            review.project_industry ||
            review.city ||
            review.province) && (
            <Div flex="column" fitContent>
              <Typography
                elementTheme="overline"
                medium
                textTransform="uppercase"
                color={theme.colors.grey_3}
                style={{
                  paddingBottom: "0.5rem",
                }}
                tablet={{ textAlign: "center" }}
              >
                {t("review_card|rc_about", {
                  company_name: review.company_name,
                })}
              </Typography>
              <Div
                flex="row"
                align="center"
                gap={16}
                wrap="wrap"
                tablet={{ flex: "column" }}
              >
                {review.size && (
                  <HdTag
                    text={review.size}
                    color={theme.colors.grey_2}
                    icon={<Employees width={14} height={14} />}
                    translateTag
                  />
                )}
                {review.project_industry && (
                  <HdTag
                    translateTag
                    text={review.project_industry}
                    color={theme.colors.grey_2}
                    icon={<Industry width={14} height={14} />}
                  />
                )}
                {(review.city || review.province) && (
                  <HdTag
                    text={formatCityTag()}
                    color={theme.colors.grey_2}
                    icon={<Location width={14} height={14} />}
                  />
                )}
              </Div>
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
};

const ExpandIcon = ({
  isActive,
  review,
}: {
  isActive?: boolean;
  review: ReviewResult;
}) => {
  const theme = useTheme();
  return (
    <Div
      flex="row"
      fitContent
      align="center"
      gap={34}
      tablet={{ flex: "column", align: "center" }}
    >
      <Div
        flex="row"
        align="center"
        gap={24}
        fitContent
        tablet={{ flex: "column", align: "center" }}
      >
        {!!review.project_service && (
          <>
            <OverallServiceReceived review={review} />
            <Tablet direction="up">
              <Divider
                type="vertical"
                style={{
                  borderColor: theme.colors.grey_1,
                  height: 41,
                  margin: 0,
                }}
              />
            </Tablet>
          </>
        )}

        <OverallScore review={review} />
      </Div>
      <TabArrow width={12} down={isActive} />
    </Div>
  );
};

const OverallServiceReceived = ({ review }: { review: ReviewResult }) => {
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <Div flex="column" gap={7} fitContent>
      <Typography
        elementTheme="overline"
        color={theme.colors.grey_3}
        textTransform="uppercase"
        medium
        nowrap
        tablet={{ textAlign: "center" }}
      >
        {t("review_card|rc_serviceReceived")}
      </Typography>
      <HdTag text={review.project_service} />
    </Div>
  );
};

const Extras = ({ review }: { review: ReviewResult }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery({ minWidth: theme.breakpoints.l });
  const { t } = useTranslations();
  return (
    <Div
      gap={16}
      flex="row"
      align="center"
      justify="center"
      tablet={{ flex: "column" }}
    >
      {isLarge && review.project_service && (
        <RatingsBox
          label={t("review_card|rc_serviceReceived")}
          value={
            <HdTag
              text={review.project_service}
              color={theme.colors.purple_1}
              translateTag
            />
          }
        />
      )}
      {isLarge && (
        <Divider
          style={{
            borderColor: theme.colors.grey_2,
            borderWidth: 1,
            margin: "0 2rem",
            height: "50px",
          }}
          type="vertical"
        />
      )}
      <Div wrap="nowrap" flex="column" justify="center">
        <Typography
          elementTheme="overline"
          medium
          textTransform="uppercase"
          color={theme.colors.grey_2}
          textAlign="right"
        >
          {t("review_card|rc_overallScore")}
        </Typography>
        <ReviewOverallScore review={review} />
      </Div>
    </Div>
  );
};

const useReviewCollapseProps = ({ review, index, locale }: ReviewCardProps) => {
  const [isActive, setOpen] = useState(false);
  const handleCollapse = () => setOpen(!isActive);

  const collapseProps: CollapsePanelExtendedProps = {
    ExpandIcon: ({ isActive }) => (
      <ExpandIcon isActive={isActive} review={review} />
    ),
    styles: { container: { overflow: "hidden", maxWidth: "100vw" } },
    id: `${index}-1`,
    headerIsColumnOnTablet: true,
    title: <ReviewHeader review={review} />,
    children: <ReviewBody review={review} locale={locale} index={index} />,
    extra: <Extras review={review} />,
    onClick: handleCollapse,
    isActive,
  };
  return collapseProps;
};
const ReviewCard: React.FC<ReviewCardProps> = (props: ReviewCardProps) => {
  const collapseProps = useReviewCollapseProps(props);

  return <ReviewCollapse {...collapseProps} />;
};

export default ReviewCard;

