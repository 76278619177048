import { IconProps } from "../features/entities/general";

const Logout = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1238_2110)">
          <path
            d="M5.94331 5.9433C3.95431 7.93232 2.59979 10.4665 2.05104 13.2253C1.50229 15.9841 1.78394 18.8438 2.8604 21.4425C3.93685 24.0413 5.75974 26.2625 8.09857 27.8252C10.4374 29.388 13.1871 30.2221 16 30.2221C18.8129 30.2221 21.5626 29.388 23.9014 27.8252C26.2402 26.2625 28.0631 24.0413 29.1396 21.4425C30.216 18.8438 30.4977 15.9841 29.9489 13.2253C29.4002 10.4665 28.0457 7.93232 26.0567 5.9433M16 1.77777V16"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1238_2110">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Logout;
