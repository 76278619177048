import Steps from "antd/es/steps";
import { useRef, useState } from "react";
import { ReviewForms } from "../../../../features/entities/form-entities";
import { ReviewFormSteps } from "../../../../features/entities/project-status-flow-entities";
import {
  Review,
  ReviewRequest,
  ReviewStatus,
} from "../../../../features/entities/reviews-entities";
import { ParsedTag } from "../../../../features/entities/tags-entities";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Spin from "../../../common/spin";
import ReviewFlowLayout from "../../common/review-flow-layout";
import ReviewConfirm from "./review-confirm";
import ReviewContact from "./review-contact";
import ReviewExperience from "./review-experience";

type ReviewFormProps = {
  providerName: string;
  providerId: string;
  projectId: string;
  handleClose: () => void;
  reviewProvider: (reviewRequest: ReviewRequest) => Promise<void>;
  reviewStep: ReviewFormSteps;
  setReviewStep: (reviewStep: ReviewFormSteps) => void;
  tags: { [key: string]: ParsedTag };
  companyTags: string[];
};

const ReviewFormHeader = ({
  projectId,
  reviewStep,
}: {
  projectId?: string;
  reviewStep: ReviewFormSteps;
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  if (projectId || isTablet) return <></>;

  return (
    <div style={{ marginBottom: 24 }}>
      <Steps
        style={{
          width: "100%",
        }}
        size="small"
        current={reviewStep == ReviewFormSteps.Experience ? 0 : 1}
        items={[
          {
            title: t("reviews|newReviewStepsStep1"),
          },
          {
            title: t("reviews|newReviewStepsStep2"),
          },
        ]}
      />
    </div>
  );
};

const ReviewForm = ({
  providerName,
  providerId,
  projectId,
  handleClose,
  reviewProvider,
  reviewStep,
  setReviewStep,
  tags,
  companyTags,
}: ReviewFormProps) => {
  const { t } = useTranslations();

  const ref = useRef<HTMLDivElement>(null);

  const [forms, setForms] = useState<ReviewForms>({
    experienceForm: undefined,
    contactForm: undefined,
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      const [experienceFormValues, contactFromValues] = await Promise.all([
        forms.experienceForm?.validateFields(),
        forms.contactForm?.validateFields(),
      ]);

      setSubmitLoading(true);

      const review: Review = {
        review_id: "",
        provider_id: providerId,
        project_id: projectId,
        description_summary: experienceFormValues.summary,
        rating_collaboration: experienceFormValues.relationship,
        rating_quality: experienceFormValues.quality,
        recommend: experienceFormValues.recommend,
        status: ReviewStatus.Public,
        locale: "fr",
        service: experienceFormValues.service,
        verified: "Unverified",
        review_source: "Client App",
      };

      const reviewRequest: ReviewRequest = {
        review: review,
        contact_name: contactFromValues?.contact_name,
        contact_email: contactFromValues?.contact_email,
        contact_title: contactFromValues?.contact_title,
        company_name: contactFromValues?.company_name,
        company_industry: contactFromValues?.company_industry,
        company_size: contactFromValues?.company_size,
        terms: "Agree",
      };

      await reviewProvider(reviewRequest);

      setReviewStep(ReviewFormSteps.Confirm);
      setSubmitLoading(false);
    } catch (e: any) {
      console.error(e);
      setSubmitLoading(false);
    }
  };
  const theme = useTheme();

  const isFromWebsite = !projectId;

  return reviewStep === ReviewFormSteps.Confirm ? (
    <ReviewConfirm handleClose={handleClose} isActive={true} />
  ) : (
    <ReviewFlowLayout
      title={t("reviews|newReviewRatingHeading", { providerName })}
      description={
        reviewStep === ReviewFormSteps.Contact
          ? t("reviews|newReviewRatingSubheadingContact")
          : t("reviews|newReviewRatingSubheading")
      }
    >
      <div
        ref={ref}
        style={{
          background: theme.colors.white_1,
          maxWidth: 900,
          margin: "auto",
        }}
      >
        {submitLoading ? (
          <Spin />
        ) : (
          <>
            <ReviewFormHeader projectId={projectId} reviewStep={reviewStep} />
            <ReviewExperience
              providerName={providerName}
              experiences={forms?.experienceForm?.getFieldsValue(true)}
              setForms={setForms}
              setFlowStep={setReviewStep}
              isActive={reviewStep == ReviewFormSteps.Experience}
              tags={tags}
              isFromWebsite={isFromWebsite}
              handleSubmit={handleSubmit}
            />
            {isFromWebsite && (
              <ReviewContact
                contactInfo={forms?.contactForm?.getFieldsValue(true)}
                setForms={setForms}
                setFlowStep={setReviewStep}
                isActive={reviewStep == ReviewFormSteps.Contact}
                handleSubmit={handleSubmit}
                isSubmit={true}
                companyTags={companyTags}
              />
            )}
          </>
        )}
      </div>
    </ReviewFlowLayout>
  );
};

export default ReviewForm;

