import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import RatingOutline from "@hellodarwin/icons/dist/icons/RatingOutline";
import Form from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import Radio from "antd/es/radio";
import Rate from "antd/es/rate";
import Select from "antd/es/select";
import { Dispatch, SetStateAction, useEffect } from "react";
import {
  ExperienceFormValues,
  ReviewForms,
} from "../../../../features/entities/form-entities";
import { ReviewFormSteps } from "../../../../features/entities/project-status-flow-entities";
import { ParsedTag } from "../../../../features/entities/tags-entities";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import ReviewNavigation from "./review-navigation";
import ReviewStepCard from "./review-step-card";

type ReviewExperienceProps = {
  providerName: string;
  experiences: ExperienceFormValues;
  setForms: Dispatch<SetStateAction<ReviewForms>>;
  setFlowStep: (step: ReviewFormSteps) => void;
  isActive?: boolean;
  tags?: { [key: string]: ParsedTag };
  isFromWebsite: boolean;
  handleSubmit?: () => Promise<void>;
};

const ReviewExperience = ({
  providerName,
  experiences,
  setForms,
  setFlowStep,
  isActive,
  tags,
  isFromWebsite,
  handleSubmit,
}: ReviewExperienceProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const { t: translateTag } = useTranslation(["tags"], { useSuspense: false });

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const [form] = Form.useForm<ExperienceFormValues>();

  useEffect(() => {
    setForms((prevState: ReviewForms) => ({
      ...prevState,
      experienceForm: form,
    }));
  }, [form, setForms]);

  const selectedTags = {
    selectedCategories: Object.values(tags ?? {})?.map((category) => ({
      label: category.category,
      services: category?.services?.map((service) => ({
        tag: service.tag,
        label: service.tag.split(":").pop(),
      })),
    })),
  };

  const initialValues: ExperienceFormValues = {
    service: experiences?.service,
    quality: experiences?.quality,
    relationship: experiences?.relationship,
    summary: experiences?.summary,
    recommend: experiences?.recommend,
  };

  const handleNextStep = async () => {
    form.validateFields().then((values) => {
      isFromWebsite
        ? setFlowStep(ReviewFormSteps.Contact)
        : setFlowStep(ReviewFormSteps.Confirm);
    });
  };

  return (
    <Form
      form={form}
      layout={"vertical"}
      initialValues={initialValues}
      name={"experience"}
      hidden={!isActive}
    >
      <Div flex="column" gap={16}>
        {isFromWebsite && (
          <ReviewStepCard
            key={"Service"}
            extra={t(`reviews|newReviewServiceQuestion`)}
            error={t(`reviews|newReviewServiceError`, { providerName })}
            label={t(`reviews|newReviewServiceLabel`, { providerName })}
            name={"service"}
          >
            <Select style={{ width: "100%" }} placeholder="Select a service">
              {selectedTags?.selectedCategories?.map((category, index) => (
                <Select.OptGroup
                  label={translateTag(`tags|${category.label}`)}
                  key={index}
                >
                  {category?.services?.map((service) => (
                    <Select.Option key={service.tag} value={service.tag}>
                      {translateTag(`tags|${service.label}`)}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </ReviewStepCard>
        )}
        <ReviewStepCard
          key={"Quality"}
          error={t(`reviews|newReviewQualityError`, { providerName })}
          label={t(`reviews|newReviewQualityLabel`, { providerName })}
          name={"quality"}
          type={"rating"}
        >
          <Rate
            character={(props) => {
              return !!props.value && props.value >= (props?.index || 0) + 1 ? (
                <RatingFill
                  color={theme.colors.yellow_star}
                  width={isTablet ? 30 : 37}
                  height={isTablet ? 30 : 37}
                />
              ) : (
                <RatingOutline
                  color={theme.colors.grey_3}
                  width={isTablet ? 30 : 37}
                  height={isTablet ? 30 : 37}
                />
              );
            }}
          />
        </ReviewStepCard>

        <ReviewStepCard
          key={"Relationship"}
          error={t(`reviews|newReviewRelationshipError`, { providerName })}
          label={t(`reviews|newReviewRelationshipLabel`, { providerName })}
          name={"relationship"}
          type={"rating"}
        >
          <Rate
            character={(props) => {
              return !!props.value && props.value >= (props?.index || 0) + 1 ? (
                <RatingFill
                  color={theme.colors.yellow_star}
                  width={isTablet ? 30 : 37}
                  height={isTablet ? 30 : 37}
                />
              ) : (
                <RatingOutline
                  color={theme.colors.grey_3}
                  width={isTablet ? 30 : 37}
                  height={isTablet ? 30 : 37}
                />
              );
            }}
          />
        </ReviewStepCard>

        <ReviewStepCard
          key={"Summary"}
          error={t(`reviews|newReviewDescribeError`, { providerName })}
          label={t(`reviews|newReviewDescribeLabel`, { providerName })}
          name={"summary"}
          type={"textarea"}
        >
          <TextArea
            placeholder={t("reviews|newReviewDescribePlaceholder", {
              providerName,
            })}
            style={{ minHeight: 150 }}
          />
        </ReviewStepCard>
        <ReviewStepCard
          key={"Recommend"}
          error={t(`reviews|newReviewRecommendError`, { providerName })}
          label={t(`reviews|newReviewRecommendLabel`, { providerName })}
          name={"recommend"}
          type="radio"
        >
          <Radio.Group
            style={{
              display: isTablet ? "flex" : "",
              justifyContent: isTablet ? "center" : "",
            }}
          >
            <Radio value={true} className="flex-1">
              {t("reviews|yes")}
            </Radio>
            <Radio value={false} className="flex-1">
              {t("reviews|no")}
            </Radio>
          </Radio.Group>
        </ReviewStepCard>

        <ReviewNavigation
          handleNext={handleNextStep}
          handlePrev={undefined}
          handleSubmit={handleSubmit}
          isSubmit={!isFromWebsite}
        />
      </Div>
    </Form>
  );
};

export default ReviewExperience;

