import ProviderRefuseModal from "@hellodarwin/core/lib/components/project/provider-refuse-modal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/index";
import {
  refuseProvider,
  selectProjectMatchById,
} from "../../../features/api/slices/matches-slice";
import { selectProject } from "../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../features/api/use-client-api";
type RefuseModalProps = {
  open: boolean;
  handleCancel: () => void;
  matchId: string;
};

const RefuseModal = ({ open, handleCancel, matchId }: RefuseModalProps) => {
  const navigate = useNavigate();
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const selectedMatch = useAppSelector((state) =>
    selectProjectMatchById(state, matchId)
  );

  const project = useAppSelector(selectProject);

  const handleRefuse = async (
    matchId: string,
    refusedReason: string,
    refusedReasonSpecified: string
  ) => {
    (async () => {
      await dispatch(
        refuseProvider({
          api,
          matchId,
          refusedReason,
          refusedReasonSpecified,
        })
      ).then(() => {
        setTimeout(() => {
          if (!!project?.project_id) {
            navigate(`/projects/${project?.project_id}/candidates`);
          }
        }, 200);
      });
    })();
  };

  return (
    <>
      {selectedMatch && (
        <ProviderRefuseModal
          modalVisible={open}
          handleRefuse={handleRefuse}
          closeModal={handleCancel}
          match={selectedMatch}
        />
      )}
    </>
  );
};

export default RefuseModal;

