import styled from "../../../../plugins/styled";

export const UserDropdownContent = styled.div`
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};
`;

export const LogoutItem = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  &:hover {
    background: ${({ theme }) => theme.colors.grey_5};
  }
`;
