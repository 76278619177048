import { useEffect, useState } from "react";
import { Match } from "../../../features/entities";
import {
  Project,
  ProjectUpdateFunctions,
} from "../../../features/entities/project-entities";
import {
  CancelProjectFlowSteps,
  ChoosePartnerFlowSteps,
} from "../../../features/entities/project-status-flow-entities";
import Div from "../../common/div";
import ModalLayoutSimple from "../../common/hd-modal/layouts/modal-simple";
import CancelProjectFlow from "./cancel-project-flow";
import ChooseProjectPartnerFlow from "./choose-partner-flow";
import NoPartnerSelectedFlow from "./no-partner-selected-flow";
import StatusFlowFooter from "./status-flow-footer";
import { StatusFlowContainer, StatusFlowContent } from "./styles";

export enum ProjectStatusFlowSteps {
  NoPartnerSelectedOptions = "noPartnerSelectedOptions",
  PartnerChooseSelected = "partnerChooseSelected",
  CancelProjectFlow = "Cancel Project Flow",
}

interface ProjectStatusFlowProps {
  initialStep?: ProjectStatusFlowSteps;
  editionFunctions: ProjectUpdateFunctions;
  project: Project;
  shortlistMatches: Match[];
  handleClose: () => void;
  handleChangeFlow: (status: ProjectStatusFlowSteps) => void;
  isModal?: boolean;
  modalOpen?: boolean;
}

interface FlowContentProps extends ProjectStatusFlowProps {
  step: ProjectStatusFlowSteps;
  cancelFlowStep: CancelProjectFlowSteps;
  setCancelFlowStep: (step: CancelProjectFlowSteps) => void;
  choosePartnerFlowStep: ChoosePartnerFlowSteps;
  setChoosePartnerFlowStep: (step: ChoosePartnerFlowSteps) => void;
}
const FlowContent = ({
  editionFunctions,
  project,
  step,
  cancelFlowStep,
  setCancelFlowStep,
  choosePartnerFlowStep,
  setChoosePartnerFlowStep,
  handleClose,
  shortlistMatches,
  isModal,
}: FlowContentProps) => {
  return (
    <StatusFlowContent
      className="fade-in"
      $isModal={isModal}
      style={{ width: "100%" }}
    >
      {step === ProjectStatusFlowSteps.CancelProjectFlow ? (
        <CancelProjectFlow
          handleClose={handleClose}
          editionFunctions={editionFunctions}
          project={project}
          flowStep={cancelFlowStep}
          setFlowStep={setCancelFlowStep}
        />
      ) : step === ProjectStatusFlowSteps.NoPartnerSelectedOptions ? (
        <NoPartnerSelectedFlow handleClose={handleClose} />
      ) : (
        step === ProjectStatusFlowSteps.PartnerChooseSelected && (
          <ChooseProjectPartnerFlow
            handleClose={handleClose}
            editionFunctions={editionFunctions}
            project={project}
            shortlistMatches={shortlistMatches}
            flowStep={choosePartnerFlowStep}
            setFlowStep={setChoosePartnerFlowStep}
          />
        )
      )}
    </StatusFlowContent>
  );
};

const ProjectStatusFlow = (props: ProjectStatusFlowProps) => {
  const {
    initialStep,
    project,
    shortlistMatches,
    modalOpen,
    handleChangeFlow,
    isModal,
    handleClose,
  } = props;

  const [editableProject, setEditableProject] = useState(project);
  const [step, setStep] = useState<ProjectStatusFlowSteps>(
    initialStep || ProjectStatusFlowSteps.PartnerChooseSelected
  );

  const [choosePartnerFlowStep, setChoosePartnerFlowStep] =
    useState<ChoosePartnerFlowSteps>(ChoosePartnerFlowSteps.chosenSpStep);

  const [cancelFlowStep, setCancelFlowStep] = useState<CancelProjectFlowSteps>(
    CancelProjectFlowSteps.cancelFormStep
  );

  useEffect(() => {
    if (!!initialStep) {
      setStep(initialStep);
    }
  }, [initialStep]);

  useEffect(() => {
    setCancelFlowStep(CancelProjectFlowSteps.cancelFormStep);
    setChoosePartnerFlowStep(ChoosePartnerFlowSteps.chosenSpStep);
  }, [step]);

  useEffect(() => {
    if (!!project.project_id) setEditableProject(project);
  }, [project]);

  if (!editableProject.project_id) return <></>;

  return isModal ? (
    <ModalLayoutSimple
      open={modalOpen}
      handleCancel={handleClose}
      size={
        step === ProjectStatusFlowSteps.PartnerChooseSelected
          ? "large"
          : "medium"
      }
    >
      <Div flex="column" gap={54}>
        <FlowContent
          {...props}
          project={editableProject}
          step={step}
          cancelFlowStep={cancelFlowStep}
          setCancelFlowStep={setCancelFlowStep}
          choosePartnerFlowStep={choosePartnerFlowStep}
          setChoosePartnerFlowStep={setChoosePartnerFlowStep}
        />

        <StatusFlowFooter
          step={step}
          handleChangeFlow={handleChangeFlow}
          cancelFlowStep={cancelFlowStep}
          choosePartnerFlowStep={choosePartnerFlowStep}
          hasShortlistMatches={!!shortlistMatches.length}
        />
      </Div>
    </ModalLayoutSimple>
  ) : (
    <StatusFlowContainer
      $size={
        step === ProjectStatusFlowSteps.NoPartnerSelectedOptions
          ? "large"
          : "medium"
      }
    >
      <FlowContent
        {...props}
        project={editableProject}
        step={step}
        cancelFlowStep={cancelFlowStep}
        setCancelFlowStep={setCancelFlowStep}
        choosePartnerFlowStep={choosePartnerFlowStep}
        setChoosePartnerFlowStep={setChoosePartnerFlowStep}
      />
      <StatusFlowFooter
        step={step}
        handleChangeFlow={handleChangeFlow}
        cancelFlowStep={cancelFlowStep}
        choosePartnerFlowStep={choosePartnerFlowStep}
        hasShortlistMatches={!!shortlistMatches.length}
      />
    </StatusFlowContainer>
  );
};

export default ProjectStatusFlow;

export { default as ProjectStatusFlowModal } from "./project-status-flow-modal";

