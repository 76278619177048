import Button from "@hellodarwin/core/lib/components/common/button";
import Carousel from "@hellodarwin/core/lib/components/common/carousel";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PreviewCard, {
  PreviewProps,
} from "@hellodarwin/core/lib/components/common/layout/preview-card";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  Program,
  ProgramGrantStatus,
} from "@hellodarwin/core/lib/features/entities";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import { getProgramGrantStatusColor } from "@hellodarwin/core/lib/features/helpers/get-status-color";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import theme from "@hellodarwin/core/lib/theme";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import Empty from "antd/es/empty";
import { useNavigate } from "react-router-dom";

interface RecentProgramsSectionProps {
  programs: Program[];
  isLoading: boolean;
}
const RecentRoadmapProgramsSection = ({
  programs,
  isLoading,
}: RecentProgramsSectionProps) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const navigate = useNavigate();

  const handleNavigateRoadmap = () => {
    navigate("/programs/roadmap");
  };

  return (
    <Container
      style={{ minHeight: 420, border: `1px solid ${theme.colors.primary}` }}
    >
      <Div flex="column" gap={24}>
        <Typography.Title level={2} elementTheme="h6">
          {t("recent_roadmap_program|ongoingPrograms")}
        </Typography.Title>
        <Div
          flex="column"
          align="center"
          justify="center"
          style={{ minHeight: 304 }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Carousel
              isLoading={isLoading}
              tileWidth={300}
              emptyState={
                <Empty description={t("recent_roadmap_program|noContentYet")} />
              }
              actions={
                <Button
                  defaultStyle={theme.colors.purple_1}
                  onClick={handleNavigateRoadmap}
                  size="small"
                  withArrowRight
                >
                  {t("recent_roadmap_program|program_roadmap_overview_cta")}
                </Button>
              }
              styles={{ container: { height: "100%" } }}
              buttonPosition="bottom-right"
            >
              {programs?.slice(0, 5).map((program) => {
                const previewProps: PreviewProps = {
                  parentSlug: "propulsion",
                  tag: {
                    color: getProgramGrantStatusColor(
                      program.program_grant_status ||
                        ProgramGrantStatus.IdentifiedOpportunities
                    ),
                    text: t(`programs_labels|${program.program_grant_status}`),
                  },
                  title: `${program.program_name}`,
                  infos: [
                    {
                      Icon: Timeline,
                      label: t("program_preview_card|updatedAt"),
                      value: `${getStringDate(
                        new Date(`${program?.program_updated_at}`),
                        selectedLocale
                      )}`,
                      hide: !program.program_updated_at,
                    },
                  ],
                  ctaText: t("program_preview_card|seeProgram"),
                  id: program.program_id,
                };
                return (
                  <PreviewCard {...previewProps} key={program.program_id} />
                );
              })}
            </Carousel>
          )}
        </Div>
      </Div>
    </Container>
  );
};

export default RecentRoadmapProgramsSection;

