import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import VideoPlayer from "@hellodarwin/core/lib/components/common/VideoPlayer";
import Button from "@hellodarwin/core/lib/components/common/button";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import { getStringDate } from "@hellodarwin/core/lib/features/helpers";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Download from "@hellodarwin/icons/dist/icons/Download";
import Image from "antd/es/image";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/index";
import {
  getResourceBySlug,
  selectResourceById,
} from "../../../features/api/slices/resources-slice";
import { useContentfulApi } from "../../../features/api/use-contentful-api";
import { ResourceSingleContent } from "./styles";

const ClientResourcesSinglePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const api = useContentfulApi();
  const dispatch = useAppDispatch();
  const { resourceSlug } = useParams<{ resourceSlug: string }>();

  const resource = useAppSelector((state) =>
    selectResourceById(state, resourceSlug || "")
  );

  useEffect(() => {
    dispatch(getResourceBySlug({ slug: resourceSlug || "", api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceSlug]);

  const handleBack = () => {
    navigate("/resources");
  };

  const handleDownloadCLick = () => {
    if (resource?.linkToResource) {
      window.open(resource?.linkToResource, "download");
    }
  };

  const findAsset = (asset_id: string) => {
    return resource?.content.links.assets.block.find(
      (asset) => asset?.sys?.id === asset_id
    );
  };
  const options: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography elementTheme="body2">{children}</Typography>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const asset = findAsset(node.data.target.sys.id);
        if (!asset) return <></>;
        const { url, width } = asset;
        return (
          <div style={{ display: "flex", paddingBlock: "0 32px" }}>
            <Image
              src={`${url}`}
              width={Math.min(600, width)}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              wrapperStyle={{
                margin: "auto",
              }}
              alt={`${asset.description}`}
            />
          </div>
        );
      },

      [INLINES.HYPERLINK]: (node, children) => {
        const url = node.data.uri;
        if (
          url.includes("vimeo.com") ||
          url.includes("youtube.com") ||
          url.includes("youtu.be")
        ) {
          return (
            <div
              style={{
                aspectRatio: 16 / 9,
                width: 900,
                maxWidth: "100%",
                margin: "auto",
              }}
              className="video"
            >
              <VideoPlayer url={url} width="100%" controls height={"100%"} />
            </div>
          );
        } else {
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
        }
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography.Title level={1} elementTheme="h4">
          {children}
        </Typography.Title>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography.Title level={2} elementTheme="h5">
          {children}
        </Typography.Title>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography.Title level={3} elementTheme="h6">
          {children}
        </Typography.Title>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography.Title level={4} elementTheme="subtitle1">
          {children}
        </Typography.Title>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography.Title
          level={5}
          color={theme.colors.grey_2}
          elementTheme="subtitle2"
        >
          {children}
        </Typography.Title>
      ),
    },
    renderText: (text) => text.replace("!", "?"),
  };

  if (!resource) return <Loading />;
  return (
    <PageLayout
      handleBack={handleBack}
      title={resource.title}
      app="client"
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("resource|resourcesYouCouldUse"),
          path: "/resources",
        },
        {
          breadcrumbName: resource?.title,
        },
      ]}
    >
      <Container style={{ maxWidth: theme.caps.normal }}>
        <Div flex="column" gap={61} tablet={{ gap: 30 }}>
          <Div flex="column" gap={40} align="center" justify="center">
            {!!resource.bannerImage && (
              <Div
                style={{
                  maxWidth: "100%",
                  width: 800,
                  height: "auto",
                  padding: 20,
                  overflow: "hidden",
                }}
                borderColor={theme.colors.grey_1}
                backgroundColor={theme.colors.yellow_1}
                tablet={{ style: { padding: 10 } }}
              >
                <Image
                  src={resource.bannerImage.url}
                  style={{
                    borderRadius: 10,
                    height: "100%",
                    width: "100%",
                    aspectRatio: 16 / 9,
                    objectFit: "cover",
                  }}
                />
              </Div>
            )}
            <Div flex="column" gap={8}>
              <Typography
                textAlign="center"
                elementTheme="caption"
                color={theme.colors.grey_2}
                tablet={{ textAlign: "left" }}
              >
                {getStringDate(new Date(resource.date), selectedLocale)}
              </Typography>
              <Typography.Title
                textAlign="center"
                level={1}
                elementTheme="h3"
                tablet={{ textAlign: "left" }}
              >
                {resource.title}
              </Typography.Title>
            </Div>
          </Div>
          <Div
            style={{ flex: 2, padding: "0 40px" }}
            tablet={{ style: { padding: 0 } }}
            flex="column"
            gap={29}
          >
            <ResourceSingleContent>
              {!!resource.content &&
                documentToReactComponents(resource.content.json, options)}
            </ResourceSingleContent>
            {!!resource.linkToResource && (
              <Button fitContent onClick={handleDownloadCLick}>
                {t("button.download")} <Download width={24} height={24} />
              </Button>
            )}
          </Div>
          <Div flex="row">
            {!!resource.scribeEmbed && (
              <Div
                style={{ flex: 2, padding: "0 40px" }}
                tablet={{ style: { padding: 0 } }}
                flex="row"
                gap={29}
              >
                {parse(resource.scribeEmbed)}
              </Div>
            )}
          </Div>
        </Div>
      </Container>
    </PageLayout>
  );
};
export default ClientResourcesSinglePage;

