import {
  ClientMappedProject,
  Project,
  ProjectAsset,
} from "@hellodarwin/core/lib/features/entities";

export default class ProjectAdapter {
  setAll = (
    mappedProjects: { [key: string]: ClientMappedProject },
    projects: Project[]
  ) => {
    for (let project of projects) {
      this.addOne(mappedProjects, project);
    }

    return mappedProjects;
  };

  addOne = (
    mappedProjects: { [key: string]: ClientMappedProject },
    project: Project
  ) => {
    mappedProjects[project.project_id] = {
      ...project,
      assets: {} as { [key: string]: ProjectAsset },
    };

    return mappedProjects;
  };

  removeOne = (
    mappedProjects: { [key: string]: ClientMappedProject },
    project_id: string
  ) => {
    delete mappedProjects[project_id];
    return mappedProjects;
  };

  selectAll = (mappedProjects: { [key: string]: ClientMappedProject }) => {
    const projects: Project[] = [];

    for (let project of Object.values(mappedProjects)) {
      projects.push({
        ...project,
        assets: Object.values(project.assets),
      });
    }

    projects.sort((a,b)=>{
      if(a.updated_at && b.updated_at){
        return a.updated_at.localeCompare(b.updated_at)
      }
      return 0
    })

    projects.reverse()
    

    return projects;
  };

  selectById = (
    mappedProjects: { [key: string]: ClientMappedProject },
    projectId: string
  ) => {
    if (Object.keys(mappedProjects).length === 0) return;
    if (!projectId || !projectId.length) return;
    if (!mappedProjects[projectId]?.project_id) return;

    const project = {
      ...mappedProjects[projectId],
      assets: Object.values(mappedProjects[projectId].assets),
    } as Project;
    return project;
  };

  getInitialState = () => {
    return {} as { [key: string]: ClientMappedProject };
  };
}

export const createProjectAdapter = () => {
  return new ProjectAdapter();
};
