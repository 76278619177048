import HdLogo from "@hellodarwin/icons/dist/icons/HdLogo";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Divider from "antd/es/divider";
import Drawer from "antd/es/drawer";
import Menu, { MenuProps } from "antd/es/menu";
import { ItemType } from "antd/es/menu/interface";
import { useEffect, useState } from "react";
import { AppType } from "../../../features/entities";
import { NavigationLink } from "../../../features/entities/layout-entities";
import { Profile } from "../../../features/entities/profile-entities";
import { handleExternalLinkCLick } from "../../../features/helpers/get-website-url";
import { useTheme } from "../../../plugins/styled";
import Div from "../../common/div";
import Typography from "../../common/typography";
import UserMenu from "../header/user-menu";
import NavigationBurger from "./navigation-burger";
import { MobileNavigationContainer } from "./styles";

type MobileNavigationProps = {
  links: NavigationLink[];
  app?: AppType;
  profile?: Profile;
  handleLogout: () => void;
  userLinks?: NavigationLink[];
  pathname: string;
  navigate: (path: string) => void;
};

export const MobileItemLabel = ({
  link,
  isSubitem,
  handleLinkClick,
}: {
  link: NavigationLink;
  isSubitem?: boolean;
  handleLinkClick: (link: NavigationLink) => void;
}) => {
  return (
    <Div flex="row" gap={17} align="center" className="mobile-item-label">
      {!!link.Icon && (
        <link.Icon
          width={16}
          height={16}
          style={{ flexBasis: 16, flexGrow: 0, flexShrink: 0 }}
        />
      )}

      <Typography
        elementTheme={isSubitem ? "caption" : "body2"}
        bold={!isSubitem}
        style={{
          width: "100%",
          paddingLeft: isSubitem ? 20 : 0,
        }}
        onClick={(e) => {
          e.preventDefault();
          handleLinkClick(link);
        }}
      >
        {link.label}
      </Typography>
      {!!link.isExternal && (
        <NewWindow
          width={18}
          height={18}
          style={{ flexBasis: 14, flexShrink: 0, flexGrow: 0 }}
        />
      )}
    </Div>
  );
};

const MobileNavigation = ({
  links,
  app = "client",
  profile,
  userLinks,
  handleLogout,
  navigate,
  pathname,
}: MobileNavigationProps) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  const handleLinkClick = (link: NavigationLink) => {
    toggleMenu();

    if (link.external_link) handleExternalLinkCLick(link.external_link);
    else if (link.onClick) link.onClick();
    else {
      navigate(link.pathname);
    }
  };

  const [menuItems, setMenuItems] = useState<MenuProps["items"]>([]);
  useEffect(() => {
    const items: MenuProps["items"] = [];

    links?.forEach((link) => {
      const subitems: ItemType[] = [];
      link?.subLinks
        ?.filter((s) => !s.hide)
        ?.forEach((sublink) => {
          subitems.push({
            label: (
              <MobileItemLabel
                handleLinkClick={handleLinkClick}
                link={sublink}
                isSubitem
              />
            ),
            key: sublink.pathname,
            className: `hd-menu-subitem ${app} ${
              sublink.pathname === pathname ? "active" : ""
            }`,
          });
        });
      items.push({
        key: link.pathname,
        children: subitems.length ? subitems : undefined,

        label: (
          <MobileItemLabel handleLinkClick={handleLinkClick} link={link} />
        ),
        className: `hd-menu-item ${app} ${
          link.pathname === pathname ? "active" : ""
        }`,
      });
    });
    setMenuItems(items);
  }, [links]);

  const [opened, setOpened] = useState<string[]>([]);
  useEffect(() => {
    const menuOpened = links.filter((l) => !!l.subLinks).map((l) => l.pathname);
    setTimeout(() => setOpened(menuOpened), 100);
  }, []);

  const onClick: MenuProps["onClick"] = (e) => {
    e.domEvent.preventDefault();
    const link = links.find(
      (l) => l.pathname === e.keyPath[e.keyPath.length - 1]
    );
    if (e.keyPath.length > 1) {
      const sublink = link.subLinks.find((l) => l.pathname === e.keyPath[0]);
      handleLinkClick(sublink);
    } else {
      handleLinkClick(link);
    }
  };

  const Header = () => {
    const handleRedirect = () => {
      if (isOpen) toggleMenu();
      navigate("/");
    };
    return (
      <Div
        backgroundColor={
          app === "provider" ? theme.colors.primary : theme.colors.white_1
        }
        flex={"row"}
        justify="space-between"
        style={{ padding: 21, zIndex: 100 }}
        align="center"
      >
        <HdLogo
          onClick={handleRedirect}
          backgroundType={app === "provider" ? "dark" : "light"}
          type={"primary"}
          color="b/w"
          width={178}
          height={48}
        />
        <NavigationBurger app={app} isOpen={isOpen} onClick={toggleMenu} />
      </Div>
    );
  };

  return (
    <>
      <Header />

      <Drawer
        styles={{
          header: {
            padding: 0,
            flexDirection: "row-reverse",
          },
          content: {
            background:
              app === "provider" ? theme.colors.primary : theme.colors.white_1,
            padding: 0,
          },
        }}
        title={<Header />}
        closable={false}
        placement="right"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        width={"100%"}
      >
        <MobileNavigationContainer $isOpen={isOpen} $app={app}>
          <Div className="hd-menu-links">
            <Menu
              selectedKeys={[pathname]}
              onClick={onClick}
              inlineIndent={0}
              openKeys={opened}
              mode="inline"
              className={`nav`}
              forceSubMenuRender
              items={menuItems}
              theme={app === "provider" ? "dark" : "light"}
            />
          </Div>

          {profile && userLinks && (
            <Div className="hd-navigation-footer" flex="column" gap={32}>
              <Divider
                style={{ borderWidth: 1, borderColor: theme.colors.white_1 }}
              />
              <UserMenu
                user={{ name: profile.contact_name, logo: profile.logo }}
                links={userLinks}
                navigate={navigate}
                handleLogout={handleLogout}
                app={app}
              />
            </Div>
          )}
        </MobileNavigationContainer>
      </Drawer>
    </>
  );
};

export default MobileNavigation;

