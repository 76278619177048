import Button from "@hellodarwin/core/lib/components/common/button";
import Carousel from "@hellodarwin/core/lib/components/common/carousel";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ResourceCard from "@hellodarwin/core/lib/components/resources/resource-card";
import { ContentfulResource } from "@hellodarwin/core/lib/features/entities/resources-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Empty from "antd/es/empty";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/index";
import {
  getResourcesWithLimit,
  selectAllResources,
  selectResourcesLoading,
} from "../../features/api/slices/resources-slice";
import { useContentfulApi } from "../../features/api/use-contentful-api";

type RecentResourcesSectionProps = {
  className?: string;
};

const RecentResourcesSection = ({ className }: RecentResourcesSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = useContentfulApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const resources = useAppSelector(selectAllResources);
  const isLoading = useAppSelector(selectResourcesLoading);

  useEffect(() => {
    dispatch(getResourcesWithLimit({ limit: 10, api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  const onAllResourcesClick = () => {
    navigate("/resources");
  };

  const handleNavigate = (resource: ContentfulResource) => {
    navigate(`/resources/${resource.slug}`);
  };

  return (
    <Container
      style={{ minHeight: 420, border: `1px solid ${theme.colors.primary}` }}
      className={className}
    >
      <Div flex="column" gap={24}>
        <Typography.Title level={2} elementTheme="h6">
          {t("resource|resourcesYouCouldUse")}{" "}
        </Typography.Title>

        <Carousel
          isLoading={isLoading}
          tileWidth={300}
          emptyState={<Empty description={t("resource|NoResources")} />}
          actions={
            <Button
              defaultStyle={theme.colors.purple_1}
              onClick={onAllResourcesClick}
              size={"small"}
              withArrowRight
            >
              {t("resource|seeAll")}
            </Button>
          }
          styles={{ container: { height: "100%" } }}
          buttonPosition="bottom-right"
        >
          {resources.map((resource, i) => (
            <Fragment key={`resource-${i}`}>
              <ResourceCard
                {...resource}
                handleClick={() => handleNavigate(resource)}
                size="small"
              />
            </Fragment>
          ))}
        </Carousel>
      </Div>
    </Container>
  );
};

export default RecentResourcesSection;

