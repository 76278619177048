import { IconProps } from "../features/entities/general";

interface ArrowOutlineProps extends IconProps {
  bgColor?: string;
}
const ArrowOutline = ({
  width = 30,
  height = 30,
  style,
  onClick,
  color = "#2b2b2b",
  className,
  bgColor = "#BBBBBB",
}: ArrowOutlineProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={{ color, ...style }}
        onClick={onClick}
        className={className}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_723_7882)">
          <circle cx="15" cy="13" r="11" fill={bgColor} />
        </g>
        <path
          d="M16.2692 8.76904L12.0385 12.9998L16.2692 17.2306"
          stroke="currentColor"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <defs>
          <filter
            id="filter0_d_723_7882"
            x="0"
            y="0"
            width="30"
            height="30"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.519531 0 0 0 0 0.519531 0 0 0 0 0.519531 0 0 0 0.19 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_723_7882"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_723_7882"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default ArrowOutline;
