import Div from "@hellodarwin/core/lib/components/common/div";
import ProjectSidebar, {
  ProjectSidebarField,
} from "@hellodarwin/core/lib/components/project/single/project-content/project-sidebar";
import { Project } from "@hellodarwin/core/lib/features/entities";
import { dollarFormat } from "@hellodarwin/core/lib/features/helpers";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { useMemo } from "react";

import { useAppSelector } from "../../../app/app-hooks";
import {
  selectCandidateMatches,
  selectClassicMatches,
  selectHasWinner,
  selectShortlistedMatches,
} from "../../../features/api/slices/matches-slice";
import OverviewMatchesList from "./overview-matches-list";
import OverviewSummary from "./overview-summary";

type ProjectOverviewProps = {
  project: Project;
};

const ProjectOverview = ({ project }: ProjectOverviewProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const classicMatches = useAppSelector(selectClassicMatches);
  const shortlist = useAppSelector(selectShortlistedMatches);
  const candidates = useAppSelector(selectCandidateMatches);
  const hasWinner = useAppSelector(selectHasWinner);

  const sidebarSections = useMemo(() => {
    const sections: ProjectSidebarField[][] = [];

    const section1: ProjectSidebarField[] = [];

    if (project.service)
      section1.push({
        label: t("project-single|sidebar.labels.service"),

        value: project.service,
      });
    section1.push({
      label: t("project-single|sidebar.labels.budget"),

      value: t("project-single|sidebar.values.budget", {
        budgetMin: dollarFormat(project.budget_min || 0, selectedLocale),
        budgetMax: dollarFormat(project.budget_max || 0, selectedLocale),
      }),
    });
    if (!!project?.start_when) {
      section1.push({
        label: t("project-single|sidebar.labels.timeline"),

        value:
          t(
            `project-single|sidebar.values.startWhen.${project?.start_when?.toLowerCase()}`
          ) || "",
      });
    }

    if (!!section1.length) sections.push(section1);

    return sections;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return isTablet ? (
    <Div flex="column" gap={25}>
      <OverviewSummary project={project} />
      <ProjectSidebar sections={sidebarSections} />
      <OverviewMatchesList
        candidates={candidates}
        shortlist={shortlist}
        classicMatches={classicMatches}
        hasWinner={hasWinner}
      />
    </Div>
  ) : (
    <Div flex="column" gap={25}>
      <Div flex="row" gap={25} xl={{ flex: "column" }}>
        <OverviewSummary project={project} />
        <div style={{ flexBasis: 228, flexGrow: 0, flexShrink: 0 }}>
          <ProjectSidebar sections={sidebarSections} isOverview />
        </div>
      </Div>
      <OverviewMatchesList
        classicMatches={classicMatches}
        candidates={candidates}
        shortlist={shortlist}
        hasWinner={hasWinner}
      />
    </Div>
  );
};

export default ProjectOverview;

