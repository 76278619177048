import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import React, { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { GrantProject } from "../../../features/entities/grants-entities";
import { useAppData } from "../../../features/providers/app-provider";
import HdTag from "../../common/hd-tag";
import FormItem from "../../forms/form-layouts/form-item";
import FormLabel from "../../forms/form-layouts/form-label";
import {
  FilterSectionContainterStyled,
  FilterSectionStyled,
  GrantProjectsRow,
  ScrollingContainer,
  ScrollingContent,
} from "./styles";

export interface FilterProjectsProps {
  bestProjects?: GrantProject[];
  handleProjectSelection: (projectId: string) => void;
  selectedProjects?: string[];
}
const FilterProjects = ({
  bestProjects,
  handleProjectSelection,
  selectedProjects,
}: FilterProjectsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { navCollapsed } = useAppData();

  const [projectsHidden, setProjectsHidden] = useState(false);
  const handleHideProject = (e: MouseEvent<HTMLDivElement>) => {
    setProjectsHidden(!projectsHidden);
  };

  const chunkArray = (array, chunkSize) => {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  };

  const bubbleRows = chunkArray(
    bestProjects,
    Math.ceil(bestProjects?.length / 2)
  ).map((rowProjects, rowIndex) => (
    <GrantProjectsRow key={`row-${rowIndex}`}>
      {React.Children.toArray(
        rowProjects.map(
          (project, index) =>
            project.short_description && (
              <HdTag
                onClick={(e) => {
                  e.stopPropagation();
                  handleProjectSelection(project.grant_project_id);
                }}
                key={`project-${index}`}
                hoverable
                text={project.short_description}
                color={
                  selectedProjects?.includes(project.grant_project_id)
                    ? theme.colors.purple_2
                    : theme.colors.purple_1
                }
              />
            )
        )
      )}
    </GrantProjectsRow>
  ));

  if (!bestProjects?.length) return <></>;

  return (
    <FilterSectionContainterStyled
      $position="bottom"
      $navCollapsed={navCollapsed}
    >
      <FilterSectionStyled
        onClick={handleHideProject}
        style={{ cursor: "pointer" }}
        $position="bottom"
      >
        <FormItem
          label={
            <FormLabel
              label={t(`grants|fundingExplorerFilter.proposedProjects`)}
              color={theme.colors.primary}
              actions={
                <TabArrow
                  down={!projectsHidden}
                  color={theme.colors.primary}
                  width={12}
                  height={8}
                />
              }
            />
          }
          name="selectedProjects"
          style={{ margin: 0 }}
          hideContent={projectsHidden}
        >
          <ScrollingContainer>
            <ScrollingContent>{bubbleRows}</ScrollingContent>
          </ScrollingContainer>
        </FormItem>
      </FilterSectionStyled>{" "}
    </FilterSectionContainterStyled>
  );
};
export default FilterProjects;

