import LinkedinLogo from "@hellodarwin/icons/dist/icons/LinkedinLogo";
import { TeamMember } from "../../../../../features/entities/core-entities";
import prefixHTTPS from "../../../../../features/helpers/prefix-https";
import { useTranslations } from "../../../../../features/providers/translations-provider";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import { TeamMemberCardContainer } from "./styles";

interface TeamMemberCardProps {
  member: TeamMember;
  handleClick?: (member: TeamMember) => void;
  selected?: boolean;
}

const TeamMemberCard = ({
  member,
  handleClick,
  selected,
}: TeamMemberCardProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <TeamMemberCardContainer
      hoverable
      onClick={() => !!handleClick && handleClick(member)}
      $selected={!!selected}
      style={{ height: "auto" }}
    >
      {!member.name ? (
        <>
          <Typography elementTheme="body2">
            {t("profile_team|newMember")}
          </Typography>
        </>
      ) : (
        <>
          <img
            className="portfolio-logo"
            src={
              !!member.profile_picture
                ? `${member.profile_picture}?${new Date().toString()}`
                : undefined
            }
            alt={`${member.name}${member.role ? " - " + member.role : ""}`}
            loading="lazy"
            style={{
              width: 88,
              height: 84,
              flexBasis: 88,
              flexShrink: 0,
              flexGrow: 0,
              objectFit: "cover",
              borderRadius: 5,
            }}
          />

          <Div flex={"column"} fullHeight gap={6} overflow="hidden">
            <Typography.Title
              level={3}
              elementTheme="h6"
              nowrap
              overflow
              ellipsis
              noMargin
            >
              {member.name}
            </Typography.Title>
            <Typography
              color={theme.colors.grey_3}
              elementTheme="caption"
              nowrap
              overflow
              ellipsis
              style={{ maxWidth: 320 }}
            >
              {member.role}
            </Typography>

            {!!member.linked_in && (
              <a
                rel="nofollow"
                href={prefixHTTPS(member.linked_in, "team_member")}
                target="_blank"
              >
                <LinkedinLogo width={16} height={16} />
              </a>
            )}
          </Div>
        </>
      )}
    </TeamMemberCardContainer>
  );
};

export default TeamMemberCard;

