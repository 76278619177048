import CancelProject from "@hellodarwin/icons/dist/icons/CancelProject";
import DatePicker from "antd/es/date-picker";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { Suspense, useCallback, useState } from "react";
import { useTheme } from "styled-components";
import { FiltersDropdownProps } from ".";
import { useTranslation } from "../../../../plugins/i18n";
import Button from "../../../common/button";
import Div from "../../../common/div";
import { Tablet } from "../../../common/Media";
import Typography from "../../../common/typography";
import FormItem from "../../../forms/form-layouts/form-item";
import Loading from "../../../loading";
import { FiltersGrid } from "../styles";
import useGetOptions from "../utils/use-get-options";
import FilterMultipleSelect from "./filter-multiple-select";
import SavingBlock from "./saving-block";

const FilterDropdownForm = ({
  filterform,
  financingType,
  industries,
  subIndustries,
  provinces,
  services,
  companySize,
  annualRevenue,
  applyFilter,
  resetFilter,
  TagsCollapse,
  handleClose,
  setSelectedIndustries,
}: FiltersDropdownProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { statusOptions, filterOptions } = useGetOptions();

  const [needSaving, setNeedSaving] = useState(false);
  const handleSaveContainer = () => setNeedSaving(false);

  const debouncedApplyFilter = useCallback(debounce(applyFilter, 300), [
    applyFilter,
  ]);

  return (
    <Div flex="column" gap={8}>
      <Tablet direction="up">
        <Div
          flex="row"
          gap={8}
          justify="space-between"
          align="center"
          style={{ padding: "12px 32px" }}
          borderColor={theme.colors.grey_4}
          bordersSide="bottom"
          backgroundColor={theme.colors.white_1}
        >
          <Typography elementTheme="subtitle2">
            {t(`grants|fundingExplorerFilter.advancedFilters`)}
          </Typography>{" "}
          <Div flex="row" gap={8} fitContent>
            <Button
              onClick={resetFilter}
              size="small"
              style={{ borderRadius: 4, minWidth: 0 }}
              defaultStyle={theme.colors.white_1}
            >
              {t(`grants|fundingExplorerFilter.resetFilter`)}
            </Button>
            <Button
              onClick={handleClose}
              size="small"
              style={{ borderRadius: 4, minWidth: 0 }}
              defaultStyle={theme.colors.primary}
            >
              {t(`grants|fundingExplorerFilter.closeFilters`)}
            </Button>
          </Div>
        </Div>
      </Tablet>
      <div style={{ paddingTop: 12 }}>
        <FiltersGrid
          xl={3}
          lg={3}
          md={3}
          sm={1}
          xs={1}
          gutter={12}
          style={{
            padding: "12px 24px",

            paddingTop: 0,
          }}
          $needSaving={needSaving}
        >
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={filterOptions}
            label={t(`grants|fundingExplorerFilter.filterBy`)}
            name="filterBy"
            placeholder={t(`grants|fundingExplorerFilter.filterByPlaceholder`)}
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={industries}
            label={t(`grants|fundingExplorerFilter.industry`)}
            name="industry"
            onChange={setSelectedIndustries}
            isMultiple
            placeholder={t(`grants|fundingExplorerFilter.industryPlaceholder`)}
          />
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={subIndustries}
            label={t(`grants|fundingExplorerFilter.industrySubsector`)}
            name="subindustry"
            isMultiple
            placeholder={t(
              `grants|fundingExplorerFilter.industrySubsectorPlaceholder`
            )}
            notFoundContent={
              <Div
                flex="column"
                align="center"
                justify="center"
                gap={8}
                style={{ padding: "8px" }}
              >
                <CancelProject height={24} width={24} />
                <Typography elementTheme="body1" bold textAlign="center">
                  {t(`grants|fundingExplorerFilter.notIndustrySelected`)}
                </Typography>
              </Div>
            }
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={financingType}
            label={t(`grants|fundingExplorerFilter.financingType`)}
            name="financingType"
            isMultiple
            placeholder={t(
              `grants|fundingExplorerFilter.financingTypePlaceholder`
            )}
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={services}
            label={t(`grants|fundingExplorerFilter.services`)}
            name="service"
            isMultiple
            placeholder={t(`grants|fundingExplorerFilter.servicesPlaceholder`)}
          />

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={statusOptions}
            label={t(`grants|fundingExplorerFilter.status`)}
            name="status"
            isMultiple
            isStatusTag
            placeholder={t(`grants|fundingExplorerFilter.statusPlaceholder`)}
          />
          <Suspense fallback={<Loading />}>
            <FormItem
              name="closingDate"
              label={t(`grants|fundingExplorerFilter.closingDate`)}
              getValueFromEvent={(onChange) =>
                onChange ? dayjs(onChange) : ""
              }
              getValueProps={(value) => ({
                value: value ? dayjs(value) : null,
              })}
              style={{ width: "100%", margin: 0 }}
              layout="vertical"
            >
              <DatePicker
                size="large"
                picker="date"
                allowClear
                placeholder={t(
                  `grants|fundingExplorerFilter.closingDatePlaceholder`
                )}
                style={{ width: "100%", height: "32px" }}
                onChange={debouncedApplyFilter}
              />
            </FormItem>
          </Suspense>

          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={provinces}
            label={t(`grants|fundingExplorerFilter.region`)}
            name="region"
            isMultiple
            placeholder={t(`grants|fundingExplorerFilter.regionPlaceholder`)}
          />
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={annualRevenue}
            label={t(`grants|fundingExplorerFilter.annualRevenue`)}
            name="annualRevenue"
            isMultiple={false}
            placeholder={t(
              `grants|fundingExplorerFilter.annualRevenuePlaceholder`
            )}
          />
          <FilterMultipleSelect
            filterform={filterform}
            applyFilter={debouncedApplyFilter}
            options={companySize}
            label={t(`grants|fundingExplorerFilter.companySize`)}
            name="companySize"
            isMultiple={false}
            placeholder={t(
              `grants|fundingExplorerFilter.companySizePlaceholder`
            )}
          />
          {needSaving && <SavingBlock handleClose={handleSaveContainer} />}
        </FiltersGrid>{" "}
      </div>
      {TagsCollapse && (
        <Div
          flex="row"
          style={{
            color: theme.colors.grey_2,
            margin: 0,
            width: "100%",
          }}
          justify="space-between"
        >
          <TagsCollapse />
        </Div>
      )}
    </Div>
  );
};

export default FilterDropdownForm;

