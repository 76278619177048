import { CSSProperties } from "react";
import { AppType } from "../../../features/entities";
import { useTheme } from "../../../plugins/styled";
import Div from "../../common/div";
import { Burger } from "./styles";

type BurgerProps = {
  className?: string;
  onClick: () => void;
  app?: AppType;
  isOpen?: boolean;
};

const NavigationBurger = ({ className, onClick, app, isOpen }: BurgerProps) => {
  const theme = useTheme();

  const barStyle: CSSProperties = {
    width: 26,
    height: 2,
  };
  return (
    <Burger
      className={`hd-navigation-burger ${className}`}
      onClick={onClick}
      $app={app}
      $isOpen={isOpen}
    >
      <Div
        className="bar1"
        style={barStyle}
        backgroundColor={theme.colors.grey_3}
        borderRadius={12}
      >
        {""}
      </Div>
      <Div
        className="bar2"
        style={barStyle}
        backgroundColor={theme.colors.grey_3}
        borderRadius={12}
      >
        {""}
      </Div>
      <Div
        className="bar3"
        style={barStyle}
        backgroundColor={theme.colors.grey_3}
        borderRadius={12}
      >
        {""}
      </Div>
    </Burger>
  );
};

export default NavigationBurger;

