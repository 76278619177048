import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/src/components/forms/utils/company-infos";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Form from "antd/es/form";
import Input from "antd/es/input/Input";
import Select from "antd/es/select";
import dayjs from "dayjs";
import { ReactNode, Suspense, lazy, useEffect, useState } from "react";
import { Company } from "../../../../features/entities/core-entities";
import { ProfileModalProps } from "../../../../features/entities/profile-entities";
import { Industry } from "../../../../features/entities/tags-entities";
import Language from "../../../../features/enums/language";
import SingleLanguage from "../../../../features/enums/single-language";
import prefixHTTPS from "../../../../features/helpers/prefix-https";
import { useTranslation } from "../../../../plugins/i18n";
import Collapse, { CollapseActiveKeysType } from "../../../common/Collapse";
import ContainerFullWidthHeader from "../../../common/container/container-header";
import Div from "../../../common/div";
import { Tablet } from "../../../common/Media";
import Typography from "../../../common/typography";
import ProfileLogo from "../../../provider/profile/profile-assets/profile-logo";
import AddressAutocompleteInput from "../../form-groups/adress-autocomplete-input";
import FormItem from "../../form-layouts/form-item";
import FormLabel from "../../form-layouts/form-label";
import validateWebsite from "../../utils/validate-website";
import ProfileFormFooter from "./profile-form-footer";
import { ProfileFormContainer, ProfileFormRow } from "./styles";
const DatePicker = lazy(() => import("antd/es/date-picker"));
type ProfileCompanyFormProps = {
  company: Company;
  handleSave: (values: Company) => void;
  isPartner?: boolean;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  industrySectors: { label: string; value: string }[];
  industrySubsectors: Industry[];
};

const ProfileCompanyForm = ({
  company,
  handleSave,

  isPartner,
  toggleEditModal,
  industrySectors,
  industrySubsectors,
}: ProfileCompanyFormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<Company>();

  const [externalIsOpen, setExternalOpen] = useState<CollapseActiveKeysType>([
    "external_links",
  ]);
  const [filteredSubsectors, setFilteredSubsectors] = useState<
    { value: string; label: string }[]
  >([]);

  const languageOptionsProfile: {
    value: string;
    label: string | ReactNode;
  }[] = [
    {
      value: SingleLanguage.French,
      label: t(`language.${SingleLanguage.French}`),
    },
    {
      value: SingleLanguage.English,
      label: t(`language.${SingleLanguage.English}`),
    },
  ];

  const languageOptionsProject: {
    value: string;
    label: string | ReactNode;
  }[] = [
    {
      value: SingleLanguage.French,
      label: t(`language.${SingleLanguage.French}`),
    },
    {
      value: SingleLanguage.English,
      label: t(`language.${SingleLanguage.English}`),
    },
    {
      value: Language.Any,
      label: t(`language.any`),
    },
  ];

  useEffect(() => {
    setFilteredSubsectors(
      industrySubsectors
        .filter((item: Industry) =>
          form
            .getFieldValue("industry_sector")
            ?.some((sector) => sector === item.parent)
        )
        .map((item) => {
          return {
            label: `${item.id} - ${item.class_title}`,
            value: item.id,
          };
        })
        .sort((a, b) => a.value.localeCompare(b.value))
    );
  }, [industrySectors]);

  const handleChange = () => {
    setFilteredSubsectors(
      industrySubsectors
        .filter((item: Industry) =>
          form
            .getFieldValue("industry_sector")
            ?.some((sector) => sector === item.parent)
        )
        .map((item) => {
          return {
            label: `${item.id} - ${item.class_title}`,
            value: item.id,
          };
        })
        .sort((a, b) => a.value.localeCompare(b.value))
    );
  };

  const handleReset = () => {
    form.resetFields();
    form.setFieldsValue(company);
  };

  useEffect(() => {
    handleReset();
  }, [company]);

  const CompanyContactDetails = () => {
    return (
      <Div>
        <Typography.Title
          level={2}
          elementTheme="h6"
          fitContent
          nowrap
          style={{ paddingBottom: 32, paddingTop: 24 }}
        >
          {t("profile|profileForm.company.labels.companyContactDetails")}
        </Typography.Title>
        <ProfileFormRow>
          <FormItem
            name="email"
            rules={[
              {
                required: true,
                message: t("profile|profileForm.company.validation.email"),
              },
            ]}
            label={
              <FormLabel
                label={t("profile|profileForm.company.labels.email")}
                extra={t("profile|profileForm.company.extra.email")}
              />
            }
            style={{ flex: 2 }}
          >
            <Input
              size={"large"}
              placeholder={t("profile|profileForm.company.placeholder.email")}
            />
          </FormItem>
          <FormItem
            name="phone"
            label={
              <FormLabel
                label={t("profile|profileForm.company.labels.phone")}
                extra={t("profile|profileForm.company.extra.phone")}
              />
            }
            style={{ flex: 1 }}
          >
            <Input
              size={"large"}
              placeholder={t("profile|profileForm.company.placeholder.phone")}
            />
          </FormItem>
          {!isPartner && <LanguageInputs />}
        </ProfileFormRow>
        {isPartner && <LanguagesRow />}
      </Div>
    );
  };

  const ExternalLinks = () => {
    const SocialItem = ({
      label,
      name,
      placeholder,
    }: {
      label: string;
      name: string;
      placeholder: string;
    }) => {
      return (
        <FormItem
          label={<FormLabel label={label} />}
          name={name}
          normalize={(value) => {
            const valueWithHttps = prefixHTTPS(value);
            return value.length && !"https://".includes(valueWithHttps)
              ? valueWithHttps
              : "";
          }}
          rules={[
            {
              validator: validateWebsite({
                message: t(
                  "profile|profileForm.company.validation.invalid_website"
                ),
                isRequired: false,
              }),
            },
          ]}
        >
          <Input allowClear placeholder={placeholder} />
        </FormItem>
      );
    };
    return (
      <Collapse
        style={{ marginTop: 32 }}
        ghost
        noPadding
        activeKey={externalIsOpen}
        onChange={setExternalOpen}
        ExpandIcon={({ isActive }) => <TabArrow down={!isActive} />}
        items={[
          {
            title: (
              <Typography.Title level={2} elementTheme="h6" fitContent nowrap>
                {t("profile|profileForm.company.labels.externalLinks")}
              </Typography.Title>
            ),
            children: (
              <Div flex="column" gap={12} style={{ marginTop: 24 }}>
                <SocialItem
                  label="Facebook"
                  name="facebook"
                  placeholder="https://www.facebook.com/match.hellodarwin"
                />
                <SocialItem
                  label="Twitter"
                  name="twitter"
                  placeholder="https://twitter.com/say_hellodarwin"
                />
                <SocialItem
                  label="Linkedin"
                  name="linked_in"
                  placeholder="https://www.linkedin.com/company/hellodarwin/"
                />
                <SocialItem
                  label="Instagram"
                  name="instagram"
                  placeholder="https://www.instagram.com/hellodarwin.match/"
                />
                <SocialItem
                  label="Pinterest"
                  name="pinterest"
                  placeholder="https://www.pinterest.com/hellodarwin.match"
                />
                <SocialItem
                  label="Tiktok"
                  name="tiktok"
                  placeholder="https://www.tiktok.com/@hellodarwin.match"
                />
                <SocialItem
                  label="Youtube"
                  name="youtube"
                  placeholder="https://www.youtube.com/@hellodarwin8652"
                />
              </Div>
            ),
            id: "external_links",
          },
        ]}
      />
    );
  };

  const Row1 = () => {
    return (
      <ProfileFormRow>
        <div>
          <ProfileLogo
            name={company.name}
            logo={company.logo}
            isEditable
            toggleEditModal={toggleEditModal}
            style={{ marginRight: 10 }}
          />
        </div>
        <FormItem
          style={{ flex: 3 }}
          name="name"
          rules={[
            {
              required: true,
              message: t("profile|profileForm.company.validation.name"),
            },
          ]}
          label={
            <FormLabel label={t("profile|profileForm.company.labels.name")} />
          }
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.company.placeholder.name")}
          />
        </FormItem>
        <FormItem
          style={{ flex: 2 }}
          name="website"
          rules={[
            {
              required: true,
              validator: validateWebsite({
                message: t("profile|profileForm.company.validation.website"),
                isRequired: true,
              }),
              message: t("profile|profileForm.company.validation.website"),
            },
          ]}
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.website")}
            />
          }
          normalize={(value) => prefixHTTPS(value)}
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.company.placeholder.website")}
            allowClear
          />
        </FormItem>
      </ProfileFormRow>
    );
  };
  const RowTagline = () => {
    return (
      <ProfileFormRow>
        <FormItem
          style={{ flex: 3 }}
          name="tagline"
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.tagline")}
            />
          }
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.company.placeholder.tagline")}
          />
        </FormItem>
      </ProfileFormRow>
    );
  };

  const Row2 = () => {
    return (
      <ProfileFormRow>
        <FormItem
          style={{ flex: 1 }}
          name="size"
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.size")}
              extra={" "}
            />
          }
        >
          <Select placeholder="Select option" size="large">
            {companySize.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.annualRevenue")}
              extra={" "}
            />
          }
          name="annual_revenue"
          style={{ flex: 1 }}
        >
          <Select
            placeholder={t(
              "profile|profileForm.company.placeholder.annualRevenue"
            )}
            size="large"
          >
            {annualRevenue.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <Suspense fallback={<></>}>
          <FormItem
            name="founded_at"
            label={
              <FormLabel
                label={t("profile|profileForm.company.labels.foundedAt")}
                extra={"    "}
              />
            }
            style={{ flex: 1 }}
            getValueFromEvent={(onChange) => (onChange ? dayjs(onChange) : "")}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : null,
            })}
          >
            <DatePicker
              size="large"
              picker="year"
              allowClear
              style={{ width: "100%" }}
            />
          </FormItem>
        </Suspense>
      </ProfileFormRow>
    );
  };

  const LanguageInputs = () => {
    return (
      <>
        <FormItem
          style={{ flex: 1 }}
          name="profile_language"
          rules={[
            {
              required: true,
              message: t(
                "profile|profileForm.company.validation.profileLanguage"
              ),
            },
          ]}
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.profileLanguage")}
              extra={t("profile|profileForm.company.extra.profileLanguage")}
            />
          }
        >
          <Select
            options={languageOptionsProfile}
            size="large"
            placeholder={t(
              "profile|profileForm.company.labels.profileLanguage"
            )}
          />
        </FormItem>
        {isPartner && (
          <FormItem
            style={{ flex: 1 }}
            name="project_language"
            rules={[
              {
                required: true,
                message: t(
                  "profile|profileForm.company.validation.projectLanguage"
                ),
              },
            ]}
            label={
              <FormLabel
                label={t("profile|profileForm.company.labels.projectLanguage")}
                extra={t("profile|profileForm.company.extra.projectLanguage")}
              />
            }
          >
            <Select
              options={languageOptionsProject}
              size="large"
              placeholder={t(
                "profile|profileForm.company.labels.projectLanguage"
              )}
            />
          </FormItem>
        )}
      </>
    );
  };

  const LanguagesRow = () => {
    return (
      <ProfileFormRow>
        <LanguageInputs />
      </ProfileFormRow>
    );
  };

  const Row3 = () => {
    return (
      <ProfileFormRow>
        <FormItem
          style={{ flex: 1 }}
          name="industry_sector"
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.industrySector")}
            />
          }
        >
          <Select
            mode="multiple"
            options={industrySectors}
            onChange={handleChange}
          />
        </FormItem>

        <FormItem
          name="industry_subsector"
          style={{ flex: 1 }}
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.industrySubsector")}
            />
          }
        >
          <Select
            mode="multiple"
            options={filteredSubsectors}
            notFoundContent={t(
              "profile|profileForm.company.extra.industrySubsector"
            )}
          />
        </FormItem>
      </ProfileFormRow>
    );
  };

  const handleFinish = () => {
    form?.submit();
  };

  return (
    <Div flex="column" gap={32}>
      <ContainerFullWidthHeader>
        <Div flex="row" gap={32} align="center" justify="space-between">
          <Typography.Title level={2} elementTheme="h6">
            {t("profile|profileForm.company.title")}
          </Typography.Title>

          <Tablet direction="up">
            <ProfileFormFooter
              handleFinish={handleFinish}
              handleReset={handleReset}
              form={form}
              isSmall
            />
          </Tablet>
        </Div>{" "}
      </ContainerFullWidthHeader>

      <Form
        form={form}
        layout={"vertical"}
        name={"cancel-project"}
        initialValues={{ company, isCanada: company.country === "Canada" }}
        onFinish={handleSave}
      >
        <ProfileFormContainer>
          <Row1 />
          {isPartner && <RowTagline />}
          <Row2 />

          <Div flex="column" gap={8}>
            <AddressAutocompleteInput
              form={form}
              showManualAddress
              provinceRequired
            />
          </Div>
          <Row3 />
          <CompanyContactDetails />
          <ExternalLinks />
        </ProfileFormContainer>
      </Form>
      <Tablet direction="down">
        <ProfileFormFooter
          handleFinish={handleFinish}
          handleReset={handleReset}
          form={form}
          isSmall
        />
      </Tablet>
    </Div>
  );
};
export default ProfileCompanyForm;

