import styled from "../../../../plugins/styled";

export const ProfileFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ProfileFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 56px;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    & > div {
      flex: auto !important;
      width: 100%;
    }
  }
`;

