import { IconProps } from "../features/entities/general";

const NewWindow = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        style={style}
        className={className}
        onClick={onClick}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1238_2102)">
          <path
            d="M16.7704 6.51851H5.33333C4.39033 6.51851 3.48597 6.89311 2.81917 7.55991C2.15237 8.22671 1.77777 9.13108 1.77777 10.0741V26.6667C1.77777 27.6097 2.15237 28.514 2.81917 29.1808C3.48597 29.8476 4.39033 30.2222 5.33333 30.2222H21.9259C22.8689 30.2222 23.7733 29.8476 24.4401 29.1808C25.1069 28.514 25.4815 27.6097 25.4815 26.6667V15.2296M8.88888 23.1111L30.2222 1.77777M30.2222 1.77777H21.9259M30.2222 1.77777V10.0741"
            stroke="currentColor"
            strokeWidth="1.8"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1238_2102">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default NewWindow;
