import { CSSProperties, PropsWithChildren, useMemo } from "react";
import useMediaQuery from "../../../features/helpers/use-media-query";
import { useTheme } from "../../../plugins/styled";
import { DefaultTheme } from "../../../theme";
import { DivContainer, DivContainerProps } from "./styles";

export interface GeneralDivProps {
  onClick?: (...args: any) => void;
  onDoubleClick?: (...args: any) => void;
  onMouseEnter?: (...args: any) => void;
  id?: string;
  className?: string;
}

export interface ViewDivProps {
  flex?: "column" | "row" | "column-reverse" | "row-reverse";
  justify?: CSSProperties["justifyContent"];
  align?: CSSProperties["alignItems"];
  justifySelf?: CSSProperties["justifySelf"];
  alignSelf?: CSSProperties["alignSelf"];
  fitContent?: boolean;
  fitMaxContent?: boolean;
  fitMinContent?: boolean;
  fullHeight?: boolean;
  wrap?: CSSProperties["flexWrap"];
  hidden?: boolean;
  backgroundColor?: string;
  backgroundOpacity?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  backgroundOpacityHover?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  borderColor?: string;
  borderColorHover?: string;
  bordersSide?:
    | "all"
    | "vertical"
    | "horizontal"
    | "left"
    | "right"
    | "top"
    | "bottom";
  borderRadius?: CSSProperties["borderRadius"];
  gap?: CSSProperties["gap"];
  overflow?: CSSProperties["overflow"];
  position?: CSSProperties["position"];
  style?: CSSProperties;
}

export type DivProps = GeneralDivProps &
  ViewDivProps & {
    tablet?: ViewDivProps;
    xl?: ViewDivProps;
    xxl?: ViewDivProps;
  } & PropsWithChildren;

const Div = (props: DivProps) => {
  const { children, onClick, onDoubleClick, onMouseEnter, id, className } =
    props;

  const theme = useTheme() as DefaultTheme;

  const isXL = useMediaQuery({ minWidth: theme.breakpoints.xl });
  const isL = useMediaQuery({ maxWidth: theme.breakpoints.l });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const { currentProps, currentStyle } = useMemo(() => {
    const currentProps: DivContainerProps = {};
    const currentStyle: CSSProperties = {};

    const addProp = (property, value) => {
      if (value !== undefined) {
        currentProps[property] = value;
      }
    };

    const addStyling = (property, value) => {
      if (value !== undefined) {
        currentStyle[property] = value;
      }
    };

    const prepareStyling = (data: ViewDivProps) => {
      if (data) {
        const { gap, flex, style, justify, align, wrap, ...rest } = data;

        if (gap !== undefined) {
          addStyling("gap", `${gap}px`);
        }
        if (flex !== undefined) {
          addStyling("display", "flex");
          addStyling("flexDirection", flex);
        }
        if (justify !== undefined) {
          addStyling("justifyContent", justify);
        }
        if (align !== undefined) {
          addStyling("alignItems", align);
        }
        if (wrap !== undefined) {
          addStyling("flexWrap", wrap);
        }

        Object.entries(rest).forEach(([key, value]) => {
          addStyling(key, value);
        });

        if (style) {
          Object.entries(style).forEach(([key, value]) => {
            addStyling(key, value);
          });
        }
      }
    };

    const prepareProps = (data: ViewDivProps) => {
      if (data) {
        const {
          backgroundOpacity,
          backgroundOpacityHover,
          borderColor,
          borderColorHover,
          hidden,
          fitContent,
          fitMaxContent,
          fitMinContent,
          fullHeight,
          bordersSide,
          ...rest
        } = data;
        prepareStyling(rest);

        const propMappings = {
          $backgroundOpacity: backgroundOpacity,
          $backgroundOpacityHover: backgroundOpacityHover,
          $borderColor: borderColor,
          $borderColorHover: borderColorHover,
          $hidden: hidden,
          $fitContent: fitContent,
          $minContent: fitMinContent,
          $maxContent: fitMaxContent,
          $fullHeight: fullHeight,
          $borderSide: bordersSide,
        };

        Object.entries(propMappings).forEach(([key, value]) => {
          addProp(key, value);
        });
      }
    };

    prepareProps(props);
    if (isXL) prepareProps(props.xxl);
    if (isL) prepareProps(props.xl);
    if (isTablet) prepareProps(props.tablet);

    return { currentProps, currentStyle };
  }, [isTablet, isXL, isL, props]);

  return (
    <DivContainer
      $backgroundColor={currentProps?.$backgroundColor}
      $backgroundOpacity={currentProps?.$backgroundOpacity}
      $backgroundOpacityHover={currentProps?.$backgroundOpacityHover}
      $flexDirection={currentProps?.$flexDirection}
      $borderColor={currentProps?.$borderColor}
      $borderColorHover={currentProps?.$borderColorHover}
      $hidden={currentProps?.$hidden}
      $fitContent={currentProps?.$fitContent}
      $minContent={currentProps?.$minContent}
      $maxContent={currentProps?.$maxContent}
      $fullHeight={currentProps?.$fullHeight}
      $borderSide={currentProps?.$borderSide}
      className={className}
      style={currentStyle}
      id={id}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </DivContainer>
  );
};

export default Div;

