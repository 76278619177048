import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import PreviewCard from "@hellodarwin/core/lib/components/common/layout/preview-card";
import PreviewRowLabels from "@hellodarwin/core/lib/components/common/layout/preview-card/PreviewRowLabels";
import RowSelector from "@hellodarwin/core/lib/components/common/row-selector";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Project } from "@hellodarwin/core/lib/features/entities";
import { filterProjects } from "@hellodarwin/core/lib/features/helpers";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import ConfigProvider from "antd/es/config-provider";
import Empty from "antd/es/empty";
import List from "antd/es/list";
import Select from "antd/es/select";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  fetchProjects,
  selectProjects,
  selectProjectsLoading,
} from "../../features/api/slices/projects-slice";
import { useClientApi } from "../../features/api/use-client-api";
import getProjectPreviewProps from "../../features/utils/get-project-preview-props";

type SortType = "recent" | "status";
const SortBySelect = ({
  setSortType,
  sortType,
}: {
  sortType: SortType;
  setSortType: Dispatch<SetStateAction<SortType>>;
}) => {
  const { t } = useTranslation();
  const SortOptions: {
    value: string;
    label: string | ReactNode;
  }[] = [
    {
      value: "status",
      label: t("project|filters.sortBy.options.status"),
    },
    {
      value: "recent",
      label: t("project|filters.sortBy.options.recent"),
    },
  ];

  return (
    <div>
      <Typography elementTheme="body2" style={{ marginBottom: 8 }}>
        {t("project|filters.sortBy.title")}
      </Typography>
      <Select
        options={SortOptions}
        onChange={(value: SortType) => setSortType(value)}
        placeholder={t("project|filters.sortBy.title")}
        value={sortType}
        style={{ width: 221 }}
        suffixIcon={
          <TabArrow width={10} height={10} down style={{ marginRight: 10 }} />
        }
      />
    </div>
  );
};

type OrderByType = "ascending" | "descending";

const OrderBySelect = ({
  setOrderBy,
  orderBy,
}: {
  orderBy: OrderByType;
  setOrderBy: Dispatch<SetStateAction<OrderByType>>;
}) => {
  const { t } = useTranslation();

  const orderOptions: {
    value: string;
    label: string | ReactNode;
  }[] = [
    {
      value: "ascending",
      label: t("project|filters.orderBy.options.ascending"),
    },
    {
      value: "descending",
      label: t("project|filters.orderBy.options.descending"),
    },
  ];

  return (
    <div>
      <Typography elementTheme="body2" style={{ marginBottom: 8 }}>
        {t("project|filters.orderBy.title")}
      </Typography>
      <Select
        options={orderOptions}
        onChange={(value: OrderByType) => setOrderBy(value)}
        placeholder={t("project|filters.orderBy.title")}
        value={orderBy}
        style={{
          width: 221,
        }}
        suffixIcon={
          <TabArrow width={10} height={10} down style={{ marginRight: 10 }} />
        }
      />
    </div>
  );
};

const ClientProjectsPage = () => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectProjectsLoading);

  const isXl = useMediaQuery({ maxWidth: theme.breakpoints.xl });

  const [sortType, setSortType] = useState<SortType>("recent");
  const [orderBy, setOrderBy] = useState<OrderByType>("descending");

  const [isRow, setIsRow] = useState(true);

  const projects = useAppSelector(selectProjects);
  const [filteredProjects, setProjects] = useState<Project[]>();

  useEffect(() => {
    dispatch(fetchProjects({ api, limit: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProjects([...filterProjects(projects, sortType, orderBy)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  useEffect(() => {
    setProjects([...filterProjects(projects, sortType, orderBy)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    let filtered = projects;
    switch (orderBy) {
      case "ascending":
        filtered = filtered.reverse();
        break;
      case "descending":
        filtered = filtered.reverse();
        break;
    }

    setProjects([...filtered]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  useEffect(() => {
    if (isXl) {
      setIsRow(false);
    }
  }, [isXl]);

  const renderEmpty = () => <Empty description={t("project|noProjects.all")} />;

  return (
    <PageLayout
      app="client"
      title={t("project|yourProjects")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("project|yourProjects"),
        },
      ]}
    >
      <Container>
        <Div flex="column" gap={24}>
          <Div
            flex={"row"}
            justify="space-between"
            align="flex-end"
            gap={16}
            tablet={{
              flex: "column",
              style: { width: "100%", alignItems: "stretch" },
            }}
          >
            <Div
              flex="row"
              gap={19}
              fitContent
              align="center"
              tablet={{ flex: "column", gap: 12 }}
            >
              <SortBySelect setSortType={setSortType} sortType={sortType} />
              <OrderBySelect setOrderBy={setOrderBy} orderBy={orderBy} />
            </Div>

            {!isXl && <RowSelector isRow={isRow} setIsRow={setIsRow} />}
          </Div>
          <ConfigProvider renderEmpty={renderEmpty}>
            <List
              grid={
                isRow
                  ? { gutter: 16, column: 1 }
                  : { xs: 1, sm: 1, md: 3, lg: 3, xxl: 4, xl: 3, gutter: 16 }
              }
              pagination={{
                align: "center",
                showSizeChanger: true,
                hideOnSinglePage: true,
              }}
              header={
                isRow ? (
                  <PreviewRowLabels
                    withAction
                    infosLabel={[
                      t("project|rowLabels.project"),
                      t("project|rowLabels.status"),
                      t("project|rowLabels.date"),
                      t("project|rowLabels.progress"),
                    ]}
                  />
                ) : undefined
              }
              dataSource={filteredProjects}
              renderItem={(project) => {
                const previewProps = getProjectPreviewProps(
                  project,
                  selectedLocale,
                  t,
                  theme
                );
                return (
                  <List.Item>
                    <PreviewCard {...previewProps} isRow={isRow} />
                  </List.Item>
                );
              }}
              itemLayout="horizontal"
              bordered={false}
              loading={loading}
            />
          </ConfigProvider>
        </Div>
      </Container>
    </PageLayout>
  );
};

export default ClientProjectsPage;

