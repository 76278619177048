import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { annualRevenue } from "@hellodarwin/core/src/components/forms/utils/company-infos";
import Input from "antd/es/input";
import Select from "antd/es/select";
interface OnboardingCompanyDetailsFormFieldsProps {
  isLoading?: boolean;
}
const OnboardingCompanyDetailsFormFields = ({
  isLoading,
}: OnboardingCompanyDetailsFormFieldsProps) => {
  const { t } = useTranslation();

  return (
    <Container
      style={{
        minHeight: 350,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 60,
        paddingBottom: 60,
      }}
    >
      <Div flex="column" gap={24}>
        <Div
          flex="column"
          gap={12}
          style={{ width: "100%", marginBottom: 42 }}
          align="center"
        >
          <Div flex="column" gap={12} style={{ maxWidth: "60%" }}>
            <Typography.Title
              noMargin
              level={2}
              elementTheme="h4"
              medium
              textAlign="center"
            >
              {t("onboarding|profile.companyName")}
            </Typography.Title>
            <Typography color={theme.colors.grey_2} textAlign="center">
              {t("onboarding|profile.companyNameDescription")}
            </Typography>
          </Div>
          <FormItem
            name="company_name"
            label={t("profile|profileForm.company.labels.name")}
            rules={[
              {
                required: true,
                message: t("profile|profileForm.company.validation.name"),
              },
            ]}
            noStyle
            style={{ flex: 1 }}
          >
            <Input
              size={"large"}
              placeholder={t("profile|profileForm.company.placeholder.name")}
              disabled={isLoading}
              style={{
                minHeight: 60,
                marginTop: 20,
                textAlign: "center",
                fontSize: 32,
                width: "80%",
                margin: "auto",
              }}
            />
          </FormItem>
        </Div>
        <Div flex="column" align="center" gap={12} style={{ width: "100%" }}>
          <Div
            flex="column"
            align="flex-start"
            gap={12}
            style={{ width: "80%" }}
          >
            <Typography.Title
              noMargin
              level={1}
              elementTheme="h6"
              medium
              textAlign="center"
            >
              {t("onboarding|profile.annualRevenue")}
            </Typography.Title>

            <FormItem
              name="annual_revenue"
              rules={[
                {
                  required: true,
                  message: t(
                    "profile|profileForm.company.validation.annualRevenue"
                  ),
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                placeholder={t(
                  "profile|profileForm.company.placeholder.annualRevenue"
                )}
                style={{
                  minHeight: 20,
                  marginTop: 20,
                  textAlign: "center",
                  fontSize: 32,
                  width: "100%",
                  margin: "auto",
                }}
                size="large"
                disabled={isLoading}
              >
                {annualRevenue.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Div>
        </Div>
      </Div>
    </Container>
  );
};

export default OnboardingCompanyDetailsFormFields;

