import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import HdProgression from "../../../common/hd-progression";
import Typography from "../../../common/typography";

type ProgressPreviewSize = "large" | "medium" | "small";
interface ProgressPreviewProps {
  progress: number;
  title: string;
  size?: ProgressPreviewSize;
}

const ProgressPreview = ({
  progress,
  title,
  size = "medium",
}: ProgressPreviewProps) => {
  const theme = useTheme();

  const width = size === "large" ? 200 : size === "small" ? 120 : 166;
  return (
    <Div flex="column" gap={16} align="center" className="progress-preview">
      <Typography elementTheme="h6" style={{ width: "100%" }}>
        {title}
      </Typography>

      <HdProgression
        value={progress}
        size={width}
        barSize={16}
        outerBarSize={18}
        progressBarColor={theme.colors.white_1}
        progressColor={theme.colors.green_1}
        outerColor={theme.colors.green_4}
      />
    </Div>
  );
};
export default ProgressPreview;
